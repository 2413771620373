import { ScrollTableBehavior } from '@swivel-finance/ui/behaviors/scroll-table';
import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { SelectConfig, SELECT_CONFIG_MENU_RADIO } from '@swivel-finance/ui/elements/select';
import { html, LitElement, nothing, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { SECONDS_PER_DAY, TOOLTIPS } from '../../constants';
import { emptyOrZero, inferPrice, inferTokenType, inferYieldType, locked } from '../../helpers';
import { config } from '../../services';
import { amount, amountLabel, balance, BALANCE_DECIMALS, errorMessage, price, status, tokenBalance, transaction, unit } from '../../shared/templates';
import { services } from '../../state';
import { UserOrdersFilter, UserOrdersView, USER_ORDERS_FILTERS } from '../../state/user-orders';
import { OrderWithMeta } from '../../types';
import { makeTradeHistory, orderFillPercentage, orderStatusLabel, orderTypeLabel, SyntheticOrder } from './helpers';

type UserOrdersState = typeof services.userOrders.state;

/**
 * The amount of orders per page to show in the 'orders' view
 */
const ORDERS_PER_PAGE = 25;

/**
 * The max amount of weeks we currently allow users to go back through their trade history.
 *
 * @remarks
 * The trade history is not searchable or exportable, so showing a huge history at this point
 * doesn't provide too much benefit. By keeping it smaller our naive pagination will do until
 * we decide to update/improve this feature.
 */
const MAX_FILLS_WEEKS = 12;

/**
 * A `SelectConfig` for the orders status filter.
 *
 * @remarks
 * We want a radio menu, but the width should not be restricted to the origin, as our trigger
 * is relatively small and would otherwise force ellipsis on the list items.
 */
const ORDERS_FILTER_CONFIG: SelectConfig = {
    ...SELECT_CONFIG_MENU_RADIO,
    position: {
        ...SELECT_CONFIG_MENU_RADIO.position,
        width: undefined,
    },
};

const titleTemplate = function (this: UserOrdersElement) {

    const state = this.userOrdersService.state;
    const { orders, view } = state.context;

    // show loader only, if no loader is shown in the status region
    const showLoader = state.matches('fetching') && orders?.length;

    return html`
    <div class="title">
        <div role="tablist">
            <button role="tab"
                aria-controls="user-orders-panel"
                aria-selected="${ view === 'orders' }"
                aria-describedby="orders-tab-tooltip"
                @click=${ () => this.setView('orders') }>
                Orders
                <ui-icon name="question"></ui-icon>
                <ui-tooltip id="orders-tab-tooltip">${ TOOLTIPS.PROFESSIONAL.ORDERS.ORDERS_TAB }</ui-tooltip>
            </button>
            <button role="tab"
                aria-controls="user-orders-panel"
                aria-selected="${ view === 'fills' }"
                aria-describedby="trades-tab-tooltip"
                @click=${ () => this.setView('fills') }>
                Trades
                <ui-icon name="question"></ui-icon>
                <ui-tooltip id="trades-tab-tooltip">${ TOOLTIPS.PROFESSIONAL.ORDERS.TRADES_TAB }</ui-tooltip>
            </button>
        </div>
        <div class="info">
            ${ showLoader ? status('loading') : nothing }
            <ui-icon name="question" aria-describedby="orders-rewards-tooltip"></ui-icon>
            <ui-tooltip id="orders-rewards-tooltip">${ TOOLTIPS.PROFESSIONAL.ORDERS.REWARDS }</ui-tooltip>
        </div>
    </div>
    `;
};

const headerTemplate = function (this: UserOrdersElement) {

    const state = this.userOrdersService.state;
    const view = state.context.view;
    const underlying = state.context.market?.tokens.underlying;

    return (view === 'orders')
        ? html`
        <div class="header">
            <span><span class="label">Type</span></span>
            <span>${ amountLabel('Principal') }</span>
            <span>${ amountLabel('Premium', underlying?.symbol) }</span>
            <span>${ amountLabel('Price', underlying?.symbol) }</span>
            <span>${ amountLabel('Filled', '%') }</span>
            <span>Status</span>
        </div>
        `
        : html`
        <div class="header">
            <span><span class="label">Type</span></span>
            <span>${ amountLabel('Principal') }</span>
            <span>${ amountLabel('Premium', underlying?.symbol) }</span>
            <span>${ amountLabel('Price', underlying?.symbol) }</span>
            <span><span class="label">Time</span></span>
        </div>
        `;
};

const statusTemplate = function (this: UserOrdersElement) {

    const state = this.userOrdersService.state;
    const { orders, view } = state.context;
    let content: TemplateResult | undefined;

    const emptyState = state.matches('initial') || state.matches('initialized') || state.matches('fetching');
    const emptyView = (view === 'orders') ? !orders?.length : !this.trades.length;

    if (emptyState && emptyView) {

        content = status(state.matches('fetching') ? 'loading' : 'initial');

    } else if (state.matches('error')) {

        content = errorMessage(state.context.error);

    } else if (emptyView) {

        content = emptyTemplate.call(this);
    }

    return content
        ? html`<div class="status">${ content }</div>`
        : nothing;
};


const badgeTemplate = (o: { exit: boolean; vault: boolean; } | string) => {

    const label = typeof o === 'string' ? o : orderTypeLabel(o);

    const className = (label === 'Unknown')
        ? ''
        : label.startsWith('Sell')
            ? 'exit'
            : 'initiate';

    return html`<span class="badge ${ className }"></span><span class="label">${ label }</span>`;
};

const directionTemplate = (i: boolean) => {

    return html`<ui-icon name="arrow" class="${ i ? 'in' : 'out' }"></ui-icon>`;
};

const emptyTemplate = function (this: UserOrdersElement) {

    const state = this.userOrdersService.state;
    const { view, ordersFilter } = state.context;
    const MARKET_LOCKED_TIME = config.cache.global.marketLockedAt;

    const ordersView = (this.locked && ordersFilter === 'open')
        ? html`<ui-icon name="lock-clock"></ui-icon>All orders expire ${ Math.round(MARKET_LOCKED_TIME / SECONDS_PER_DAY) } days before maturity.`
        : html`<ui-icon name="list"></ui-icon>Your orders will appear here.`;

    const tradesView = html`<ui-icon name="list"></ui-icon>You have no trades in this period.`;

    return html`<div class="message-box">
    ${ view === 'orders'
        ? ordersView
        : tradesView
    }
    </div>`;
};

const orderTableRowTemplate = function (this: UserOrdersElement, o: OrderWithMeta) {

    const state = this.userOrdersService.state;

    if (!state.matches('success') && !state.matches('cancelling') && !state.matches('fetching')) {

        return html``;
    }

    const { market, cancellingOrders } = state.context;
    const { underlying } = market.tokens;
    const principalToken = market.tokens[inferYieldType(o.order) === 'floating' ? 'nToken' : inferTokenType(o.order)];
    const full = emptyOrZero(o.meta.principalAvailable);
    const insolvent = o.meta.status === 'INSOLVENT_TEMPORARY';
    const cancelling = !!cancellingOrders && cancellingOrders.has(o);
    const classes = [
        'order',
        full ? 'full' : '',
        insolvent ? 'insolvent' : '',
        cancelling ? 'cancelling' : '',
    ].filter(className => className !== '').join(' ');

    return html`
    <tr class="${ classes }">
        <td class="type">${ insolvent ? html`<ui-icon name="flag" aria-describedby="order-temporary-insolvent"></ui-icon>` : nothing }${ badgeTemplate(o.order) }</td>
        <td class="principal">${ tokenBalance(o.order.principal, principalToken, this.precision) }</td>
        <td class="premium">${ balance(o.order.premium, underlying, this.precision) }</td>
        <td class="price">${ price(inferPrice(o.order, market)) }</td>
        <td class="filled">${ amount(orderFillPercentage(o), undefined, undefined, true) }</td>
        <td class="status">${ orderStatusLabel(o) }</td>
        <td class="actions">
        ${ o.meta.status === 'VALID' || o.meta.status === 'INSOLVENT_TEMPORARY' || o.meta.status === 'INSOLVENT'
            ? html`
            <button class="button-small" aria-labelledby="cancel-order-${ o.meta.sequence }" ?disabled=${ cancelling || full } @click=${ () => this.cancel(o) }><ui-icon name="times"></ui-icon></button>
            <ui-tooltip id="cancel-order-${ o.meta.sequence }">
                ${ TOOLTIPS.PROFESSIONAL.ORDERS.CANCELLING(cancelling, full) }
            </ui-tooltip>
            `
            : nothing
        }
        </td>
        </tr>
    `;
};

const orderTableTemplate = function (this: UserOrdersElement) {

    const state = this.userOrdersService.state;

    const underlying = state.context.market?.tokens.underlying;
    const filter = state.context.ordersFilter ?? 'open';
    const page = state.context.ordersPage ?? 0;
    const maxPage = Math.ceil((this.orders?.length || ORDERS_PER_PAGE) / ORDERS_PER_PAGE) - 1;
    const start = page * ORDERS_PER_PAGE;
    const end = start + ORDERS_PER_PAGE;

    return html`
    <table class="orders ${ filter }">
        <thead>
            <tr>
                <th class="type"><span class="label">Type</span></th>
                <th class="principal">${ amountLabel('Principal') }</th>
                <th class="premium">${ amountLabel('Premium', underlying?.symbol) }</th>
                <th class="price">${ amountLabel('Price', underlying?.symbol) }</th>
                <th class="filled">${ amountLabel('Filled', '%') }</th>
                <th class="status">
                    <ui-select
                        .config=${ ORDERS_FILTER_CONFIG }
                        @ui-value-changed=${ (e: ValueChangeEvent<UserOrdersFilter>) => this.setFilter(e.detail.current) }>
                        <button data-part="trigger">
                            <span class="ui-select-trigger-label">Status</span>
                            <ui-icon name="filter"></ui-icon>
                        </button>
                        <ui-listbox data-part="overlay">
                        ${ Object.keys(USER_ORDERS_FILTERS).map(status => html`
                            <ui-listitem aria-checked="${ status === filter }" .value=${ status }>${ status.toLocaleUpperCase() }</ui-listitem>`)
                        }
                        </ui-listbox>
                    </ui-select>
                </th>
                <th class="actions"></th>
            </tr>
        </thead>
        <tbody>
            ${ this.orders.slice(start, end).map(order => orderTableRowTemplate.call(this, order)) }
            ${ !this.orders.length
                ? html`
                <tr class="status">
                    <td colspan="7">
                        ${ emptyTemplate.apply(this) }
                    </td>
                </tr>
                `
                : nothing
            }
            ${ (page > 0 || page < maxPage)
                ? html`
                <tr class="footer">
                    <td colspan="7">
                        <div class="pagination">
                            ${ page !== undefined && page > 0
                                ? html`
                                <button class="previous button-small" .disabled=${ !state.matches('success') } @click=${ () => this.paginate(-1) }>
                                    Newer
                                    <ui-icon name="chevron"></ui-icon>
                                </button>
                                `
                                : nothing
                            }
                            ${ page !== undefined && page < maxPage
                                ? html`
                                <button class="next button-small" .disabled=${ !state.matches('success') } @click=${ () => this.paginate(1) }>
                                    Older
                                    <ui-icon name="chevron"></ui-icon>
                                </button>
                                `
                                : nothing
                            }
                        </div>
                    </td>
                </tr>
                `
                : nothing
            }
        </tbody>
    </table>

    ${ this.orders.some(order => order.meta.status === 'INSOLVENT_TEMPORARY')
        ? html`
        <ui-tooltip id="order-temporary-insolvent">
            ${ TOOLTIPS.PROFESSIONAL.ORDERS.INSOLVENT }
        </ui-tooltip>
        `
        : nothing
    }
    `;
};

const fillsTableRowTemplate = function (this: UserOrdersElement, o: SyntheticOrder) {

    const state = this.userOrdersService.state;

    if (!state.matches('success') && !state.matches('cancelling') && !state.matches('fetching')) {

        return html``;
    }

    const { market } = state.context;
    const underlying = state.context.market.tokens.underlying;
    const id = `order-history-fills-${ o.key }`;

    return html`
    <tr class="order" aria-controls="${ id }" aria-expanded="${ this.expanded === o.key }" @click=${ () => this.toggleTradeOrder(o) }>
        <td class="type">
            <div class="order-identifier">
                <ui-icon name="chevron"></ui-icon>${ badgeTemplate(o) }${ unit(o.type === 'limit' ? 'Limit' : 'Market') }
            </div>
        </td>
        <td class="principal">
            ${ tokenBalance(o.type === 'limit' ? o.principalFilled : o.principal, market.tokens[o.token], this.precision) }${ directionTemplate(o.received === 'principal') }
            ${ o.type === 'limit' ? html`<div class="total">${ tokenBalance(o.principal, market.tokens[o.token], this.precision) }</div>` : nothing }
        </td>
        <td class="premium">
            ${ balance(o.type === 'limit' ? o.premiumFilled : o.premium, underlying, this.precision) }${ directionTemplate(o.received === 'premium') }
            ${ o.type === 'limit' ? html`<div class="total">${ balance(o.premium, underlying, this.precision) }</div>` : nothing }
        </td>
        <td class="price">${ price(o.price) }</td>
        <td class="date">${ o.time }</td>
    </tr>
    ${
        (this.expanded === o.key)
            ? o.fills.map(fill => html`
            <tr class="fill" id="${ id }">
                <td class="type">${ fill.transactionHash ? transaction(fill.transactionHash, '') : 'Transaction n/a.' }</td>
                <td class="principal">${ tokenBalance(fill.principal, market.tokens[fill.token], this.precision) }${ directionTemplate(fill.received === 'principal') }</td>
                <td class="premium">${ balance(fill.premium, underlying, this.precision) }${ directionTemplate(fill.received === 'premium') }</td>
                <td class="price">${ price(fill.price) }</td>
                <td class="date">${ fill.time }</td>
            </tr>
            `)
            : nothing
    }
    `;
};

const fillsTableTemplate = function (this: UserOrdersElement) {

    const state = this.userOrdersService.state;
    const underlying = state.context.market?.tokens.underlying;
    const page = state.context.fillsPage;

    return html`
    <table class="fills">
        <thead>
            <tr>
                <th class="type"><span class="label">Type</span></th>
                <th class="principal">${ amountLabel('Principal') }</th>
                <th class="premium">${ amountLabel('Premium', underlying?.symbol) }</th>
                <th class="price">${ amountLabel('Price', underlying?.symbol) }</th>
                <th class="date"><span class="label">Time</span></th>
            </tr>
        </thead>
        <tbody>
            ${ this.trades.map(order => fillsTableRowTemplate.call(this, order)) }
            ${ !this.trades.length
                ? html`
                <tr class="status">
                    <td colspan="5">
                        ${ emptyTemplate.apply(this) }
                    </td>
                </tr>
                `
                : nothing
            }
            <tr class="footer">
                <td colspan="5">
                    <div class="pagination">
                        ${ page !== undefined && page > 0
                            ? html`
                            <button class="previous button-small" .disabled=${ !state.matches('success') } @click=${ () => this.paginate(-1) }>
                                Next week
                                <ui-icon name="chevron"></ui-icon>
                            </button>
                            `
                            : nothing
                        }
                        ${ page !== undefined && page < MAX_FILLS_WEEKS
                            ? html`
                            <button class="next button-small" .disabled=${ !state.matches('success') } @click=${ () => this.paginate(1) }>
                                Previous week
                                <ui-icon name="chevron"></ui-icon>
                            </button>
                            `
                            : nothing
                        }
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    `;
};

const tableTemplate = function (this: UserOrdersElement) {

    const state = this.userOrdersService.state;
    const { view } = state.context;

    return view === 'orders'
        ? orderTableTemplate.call(this)
        : fillsTableTemplate.call(this);
};


const template = function (this: UserOrdersElement) {

    const state = this.userOrdersService.state;

    const validState = state.matches('success') || state.matches('cancelling') || state.matches('fetching');

    if (validState) {

        return html`
        ${ titleTemplate.call(this) }
        <div class="ui-scroll-table-area">
            ${ tableTemplate.call(this) }
        </div>
        `;

    } else {

        return [
            titleTemplate.call(this),
            headerTemplate.call(this),
            statusTemplate.call(this),
        ];
    }
};

@customElement('sw-user-orders')
export class UserOrdersElement extends LitElement {

    protected userOrdersService = services.userOrders;

    protected tableScrollBehavior = new ScrollTableBehavior();

    @property({
        type: Number,
    })
    precision = BALANCE_DECIMALS;

    @property({
        reflect: true,
        type: Boolean,
    })
    locked = false;

    @state()
    orders: OrderWithMeta[] = [];

    @state()
    trades: SyntheticOrder[] = [];

    @state()
    expanded?: string;

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.userOrdersService.onTransition(this.handleTransition);
    }

    disconnectedCallback () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.userOrdersService.off(this.handleTransition);

        this.tableScrollBehavior.detach();

        super.disconnectedCallback();
    }

    updated () {

        if (this.userOrdersService.state.matches('success')
            || this.userOrdersService.state.matches('cancelling')
            || this.userOrdersService.state.matches('fetching')) {

            const { view } = this.userOrdersService.state.context;

            if (view === 'orders' && !this.orders.length || view === 'fills' && !this.trades.length) {

                this.tableScrollBehavior.detach();

            } else {

                if (!this.tableScrollBehavior.hasAttached) {

                    this.tableScrollBehavior.attach(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        this.renderRoot.querySelector<HTMLElement>('table')!,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        this.renderRoot.querySelector<HTMLElement>('thead')!,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        this.renderRoot.querySelector<HTMLElement>('tbody')!,
                    );
                }

                this.tableScrollBehavior.update();
            }

        } else {

            this.tableScrollBehavior.detach();
        }
    }

    protected createRenderRoot () {

        return this;
    }

    protected render () {

        return template.apply(this);
    }

    protected setView (view: UserOrdersView) {

        if (!this.userOrdersService.state.matches('success')) return;

        this.tableScrollBehavior.detach();

        this.userOrdersService.send({
            type: 'USERORDERS.VIEW',
            payload: view,
        });
    }

    protected setFilter (filter: UserOrdersFilter) {

        if (!this.userOrdersService.state.matches('success')) return;

        this.tableScrollBehavior.detach();

        this.userOrdersService.send({
            type: 'USERORDERS.FILTER',
            payload: filter,
        });
    }

    protected paginate (direction: -1 | 1) {

        if (!this.userOrdersService.state.matches('success')) return;

        const { fillsPage, ordersPage, view } = this.userOrdersService.state.context;

        const page = Math.max((view === 'orders' ? ordersPage : fillsPage) + direction, 0);

        this.userOrdersService.send({
            type: 'USERORDERS.PAGINATE',
            payload: page,
        });
    }

    protected async toggleTradeOrder (order: SyntheticOrder) {

        this.expanded = (this.expanded === order.key) ? undefined : order.key;

        await this.updateComplete;

        // fix the table scroll position to keep the expanded order visible
        // (sometimes, when collapsing a larger list of fills, the next row is above the fold)
        const tableBody = this.renderRoot.querySelector<HTMLElement>('tbody');
        const tableRow = tableBody?.querySelector<HTMLElement>('[aria-expanded=true]');

        if (tableBody && tableRow) {

            tableBody.scrollTo({
                top: tableRow.offsetTop - tableBody.offsetTop,
                behavior: 'smooth',
            });
        }
    }

    protected cancel (o: OrderWithMeta) {

        const { cancellingOrders } = this.userOrdersService.state.context;

        if (cancellingOrders?.has(o)) return;

        this.userOrdersService.send({
            type: 'USERORDERS.CANCEL',
            payload: o,
        });
    }

    protected handleTransition (state: UserOrdersState) {

        // check if market is locked on each transition
        this.locked = state.matches('success')
            ? locked(state.context.market)
            : false;

        // update our local orders / trades lists
        this.prepareOrders(state);
        this.prepareTrades(state);

        this.requestUpdate();
    }

    /**
     * Update the list of orders based on the selected filter.
     *
     * @remarks
     * This way we always know of there are orders for the selcted filter available.
     */
    protected prepareOrders (state: UserOrdersState) {

        if (state.matches('success')) {

            const { ordersFilter: filter, orders } = state.context;

            this.orders = orders?.filter(o => USER_ORDERS_FILTERS[filter].includes(o.meta.status));

        } else if (state.matches('error')) {

            // if an error occurred, empty the orders
            this.orders = [];
        }
    }

    /**
     * Update the list of trades.
     *
     * @remarks
     * Trades are fills grouped by orders, however the orders are sythetic (only exist
     * on FE at runtime). We need to perform this transformation every time fills data
     * changes.
     */
    protected prepareTrades (state: UserOrdersState) {

        if (state.matches('success')) {

            const { account, fills } = state.context;

            this.trades = makeTradeHistory(account, fills);

        } else if (state.matches('error')) {

            // if an error occurred, empty the trades
            this.trades = [];
        }
    }
}
