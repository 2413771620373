import { FocusTrap } from '@swivel-finance/ui/behaviors/focus';
import { ActivateEvent, ListBehavior, SelectEvent } from '@swivel-finance/ui/behaviors/list';
import { OpenChangeEvent, OverlayBehavior, OverlayTriggerBehavior } from '@swivel-finance/ui/behaviors/overlay';
import { PositionBehavior } from '@swivel-finance/ui/behaviors/position';
import { POSITION_CONFIG_CONNECTED } from '@swivel-finance/ui/elements/constants';
import { ARROW_DOWN, ARROW_UP, ENTER } from '@swivel-finance/ui/utils';
import { cancel } from '@swivel-finance/ui/utils/events';
import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { createRef, Ref, ref } from 'lit/directives/ref.js';
import { ERRORS } from '../../constants';
import { fixed, locked, maturityDate, maturityDays, MATURITY_DATE_FORMAT, paused } from '../../helpers';
import { layout } from '../../services';
import { amount, loader, marketStatus, status, unit } from '../../shared/templates';
import { services } from '../../state';
import { Market } from '../../types';

type MarketState = typeof services.market.state;

const rate = (v?: string) => v
    ? amount(fixed(v).mulUnsafe(fixed(100)).toString(), '%', 4, true)
    : html`<span class="amount"><span class="value">-</span>${ unit('%') }</span>`;

const loadingTemplate = function (this: MarketSelectorElement) {

    const state = this.marketService.state;

    return html`
    <div class="market-item">
        <span class="identifier">
            ${ status(state.matches('fetching') ? 'loading' : 'initial') }
        </span>
        <span class="extra">
            ${ state.matches('fetching') ? 'Updating markets...' : 'Not connected...' }
        </span>
    </div>
    `;
};

const errorTemplate = function (this: MarketSelectorElement) {

    const { error } = this.marketService.state.context;

    return html`
    <div class="market-item">
        <span class="identifier">${ error || ERRORS.COMPONENTS.MARKETS.DEFAULT.message }</span>
        <span class="extra">Click to update markets.</span>
    </div>
    `;
};

const marketTemplate = function (this: MarketSelectorElement, market: Market, list = false) {

    const isLocked = locked(market);
    const isPaused = paused(market);

    return html`
    <div class="market-item">
        <div class="identifier">
            <sw-symbol .name=${ market.tokens.underlying.image }></sw-symbol>
            <span class="unit">${ market.tokens.underlying.symbol }</span>
            <sw-symbol .name=${ market.protocolData.image }></sw-symbol>
            <span>${ market.protocolData.name }</span>
            <span>
            ${ isLocked || isPaused
                ? marketStatus(isLocked ? 'locked' : 'paused')
                : rate(market.swivelRate)
            }
            </span>
        </div>
        <div class="extra">
            <span>${ list ? maturityDate(market.maturity, MATURITY_DATE_FORMAT.MEDIUM) : `Matures in ${ maturityDays(market.maturity) } day(s)` }</span>
            <span>${ rate(market.interestRate) }</span>
        </div>
    </div>
    `;
};

const template = function (this: MarketSelectorElement) {

    const state = this.marketService.state;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const markets = state.context.markets!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const selected = state.context.selected!;

    return html`
    <button type="button"
        @click=${ (event: MouseEvent) => this.handleClick(event) }
        @keydown=${ (event: KeyboardEvent) => this.handleKeyDown(event) }
        ${ ref(this.triggerRef) }>
    ${ state.matches('initial') || state.matches('fetching')
        ? loadingTemplate.call(this)
        : state.matches('error')
            ? errorTemplate.call(this)
            : html`
            ${
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                marketTemplate.call(this, markets.get(selected)!)
            }
            <div class="toggle">
                <ui-icon name="chevron"></ui-icon>
            </div>
            `
    }
    </button>
    <div class="sw-market-selector-overlay" ${ ref(this.overlayRef) }>
        <input type="text"
            placeholder="Filter Markets"
            role="combobox"
            aria-autocomplete="list"
            aria-controls="sw-market-selector-listbox"
            aria-expanded="true"
            aria-activedescendant="${ this.activeDescendant }"
            ${ ref(this.searchRef) }
            @keydown=${ (event: KeyboardEvent) => this.handleKeyDown(event) }
            @input=${ () => this.handleInput() }>
        <ui-icon name="search"></ui-icon>
        <ul id="sw-market-selector-listbox" ${ ref(this.listRef) }
            @ui-activate-item=${ (event: ActivateEvent) => this.handleActivateItem(event) }
            @ui-select-item=${ (event: SelectEvent) => this.handleSelectItem(event) }>
            ${ state.matches('completing') || state.matches('success')
                ? [...markets.entries()].map(([key, market]) => html`
                <li data-value="${ key }" aria-selected=${ key === selected }>
                    ${ marketTemplate.call(this, market, true) }
                </li>`)
                : nothing
             }
        </ul>
        ${ state.matches('completing') ? loader() : nothing }
    </div>
    `;
};

@customElement('sw-market-selector')
export class MarketSelectorElement extends LitElement {

    protected marketService = services.market;

    protected triggerRef: Ref<HTMLButtonElement> = createRef();

    protected overlayRef: Ref<HTMLElement> = createRef();

    protected searchRef: Ref<HTMLInputElement> = createRef();

    protected listRef: Ref<HTMLUListElement> = createRef();

    protected triggerBehavior?: OverlayTriggerBehavior;

    protected positionBehavior?: PositionBehavior;

    protected overlayBehavior?: OverlayBehavior;

    protected listBehavior?: ListBehavior;

    @property()
    protected activeDescendant?: string;

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
        this.handleLayoutChange = this.handleLayoutChange.bind(this);
    }

    fetchMarkets () {

        if (this.marketService.state.matches('fetching')) return;

        this.marketService.send('MARKET.FETCH');
    }

    /**
     * Select a market.
     *
     * @param k - market key of the market to select
     */
    selectMarket (k: string) {

        if (this.marketService.state.context.selected === k) return;

        this.marketService.send({
            type: 'MARKET.SELECT',
            payload: k,
        });
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketService.onTransition(this.handleTransition);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        layout.subscribe(this.handleLayoutChange, 'CHANGE');
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketService.off(this.handleTransition);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        layout.unsubscribe(this.handleLayoutChange, 'CHANGE');

        this.listBehavior?.detach();
        this.overlayBehavior?.detach();
        this.triggerBehavior?.detach();

        this.overlayRef.value?.remove();

        super.disconnectedCallback();
    }

    protected firstUpdated (): void {

        // we only create the list behavior instance here, we'll attach/detach it when
        // the overlay is shown/hidden so it's only active while the list is visible
        this.listBehavior = new ListBehavior();

        this.triggerBehavior = new OverlayTriggerBehavior();

        this.positionBehavior = new PositionBehavior({
            ...POSITION_CONFIG_CONNECTED,
            origin: this.triggerRef.value as HTMLElement,
            alignment: {
                origin: {
                    horizontal: 'center',
                    vertical: 'start',
                },
                target: {
                    horizontal: 'center',
                    vertical: 'start',
                },
            },
            width: 'origin',
            minHeight: 'origin',
        });

        this.overlayBehavior = new OverlayBehavior({
            triggerBehavior: this.triggerBehavior,
            focusBehavior: new FocusTrap(),
            positionBehavior: this.positionBehavior,
            animated: true,
        });

        this.overlayBehavior.attach(this.overlayRef.value as HTMLElement);
        this.triggerBehavior.attach(this.triggerRef.value as HTMLElement, this.overlayBehavior);

        this.overlayRef.value?.addEventListener('ui-open-changed', this.handleOpenChange.bind(this));
    }

    protected updated (): void {

        void this.overlayBehavior?.update();
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected filterMarkets (term: string) {

        const list = this.listRef.value as HTMLElement;
        const items = list.querySelectorAll('li');

        term = term.toLowerCase();

        items.forEach(item => {

            const match = term !== '' && !item.innerText.toLowerCase().replace(/\s+/gm, ' ').includes(term);

            item.hidden = match;
        });

        this.listBehavior?.scrollTo('active');
    }

    protected updateListBehavior () {

        // in case the overlay is closed we want to detach the list behavior
        // we also want to detach it before updating the list items
        this.listBehavior?.detach();

        if (this.overlayBehavior && !this.overlayBehavior.hidden) {

            const { markets, selected } = this.marketService.state.context;
            const selectedIndex = (markets && selected)
                ? [...markets.keys()].indexOf(selected)
                : undefined;

            const list = this.listRef.value as HTMLElement;
            const items = list.querySelectorAll('li');

            this.listBehavior?.attach(list, items);
            this.listBehavior?.setSelected(selectedIndex);
            this.listBehavior?.setActive(selectedIndex ?? 'first', true);
        }
    }

    protected handleTransition (state: MarketState) {

        this.requestUpdate();

        // if we transition to a non-success state, the popup should close
        if (!state.matches('completing') && !state.matches('success')) {

            void this.overlayBehavior?.hide();
        }

        // in any case, after the component is updated, update the list behavior
        void this.updateComplete.then(() => this.updateListBehavior());
    }

    protected handleClick (e: MouseEvent) {

        if (!this.marketService.state.matches('completing') && !this.marketService.state.matches('success')) {

            e.stopImmediatePropagation();

            if (this.marketService.state.matches('error')) {

                this.fetchMarkets();
            }
        }
    }

    protected handleOpenChange (event: OpenChangeEvent) {

        this.updateListBehavior();

        if (event.detail.open) {

            (this.renderRoot as HTMLElement).classList.add('open');

        } else {

            (this.renderRoot as HTMLElement).classList.remove('open');
        }
    }

    protected handleKeyDown (event: KeyboardEvent) {

        switch (event.key) {

            case ARROW_UP:
                cancel(event);
                this.listBehavior?.setActive('previous', true);
                break;

            case ARROW_DOWN:
                cancel(event);
                this.listBehavior?.setActive('next', true);
                break;

            case ENTER:
                cancel(event);
                if (this.listBehavior?.activeEntry) this.listBehavior.setSelected(this.listBehavior.activeEntry, true);
                break;
        }
    }

    protected handleInput () {

        this.filterMarkets(this.searchRef.value?.value ?? '');

        if (!this.listBehavior?.activeEntry || this.listBehavior?.activeEntry.item.hidden) {

            if (this.searchRef.value?.value !== '') {

                this.listBehavior?.setActive('first', true);

            } else {

                this.listBehavior?.setActive(this.listBehavior.selectedEntry ?? 'first', true);
            }
        }
    }

    protected handleActivateItem (event: ActivateEvent) {

        this.activeDescendant = event.detail.current?.item.id;
    }

    protected handleSelectItem (event: SelectEvent) {

        if (!this.marketService.state.matches('success')) return;

        const { change, current } = event.detail;

        if (change && current?.index !== undefined) {

            const key = [...this.marketService.state.context.markets.keys()][current.index];

            this.selectMarket(key);
        }

        if (this.searchRef.value) {

            this.searchRef.value.value = '';
            this.handleInput();
        }

        void this.overlayBehavior?.hide(true);
    }

    protected handleLayoutChange () {

        void this.positionBehavior?.requestUpdate();
    }
}
