/**
 * An interface for an observer callback.
 */
export interface Observer<T = unknown> {
    (value: T): void;
}

/**
 * A simple observable base class.
 *
 * @remarks
 * This class can be extended by services to add simple reactive behavior.
 */
export class Observable<T = unknown> {

    /**
     * A set of observers currently subscribed to this observable.
     */
    protected observers = new Set<Observer<Readonly<T>>>();

    /**
     * Subscribe to the observable.
     */
    subscribe (observer: Observer<Readonly<T>>): void {

        this.observers.add(observer);
    }

    /**
     * Unsubscribe from the observable.
     */
    unsubscribe (observer: Observer<Readonly<T>>): void {

        this.observers.delete(observer);
    }

    /**
     * Unsubscribe all observers from the observable.
     *
     * @remarks
     * This is useful for teardown operations: If the observable no longer notifies its
     * observers, they can be safely unsubscribed and garbage collected.
     */
    unsubscribeAll (): void {

        this.observers.clear();
    }

    /**
     * Notify all subscribed observers with the specified value.
     *
     * @remarks
     * The notified value is shared between all observers. Freezing the value can minimize
     * the risk of one observer updating a notified object and affecting other observers,
     * however, freezing is shallow. A specialized observable could override the notify
     * method to treat known values more safely.
     * Trying to `freeze` `TypedArray`s or `DataView`s will throw, as those are views over
     * memory, not objects. We ignore these errors.
     */
    notify (value: T): void {

        try {

            Object.freeze(value);

        } catch (error) {

            // ignore
        }

        this.observers.forEach(observer => observer(value));
    }
}
