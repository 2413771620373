import { annualize, deannualize, fixed, trim } from '../amount';

/**
 * Calculates the fixed side annual percentage yield of an order.
 *
 * @remarks
 * ```
 * apy = price * (SECONDS_PER_YEAR / time_until_maturity) * 100
 * ```
 *
 * @param p - price of the order
 * @param m - maturity of the order
 * @param d - number of decimals for principal and premium (depends on token)
 * @returns annual percentage yield (APY) of the order
 */
export function orderFixedAPY (p: string, m: string, d: number): string;
/**
 * Calculates the fixed side annual percentage yield of an order.
 *
 * @remarks
 * ```
 * apy = price * (SECONDS_PER_YEAR / time_until_maturity) * 100
 * ```
 *
 * @param principal - principal amount of the order
 * @param premium - premium amount of the order
 * @param m - maturity of the order
 * @param d - number of decimals for principal and premium (depends on token)
 * @returns annual percentage yield (APY) of the order
 */
export function orderFixedAPY (principal: string, premium: string, m: string, d: number): string;
export function orderFixedAPY (principalOrPrice: string, premiumOrMaturity: string, maturityOrDecimals: string | number, decimals?: number): string {

    const isPrice = typeof maturityOrDecimals === 'number';

    const price = isPrice
        ? fixed(principalOrPrice)
        : fixed(orderPrice(principalOrPrice, premiumOrMaturity));

    const annual = isPrice
        ? annualize(premiumOrMaturity)
        : annualize(maturityOrDecimals);

    const apy = price.mulUnsafe(annual).mulUnsafe(fixed(100));

    return apy.round(isPrice ? maturityOrDecimals : decimals).toString();
}

/**
 * Calculates the projected profit of a floating yield order based on the current supply rate.
 *
 * @remarks
 * ```
 * profit = principal * supply_rate * (time_until_maturity / SECONDS_PER_YEAR) - premium
 * ```
 *
 * @param principal - principal amount of the order
 * @param premium - premium amount of the order
 * @param m - maturity of the order
 * @param r - supply rate of the market's cToken
 * @returns projected realized floating yield profit of the order
 */
export const orderFloatingProfit = (principal: string, premium: string, m: string, r: string): string => {

    const prin = fixed(principal);
    const prem = fixed(premium);
    const rate = fixed(r);
    const deannual = deannualize(m);

    const profit = prin.mulUnsafe(rate).mulUnsafe(deannual).subUnsafe(prem);

    return trim(profit.round(0).toString());
};

/**
 * Calculates the floating side annual percentage yield of an order.
 *
 * @remarks
 * ```
 * apy = ((principal * supply_rate * (time_until_maturity / SECONDS_PER_YEAR) - premium) / premium) * (SECONDS_PER_YEAR / time_until_maturity) * 100
 * ```
 *
 * @param principal - principal amount of the order
 * @param premium - premium amount of the order
 * @param m - maturity of the order
 * @param r - supply rate of the market's cToken
 * @param d - number of decimals for principal and premium (depends on token)
 * @returns floating side annual percentage yield (APY) of the order
 */
export const orderFloatingAPY = (principal: string, premium: string, m: string, r: string, d: number): string => {

    const profit = fixed(orderFloatingProfit(principal, premium, m, r));
    const prem = fixed(premium);
    const annual = annualize(m);

    const apy = profit.divUnsafe(prem).mulUnsafe(annual).mulUnsafe(fixed(100));

    return apy.round(d).toString();
};

/**
 * Calculates the price of an order based on principal and premium.
 *
 * @remarks
 * ```
 * price = premium / principal
 * ```
 *
 * @param principal - principal amount of the order
 * @param premium - premium amount of the order
 * @param d - number of decimals for price
 * @returns the ratio of underlying received per unit of underlying lent
 */
export const orderPrice = (principal: string, premium: string, d = 18): string => {

    const price = fixed(premium).divUnsafe(fixed(principal));

    return price.round(d).toString();
};

/**
 * Inverts the price for conversion between regular order price and zcToken price.
 *
 * @remarks
 * ```
 * inverted = 1 - price
 * ```
 *
 * @param p - the price to invert
 * @param d - number of decimals for price
 * @returns the inverted price
 */
export const invertPrice = (p: string, d = 18): string => {

    const price = fixed(1).subUnsafe(fixed(p));

    return price.round(d).toString();
};

/**
 * Calculates the premium of an order based on principal and price.
 *
 * @remarks
 * ```
 * premium = principal * price
 * ```
 *
 * @param principal - principal amount of the order
 * @param price - price per unit of underying of the order
 * @returns premium amount for principal at specified price
 */
export const orderPremium = (principal: string, price: string): string => {

    const premium = fixed(principal).mulUnsafe(fixed(price));

    // we need to ensure that the premium has no decimals (it's a uint256)
    return trim(premium.round(0).toString());
};

/**
 * Calculates the principal of an order based on premium and price.
 *
 * @remarks
 * ```
 * principal = premium / price
 * ```
 *
 * @param premium - premium amount of the order
 * @param price - price per unit of underying of the order
 * @returns principal amount for premium at specified price
 */
export const orderPrincipal = (premium: string, price: string): string => {

    const principal = fixed(premium).divUnsafe(fixed(price));

    // we need to ensure that the principal has no decimals (it's a uint256)
    return trim(principal.round(0).toString());
};
