import { html, nothing } from 'lit';
import { ERRORS } from '../../../constants';
import { errors } from '../../../services';
import { Banner, banners } from '../../../shared/components';

const refresh = () => window.location.reload();

const template = (message: string) => {

    const recoverable = message !== ERRORS.ETHEREUM.METAMASK.NO_METAMASK.message
        && message !== ERRORS.ETHEREUM.METAMASK.NON_METAMASK.message
        && message !== ERRORS.ETHEREUM.METAMASK.CHAIN.message;

    const error = errors.updateMessage(
        ERRORS.COMPONENTS.APP.METAMASK,
        [[/\.$/, `: ${ message || ERRORS.DEFAULT.message }`]],
    );

    return html`${ error.message }${ recoverable
        ? html`<button @click=${ refresh }>Retry</button>`
        : nothing
    }`;
};

let bannerId: string | undefined;

/**
 * Handle account state errors by displaying a banner.
 *
 * @remarks
 * This method will show a banner if the account state has an error and remove it otherwise.
 *
 * @param m - the account state error message
 */
export const handleAccountError = (m?: string) => {

    if (m) {

        const banner: Banner = {
            type: 'failure',
            priority: 'high',
            content: () => template(m),
        };

        if (bannerId) {

            banners.update(bannerId, banner);

        } else {

            bannerId = banners.show(banner);
        }

    } else {

        if (bannerId) banners.dismiss(bannerId);

        bannerId = undefined;
    }
};
