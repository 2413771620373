import { EVENT_INIT_DEFAULT } from '@swivel-finance/ui/utils/events';

/**
 * An event for requesting an element to be toggled
 *
 * @remarks
 * We currently use this to request the account popup to be opened from different locations
 * (i.e. notifications), but the same approach (and event) can be used to toggle any element
 * which supports being toggled.
 */
export class RequestRewardsEvent extends CustomEvent<void> {

    static type = 'sw-request-rewards' as const;

    type!: typeof RequestRewardsEvent.type;

    constructor () {

        super(RequestRewardsEvent.type, EVENT_INIT_DEFAULT);
    }
}

/**
 * Add the event to the global HTMLElementEventMap.
 */
declare global {
    interface HTMLElementEventMap {
        [RequestRewardsEvent.type]: RequestRewardsEvent;
    }
}
