import { logger as logService } from './services/logger';
import { ServiceFactory, ServiceIdentifier, serviceLocator } from './services/service-locator';
import { TimeService, TIME_SERVICE } from './services/time';
import { LocalTimeService } from './services/time/local-time';

export const configureServices = () => {

    const serviceCache = new Map<ServiceIdentifier, unknown>();

    const timeServiceFactory: ServiceFactory<TimeService> = () => {

        if (!serviceCache.has(TIME_SERVICE)) {

            serviceCache.set(TIME_SERVICE, new LocalTimeService());
        }

        return serviceCache.get(TIME_SERVICE) as TimeService;
    };

    // TODO: use the blockchain time service when testing on tenderly with time manipulation
    // const timeServiceFactory: ServiceFactory<TimeService> = () => {

    //     if (!serviceCache.has(TIME_SERVICE)) {

    //         serviceCache.set(TIME_SERVICE, new BlockChainTimeService(wallet));
    //     }

    //     return serviceCache.get(TIME_SERVICE) as TimeService;
    // };

    serviceLocator.register(TIME_SERVICE, timeServiceFactory);

    const logger = logService.group('config');
    const timer = serviceLocator.get(TIME_SERVICE);

    logger.log(`service locator configured... (${ new Date(timer.time()).toUTCString() }})`);
};

// immediately run the configuration to ensure all services are configured before other modules are loaded
configureServices();
