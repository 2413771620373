import { errors } from '../../../services/errors';
import type { NotificationOutletElement } from './notification-outlet';
import { Notification } from './types';

const NO_OUTLET = 'Error showing notification: No notification outlet found in page.';
const UNKNOWN_OUTLET = 'Error showing notification: The specified outlet is not connected.';

class NotificationService {

    protected outlets = new Map<string, NotificationOutletElement>();

    protected notifications = new Map<string, string>();

    protected defaultOutlet: string | undefined;

    connect (o: NotificationOutletElement) {

        this.outlets.set(o.id, o);

        if (!this.defaultOutlet) {

            this.defaultOutlet = o.id;
        }
    }

    disconnect (o: NotificationOutletElement) {

        if (this.outlets.has(o.id)) {

            this.outlets.delete(o.id);
        }
    }

    show (n: Notification, o?: string): string {

        const outlet = this.getOutlet(o);

        const id = outlet.show(n);

        this.notifications.set(id, outlet.id);

        return id;
    }

    dismiss (i: string) {

        const outlet = this.getOutlet(this.notifications.get(i));

        outlet.dismiss(i);

        this.notifications.delete(i);
    }

    update (i: string, n: Notification) {

        const outlet = this.getOutlet(this.notifications.get(i));

        outlet.refresh(i, n);
    }

    protected getOutlet (o?: string): NotificationOutletElement {

        let outlet: NotificationOutletElement;

        if (o) {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            outlet = this.outlets.get(o)!;

            if (!outlet) throw errors.process(new Error(UNKNOWN_OUTLET));

        } else {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            outlet = this.outlets.get(this.defaultOutlet || '')!;

            if (!outlet) throw errors.process(new Error(NO_OUTLET));
        }

        return outlet;
    }
}

export const notifications = new NotificationService();
