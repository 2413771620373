import { TaskReference, microtask } from '@swivel-finance/ui/utils/async';
import { BigNumber, BigNumberish, ethers } from 'ethers';
import { SECONDS_PER_DAY } from '../constants';
import { ENV } from '../env/environment';
import { compareAmounts, confirmTransaction, emptyOrZero, expandAmount, fixed, trim } from '../helpers';
import { Observable } from '../shared/helpers';
import { Token } from '../types';
import { errors } from './errors';
import { Logger, logger as logService } from './logger';
import { rewardsApi } from './rewards';
import { serviceLocator } from './service-locator';
import { TIME_SERVICE, TimeService } from './time';
import { TokenService, token } from './token';
import { wallet } from './wallet';

// --------------------------
// the staked swivel contract
// --------------------------

const STAKED_SWIVEL_ABI = [
    'function SWIV() public view returns (address)',
    'function balancerLPT() public view returns (address)',
    'function balancerVault() public view returns (address)',
    'function balancerPoolID() public view returns (bytes32)',
    'function cooldownLength() public view returns (uint256)',
    'function withdrawalWindow() public view returns (uint256)',
    'function cooldownTime(address owner) public view returns (uint256)',
    'function cooldownAmount(address owner) public view returns (uint256)',
    'function asset() public view returns (address)',
    'function totalAssets() public view returns (uint256)',
    'function totalSupply() public view returns (uint256)',
    'function exchangeRateCurrent() public view returns (uint256)',
    'function convertToShares(uint256 assets) public view returns (uint256)',
    'function convertToAssets(uint256 shares) public view returns (uint256)',
    'function cooldown(uint256 amount) public returns (uint256)',
    'function depositZap(uint256 assets, address receiver, uint256 minimumBPT) public payable returns (uint256)',
    'function withdrawZap(uint256 assets, uint256 ethAssets, address receiver, address owner, uint256 maximumBPT) public returns (uint256)',
    'function redeemZap(uint256 shares, address receiver, address owner, uint256 minimumETH, uint256 minimumSWIV) public returns (uint256, uint256, uint256[2])',
];

interface StakedSwivelContract {
    // the swivel token
    SWIV (overrides?: ethers.Overrides): Promise<string>;
    // the SWIV/ETH balancer LP token
    balancerLPT (overrides?: ethers.Overrides): Promise<string>;
    // the static balancer vault
    balancerVault (overrides?: ethers.Overrides): Promise<string>;
    // the balancer pool id
    balancerPoolID (overrides?: ethers.Overrides): Promise<number>;
    // the withdrawal cooldown length
    cooldownLength (overrides?: ethers.Overrides): Promise<BigNumber>;
    // the window to withdraw after cooldown
    withdrawalWindow (overrides?: ethers.Overrides): Promise<BigNumber>;
    // mapping of user address -> unix timestamp for cooldown
    cooldownTime (owner: string, overrides?: ethers.Overrides): Promise<BigNumber>;
    // mapping of user address -> amount of shares (staked swivel tokens) to be withdrawn
    cooldownAmount (owner: string, overrides?: ethers.Overrides): Promise<BigNumber>;
    // the underlying asset (the balancer lp token)
    asset (overrides?: ethers.Overrides): Promise<string>;
    // the total amount of underlying assets (balancer lp tokens)
    totalAssets (overrides?: ethers.Overrides): Promise<BigNumber>;
    // the total supply of shares (staked swivel tokens)
    totalSupply (overrides?: ethers.Overrides): Promise<BigNumber>;
    // the current exchange rate
    exchangeRateCurrent (overrides?: ethers.Overrides): Promise<BigNumber>;
    // convert underlying assets to shares (staked swivel tokens)
    convertToShares (assets: BigNumberish, overrides?: ethers.Overrides): Promise<BigNumber>;
    // convert shares to underlying assets (balancer lp tokens)
    convertToAssets (shares: BigNumberish, overrides?: ethers.Overrides): Promise<BigNumber>;
    // queues `amount` of shares (staked swivel tokens) to be withdrawn after the cooldown period
    cooldown (amount: BigNumberish, overrides?: ethers.PayableOverrides): Promise<ethers.providers.TransactionResponse>;
    // transfers `assets` of SWIV tokens from `msg.sender` while receiving `msg.value` of ETH
    // then joins the balancer pool with the SWIV and ETH before minting `shares` to `receiver`
    depositZap (assets: BigNumberish, receiver: string, minimumBPT: BigNumberish, overrides?: ethers.PayableOverrides): Promise<ethers.providers.TransactionResponse>;
    // exits the balancer pool and transfers `assets` of SWIV tokens and the current balance of ETH to `receiver`
    // then burns `shares` from `owner`
    withdrawZap (assets: BigNumberish, ethAssets: BigNumberish, receiver: string, owner: string, maximumBPT: BigNumberish, overrides?: ethers.PayableOverrides): Promise<ethers.providers.TransactionResponse>;
    // exits the balancer pool and transfers `assets` of SWIV tokens and the current balance of ETH to `receiver`
    // then burns `shares` from `owner`
    redeemZap (shares: BigNumberish, receiver: string, owner: string, minimumETH: BigNumberish, minimumSWIV: BigNumberish, overrides?: ethers.PayableOverrides): Promise<ethers.providers.TransactionResponse>;
}

// ---------------------------
// the balancer vault contract
// ---------------------------

const BALANCER_VAULT_ABI = [
    'function getPoolTokens(bytes32 poolId) external view returns (address[], uint256[], uint256)',
];

interface BalancerVaultContract {
    // the pool's registered tokens, the total balance for each, and the latest block when any of the tokens' `balances` changed
    getPoolTokens (poolId: number, overrides?: ethers.Overrides): Promise<[string[], BigNumber[], BigNumber]>;
}

// ---------------------------
// the balancer query contract
// ---------------------------

const POOL_JOIN_REQUEST = '(address[] assets, uint256[] maxAmountsIn, bytes userData, bool fromInternalBalance)';
const POOL_EXIT_REQUEST = '(address[] assets, uint256[] minAmountsOut, bytes userData, bool toInternalBalance)';

const BALANCER_QUERY_ABI = [
    `function queryJoin(bytes32 poolId, address sender, address recipient, ${ POOL_JOIN_REQUEST } request) external returns (uint256 bptOut, uint256[] amountsIn)`,
    `function queryExit(bytes32 poolId, address sender, address recipient, ${ POOL_EXIT_REQUEST } request) external returns (uint256 bptIn, uint256[] amountsOut)`,
];

interface PoolJoinRequest {
    assets: string[];
    maxAmountsIn: BigNumberish[];
    userData: string;
    fromInternalBalance: boolean;
}

interface PoolExitRequest {
    assets: string[];
    minAmountsOut: BigNumberish[];
    userData: string;
    toInternalBalance: boolean;
}

interface BalancerQueryContract {
    queryJoin (poolId: number, sender: string, recipient: string, request: PoolJoinRequest, overrides?: ethers.Overrides): Promise<[BigNumber, BigNumber[]]>;
    queryExit (poolId: number, sender: string, recipient: string, request: PoolExitRequest, overrides?: ethers.Overrides): Promise<[BigNumber, BigNumber[]]>;
}

// ------------------------
// the saftey module tokens
// ------------------------

const ETH: Token = {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
    address: '',
    image: undefined,
};

const WETH: Token = {
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18,
    address: ENV.wethAddress,
    image: undefined,
};

const SWIV: Token = {
    name: 'Swivel Token',
    symbol: 'SWIV',
    decimals: 18,
    address: '0x0',
    image: 'swiv',
};

// the staked swivel is a share of the balancer lp tokens held by the staked swivel contract
const stkSWIV: Token = {
    name: 'Staked Swivel Token',
    symbol: 'stkSWIV',
    decimals: 18,
    address: '0x0',
    image: 'stkswiv',
};

// the balancer lp token (the asset held by the staked swivel contract)
const LP: Token = {
    name: 'Balancer 80 BAL 20 WETH',
    symbol: 'B-80SWIV-20WETH',
    decimals: 18,
    address: '0x0',
    image: undefined,
};

const TOKENS = {
    ETH,
    WETH,
    SWIV,
    stkSWIV,
    LP,
};

// export tokens as a readonly object
export const SafetyModuleTokens = TOKENS as Readonly<typeof TOKENS>;

// -----------------------
// the saftey module state
// -----------------------

export interface SafetyModuleState {
    account: string;
    swivAddress: string;
    poolAddress: string;
    poolId: number;
    withdrawalWindow: number;
    cooldownDuration: number;
    cooldownAmount: string;
    cooldownTime: number;
    // aprs might include empty strings if calculations on BE failed
    aprs: {
        fees: string;
        pool: string;
        incentives: string;
        staking: string;
    };
    balances: {
        ETH: string;
        SWIV: string;
        stkSWIV: string;
        LP: string;
    };
    stakedBalances: {
        WETH: string;
        SWIV: string;
    };
    cooldownBalances: {
        WETH: string;
        SWIV: string;
    };
    ssmBalances: {
        SWIV: string;
        WETH: string;
    };
    totalSupply: string;
    totalAssets: string;
    poolBalances: {
        SWIV: string;
        WETH: string;
    };
    poolRatio: string;
    poolSupply: string;
    isFetching: boolean;
}

const initialState: SafetyModuleState = {
    account: '',
    swivAddress: '0x0',
    poolAddress: '0x0',
    poolId: 0,
    withdrawalWindow: SECONDS_PER_DAY * 7,
    cooldownDuration: SECONDS_PER_DAY * 14,
    cooldownAmount: '0',
    cooldownTime: 0,
    aprs: {
        fees: '0',
        pool: '0',
        incentives: '0',
        staking: '0',
    },
    balances: {
        ETH: '0',
        SWIV: '0',
        stkSWIV: '0',
        LP: '0',
    },
    stakedBalances: {
        WETH: '0',
        SWIV: '0',
    },
    cooldownBalances: {
        WETH: '0',
        SWIV: '0',
    },
    ssmBalances: {
        SWIV: '0',
        WETH: '0',
    },
    totalSupply: '0',
    totalAssets: '0',
    poolBalances: {
        SWIV: '0',
        WETH: '0',
    },
    poolRatio: '0',
    poolSupply: '0',
    isFetching: false,
};

// -------------------------
// the saftey module service
// -------------------------

export class SafetyModuleService extends Observable<SafetyModuleState> {

    protected static instance?: SafetyModuleService;

    protected static mockInstance?: SafetyModuleService;

    static async getInstance (): Promise<SafetyModuleService> {

        if (!this.instance) {

            const { account, signer } = await wallet.connection();

            const stakedSwivel = new ethers.Contract(ENV.stakedSwivelAddress, STAKED_SWIVEL_ABI, signer) as ethers.Contract & StakedSwivelContract;

            const vaultAddress = await stakedSwivel.balancerVault();

            const balancerVault = new ethers.Contract(vaultAddress, BALANCER_VAULT_ABI, signer) as ethers.Contract & BalancerVaultContract;

            const balancerQuery = new ethers.Contract(ENV.balancerQueryAddress, BALANCER_QUERY_ABI, signer) as ethers.Contract & BalancerQueryContract;

            const tokenService = token;

            const timeService = serviceLocator.get(TIME_SERVICE);

            const logger = logService.group('safety module');

            this.instance = new SafetyModuleService(account.address, stakedSwivel, balancerVault, balancerQuery, tokenService, timeService, logger);
        }

        return this.instance;
    }

    // TODO: remove...
    // static async getMockInstance (): Promise<SafetyModuleService> {

    //     if (!this.mockInstance) {

    //         const { account } = await wallet.getConnection();

    //         const stakedSwivel = stakedSwivelMock;
    //         const balancerVault = balancerVaultMock;
    //         const tokenService = tokenServiceMock;

    //         this.mockInstance = new SafetyModuleService(account, stakedSwivel, balancerVault, tokenService);
    //     }

    //     return this.mockInstance;
    // }

    protected _state: SafetyModuleState = initialState;

    protected _isFirstFetch = true;

    protected _fetchComplete?: Promise<void>;

    protected _notifyTask?: TaskReference;

    protected stakedSwivel: StakedSwivelContract;

    protected balancerVault: BalancerVaultContract;

    protected balancerQuery: ethers.Contract & BalancerQueryContract;

    protected tokenService: TokenService;

    protected timeService: TimeService;

    protected logService: Logger;

    protected precision = 18 * 2;

    get state (): Readonly<SafetyModuleState> {

        return this._state;
    }

    protected constructor (
        account: string,
        stakedSwivel: StakedSwivelContract,
        balancerVault: BalancerVaultContract,
        balancerQuery: ethers.Contract & BalancerQueryContract,
        tokenService: TokenService,
        timeService: TimeService,
        logService: Logger,
    ) {

        super();

        this.stakedSwivel = stakedSwivel;
        this.balancerVault = balancerVault;
        this.balancerQuery = balancerQuery;
        this.tokenService = tokenService;
        this.timeService = timeService;
        this.logService = logService;

        this.updateState({ account });
    }

    async fetch (): Promise<void> {

        // subsequent calls to fetch will not cause another fetch to be initiated
        // until the currently pending fetch has completed
        // we cache the currently pending fetch promise and return it if it exists
        // so that callers can await the fetch completion
        if (this.state.isFetching) return this._fetchComplete;

        this.updateState({ isFetching: true });

        this._fetchComplete = (this._isFirstFetch ? this.fetchStatic() : Promise.resolve())
            .then(() => this._isFirstFetch = false)
            .then(() => this.fetchBalances())
            .finally(() => {
                this._fetchComplete = undefined;
                this.updateState({ isFetching: false });
            });

        return this._fetchComplete;
    }

    async approve (amount: string): Promise<ethers.providers.TransactionReceipt | void> {

        const { account, swivAddress } = this.state;

        try {

            const allowance = await this.tokenService.allowance(swivAddress, account, ENV.stakedSwivelAddress);

            if (compareAmounts(allowance, amount, TOKENS.SWIV) >= 0) return;

            const receipt = await this.tokenService.approve(swivAddress, ENV.stakedSwivelAddress, ethers.constants.MaxUint256);

            return receipt;

        } catch (error) {

            throw errors.isProcessed(error) ? error : errors.process(error);
        }
    }

    async stake (amount: string): Promise<ethers.providers.TransactionReceipt> {

        const { account } = this.state;

        try {

            const [expectedBPT, [swivIn, ethIn]] = await this.queryJoin(amount, this.getProportionalETH(amount));

            // calculate slippage...
            const minimumBPT = trim(fixed(expectedBPT, this.precision)
                .subUnsafe(fixed(expectedBPT, this.precision).mulUnsafe(fixed(ENV.stakingSlippage, this.precision)))
                .floor()
                .toString());

            this.logService.warn([
                'staking slippage:',
                `amount:      ${ amount }`,
                '------------',
                `swivIn:      ${ swivIn }`,
                `ethIn:       ${ ethIn }`,
                `expectedBPT: ${ expectedBPT }`,
                `minimumBPT:  ${ minimumBPT }`,
            ].join('\n'));

            // we pass the ETH amount as the `msg.value` to the transaction using the overrides object
            const tx = await this.stakedSwivel.depositZap(swivIn, account, minimumBPT, { value: ethIn });

            const receipt = await confirmTransaction(tx);

            return receipt;

        } catch (error) {

            throw errors.isProcessed(error) ? error : errors.process(error);
        }
    }

    async unstake (amount: string): Promise<ethers.providers.TransactionReceipt> {

        const { account, cooldownAmount, cooldownBalances } = this.state;

        try {

            // convert amount of SWIV to shares
            // amount / cooldownBalances.SWIV = shares / cooldownAmount
            // shares = amount / cooldownBalances.SWIV * cooldownAmount
            let shares = fixed(amount, this.precision)
                .divUnsafe(fixed(cooldownBalances.SWIV, this.precision))
                .mulUnsafe(fixed(cooldownAmount, this.precision))
                .floor();

            // get the maximum amount of shares that can be unstaked
            // in case there are some rounding issues when converting SWIV to shares
            const max = fixed(cooldownAmount, this.precision);

            // limit the shares amount
            if (max.subUnsafe(shares).isNegative()) shares = max;

            const sharesAmount = trim(shares.toString());

            const [expectedBPT, [swivOut, ethOut]] = await this.queryExit(sharesAmount);

            const availableBPT = this.convertToAssets(cooldownAmount);

            const minimumSWIV = trim(fixed(swivOut, this.precision)
                .subUnsafe(fixed(swivOut, this.precision).mulUnsafe(fixed(ENV.stakingSlippage, this.precision)))
                .floor()
                .toString());

            const minimumETH = trim(fixed(ethOut, this.precision)
                .subUnsafe(fixed(ethOut, this.precision).mulUnsafe(fixed(ENV.stakingSlippage, this.precision)))
                .floor()
                .toString());

            this.logService.warn([
                'unstaking slippage:',
                `amount:       ${ amount }`,
                `shares:       ${ sharesAmount }`,
                '-------------',
                `expectedBPT:  ${ expectedBPT }`,
                `availableBPT: ${ availableBPT }`,
                `swivOut:      ${ swivOut }`,
                `ethOut:       ${ ethOut }`,
                `minimumSWIV:  ${ minimumSWIV }`,
                `minimumETH:   ${ minimumETH }`,
            ].join('\n'));

            const tx = await this.stakedSwivel.redeemZap(sharesAmount, account, account, minimumETH, minimumSWIV);

            const receipt = await confirmTransaction(tx);

            return receipt;

        } catch (error) {

            throw errors.isProcessed(error) ? error : errors.process(error);
        }
    }

    async cooldown (amount: string): Promise<ethers.providers.TransactionReceipt> {

        const { balances, cooldownAmount, stakedBalances } = this.state;

        try {

            // convert amount of SWIV to shares
            // amount / stakeBalances.SWIV = shares / balances.stkSWIV
            // shares = amount / stakeBalances.SWIV * balances.stkSWIV
            let shares = fixed(amount, this.precision)
                .divUnsafe(fixed(stakedBalances.SWIV, this.precision))
                .mulUnsafe(fixed(balances.stkSWIV, this.precision))
                .floor();

            // get the maximum amount of shares that can be queued for cooldown
            // in case there are some rounding issues when converting SWIV to shares
            const max = fixed(balances.stkSWIV, this.precision)
                .subUnsafe(fixed(cooldownAmount, this.precision));

            // limit the shares amount
            if (max.subUnsafe(shares).isNegative()) shares = max;

            const tx = await this.stakedSwivel.cooldown(trim(shares.toString()));

            const receipt = await confirmTransaction(tx);

            return receipt;

        } catch (error) {

            throw errors.isProcessed(error) ? error : errors.process(error);
        }
    }

    getMaxStakeableSWIV (): string {

        const swivBalance = fixed(this.state.balances.SWIV);
        const ethBalance = fixed(this.state.balances.ETH);
        const poolRatio = fixed(this.state.poolRatio);

        if (poolRatio.isZero()) return '0';

        const maxSWIV = ethBalance.divUnsafe(poolRatio).floor();

        if (maxSWIV.subUnsafe(swivBalance).isNegative()) {

            return trim(maxSWIV.toString());
        }

        return trim(swivBalance.toString());
    }

    getMaxQueueableSWIV (): string {

        const stakedBalance = fixed(this.state.stakedBalances.SWIV);
        const queuedBalance = fixed(this.state.cooldownBalances.SWIV);

        const maxSWIV = stakedBalance.subUnsafe(queuedBalance);

        if (maxSWIV.isNegative()) {

            return '0';
        }

        return trim(maxSWIV.toString());
    }

    getProportionalETH (amount: string): string {

        const poolRatio = fixed(this.state.poolRatio, this.precision);

        return trim(fixed(amount, this.precision).mulUnsafe(poolRatio).floor().toString());
    }

    convertToAssets (shares: string): string {

        const assets = fixed(shares, this.precision)
            .mulUnsafe(fixed(this.state.totalAssets, this.precision).addUnsafe(fixed('1', this.precision)))
            .divUnsafe(fixed(this.state.totalSupply, this.precision).addUnsafe(fixed(expandAmount('1', 18), this.precision)))
            .floor()
            .toString();

        // this.logService.warn('convert to assets:', assets);

        return trim(assets);
    }

    convertToShares (assets: string): string {

        const shares = fixed(assets, this.precision)
            .mulUnsafe(fixed(this.state.totalSupply, this.precision).addUnsafe(fixed(expandAmount('1', 18), this.precision)))
            .divUnsafe(fixed(this.state.totalAssets, this.precision).addUnsafe(fixed('1', this.precision)))
            .floor()
            .toString();

        // this.logService.warn('convert to shares:', shares);

        return trim(shares);
    }

    protected async fetchStatic (): Promise<void> {

        const [swivAddress, poolAddress, poolId, cooldownLength, withdrawalWindow] = await Promise.all([
            this.stakedSwivel.SWIV(),
            this.stakedSwivel.balancerLPT(),
            this.stakedSwivel.balancerPoolID(),
            this.stakedSwivel.cooldownLength(),
            this.stakedSwivel.withdrawalWindow(),
        ]);

        this.updateState({
            swivAddress,
            poolAddress,
            poolId,
            cooldownDuration: cooldownLength.toNumber(),
            withdrawalWindow: withdrawalWindow.toNumber(),
        });

        await this.fetchTokens();
    }

    protected async fetchTokens (): Promise<void> {

        const { swivAddress, poolAddress } = this.state;

        const [stkSwivToken, swivToken, poolToken, wethToken] = await Promise.all([
            this.tokenService.fetch(ENV.stakedSwivelAddress),
            this.tokenService.fetch(swivAddress),
            this.tokenService.fetch(poolAddress),
            this.tokenService.fetch(ENV.wethAddress),
        ]);

        TOKENS.stkSWIV = { ...TOKENS.stkSWIV, ...stkSwivToken };
        TOKENS.SWIV = { ...TOKENS.SWIV, ...swivToken };
        TOKENS.LP = { ...TOKENS.LP, ...poolToken };
        TOKENS.WETH = { ...TOKENS.WETH, ...wethToken };
    }

    protected async fetchBalances (): Promise<void> {

        const { account, poolAddress, poolId, swivAddress } = this.state;

        const [ethBalance, swivBalance, stkSwivBalance, poolBalance, poolTokens, poolSupply, totalAssets, totalSupply, cooldownAmount, cooldownTime, aprs] = await Promise.all([
            this.tokenService.fetchAccountBalance(),
            this.tokenService.fetchBalance(swivAddress, account),
            this.tokenService.fetchBalance(ENV.stakedSwivelAddress, account),
            this.tokenService.fetchBalance(poolAddress, account),
            this.balancerVault.getPoolTokens(poolId),
            this.tokenService.totalSupply(poolAddress),
            this.stakedSwivel.totalAssets(),
            this.stakedSwivel.totalSupply(),
            this.stakedSwivel.cooldownAmount(account),
            this.stakedSwivel.cooldownTime(account),
            rewardsApi.fetchStakingAPRs(),
        ]);

        // update the user's wallet balances

        this.updateState({
            balances: {
                ETH: ethBalance.balance,
                SWIV: swivBalance,
                stkSWIV: stkSwivBalance,
                LP: poolBalance,
            },
            aprs,
        });

        // update the pool's underlying balances

        poolTokens[0].forEach((address, index) => {

            const balance = poolTokens[1][index].toString();

            if (address === ENV.wethAddress) {

                this.updateState({ poolBalances: { ...this.state.poolBalances, WETH: balance } });
            }

            if (address === swivAddress) {

                this.updateState({ poolBalances: { ...this.state.poolBalances, SWIV: balance } });
            }
        });

        const POOL_WETH = fixed(this.state.poolBalances.WETH, this.precision);
        const POOL_SWIV = fixed(this.state.poolBalances.SWIV, this.precision);

        this.updateState({
            poolRatio: POOL_SWIV.isZero() ? '0' : POOL_WETH.divUnsafe(POOL_SWIV).toString(),
            poolSupply,
        });

        // update the ssm's staked balances

        const POOL_SUPPLY = fixed(poolSupply, this.precision);
        const LP_SWIV_RATIO = POOL_SUPPLY.isZero() ? fixed('0') : fixed(this.state.poolBalances.SWIV, this.precision).divUnsafe(POOL_SUPPLY);
        const LP_WETH_RATIO = POOL_SUPPLY.isZero() ? fixed('0') : fixed(this.state.poolBalances.WETH, this.precision).divUnsafe(POOL_SUPPLY);

        const SSM_SWIV = fixed(totalAssets.toString(), this.precision).mulUnsafe(LP_SWIV_RATIO);
        const SSM_WETH = fixed(totalAssets.toString(), this.precision).mulUnsafe(LP_WETH_RATIO);

        this.updateState({
            ssmBalances: {
                SWIV: trim(SSM_SWIV.floor().toString()),
                WETH: trim(SSM_WETH.floor().toString()),
            },
            totalSupply: totalSupply.toString(),
            totalAssets: totalAssets.toString(),
        });

        // update the user's staked balances

        const [, stakedAmounts] = emptyOrZero(stkSwivBalance)
            ? ['0', ['0', '0']]
            : await this.queryExit(stkSwivBalance);

        this.updateState({
            stakedBalances: {
                SWIV: stakedAmounts[0],
                WETH: stakedAmounts[1],
            },
        });

        // update the user's cooldown balances

        const NOW = parseInt(this.timeService.timestamp());

        const HAS_COOLDOWN = !emptyOrZero(cooldownAmount.toString())
            && cooldownTime.toNumber() > 0
            && cooldownTime.toNumber() + this.state.withdrawalWindow > NOW;

        const [, cooldownAmounts] = !HAS_COOLDOWN
            ? ['0', ['0', '0']]
            : await this.queryExit(cooldownAmount.toString());

        this.updateState({
            cooldownAmount: cooldownAmount.toString(),
            cooldownTime: cooldownTime.toNumber(),
            cooldownBalances: {
                SWIV: cooldownAmounts[0],
                WETH: cooldownAmounts[1],
            },
        });

        this.logService.warn('ssm state:');
        this.logService.warn(this.state);
    }

    /**
     * Get the amount of BPT received when joining the pool with the given amounts of assets.
     *
     * @remarks
     * https://docs.balancer.fi/reference/joins-and-exits/pool-joins.html
     */
    protected async queryJoin (swivAssets: string, ethAssets: string): Promise<[string, string[]]> {

        const { account, poolId } = this.state;

        const amountData = [swivAssets, ethAssets];

        const EXACT_TOKENS_IN_FOR_BPT_OUT = 1;

        const userData = ethers.utils.defaultAbiCoder.encode(
            ['uint256', 'uint256[]', 'uint256'],
            [EXACT_TOKENS_IN_FOR_BPT_OUT, amountData, BigNumber.from('0')],
        );

        const request: PoolJoinRequest = {
            assets: [TOKENS.SWIV.address, TOKENS.WETH.address],
            maxAmountsIn: amountData,
            userData,
            fromInternalBalance: false,
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const [bptOut, amountsIn] = await this.balancerQuery.callStatic.queryJoin(poolId, account, account, request) as [BigNumber, BigNumber[]];

        const result = [
            bptOut.toString(),
            amountsIn.map((amount) => amount.toString()),
        ] as [string, string[]];

        this.logService.warn([
            'queryJoin:',
            `bptOut: ${ result[0] }`,
            `swivIn: ${ result[1][0] }`,
            `ethIn:  ${ result[1][1] }`,
        ].join('\n'));

        return result;
    }

    /**
     * Get the amount of assets received when exiting the pool with the given amount of shares.
     *
     * @remarks
     * https://docs.balancer.fi/reference/joins-and-exits/pool-exits.html
     */
    protected async queryExit (shares: string): Promise<[string, string[]]> {

        const { account, poolId } = this.state;

        const bpt = this.convertToAssets(shares);

        const amountData = [
            '0',
            '0',
        ];

        const EXACT_BPT_IN_FOR_TOKENS_OUT = 1;

        const userData = ethers.utils.defaultAbiCoder.encode(
            ['uint256', 'uint256'],
            [EXACT_BPT_IN_FOR_TOKENS_OUT, BigNumber.from(bpt)],
        );

        const request: PoolExitRequest = {
            assets: [TOKENS.SWIV.address, TOKENS.WETH.address],
            minAmountsOut: amountData,
            userData,
            toInternalBalance: false,
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const [bptIn, amountsOut] = await this.balancerQuery.callStatic.queryExit(poolId, account, account, request) as [BigNumber, BigNumber[]];

        const result = [
            bptIn.toString(),
            amountsOut.map((amount) => amount.toString()),
        ] as [string, string[]];

        this.logService.warn([
            'queryExit:',
            `bptIn:   ${ result[0] }`,
            `swivOut: ${ result[1][0] }`,
            `ethOut:  ${ result[1][1] }`,
        ].join('\n'));

        return result;
    }

    /**
     * Update the internal state and notify observers.
     */
    protected updateState (state: Partial<SafetyModuleState>): void {

        this._state = { ...this._state, ...state };

        this.scheduleNotify();
    }

    /**
     * Schedule a notify task to run on the next microtask.
     *
     * @remarks
     * Allows multiple state updates to be batched into a single notify call.
     */
    protected scheduleNotify (): void {

        if (this._notifyTask) return;

        this._notifyTask = microtask(() => {

            this.notify(this._state);

            this._notifyTask = undefined;
        });
    }
}

// -----
// MOCKS
// -----

// TODO: cleanup mocks...

// const readDelay = async () => await delay(() => { /* */ }, 1000).done;

// const writeDelay = async () => await delay(() => { /* */ }, 2000).done;

// const mockResposne = (hash: string) => ({
//     hash,
//     wait: (confirmations?: number) => Promise.resolve({
//         transactionHash: hash,
//         confirmations: confirmations ?? 1,
//         status: 1,
//     } as ethers.providers.TransactionReceipt),
// } as ethers.providers.TransactionResponse);

// const tokenCache = new Map<string, Token>([
//     ['', {
//         name: 'Ether',
//         symbol: 'ETH',
//         decimals: 18,
//         address: '',
//         image: undefined,
//     }],
//     [ENV.wethAddress, {
//         name: 'Wrapped Ether',
//         symbol: 'WETH',
//         decimals: 18,
//         address: ENV.wethAddress,
//         image: undefined,
//     }],
//     ['0xSWIV', {
//         name: 'Swivel Token',
//         symbol: 'SWIV',
//         decimals: 18,
//         address: '0xSWIV',
//         image: 'swiv',
//     }],
//     ['0xstkSWIV', {
//         name: 'Staked Swivel Token',
//         symbol: 'stkSWIV',
//         decimals: 18,
//         address: '0xstkSWIV',
//         image: 'stkswiv',
//     }],
//     ['0xBAL-LP', {
//         name: 'Balancer 80 BAL 20 WETH',
//         symbol: 'B-80SWIV-20WETH',
//         decimals: 18,
//         address: '0xBAL-LP',
//         image: undefined,
//     }],
// ]);

// const tokenServiceMock: TokenService = {

//     async fetch (address: string): Promise<Token> {

//         if (tokenCache.has(address)) {

//             // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//             return tokenCache.get(address)!;
//         }

//         await readDelay();

//         const symbol = address.replace('0x', '').toUpperCase();
//         const name = symbol;
//         const decimals = 18;
//         const image = symbol.toLowerCase();

//         const token: Token = {
//             address,
//             name,
//             symbol,
//             decimals,
//             image,
//         };

//         tokenCache.set(address, token);

//         return token;
//     },

//     async fetchBalance (address: string, owner: string): Promise<string> {

//         await readDelay();

//         let balance = '0';

//         switch (address) {

//             case '0xSWIV':
//                 balance = '1000';
//                 break;

//             case '0xstkSWIV':
//                 balance = '20';
//                 break;

//             case '':
//                 balance = '1';
//                 break;

//             default:
//                 break;
//         }

//         return ethers.utils.parseEther(balance).toString();
//     },

//     async fetchAccountBalance (): Promise<Balance> {

//         const balance = await this.fetchBalance('', '');

//         return {
//             // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//             ...tokenCache.get('')!,
//             balance,
//         };
//     },

//     async allowance (address: string, owner: string, spender: string): Promise<string> {

//         await readDelay();

//         return ethers.utils.parseEther('0').toString();
//     },

//     async approve (address: string, spender: string, value: ethers.BigNumber): Promise<ethers.providers.TransactionReceipt> {

//         await writeDelay();

//         // throw new Error('Transaction reverted.');

//         const response = mockResposne('0xAPPROVE');

//         const receipt = await confirmTransaction(response);

//         return receipt;
//     },

//     async totalSupply (address: string): Promise<string> {

//         await readDelay();

//         let supply = '0';

//         switch (address) {

//             case '0xBAL-LP':
//                 supply = '25000';
//                 break;

//             default:
//                 break;
//         }

//         return ethers.utils.parseEther(supply).toString();
//     },
// };

// const stakedSwivelMock: StakedSwivelContract = {

//     async SWIV () {

//         await readDelay();

//         return '0xSWIV';
//     },

//     async balancerLPT () {

//         await readDelay();

//         return '0xBAL-LP';
//     },

//     async balancerVault () {

//         await readDelay();

//         return '0xBAL-VAULT';
//     },

//     async balancerPoolID () {

//         await readDelay();

//         return 1;
//     },

//     async cooldownLength () {

//         await readDelay();

//         return BigNumber.from(SECONDS_PER_DAY * 14);
//     },

//     async withdrawalWindow () {

//         await readDelay();

//         return BigNumber.from(SECONDS_PER_DAY * 7);
//     },

//     async cooldownTime (owner: string) {

//         await readDelay();

//         return BigNumber.from(Math.floor(Date.now() / 1000) + SECONDS_PER_DAY * 3);
//         // return BigNumber.from(Math.floor(Date.now() / 1000));
//         // return BigNumber.from(0);
//     },

//     async cooldownAmount (owner: string) {

//         await readDelay();

//         return ethers.utils.parseEther('2');
//         // return ethers.utils.parseEther('0');
//     },

//     async asset () {

//         return await this.balancerLPT();
//     },

//     async totalAssets () {

//         await readDelay();

//         return ethers.utils.parseEther('5000');
//     },

//     async totalSupply () {

//         await readDelay();

//         return ethers.utils.parseEther('10000');
//     },

//     async exchangeRateCurrent () {

//         const [totalAssets, totalSupply] = await Promise.all([
//             this.totalAssets(),
//             this.totalSupply(),
//         ]);

//         const rate = fixed(totalSupply.toString()).divUnsafe(fixed(totalAssets.toString())).floor();

//         return BigNumber.from(rate.toString());
//     },

//     async convertToShares (assets: string) {

//         const [totalAssets, totalSupply] = await Promise.all([
//             this.totalAssets(),
//             this.totalSupply(),
//         ]);

//         const shares = fixed(assets).mulUnsafe(fixed(totalSupply.toString()).divUnsafe(fixed(totalAssets.toString())));

//         return BigNumber.from(shares.floor());
//     },

//     async convertToAssets (shares: string) {

//         const [totalAssets, totalSupply] = await Promise.all([
//             this.totalAssets(),
//             this.totalSupply(),
//         ]);

//         const assets = fixed(shares).mulUnsafe(fixed(totalAssets.toString()).divUnsafe(fixed(totalSupply.toString())));

//         return BigNumber.from(assets.floor());
//     },

//     async cooldown (amount: string, overrides?: ethers.Overrides) {

//         await writeDelay();

//         return mockResposne('0xCOOLDOWN');
//     },

//     async depositZap (assets: string, receiver: string, overrides?: ethers.Overrides) {

//         await writeDelay();

//         // throw new Error('Transaction reverted.');

//         return mockResposne('0xDEPOSIT');
//     },

//     async withdrawZap (assets: string, receiver: string, owner: string, overrides?: ethers.Overrides) {

//         await writeDelay();

//         return mockResposne('0xWITHDRAW');
//     },
// };

// const balancerVaultMock: BalancerVaultContract = {

//     async getPoolTokens (poolId: number): Promise<[string[], BigNumber[], BigNumber]> {

//         await readDelay();

//         return [
//             [
//                 ENV.wethAddress,
//                 '0xSWIV',
//             ],
//             [
//                 ethers.utils.parseEther('2000'),
//                 ethers.utils.parseEther('100000'),
//             ],
//             BigNumber.from(Math.floor(Date.now() / 1000)),
//         ];
//     },
// };
