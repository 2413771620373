import { BigNumber } from 'ethers';
import { TemplateResult } from 'lit';
import { ERRORS } from '../../../constants';
import { amountPriceToPrincipalPremium, amountToken, checkBalance as checkBalanceBase, checkMinimum as checkMinimumBase, contractAmount, emptyOrZero, expandAmount, fixed, inferOrderSide, inferTokenType, inferYieldType, principalPremiumToAmountPrice } from '../../../helpers';
import { Balances, Market, Order, OrderbookEntry, OrderSide, OrderStatus, OrderType, OrderWithMeta } from '../../../types';

export * from './trade-history';

/**
 * Returns a market order's `volume` based on the `amount` input.
 *
 * @remarks
 * This method should be used for market orders only. Refer to the {@link orderFromInputs} method
 * for use with limit orders.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param a - the amount input value
 */
export const volumeFromInputs = (o: Order, m: Market, a: string): string => {

    return expandAmount(a, amountToken(o, m));
};

/**
 * Returns the `amount` input value based on a market order's `volume`.
 *
 * @remarks
 * This method should be used for market orders only. Refer to the {@link inputsFromOrder} method
 * for use with limit orders.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param v - the order volume
 */
export const inputsFromVolume = (o: Order, m: Market, v: string): string => {

    return contractAmount(v, amountToken(o, m));
};

/**
 * Returns a limit order's `principal` and `premium` based on the `amount` and `price` inputs and
 * the order's exit/vault configuration.
 *
 * @remarks
 * This method should be used for limit orders only. Refer to the {@link volumeFromInputs} method
 * for use with market orders.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param a - the amount input value
 * @param p - the price input value
 */
export const orderFromInputs = (o: Order, m: Market, a: string, p: string): { principal: string; premium: string; } => {

    const amount = expandAmount(a, amountToken(o, m));

    return amountPriceToPrincipalPremium(o, m, amount, p);
};

/**
 * Returns the `amount` and `price` input values based on a limit order's `principal`, `premium` and
 * exit/vault configuration.
 *
 * @remarks
 * This method should be used for limit orders only. Refer to the {@link inputsFromVolume} method
 * for use with market orders.
 *
 * @param o - the order
 * @param m - the market associated with the order
 */
export const inputsFromOrder = (o: Order, m: Market): { amount: string; price: string; } => {

    const { amount, price } = principalPremiumToAmountPrice(o, m);

    return {
        amount: contractAmount(amount, amountToken(o, m)),
        price,
    };
};

/**
 * Checks if a user's token balance is sufficient for an order's `amount` input value.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param b - the user's token balances (linked to the market's tokens)
 * @param a - the amount input value
 * @returns an error message if the balance is insufficient, `undefined` otherwise
 */
export const checkBalance = (o: Order, m: Market, b: Balances, a: string): string | undefined => {

    if (emptyOrZero(a)) return;

    // get the token represented by the amount
    const token = amountToken(o, m);

    // convert the amount to the proper token unit
    const amount = expandAmount(a, token);

    if (!checkBalanceBase(o, m, b, amount)) {

        return ERRORS.COMPONENTS.ORDER.INSUFFICIENT_BALANCE.message(token);
    }
};

/**
 * Checks if a limit order's principal is above the `minPrincipal` defined by the BE's config.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param t - the order type ('limit' or 'market')
 * @returns an error message if the order principal is below minimum, `undefined` otherwise
 */
export const checkMinimum = (o: Order, m: Market, t: OrderType): TemplateResult | undefined => {

    if (!checkMinimumBase(o, m, t)) {

        const yieldType = inferYieldType(o);
        const tokenType = inferTokenType(o);

        return (yieldType === 'fixed')
            ? ERRORS.COMPONENTS.ORDER.INSUFFICIENT_PRINCIPAL.FIXED.message()
            : (yieldType === 'floating')
                ? ERRORS.COMPONENTS.ORDER.INSUFFICIENT_PRINCIPAL.AMPLIFIED.message()
                : (tokenType === 'nToken')
                    ? ERRORS.COMPONENTS.ORDER.INSUFFICIENT_PRINCIPAL.N_TOKEN.message()
                    : ERRORS.COMPONENTS.ORDER.INSUFFICIENT_PRINCIPAL.ZC_TOKEN.message();
    }
};

/**
 * Returns a class name representing the orderbook side of an order.
 *
 * @param o - the `Order` or `OrderSide`
 */
export const orderSideClass = (o: Order | OrderSide): string => {

    const side = (typeof o === 'string')
        ? o
        : inferOrderSide(o);

    return (side === 'receivingPremium')
        ? 'receiving-premium'
        : 'paying-premium';
};

/**
 * Returns a class name for the order button representing initiates/exits.
 *
 * @param o - the order
 */
export const orderButtonClass = (o: Order): string => {

    return o.exit ? 'exit' : 'initiate';
};

/**
 * Returns the orderbook's `spread` and `spreadRate`.
 *
 * @param r - an array of `receivingPremium` orders with metadata
 * @param p - an array of `payingPremium` orders with metadata
 */
export const orderbookSpread = (r: OrderWithMeta[], p: OrderWithMeta[]): { spread: string; spreadRate: string; } => {

    // the lowest price receiving premium
    const low = parseFloat(r[0]?.meta.price || '0');

    // the highest price paying premium
    const high = parseFloat(p[0]?.meta.price || '0');

    const spread = Math.abs(high - low);
    const spreadRate = (high !== 0) ? spread / high * 100 : 0;

    return {
        spread: spread.toFixed(18),
        spreadRate: spreadRate.toFixed(18),
    };
};

/**
 * Creates an array of {@link OrderbookEntry}s
 *
 * @remarks
 * An {@link OrderbookEntry} stacks multiple orders with the same price into a single entry,
 * accummulating the available premium and principal of the stacked orders into a single 'volume'.
 *
 * @param o - an array of {@link OrderWithMeta}
 */
export const orderbookEntries = (o: OrderWithMeta[]): OrderbookEntry[] => {

    return (o.length === 0)
        ? []
        : o.reduce<OrderbookEntry[]>((entries, current) => {

            const previous = entries[entries.length - 1];

            if (previous.meta.price === '' || previous.meta.price === current.meta.price) {

                previous.meta.price = current.meta.price;
                previous.meta.premiumAvailable = BigNumber.from(previous.meta.premiumAvailable).add(current.meta.premiumAvailable).toString();
                previous.meta.principalAvailable = BigNumber.from(previous.meta.principalAvailable).add(current.meta.principalAvailable).toString();

                previous.orders.push(current);

            } else {

                entries.push({
                    meta: {
                        price: current.meta.price,
                        premiumAvailable: current.meta.premiumAvailable,
                        principalAvailable: current.meta.principalAvailable,
                    },
                    orders: [current],
                });
            }

            return entries;

        }, [{
            meta: {
                price: '',
                premiumAvailable: '0',
                principalAvailable: '0',
            },
            orders: [],
        }]);
};

/**
 * Returns an order's fill amount as percentage
 *
 * @param o - the order with metadata
 */
export const orderFillPercentage = (o: OrderWithMeta): string => {

    const principal = fixed(o.order.principal);
    const available = fixed(o.meta.principalAvailable);

    return fixed(1).subUnsafe(available.divUnsafe(principal)).mulUnsafe(fixed(100)).toString();
};

/**
 * Returns a label describing the type of the order in user-friendly terms
 *
 * @param o - the order
 */
export const orderTypeLabel = (o: { exit: boolean; vault: boolean; }): string => {

    const yieldType = inferYieldType(o);
    const tokenType = inferTokenType(o);

    if (yieldType === 'fixed') return 'Fixed Yield';
    else if (yieldType === 'floating') return 'Amplified Yield';
    else if (tokenType === 'nToken') return 'Sell YT';
    else if (tokenType === 'zcToken') return 'Sell PT';
    else return 'Unknown';
};

/**
 * A mapping of {@link OrderStatus} to status label.
 */
const ORDER_STATUS_LABELS: Record<OrderStatus, string> = {
    'CANCELLED': 'CANCELLED',
    'EXPIRED': 'EXPIRED',
    'FULL': 'FULL',
    'INSOLVENT': 'INSOLVENT',
    // temporarily insolvent orders will be described as VALIDATING
    // this label could also be used for other transitive states
    'INSOLVENT_TEMPORARY': 'VALIDATING',
    // invalid orders never have the status INVALID
    'INVALID': 'INVALID',
    // valid orders will be described as OPEN
    'VALID': 'OPEN',
};

/**
 * Returns a label describing the status of the order in user-friendly terms
 *
 * @param o - the order with metadata
 */
export const orderStatusLabel = (o: OrderWithMeta): string => {

    return ORDER_STATUS_LABELS[o.meta.status];
};
