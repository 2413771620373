/**
 * Capitalizes a string
 *
 * @param s - the string to capitalize
 * @returns the capitalized string
 */
export const capitalize = (s: string): string => {

    return s[0].toUpperCase() + s.slice(1);
};
