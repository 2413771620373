import { ARBITRUM, ARBITRUM_RINKEBY, currentNetwork, ETHEREUM, ETHEREUM_GOERLI } from '../constants/networks';
import { Preferences, preferences } from './preferences';
import { wallet, WalletConnection } from './wallet';

export type BaseTheme = Exclude<Preferences['theme'], null>;

export type NetworkTheme = 'arbitrum' | 'ethereum';

const THEME_CLASSES: Record<BaseTheme | NetworkTheme, string> = {
    default: 'theme-default',
    dark: 'theme-dark',
    light: 'theme-light',
    arbitrum: 'theme-arbitrum',
    ethereum: 'theme-ethereum',
};

export class ThemeService {

    protected baseTheme?: BaseTheme;

    protected networkTheme?: NetworkTheme;

    constructor (protected root: HTMLElement = document.documentElement) {

        this.handleBaseThemeChange = this.handleBaseThemeChange.bind(this);
        this.handleNetworkThemeChange = this.handleNetworkThemeChange.bind(this);
        this.handleWalletConnection = this.handleWalletConnection.bind(this);
    }

    start () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        preferences.subscribe(this.handleBaseThemeChange, 'theme');

        // eslint-disable-next-line @typescript-eslint/unbound-method
        wallet.listen('connect', this.handleWalletConnection);

        this.detectNetworkTheme();
    }

    stop () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        preferences.unsubscribe(this.handleBaseThemeChange, 'theme');

        // eslint-disable-next-line @typescript-eslint/unbound-method
        wallet.unlisten('connect', this.handleWalletConnection);
    }

    protected handleBaseThemeChange (theme: BaseTheme) {

        this.baseTheme = theme;

        this.updateTheme();
    }

    protected handleNetworkThemeChange (theme: NetworkTheme) {

        this.networkTheme = theme;

        this.updateTheme();
    }

    protected handleWalletConnection (connection: unknown) {

        this.detectNetworkTheme(connection as WalletConnection);
    }

    protected updateTheme () {

        Object.values(THEME_CLASSES).forEach(theme => this.root.classList.remove(theme));

        if (this.baseTheme) {

            this.root.classList.add(THEME_CLASSES[this.baseTheme]);
        }

        if (this.networkTheme) {

            this.root.classList.add(THEME_CLASSES[this.networkTheme]);
        }
    }

    protected detectNetworkTheme (connection?: WalletConnection) {

        const network = currentNetwork(connection?.network.chainId);

        switch (network) {

            case ARBITRUM:
            case ARBITRUM_RINKEBY:

                this.handleNetworkThemeChange('arbitrum');
                break;

            case ETHEREUM:
            case ETHEREUM_GOERLI:

                this.handleNetworkThemeChange('ethereum');
                break;
        }
    }
}

export const theme = new ThemeService();
