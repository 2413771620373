import { TimeService } from './interfaces';

export class LocalTimeService implements TimeService {

    /**
     * Allows setting an offset from the actual local time in ms
     */
    protected offset: number;

    /**
     * Create a LocalTimeService instance
     *
     * @param offset - optional offset in ms from the actual local time
     */
    constructor (offset = 0) {

        this.offset = offset;
    }

    time (): number {

        return Date.now() + this.offset;
    }

    timestamp (): string {

        return Math.round((Date.now() + this.offset) / 1000).toString();
    }
}
