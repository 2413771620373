import { EthereumProviderIdentifier } from '@swivel-finance/connect';
import { ConsentService, ConsentStatus, ConsentTopics, LocalStorageAdapter, StorageAdapter, STORAGE_TOPIC, Subscriber } from '@swivel-finance/consent';
import { HOME_ROUTE_ID, POSITIONS_ROUTE_ID, PROFESSIONAL_ROUTE_ID } from '../routes/ids';
import { MarketLike } from '../types';
import { consent, SwivelConsentTopics } from './consent';
import { router } from './router';

/**
 * The Preferences interface
 *
 * @remarks
 * The keys of this interface are used as topics for the {@link PreferencesService}.
 * The types are mapped to the service's `set`, `get` and `subscribe` methods.
 * Furthermore, the keys of this interface are stored in localStorage if the consent
 * service grants the `ConsentCategory.PREFERENCES` permission.
 */
export interface Preferences {
    theme: 'light' | 'dark' | 'default' | null;
    autoConnect: boolean | null;
    walletIdentifier: EthereumProviderIdentifier | null;
    lastMarket: MarketLike | null;
    firstVisit: boolean | null;
    homeFirstVisit: boolean | null;
    professionalFirstVisit: boolean | null;
    positionsFirstVisit: boolean | null;
    arbitrumFirstVisit: boolean | null;
    migrationNotice: boolean | null;
    disableOversizedWarning: boolean | null;
    disableFixedRateInfo: boolean | null;
}

export type PreferencesTopic = keyof Preferences;

export const PREFERENCES_TOPICS: PreferencesTopic[] = [
    'theme',
    'autoConnect',
    'walletIdentifier',
    'lastMarket',
    'firstVisit',
    'homeFirstVisit',
    'professionalFirstVisit',
    'positionsFirstVisit',
    'arbitrumFirstVisit',
    'migrationNotice',
    'disableOversizedWarning',
    'disableFixedRateInfo',
];

// the version allows us to ignore previous preferences on the same domain
export const PREFERENCES_VERSION = 'v3';

// by including the version in the prefix, we can create unique preference keys per version
export const PREFERENCES_PREFIX = `${ PREFERENCES_VERSION }.preferences.`;

/**
 * The PreferencesService
 *
 * @remarks
 * The PreferencesService should be used to read/write user preferences as it will respect user
 * consent settings and clean up after itself when disabled (consent withdrawn).
 *
 * The preferences service works similarly to other consent-based services (e.g. BugsnagService).
 * It depends on the `ConsentService` and is enabled/disabled based on the `ConsentCategory.PREFERENCES`
 * permission. The PreferencesService uses local storage but can be configured to use a different
 * `StorageAdapter`.
 */
export class PreferencesService {

    protected consentService: ConsentService<SwivelConsentTopics>;

    protected storage: StorageAdapter<unknown, PreferencesTopic>;

    protected enabled = false;

    constructor (consentService: ConsentService<SwivelConsentTopics>, storage?: StorageAdapter<unknown, PreferencesTopic>) {

        this.consentService = consentService;

        this.consentService.subscribe((message) => {

            if (message === ConsentStatus.GRANTED) {

                this.enable();

            } else {

                this.disable();
            }

        }, STORAGE_TOPIC);

        this.storage = storage ?? new LocalStorageAdapter(PREFERENCES_TOPICS, { prefix: PREFERENCES_PREFIX });
    }

    enable (): void {

        this.enabled = true;
    }

    disable (): void {

        PREFERENCES_TOPICS.forEach(topic => this.storage.delete(topic));

        this.enabled = false;
    }

    subscribe<K extends PreferencesTopic = PreferencesTopic, T = Preferences[K]> (s: Subscriber<T, K>, t?: K | K[]): void {

        this.storage.subscribe(s as Subscriber<unknown, PreferencesTopic>, t);
    }

    unsubscribe<K extends PreferencesTopic = PreferencesTopic, T = Preferences[K]> (s: Subscriber<T, K>, t?: K | K[]): boolean {

        return this.storage.unsubscribe(s as Subscriber<unknown, PreferencesTopic>, t);
    }

    unsubscribeAll (): void {

        this.storage.unsubscribeAll();
    }

    clear (): void {

        if (!this.enabled) return;

        PREFERENCES_TOPICS.forEach(topic => this.storage.delete(topic));
    }

    delete (key: PreferencesTopic): void {

        if (!this.enabled) return;

        this.storage.delete(key);
    }

    get<K extends PreferencesTopic = PreferencesTopic, T extends Preferences[K] = Preferences[K]> (key: K): T | null {

        // we can always try to read the storage, even if storage permissions are not yet set (we can only read previously allowed entries)
        // we just have to make sure not to store data when permission is not given
        return this.storage.get(key) as T;
    }

    set<K extends PreferencesTopic = PreferencesTopic, T extends Preferences[K] = Preferences[K]> (key: K, value: T): void {

        if (!this.enabled) return;

        this.storage.set(key, value);
    }
}

// the (singleton) preferences service instance
const preferences = new PreferencesService(consent);



/**
 * Update user preferences.
 *
 * @remarks
 * We track the overall first time visit of the exchange and initialize the
 * theme preference. We also set the page preferences for the currently
 * active route.
 */
const updatePreferences = () => {

    if (consent.read('storage') === ConsentStatus.GRANTED) {

        preferences.set('firstVisit', preferences.get('firstVisit') === null ? true : false);

        preferences.set('theme', preferences.get('theme') ?? 'default');

        updatePagePreferences();
    }
};

/**
 * Update preferences for currently vistited page.
 *
 * @remarks
 * We track the first time visit of a particular page, so we can show
 * each tour separately for first time visitors.
 */
const updatePagePreferences = () => {

    const currentPage = router().activeRoute?.route.id;

    if (!currentPage) return;

    switch (currentPage) {

        case HOME_ROUTE_ID:
            preferences.set('homeFirstVisit', preferences.get('homeFirstVisit') === null ? true : false);
            break;

        case PROFESSIONAL_ROUTE_ID:
            preferences.set('professionalFirstVisit', preferences.get('professionalFirstVisit') === null ? true : false);
            break;

        case POSITIONS_ROUTE_ID:
            preferences.set('positionsFirstVisit', preferences.get('positionsFirstVisit') === null ? true : false);
            break;
    }
};

/**
 * Update preferences on consent changes.
 */
const handleConsentChange = (status: ConsentStatus, topic?: ConsentTopics) => {

    // ignore consent changes to topics other than 'storage'
    if (topic && topic !== 'storage') return;

    // only update preferences if storage consent is granted
    // (if storage consent is revoked, preferences service will automatically clean up storage)
    if (status !== ConsentStatus.GRANTED) return;

    updatePreferences();
};

/**
 * Update page preferences on route changes.
 */
const handleRouteChange = () => {

    updatePagePreferences();
};

/**
 * Connect te preferences service to consent and router changes.
 *
 * @remarks
 * Wait for the page to load to ensure the router is initialized.
 */
window.addEventListener('load', () => {

    // we subscribe to the consent service for future changes
    consent.subscribe(handleConsentChange, 'storage');

    // we subscribe to the router service for future changes
    router().subscribe(handleRouteChange);

    // we initialize preferences after all other `window.onload` handlers have finished
    // this ensures that `window.onload` subscribers to preferences are notified
    setTimeout(updatePreferences, 0);
});

export { preferences };
