import { SubscriptionService } from '@swivel-finance/consent';
import { MARKET_LOCKED_TIME } from '../constants';
import { GlobalConfig, SwivelConfig } from '../types';
import { messages } from './messages';
import { swivelApi } from './swivel';

export const CONFIG_TOPICS = ['CHANGE'] as const;

export type ConfigTopics = typeof CONFIG_TOPICS[number];

/**
 * The initial {@link GlobalConfig} populated with the original constants.
 */
const DEFAULT_GLOBAL_CONFIG: GlobalConfig = {
    global: {
        marketLockedAt: MARKET_LOCKED_TIME,
        marketPreviewTimestamp: 0,
        paused: [],
        tokens: '',
    },
};

/**
 * The `ConfigService` implementation.
 */
export class ConfigService extends SubscriptionService<SwivelConfig, ConfigTopics> {

    protected _cache = {} as SwivelConfig;

    protected updated = false;

    protected promise?: Promise<SwivelConfig>;

    /**
     * A cached version of the latest {@link SwivelConfig} for synchronous access
     *
     * @remarks
     * Some values provided by the `ConfigService` were originally constants and
     * and are accessed by helpers and templates in a synchronous manner. It's
     * almost impossible to make those consumers asynchronous, so we need to
     * provide a synchronous bridge, which can still update over time.
     *
     * By using a cache we can offer synchronous, initial default values and
     * subsequent access of the cache will reflect the updated values from the
     * {@link swivelApi} config endpoint.
     */
    get cache (): SwivelConfig {

        return this._cache;
    }

    constructor () {

        super(CONFIG_TOPICS);

        // populate the initial cache from static data
        this.update(DEFAULT_GLOBAL_CONFIG as SwivelConfig);

        this.handleMessage = this.handleMessage.bind(this);
    }

    /**
     * Start the ConfigService.
     *
     * @remarks
     * This will subscribe the ConfigService to the {@link MessageService} and
     * fetch the config initially from {@link swivelApi}.
     */
    start () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        messages.subscribe(this.handleMessage, 'CONFIG.CHANGE');

        void this.fetch();
    }

    /**
     * Stop the ConfigService.
     *
     * @remarks
     * This will unsubscribe the ConfigService from the {@link MessageService}.
     */
    stop () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        messages.unsubscribe(this.handleMessage, 'CONFIG.CHANGE');
    }

    /**
     * Get an up-to-date {@link SwivelConfig}.
     *
     * @remarks
     * If a fetch is pending, we return that pending promise.
     * If no fetch is pending and we have an updated config, we return that.
     * Otherwise we fetch the config and return the promise.
     *
     * With this strategy we can share the config service and only fetch the
     * config once. At the same time, the config service updates if a
     * `CONFIG.CHANGE` event happens on the messages service and notifies
     * subscribers.
     *
     * @returns a Promise which resolves with a {@link ConfigResponse}
     */
    async get (): Promise<SwivelConfig> {

        return (this.promise)
            ? this.promise
            : (this.updated)
                ? this.cache
                : this.fetch();
    }

    protected async fetch (): Promise<SwivelConfig> {

        this.promise = swivelApi.fetchConfig();

        this.update(await this.promise);

        this.promise = undefined;
        this.updated = true;

        return this.cache;
    }

    protected update (config: SwivelConfig) {

        // ensure there is a `global` config present (BE might return an empty config on new envs)
        if (!config.global || Object.keys(config.global).length === 0) {

            config = { ...config, ...DEFAULT_GLOBAL_CONFIG } as SwivelConfig;
        }

        this._cache = config;

        this.notify({ ...config }, 'CHANGE');
    }

    protected handleMessage () {

        void this.fetch();
    }
}

/**
 * A singleton ConfigService instance.
 *
 * @example
 * ```typescript
 * import { config } from '/services';
 *
 * // subscribe to config changes
 * let PAUSED_MARKETS: MarketLike[] = [];
 *
 * config.subscribe((config) => {
 *     PAUSED_MARKETS = config.global.paused ?? [];
 * }, 'CHANGE');
 *
 * // get the current config
 * const currentConfig = await config.get();
 *
 * // use synchronous config data
 * const lockedTime = config.cache.global.marketLockedTime;
 * ```
 */
const config = new ConfigService();

// immediately start the instance: this service is long-lived and
// shouldn't be started / stopped by other services or components
config.start();

export { config };
