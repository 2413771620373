import { html } from 'lit';

/**
 * Creates a loader template.
 *
 * @param m - an optional message
 */
export const loader = (m?: string) =>
    html`
    <div class="loader">
        <svg class="spinner" viewBox="0 0 16 16"><circle class="circle" cx="8" cy="8" r="8"/></svg>
        ${ m ? html`<span class="message">${ m }</span>` : '' }
    </div>
    `;
