import { html } from 'lit';
import { TOOLTIPS } from '../../../constants';
import { contractAmount } from '../../../helpers';
import type { TokenInputChangeEvent } from '../../../shared/components';
import { errorMessage } from '../../../shared/templates';
import type { CreateOrderElement } from '../create';

export const orderFormTemplate = function (this: CreateOrderElement) {

    const state = this.orderService.state;

    const { market, balances, token, orderType, tokenType } = state.context;

    const amountLimit = this.amountLimit;
    const priceLimit = this.priceLimit;

    const amountPlaceholder = amountLimit
        ? `Max: ${ amountLimit }.00`
        : '0.00';

    const pricePlaceholder = priceLimit
        ? `Max: ${ priceLimit }`
        : '0.00';

    const balance = balances ? contractAmount(balances[tokenType].balance, balances[tokenType].decimals) : undefined;

    const disabled = this.disabled || this.locked;

    return html`
    <form @submit=${ this.submit.bind(this) }>

        ${ !this.simple
            ? html`
            <sw-form-field class="limit-toggle" direction="horizontal">
                <ui-toggle
                    id="limit"
                    aria-describedby="limit-tooltip"
                    .checked=${ orderType === 'limit' }
                    .disabled=${ disabled }
                    @ui-toggle-changed=${ () => this.setType(orderType === 'limit' ? 'market' : 'limit') }></ui-toggle>
                <label for="limit">Limit</label>
                <ui-tooltip id="limit-tooltip">
                    ${ TOOLTIPS.PROFESSIONAL.ORDER_INPUT.LIMIT_TOGGLE }
                </ui-tooltip>
            </sw-form-field>
            `
            : html``
        }

        <sw-token-input
            .label=${ 'Amount' }
            .name=${ 'amount' }
            .token=${ token }
            .balance=${ balance ?? '0' }
            .value=${ this.amount }
            .disabled=${ disabled }
            .max=${ amountLimit }
            .placeholder=${ amountPlaceholder }
            .showBalance=${ true }
            @change=${ (e: TokenInputChangeEvent) => this.setAmount(e.detail.value) }></sw-token-input>

        ${ this.amountError ? errorMessage(this.amountError) : '' }

        ${ orderType === 'limit'
            ? html`
            <sw-token-input
                .label=${ 'Price' }
                .name=${ 'price' }
                .token=${ market?.tokens.underlying }
                .value=${ this.price }
                .disabled=${ disabled }
                .max=${ priceLimit }
                .placeholder=${ pricePlaceholder }
                @change=${ (e: TokenInputChangeEvent) => this.setPrice(e.detail.value) }></sw-token-input>
            `
            : html``
        }

    </form>
    `;
};
