import { ethers } from 'ethers';
import { confirmTransaction } from '../helpers';
import { errors } from './errors';
import { wallet } from './wallet';

const FAUCET_ABI = [
    'function allocateTo(address owner, uint256 amount) public returns (bool)',
];

interface FaucetContract {
    allocateTo (owner: string, amount: ethers.BigNumber, overrides?: ethers.Overrides): Promise<ethers.providers.TransactionResponse>;
}

export const faucet = {

    /**
     * @param t - token address
     * @param o - owner address
     * @param a - amount
     */
    async allocate (t: string, o: string, a: string): Promise<ethers.providers.TransactionReceipt> {

        const { provider, signer } = await wallet.connection();
        const contract = new ethers.Contract(t, FAUCET_ABI, provider).connect(signer) as ethers.Contract & FaucetContract;

        try {

            const amount = ethers.BigNumber.from(a);

            const tx = await contract.allocateTo(o, amount);

            const receipt = await confirmTransaction(tx);

            return receipt;

        } catch (error) {

            // process rpc errors
            throw errors.isProcessed(error) ? error : errors.process(error);
        }
    },
};
