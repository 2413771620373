import { PanelChangeEvent } from '@swivel-finance/ui/elements/panel-container';
import { task } from '@swivel-finance/ui/utils/async';
import { animationsDone } from '@swivel-finance/ui/utils/dom';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { locked, matured, paused } from '../../helpers';
import { HOME_ROUTE } from '../../routes';
import { RouteMatch, preferences, router } from '../../services';
import { services } from '../../state';
import { MarketEvent } from '../../state/market';

type MarketState = typeof services.market.state;

const enum LEND_STEPS {
    MARKET,
    PREVIEW,
}

/**
 * Navigate back to the previous step.
 */
// const back = function (this: LendElement) {

//     const wizard = this.wizard.value;

//     if (!wizard) return;

//     dispatch(wizard, new PanelNavigationEvent({ target: wizard, panel: PanelDirection.PREVIOUS }));
// };

/**
 * An alternative template for showing the selected market and allowing the user to change it.
 *
 * @description
 * This template is currently not used in favor of the header element in the simplified create order element.
 */
// const marketIndicatorTemplate = function (this: LendElement) {

//     const state = this.marketService.state;

//     const markets = state.context.markets;
//     const selected = state.context.selected;

//     const market = selected ? markets?.get(selected) : undefined;

//     return html`
//     <button class="market-indicator ${ market?.tokens.underlying.symbol || '' }" @click=${ () => back.call(this) }>
//         <div class="icon">
//             <ui-icon name="chevron"></ui-icon>
//         </div>
//         <div class="symbol">
//             <sw-symbol .name=${ market?.tokens.underlying.image }></sw-symbol>
//         </div>
//         <div class="name">
//             Lending <span>${ market ? market.tokens.underlying.symbol : '' }</span>
//         </div>
//         <div class="maturity">
//             <span>${ market ? maturityDate(market.maturity, MATURITY_DATE_FORMAT.MEDIUM) : '' }</span>
//         </div>
//         <div class="action">
//             Change Market
//         </div>
//     </button>
//     `;
// };

const notificationTemplate = function (this: LendElement) {

    return html`
    <div class="hero-notification" ${ ref(this.notification) }>
        <div class="bubbles bubbles-far">
            <div class="bubble bubble-1"></div>
            <div class="bubble bubble-2"></div>
            <div class="bubble bubble-3"></div>
            <div class="bubble bubble-4"></div>
            <div class="bubble bubble-5"></div>
        </div>
        <div class="bubbles bubbles-mid">
            <div class="bubble bubble-1"></div>
            <div class="bubble bubble-2"></div>
            <div class="bubble bubble-3"></div>
            <div class="bubble bubble-4"></div>
            <div class="bubble bubble-5"></div>
        </div>
        <div class="bubbles bubbles-near">
            <div class="bubble bubble-1"></div>
            <div class="bubble bubble-2"></div>
            <div class="bubble bubble-3"></div>
        </div>
        <div class="notification-content">
            <ui-icon name="swivel-type"></ui-icon>
            <h2>Fixed Rate Lending</h2>
            <p>
                Swivel gives you the best capital efficiency on the highest fixed rates in DeFi.
                Choose from popular markets below.
            </p>
        </div>
        <div class="notification-buttons">
            <button class="dismiss" aria-label="dismiss" @click=${ () => this.handleNotificationDismissed() }>
                <ui-icon name="times"></ui-icon>
            </button>
        </div>
    </div>
    `;
};

const template = function (this: LendElement) {

    const state = this.marketService.state;
    const hasError = state.matches('error');
    const hasActive = state.matches('success') && !![...state.context.markets.values()].find(market => !paused(market) && !locked(market) && !matured(market));

    return html`
    <ui-wizard .current=${ this.step } @ui-panel-changed=${ (event: PanelChangeEvent) => this.handleStepChange(event) } ${ ref(this.wizard) }>

        <div class="step-container" data-part="panels">

            <div class="step-panel" data-part="panel">
                ${ !this.notificationDismissed
                    ? notificationTemplate.call(this)
                    : nothing
                }
                <sw-market-grid></sw-market-grid>
            </div>

            <div class="step-panel" data-part="panel">
                <sw-create-order .simple=${ true }></sw-create-order>
            </div>

        </div>

        <nav class="step-navigation" aria-label="Lend Steps">
            <ul class="step-list" data-part="triggers">
                <li class="step-link"><a data-part="trigger" href="#" aria-disabled=${ hasError } aria-label="Select a Market"></a></li>
                <li class="step-link"><a data-part="trigger" href="#" aria-disabled=${ hasError || !hasActive } aria-label="Lend Preview"></a></li>
            </ul>
        </nav>

    </ui-wizard>
    `;
};

@customElement('sw-lend')
export class LendElement extends LitElement {

    protected marketService = services.market;

    protected wizard = createRef<HTMLElement>();

    protected notification = createRef<HTMLElement>();

    @state()
    protected notificationDismissed = true;

    @state()
    protected step = LEND_STEPS.MARKET;

    constructor () {

        super();

        this.handleRouteChange = this.handleRouteChange.bind(this);
        this.handleStepChange = this.handleStepChange.bind(this);
        this.handleMarketTransition = this.handleMarketTransition.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        router().subscribe(this.handleRouteChange);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketService.onTransition(this.handleMarketTransition);
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        router().unsubscribe(this.handleRouteChange);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketService.off(this.handleMarketTransition);

        super.disconnectedCallback();

        this.step = LEND_STEPS.MARKET;
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleMarketTransition (state: MarketState, event: MarketEvent): void {

        // ignore state changes that happen on different routes
        if (router().activeRoute?.route !== HOME_ROUTE) return;

        if (state.matches('initial') || state.matches('fetching') || state.matches('completing') || (this.step === LEND_STEPS.MARKET && state.matches('error'))) {

            this.step = LEND_STEPS.MARKET;
        }

        if (state.matches('success') && event.type === 'MARKET.SELECT' && this.step === LEND_STEPS.MARKET) {

            this.step = LEND_STEPS.PREVIEW;
        }

        // trigger the lend notification once markets have loaded
        if (state.matches('success') && this.step === LEND_STEPS.MARKET) {

            void this.showNotification();
        }

        this.requestUpdate();
    }

    protected handleStepChange (event: PanelChangeEvent): void {

        const step = event.detail.panel as LEND_STEPS;

        if (step === this.step) return;

        this.step = step;

        switch (step) {

            case LEND_STEPS.MARKET:

                // TODO: re-fetch markets every time we go to step one or maybe just after a successful lend or some timeout?
                // if (this.marketService.state.matches('fetching')) return;

                // this.marketService.send('MARKET.FETCH');
                break;

            case LEND_STEPS.PREVIEW:

                // nothing for now
                break;
        }
    }

    protected handleRouteChange (match: RouteMatch): void {

        if (match.route === HOME_ROUTE) {

            // we can simply hijack the step change handler and reset the step
            this.handleStepChange(new PanelChangeEvent({ target: this, panel: LEND_STEPS.MARKET }));
        }
    }

    protected async showNotification (): Promise<void> {

        this.notificationDismissed = preferences.get('disableFixedRateInfo') || false;

        if (this.notificationDismissed) return;

        await this.updateComplete;

        await task(() => this.notification.value?.classList.add('ui-visible')).done;
    }

    protected async handleNotificationDismissed (): Promise<void> {

        preferences.set('disableFixedRateInfo', true);

        const notification = this.notification.value;

        if (notification) {

            notification.classList.remove('ui-visible');

            await animationsDone(notification);
        }

        this.notificationDismissed = true;
    }
}
