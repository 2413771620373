import { ethers } from 'ethers';
import { confirmTransaction } from '../helpers/wallet';
import { Balance, Token } from '../types';
import { errors } from './errors';
import { wallet } from './wallet';

const ERC20_ABI = [
    'function name() public view returns (string)',
    'function symbol() public view returns (string)',
    'function decimals() public view returns (uint8)',
    'function totalSupply() public view returns (uint256)',
    'function balanceOf(address owner) public view returns (uint256)',
    'function allowance(address owner, address spender) public view returns (uint256)',
    'function approve(address spender, uint256 value) public returns (bool)',
];

/**
 * We only need the read-only part of the ERC20 token API.
 */
interface ERC20Contract {
    name (): Promise<string>;
    symbol (): Promise<string>;
    decimals (): Promise<number>;
    totalSupply (): Promise<ethers.BigNumber>;
    balanceOf (owner: string): Promise<ethers.BigNumber>;
    allowance (owner: string, spender: string): Promise<ethers.BigNumber>;
    approve (spender: string, value: ethers.BigNumber): Promise<ethers.providers.TransactionResponse>;
}

/**
 * A cache for fetched token info.
 */
const tokenCache = new Map<string, Token>([]);

export interface TokenService {
    /**
     * Returns an ERC-20's token information.
     *
     * @param address - ERC-20 token address
     */
    fetch (address: string): Promise<Token>;
    /**
     * Returns the owners token balance.
     *
     * @param address - ERC-20 token address
     * @param owner - owner/account address
     */
    fetchBalance (address: string, owner: string): Promise<string>;
    /**
     * Returns the owners account (ETH) balance.
     */
    fetchAccountBalance (): Promise<Balance>;
    /**
     * Returns the amount which spender is still allowed to withdraw from owner.
     *
     * @param address - ERC-20 token address
     * @param owner - owner/account address
     * @param spender - spender address
     */
    allowance (address: string, owner: string, spender: string): Promise<string>;
    /**
     * Approves the spender to withdraw from the owner up to the amount of value.
     *
     * @param address - ERC-20 token address
     * @param spender - spender address
     * @param value - value/amount to approve
     * @returns
     */
    approve (address: string, spender: string, value: ethers.BigNumber): Promise<ethers.providers.TransactionReceipt>;
    /**
     * Returns the total supply of the token.
     *
     * @param address - ERC-20 token address
     */
    totalSupply (address: string): Promise<string>;
}

export const token: TokenService = {

    async fetch (address: string): Promise<Token> {

        if (tokenCache.has(address)) {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return tokenCache.get(address)!;
        }

        try {

            const provider = (await wallet.connection()).provider;
            const contract = new ethers.Contract(address, ERC20_ABI, provider) as ethers.Contract & ERC20Contract;

            const [name, symbol, decimals] = await Promise.all([
                contract.name(),
                contract.symbol(),
                contract.decimals(),
            ]);

            const image = symbol.toLowerCase();
            // const image = `${ symbol.toLowerCase() }_alt`;

            const token: Token = {
                address,
                name,
                symbol,
                decimals,
                image,
            };

            tokenCache.set(address, token);

            return token;

        } catch (error) {

            throw errors.process(error);
        }
    },

    async fetchBalance (address: string, owner: string): Promise<string> {

        try {

            const provider = (await wallet.connection()).provider;
            const contract = new ethers.Contract(address, ERC20_ABI, provider) as ethers.Contract & ERC20Contract;

            const balance = await contract.balanceOf(owner);

            return balance.toString();

        } catch (error) {

            throw errors.process(error);
        }
    },

    async fetchAccountBalance (): Promise<Balance> {

        try {

            const { account, provider } = await wallet.connection();
            const ethBalance = (await provider.getBalance(account.address)).toString();

            return {
                address: '',
                balance: ethBalance,
                decimals: 18,
                name: 'Ether',
                symbol: 'ETH',
                image: undefined,
            };

        } catch (error) {

            throw errors.isProcessed(error) ? error : errors.process(error);
        }
    },

    async allowance (address: string, owner: string, spender: string): Promise<string> {

        try {

            const provider = (await wallet.connection()).provider;
            const contract = new ethers.Contract(address, ERC20_ABI, provider) as ethers.Contract & ERC20Contract;

            const allowance = await contract.allowance(owner, spender);

            return allowance.toString();

        } catch (error) {

            throw errors.process(error);
        }
    },

    async approve (address: string, spender: string, value: ethers.BigNumber): Promise<ethers.providers.TransactionReceipt> {

        try {

            const { provider, signer } = await wallet.connection();
            const contract = new ethers.Contract(address, ERC20_ABI, provider).connect(signer) as ethers.Contract & ERC20Contract;

            const tx = await contract.approve(spender, value);

            const receipt = await confirmTransaction(tx);

            return receipt;

        } catch (error) {

            throw errors.isProcessed(error) ? error : errors.process(error);
        }
    },

    async totalSupply (address: string): Promise<string> {

        try {

            const provider = (await wallet.connection()).provider;
            const contract = new ethers.Contract(address, ERC20_ABI, provider) as ethers.Contract & ERC20Contract;

            const totalSupply = await contract.totalSupply();

            return totalSupply.toString();

        } catch (error) {

            throw errors.process(error);
        }
    },
};

export default token;
