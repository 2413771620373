import { ENV } from '../env/environment';

// disable the logger if the `debug` flag is set to false
const disabled = !ENV.debug;

export interface Logger {
    disabled: boolean;
    /**
     * Log a message
     *
     * @param m - the message to log
     * @param p - additional optional params to log
     */
    log (m?: unknown, ...p: unknown[]): void;
    /**
     * Warn a message
     *
     * @param m - the message to warn
     * @param p - additional optional params to warn
     */
    warn (m?: unknown, ...p: unknown[]): void;
    /**
     * Error a message
     *
     * @param m - the message to error
     * @param p - additional optional params to error
     */
    error (m?: unknown, ...p: unknown[]): void;
}

/**
 * Create a log group with optional prefix
 *
 * @param n - the name of the log group (a common prefix)
 * @returns a {@link Logger} instance
 */
const group = (n?: string): Logger => {

    const prefix = n ? `[${ n }] ` : undefined;

    return {

        disabled: false,

        log (m?: unknown, ...p: unknown[]): void {

            if (this.disabled || disabled) return;

            console.log(...args(prefix, m, ...p));
        },

        warn (m?: unknown, ...p: unknown[]) {

            if (this.disabled || disabled) return;

            console.warn(...args(prefix, m, ...p));
        },

        error (m?: unknown, ...p: unknown[]) {

            if (this.disabled || disabled) return;

            console.error(...args(prefix, m, ...p));
        },
    };
};

/**
 * Parse the arguments for `console` invocations
 *
 * @param n - the name of the log group (the log group prefix)
 * @param m - the log message
 * @param p - additional log params
 * @returns an array of arguments for the `console` invocation
 */
const args = (n?: string, m?: unknown, ...p: unknown[]): unknown[] => {

    return [
        ...((n && typeof m === 'string')
            ? [n + m]
            : n
                ? [n, m]
                : [m]
        ),
        ...p,
    ];
};

export const logger = {

    // the logger service is a log group without prefix...
    ...group(),

    // ...and exposes a group method to create log groups
    group,
};
