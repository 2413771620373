import { PanelDirection, PanelNavigationEvent } from '@swivel-finance/ui/elements/panel-container';
import { activeElement } from '@swivel-finance/ui/utils/dom';
import { dispatch } from '@swivel-finance/ui/utils/events';
import { html, LitElement, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { TOOLTIPS } from '../../constants';
import { ENV } from '../../env/environment';
import { inferYieldType, locked, MATURITY_DATE_FORMAT, maturityDate, paused } from '../../helpers';
import '../../shared/components/token-input';
import { services } from '../../state';
import { OrderEvent } from '../../state/order';
import { OrderType, YieldType } from '../../types';
import { checkBalance, checkMinimum, inputsFromOrder, inputsFromVolume, orderButtonClass, orderFromInputs, volumeFromInputs } from './helpers';
import { orderDetailsTemplate } from './templates/order-details';
import { orderFooterTemplate } from './templates/order-footer';
import { orderFormTemplate } from './templates/order-form';
import { orderLockTemplate } from './templates/order-lock';

type OrderState = typeof services.order.state;

const simpleTemplate = function (this: CreateOrderElement) {

    const state = this.orderService.state;

    const { market } = state.context;

    return html`
    <header>
        <button aria-label="change market" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.PREVIOUS })) }>
            <ui-icon name="chevron"></ui-icon>
        </button>
        <span class="market">
            <sw-symbol class="protocol ${ market?.protocolData.symbol ?? '' }" .name=${ market?.protocolData.image }></sw-symbol>
            <sw-symbol .name=${ market?.tokens.underlying.image }></sw-symbol>
            <span class="name">${ market ? market.tokens.underlying.symbol : '' }</span>
            <span class="maturity">${ market ? maturityDate(market.maturity, MATURITY_DATE_FORMAT.MEDIUM) : '' }</span>
        </span>
    </header>
    ${ orderFormTemplate.apply(this) }
    ${ orderDetailsTemplate.apply(this) }
    ${ orderFooterTemplate.apply(this) }
    ${ this.locked ? orderLockTemplate() : '' }
    `;
};

const professionalTemplate = function (this: CreateOrderElement) {

    const state = this.orderService.state;
    const { tokenType, yieldType, market, order } = state.context;

    const nToken = market?.tokens.nToken.symbol || 'Yield Tokens';
    const zcToken = market?.tokens.zcToken.symbol || 'Principal Tokens';
    const underlying = market?.tokens.underlying.symbol || 'underlying';

    const disabled = this.disabled || this.locked;

    return html`
    <div role="tablist">
        ${ tokenType === 'underlying'
            ? html`
            <button role="tab"
                id="order-tab-fixed"
                class=${ orderButtonClass(order) }
                aria-controls="order-panel"
                aria-selected="${ yieldType === 'fixed' }"
                aria-describedby="fixed-yield-tooltip"
                ?disabled=${ disabled }
                @click=${ () => this.setYield('fixed') }>
                Fixed Yield
                <ui-icon name="question"></ui-icon>
                <ui-tooltip id="fixed-yield-tooltip">
                    ${ TOOLTIPS.PROFESSIONAL.ORDER_INPUT.FIXED_YIELD_TAB(zcToken, nToken, underlying) }
                </ui-tooltip>
            </button>
            <button role="tab"
                id="order-tab-floating"
                class=${ orderButtonClass(order) }
                aria-controls="order-panel"
                aria-selected="${ yieldType === 'floating' }"
                aria-describedby="floating-yield-tooltip"
                ?disabled=${ disabled }
                @click=${ () => this.setYield('floating') }>
                Amplified Yield
                <ui-icon name="question"></ui-icon>
                <ui-tooltip id="floating-yield-tooltip">
                    ${ TOOLTIPS.PROFESSIONAL.ORDER_INPUT.AMPLIFIED_YIELD_TAB(nToken) }
                </ui-tooltip>
            </button>
            `
            : html`
            <button role="tab"
                id="order-tab-sell"
                class=${ orderButtonClass(order) }
                aria-controls="order-panel"
                aria-selected="true"
                ?disabled=${ disabled }>
                Sell ${ tokenType === 'nToken' ? 'Yield Tokens' : 'Principal Tokens' }
                <ui-icon name="question" aria-describedby="sell-token-tooltip"></ui-icon>
                <ui-tooltip id="sell-token-tooltip">
                ${ tokenType === 'nToken'
                    ? TOOLTIPS.PROFESSIONAL.ORDER_INPUT.SELL_NTOKENS(nToken)
                    : TOOLTIPS.PROFESSIONAL.ORDER_INPUT.SELL_ZCTOKENS(nToken, zcToken, underlying)
                }
                </ui-tooltip>
            </button>
            `
        }
    </div>
    <section role="tabpanel" id="order-panel" aria-labelledby=${ tokenType === 'underlying' ? `order-tab-${ yieldType }` : 'order-tab-sell' }>
        ${ orderFormTemplate.apply(this) }
        ${ orderDetailsTemplate.apply(this) }
    </section>
    ${ orderFooterTemplate.apply(this) }
    ${ this.locked ? orderLockTemplate() : '' }
    `;
};

const template = function (this: CreateOrderElement) {

    return this.simple
        ? simpleTemplate.call(this)
        : professionalTemplate.call(this);
};

@customElement('sw-create-order')
export class CreateOrderElement extends LitElement {

    protected get amountLimit (): string | undefined {

        const state = this.orderService.state;

        if (ENV.limitAmount && state.matches('interactive')) {

            const { order, orderType } = state.context;

            // don't limit amount for limit orders
            if (orderType === 'limit') return;

            return (inferYieldType(order) === 'floating')
                ? '10'
                : '500';
        }
    }

    protected get priceLimit (): string | undefined {

        const state = this.orderService.state;

        if (ENV.limitPrice && state.matches('interactive')) {
            const decimals = state.context.token.decimals;
            return `0.${ '9'.repeat(decimals) }`;
        }
    }

    protected get canSubmit (): boolean {

        const state = this.orderService.state;

        const { error, fillPreviewPending } = state.context;

        return !this.disabled
            && !this.locked
            && !this.amountError
            && !error
            && !fillPreviewPending
            && (state.matches({ interactive: { market: 'valid' } }) || state.matches({ interactive: { limit: 'valid' } }));
    }

    protected orderService = services.order;

    @state()
    protected amountError: TemplateResult | string | undefined;

    @state()
    amount = '';

    @state()
    price = '';

    @property({
        attribute: true,
        reflect: true,
        type: Boolean,
    })
    disabled = false;

    @property({
        attribute: true,
        reflect: true,
        type: Boolean,
    })
    locked = false;

    @property({
        attribute: true,
        reflect: true,
        type: Boolean,
    })
    simple = false;

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.orderService.onTransition(this.handleTransition);
    }

    disconnectedCallback () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.orderService.off(this.handleTransition);

        super.disconnectedCallback();
    }

    createRenderRoot () {

        return this;
    }

    render () {

        return template.apply(this);
    }

    /**
     * Resets the order component and associated order state.
     */
    reset () {

        this.orderService.send('ORDER.RESET');
        this.amount = '';
        this.price = '';
        this.disabled = false;
    }

    /**
     * Sets the {@link YieldType} from the tab list.
     *
     * @param y - the `YieldType` of the order (`'fixed'` or `'floating'`)
     */
    protected setYield (y: YieldType) {

        const { yieldType } = this.orderService.state.context;

        if (yieldType === y) return;

        this.orderService.send({
            type: 'ORDER.YIELD',
            payload: y,
        });
    }

    /**
     * Sets the {@link OrderType} from the limit-toggle.
     *
     * @param t - the `OrderType` to set (`'market'` or `'limit'`)
     */
    protected setType (t: OrderType) {

        const { orderType } = this.orderService.state.context;

        if (orderType === t) return;

        this.orderService.send({
            type: 'ORDER.TYPE',
            payload: t,
        });
    }

    /**
     * Sets the amount from the amount token-input.
     *
     * @remarks
     * `v` will always be valid when received from the `sw-token-input` element.
     *
     * @param v - the new amount to set
     */
    protected setAmount (v: string) {

        if (this.amount === v) return;

        this.amount = v;

        this.updateOrder(this.amount, this.price);
    }

    /**
     * Sets the price from the price token-input.
     *
     * @remarks
     * `v` will always be valid when received from the `sw-token-input` element.
     *
     * @param v - the new price to set
     */
    protected setPrice (v: string) {

        if (this.price === v) return;

        this.price = v;

        this.updateOrder(this.amount, this.price);
    }

    /**
     * Updates the order with the provided amount and price.
     *
     * @param a - the amount in token denomination, e.g.: `1000` <DAI/USDC/...>
     * @param p - the price, e.g.: `0.05`
     */
    protected updateOrder (a: string, p: string) {

        // in `market` state we treat the `amount` input as `volume` and fetch a `fillPreview` when the amount changes
        if (this.orderService.state.matches({ interactive: 'market' })) {

            this.updateMarketOrder(a);
        }

        // in `limit` state we calculate `principal`/`premium` from the `amount` and `price` inputs and update the order when amount/price changes
        if (this.orderService.state.matches({ interactive: 'limit' })) {

            this.updateLimitOrder(a, p);
        }
    }

    /**
     * Updates a `market` order based on the `amount` input.
     *
     * @param a - the amount input value
     */
    protected updateMarketOrder (a: string) {

        const state = this.orderService.state;

        // in non-transitional states, we simply send an `ORDER.VOLUME` event which will
        // update the `volume` on the state machine context, clear any existing `fillPreview`
        // and cause the state machine to fetch a fresh `fillPreview`
        if (
            state.matches({ interactive: { market: 'invalid' } })
            || state.matches({ interactive: { market: 'valid' } })
            || state.matches({ interactive: { market: 'submitted' } })
        ) {

            const { order, market } = state.context;

            const volume = volumeFromInputs(order, market, a);

            this.orderService.send({
                type: 'ORDER.VOLUME',
                payload: volume,
            });
        }
    }

    /**
     * Updates a `limit` order based on the `amount` and `price` inputs.
     *
     * @param a - the amount input value
     * @param p - the price input value
     */
    protected updateLimitOrder (a: string, p: string) {

        const state = this.orderService.state;

        // NOTE: this state check narrows down `orderService.state.context` to the appropriate TypeState
        // and ensures TypeScript knows, which properties of the context are defined in the target state
        if (
            state.matches({ interactive: { limit: 'invalid' } })
            || state.matches({ interactive: { limit: 'valid' } })
            || state.matches({ interactive: { limit: 'submitted' } })
        ) {

            const { order, market } = state.context;

            const payload = orderFromInputs(order, market, a, p);

            // the order should receive the principal and premium in 'wei' or the appropriate unit
            this.orderService.send({
                type: 'ORDER.UPDATE',
                payload,
            });
        }
    }

    /**
     * Submits the order.
     *
     * @remarks
     * A `market` order will be submitted by invoking the swivel smart contract with the orders and fill amounts
     * from the `fillPreview`.
     * A `limit` order will be signed and submitted via the swivel API.
     *
     * @param e - the form's submit event
     */
    protected submit (e: Event) {

        // suppress form submission
        e.preventDefault();
        e.stopPropagation();

        if (!this.canSubmit) return;

        this.orderService.send('ORDER.SUBMIT');
    }

    /**
     * Updates the inputs based on the order state context
     */
    protected updateInputs () {

        const state = this.orderService.state;

        if (!state.matches('interactive')) return;

        const { order, market, orderType, volume } = state.context;

        const { amount, price } = inputsFromOrder(order, market);

        this.amount = amount;
        this.price = price;

        if (orderType !== 'market') return;

        this.amount = inputsFromVolume(order, market, volume ?? '');
    }

    protected handleTransition (state: OrderState, event: OrderEvent) {

        if (!state.matches({ interactive: { market: 'valid' } })
            && !state.matches({ interactive: { market: 'invalid' } })
            && !state.matches({ interactive: { market: 'previewing' } })
            && !state.matches({ interactive: { market: 'submitted' } })
            && !state.matches({ interactive: { limit: 'valid' } })
            && !state.matches({ interactive: { limit: 'invalid' } })
            && !state.matches({ interactive: { limit: 'submitted' } })
        ) {

            this.disabled = true;

        } else {

            const { order, market, balances, orderType } = state.context;

            // when switching the OrderType we want to update the order to reflect the `amount`
            // and `price` inputs to the relevant order `volume` or `principal/premium`
            if (event.type === 'ORDER.TYPE') {

                this.updateOrder(this.amount, this.price);
            }

            // when switching TokenType, YieldType or resetting the order, we want to update the
            // imputs with the correctly inferred values from the order state
            if (event.type === 'ORDER.TOKEN' || event.type === 'ORDER.YIELD' || event.type === 'ORDER.RESET') {

                this.updateInputs();
            }

            // check the available token balance and the minimum limit order size
            this.amountError = checkBalance(order, market, balances, this.amount)
                || checkMinimum(order, market, orderType);

            this.disabled = false;
        }

        // check if market is locked or paused on each transition (every order update causes a transition)
        this.locked = state.matches('interactive')
            ? locked(state.context.market) || paused(state.context.market)
            : false;

        this.requestUpdate();

        void this.updateComplete.finally(() => {

            const type = event.type as string;

            // during some of the invoked async services of the state machine (like checking allowance, outprized, submitting)
            // the create order form is disabled and loses focus which is bad for keyboard users - we want to restore focus
            // to the order form after certain services are done and focus hasn't moved manually in the meantime
            const validEvent = type === 'error.platform.checkAllowance'
                || type === 'error.platform.checkPrice'
                || type === 'error.platform.signOrder'
                || type === 'error.platform.submitOrder'
                || type === 'done.invoke.submitOrder';

            const otherFocus = activeElement() !== document.body;

            const shouldRefocus = validEvent && !otherFocus && !this.disabled;

            if (shouldRefocus) {

                this.querySelector<HTMLElement>('sw-token-input')?.click();
            }
        });
    }
}
