/* eslint-disable import/no-duplicates */
import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { SelectElement } from '@swivel-finance/ui/elements/select';
import { TooltipElement } from '@swivel-finance/ui/elements/tooltip';
import { utils } from 'ethers';
import { html, LitElement, nothing, PropertyValueMap } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ERRORS, TOOLTIPS } from '../../constants';
import { ENV } from '../../env/environment';
import { archived, contractAmount, emptyOrZero, expandAmount, locked, marketKey, matured, maturityDate, maturityDays, MATURITY_DATE_FORMAT, paused, redeemable } from '../../helpers';
import { POSITIONS_ROUTE } from '../../routes';
import { RouteMatch, router } from '../../services/router';
import { notifications, NotificationType } from '../../shared/components';
import { capitalize } from '../../shared/helpers';
import { errorMessage, marketStatus, message, protocolImage, status, tokenBalance, tokenImage, tokenSymbol, transaction } from '../../shared/templates';
import { services } from '../../state';
import { PositionAction, PositionActionType, PositionsEvent } from '../../state/positions';
import { Balances, Market } from '../../types';
import { actionBalance } from './helpers';
import { positionActionIcons, PositionActionSubmitEvent } from './position-popup';
import './position-popup';

type PositionsState = typeof services.positions.state;

const NOTIFICATION_OUTLET_ID = 'sw-positions-actions-outlet';

const marketIdentifier = (m: Market) => html`${ tokenSymbol(m.tokens.underlying) } ${ maturityDate(m.maturity, MATURITY_DATE_FORMAT.MEDIUM) }`;

const marketMaturity = (m: Market) => {

    const maturity = maturityDays(m.maturity);

    return (maturity > 1)
        ? html`${ maturity } days`
        : (maturity > 0)
            ? html`${ maturity } day`
            : (maturity === 0)
                ? html`Today`
                : html`Matured`;
};

const actionTemplate = function (this: PositionsElement, action: PositionAction) {

    const state = this.positionsService.state;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const balances = state.context.balances![state.context.markets!.indexOf(action.market)];
    const token = actionBalance(action.type, balances);
    const icon = positionActionIcons[action.type];

    return html`
    <div class="market">${ marketIdentifier(action.market) }</div>
    <div class="action"><ui-icon name=${ icon }></ui-icon> ${ tokenBalance(action.amount, token) }</div>
    ${ action.error ? html`<div class="error">${ action.error || ERRORS.DEFAULT.message }</div>` : transaction(action.transaction?.hash ?? '', '') }
    `;
};

const positionTemplate = function (this: PositionsElement, m: Market, b: Balances) {

    const state = this.positionsService.state;
    const actions = state.context.actions.get(marketKey(m));

    const isLocked = locked(m);
    const isPaused = paused(m);
    const isMatured = matured(m);
    const isSplitting = !!actions?.find(action => action.type === 'split' && action.status === 'pending');
    const isCombining = !!actions?.find(action => action.type === 'combine' && action.status === 'pending');
    const isRedeeming = !!actions?.find(action => action.type === 'redeem' && action.status === 'pending');
    const isRedeemingInterest = !!actions?.find(action => action.type === 'redeemInterest' && action.status === 'pending');

    const redeemableInterest = redeemable(m, b.vault);

    const entryStatus = (isLocked && !isMatured)
        ? 'locked'
        : isPaused
            ? 'paused'
            : '';

    return html`
    <div class="entry ${ entryStatus }">

        <div class="symbol">
            ${ tokenImage(m.tokens.underlying) }
            ${ protocolImage(m.protocolData) }
        </div>

        <div class="group market">
            <div class="identifier">
                ${ tokenSymbol(m.tokens.underlying) }
                <span class="label">
                    ${ maturityDate(m.maturity, MATURITY_DATE_FORMAT.MEDIUM) }
                </span>
            </div>
            <div class="remaining">
                <span class="label">
                    ${ marketMaturity(m) }
                </span>
                ${ (isLocked && !isMatured || isPaused)
                    ? marketStatus(isLocked ? 'locked' : 'paused')
                    : isMatured
                        ? nothing
                        : html`<button type="button" class="button-icon button-small primary" @click=${ () => this.goToMarket(m) }>Trade<ui-icon name="trade"></ui-icon></button>`
                }
            </div>
        </div>

        <div class="group underlying">
            <span class="label">
                Underlying Balance
            </span>
            ${ tokenBalance(b.underlying.balance, b.underlying, this.precision, this.fixed) }
            ${ !isMatured && !isLocked && !isPaused && !emptyOrZero(b.underlying.balance)
                ? html`<sw-position-popup
                    action=${ 'split' }
                    ?disabled=${ isSplitting }
                    .balances=${ b }
                    @submit=${ (e: PositionActionSubmitEvent) => this.performAction(m, e.detail.amount, 'split') }></sw-position-popup>`
                : nothing
            }
        </div>

        <div class="divider"></div>

        <div class="group tokens">
            <div class="group nToken">
                <span class="label">
                    YT Balance<ui-icon name="question" aria-describedby="positions-ntoken"></ui-icon>
                </span>
                ${ tokenBalance(b.nToken.balance, b.nToken, this.precision, this.fixed) }
            </div>
            <div class="group zcToken">
                <span class="label">
                    PT Balance<ui-icon name="question" aria-describedby="positions-zctoken"></ui-icon>
                </span>
                ${ tokenBalance(b.zcToken.balance, b.zcToken, this.precision, this.fixed) }
            </div>
            ${ !isMatured && !isPaused && !emptyOrZero(b.nToken.balance) && !emptyOrZero(b.zcToken.balance)
                ? html`<sw-position-popup
                    action=${ 'combine' }
                    ?disabled=${ isCombining }
                    .balances=${ b }
                    @submit=${ (e: PositionActionSubmitEvent) => this.performAction(m, e.detail.amount, 'combine') }></sw-position-popup>`
                : isMatured && !isPaused && !emptyOrZero(b.zcToken.balance)
                    ? html`<sw-position-popup
                        action=${ 'redeem' }
                        ?disabled=${ isRedeeming }
                        .balances=${ b }
                        @submit=${ (e: PositionActionSubmitEvent) => this.performAction(m, e.detail.amount, 'redeem') }></sw-position-popup>`
                    : nothing
            }
        </div>

        <div class="divider"></div>

        <div class="group redeemable">
            <span class="label">
                Interest<ui-icon name="question" aria-describedby="positions-redeemable"></ui-icon>
            </span>
            ${ tokenBalance(redeemableInterest, b.underlying, this.precision, this.fixed) }
            ${ !isPaused && !emptyOrZero(redeemableInterest)
                ? html`<sw-position-popup
                    action=${ 'redeemInterest' }
                    ?disabled=${ isRedeemingInterest }
                    .balances=${ b }
                    .amount=${ contractAmount(redeemableInterest, b.underlying) }
                    @submit=${ (e: PositionActionSubmitEvent) => this.performAction(m, e.detail.amount, 'redeemInterest') }></sw-position-popup>`
                : nothing
            }
        </div>
    </div>
    `;
};

const noPositionsTemplate = function (this: PositionsElement) {

    return message(html`<ui-icon name="info"></ui-icon>You have no ${ this.filter } positions.`);
};

const selectTriggerTemplate = function (this: SelectElement): unknown {

    return html`
    <span class="label">${ this.placeholder }</span>
    <span class="selection">${ this.value ? capitalize(this.value as string) : '' }</span>
    `;
};

const sidebarTemplate = function (this: PositionsElement) {

    return html`
    <div class="sidebar">

        <ui-select
            class="sort-select"
            placeholder="Sort Markets"
            .triggerTemplate=${ selectTriggerTemplate }
            @ui-value-changed=${ (e: ValueChangeEvent<PositionsSortOrder>) => this.handleSorting(e.detail.current) }>
            <ui-listbox data-part="overlay" role="menu" class="sort-select-overlay" aria-labelledby="sort-select-label">
                <label id="sort-select-label">Sort Markets</label>
                <ui-listitem role="menuitemradio" aria-checked="${ this.sorting === 'balance' }" .value=${ 'balance' }>
                    <span class="value">Balance</span>
                </ui-listitem>
                <ui-listitem role="menuitemradio" aria-checked="${ this.sorting === 'maturity' }" .value=${ 'maturity' }>
                    <span class="value">Maturity</span>
                </ui-listitem>
            </ui-listbox>
        </ui-select>

        <ui-select
            class="filter-select"
            placeholder="Filter Markets"
            .triggerTemplate=${ selectTriggerTemplate }
            @ui-value-changed=${ (e: ValueChangeEvent<PositionsFilter>) => this.handleFilter(e.detail.current) }>
            <ui-listbox data-part="overlay" role="menu" class="filter-select-overlay" aria-labelledby="filter-select-label">
                <label role="text" id="filter-select-label">Filter Markets</label>
                <ui-listitem role="menuitemradio" aria-checked="${ this.filter === 'active' }" .value=${ 'active' }>
                    <span class="value">Active</span>
                    <ui-icon tabindex="-1" name="question" aria-describedby="positions-filter-active"></ui-icon>
                    <ui-tooltip id="positions-filter-active">
                        ${ TOOLTIPS.POSITIONS.FILTERS.ACTIVE }
                    </ui-tooltip>
                </ui-listitem>
                <ui-listitem role="menuitemradio" aria-checked="${ this.filter === 'matured' }" .value=${ 'matured' }>
                    <span class="value">Matured</span>
                    <ui-icon tabindex="-1" name="question" aria-describedby="positions-filter-matured"></ui-icon>
                    <ui-tooltip id="positions-filter-matured">
                        ${ TOOLTIPS.POSITIONS.FILTERS.MATURED }
                    </ui-tooltip>
                </ui-listitem>
                <ui-listitem role="menuitemradio" aria-checked="${ this.filter === 'archived' }" .value=${ 'archived' }>
                    <span class="value">Archived</span>
                    <ui-icon tabindex="-1" name="question" aria-describedby="positions-filter-archived"></ui-icon>
                    <ui-tooltip id="positions-filter-archived">
                        ${ TOOLTIPS.POSITIONS.FILTERS.ARCHIVED }
                    </ui-tooltip>
                </ui-listitem>
                <a class="docs-link" role="link" href="${ ENV.docsUrl }swivel-exchange/maturity/" target="_blank" rel="noopener">About Maturity</a>
            </ui-listbox>
        </ui-select>

        <sw-notification-outlet id=${ NOTIFICATION_OUTLET_ID }></sw-notification-outlet>

    </div>`;
};

const template = function (this: PositionsElement) {

    const state = this.positionsService.state;

    const positions = (this.sorting === 'balance')
        ? this.filterPositions(this.positionsByBalance)
        : this.filterPositions(this.positionsByMaturity);

    const noPositions = positions.length === 0;

    return html`
    ${ sidebarTemplate.call(this) }

    ${ state.matches('initial') || (state.matches('fetching') && !this.positions.length)
        ? status(state.matches('fetching') ? 'loading' : 'initial')
        : state.matches('error')
            ? errorMessage(state.context.error)
            : html`
            <div class="main">
                <h2>Positions</h2>
                <div class="header">
                    <span>Market</span>
                    <span>Underlying Balance</span>
                    <span></span>
                    <span>
                        <ui-icon name="question" aria-describedby="positions-ntoken"></ui-icon>
                        <ui-tooltip id="positions-ntoken">
                            ${ TOOLTIPS.POSITIONS.HEADER.NTOKEN_BALANCE }
                        </ui-tooltip>
                        YT Balance
                    </span>
                    <span>
                        <ui-icon name="question" aria-describedby="positions-zctoken"></ui-icon>
                        <ui-tooltip id="positions-zctoken">
                            ${ TOOLTIPS.POSITIONS.HEADER.ZCTOKEN_BALANCE }
                        </ui-tooltip>
                        PT Balance
                    </span>
                    <span></span>
                    <span>
                        <ui-icon name="question" aria-describedby="positions-redeemable"></ui-icon>
                        <ui-tooltip id="positions-redeemable">
                            ${ TOOLTIPS.POSITIONS.HEADER.INTEREST }
                        </ui-tooltip>
                        Interest
                    </span>
                </div>
                <div class="list">
                    ${ noPositions
                        ? noPositionsTemplate.apply(this)
                        : positions.map(position => positionTemplate.call(this, position[0], position[1]))
                    }
                </div>
            </div>`
    }`;
};

export type PositionsSortOrder = 'balance' | 'maturity';

export type PositionsFilter = 'active' | 'matured' | 'archived';

@customElement('sw-positions')
export class PositionsElement extends LitElement {

    protected positionsService = services.positions;

    protected walletService = services.wallet;

    protected get notificationOutletId (): string|undefined {

        // TODO: this is not ideal - we have a breakpoint encoded in the component implementation
        return (window.innerWidth >= 768)
            ? NOTIFICATION_OUTLET_ID
            : undefined;
    }

    @property({
        attribute: true,
        type: Number,
    })
    precision = 4;

    @property({
        attribute: true,
        type: Boolean,
    })
    fixed = true;

    @property({
        attribute: true,
    })
    sorting: PositionsSortOrder = 'balance';

    @property({
        attribute: true,
    })
    filter: PositionsFilter = 'active';

    positions: [Market, Balances][] = [];

    positionsByBalance: [Market, Balances][] = [];

    positionsByMaturity: [Market, Balances][] = [];

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
        this.handleRouteChange = this.handleRouteChange.bind(this);
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.positionsService.onTransition(this.handleTransition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        router().subscribe(this.handleRouteChange);

        // the router dispatches the route change before the positions element
        // is rendered (the app element handles the main routing)
        // to process the current route, we need to manually invoke the handler
        this.handleRouteChange(router().activeRoute);
    }

    disconnectedCallback () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.positionsService.off(this.handleTransition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        router().unsubscribe(this.handleRouteChange);

        super.disconnectedCallback();
    }

    protected updated (changes: PropertyValueMap<PositionsElement>) {

        if (!this.hasUpdated) return;

        if (!changes.has('sorting') && !changes.has('filter')) return;

        // when `sorting` or `filter` changes, the position entries update and with it
        // the tooltip triggers - in this case we re-connect the tooltips
        this.updateTooltips();
    }

    protected createRenderRoot () {

        return this;
    }

    protected render () {

        return template.apply(this);
    }

    protected handleTransition (state: PositionsState, event: PositionsEvent) {

        if (state.matches('success')) {

            if (event.type === 'POSITIONS.ACTION'
                || event.type === 'POSITIONS.ACTION.SUCCESS'
                || event.type === 'POSITIONS.ACTION.FAILURE') {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const actions = state.context.actions.get(marketKey(event.payload.market))!;
                const action = actions[actions?.length - 1];

                switch (event.type) {

                    case 'POSITIONS.ACTION':

                        notifications.show({
                            id: `action-${ action.id }`,
                            type: 'progress',
                            dismissable: false,
                            content: () => actionTemplate.call(this, action),
                        }, this.notificationOutletId);

                        break;

                    case 'POSITIONS.ACTION.SUCCESS':
                    case 'POSITIONS.ACTION.FAILURE':

                        notifications.update(`action-${ event.payload.id }`, {
                            id: `action-${ event.payload.id }`,
                            type: event.payload.status as NotificationType,
                            dismissable: true,
                            timeout: event.payload.error ? 10 : undefined,
                            content: () => actionTemplate.call(this, event.payload),
                        });

                        if (event.type === 'POSITIONS.ACTION.SUCCESS') {

                            this.walletService.send('WALLET.FETCH');
                            this.positionsService.send('POSITIONS.FETCH');
                        }

                        break;
                }

            } else {

                // this happens of successful fetch
                this.sortPositions();
            }
        }

        this.requestUpdate();
    }

    protected handleRouteChange (m?: RouteMatch) {

        if (m?.route.id === POSITIONS_ROUTE.id) {

            if (m.url.searchParams.has('sortBy')) {

                this.sorting = (m.url.searchParams.get('sortBy') === 'maturity')
                    ? 'maturity'
                    : 'balance';
            }

            if (m.url.searchParams.has('filterBy')) {

                this.filter = (m.url.searchParams.get('filterBy') === 'matured')
                    ? 'matured'
                    : (m.url.searchParams.get('filterBy') === 'archived')
                        ? 'archived'
                        : 'active';
            }
        }
    }

    protected sortPositions () {

        const state = this.positionsService.state;

        if (!state.matches('success')) return;

        // group markets and balances
        this.positions = state.context.markets.map((market, index) => [market, state.context.balances[index]]);

        // sort by balance - balance is the sum on nTokens and zcTokens in a market
        this.positionsByBalance = [...this.positions].sort((p1, p2) => {

            const balance1 = utils.parseUnits(p1[1].nToken.balance, p1[1].nToken.decimals).add(utils.parseUnits(p1[1].zcToken.balance, p1[1].zcToken.decimals));
            const balance2 = utils.parseUnits(p2[1].nToken.balance, p2[1].nToken.decimals).add(utils.parseUnits(p2[1].zcToken.balance, p2[1].zcToken.decimals));

            return balance1.lt(balance2) ? -1 : balance1.gt(balance2) ? 1 : 0;
        }).reverse();

        // sort by maturity
        this.positionsByMaturity = [...this.positions].sort((p1, p2) => {

            const maturity1 = parseInt(p1[0].maturity);
            const maturity2 = parseInt(p2[0].maturity);

            return maturity1 - maturity2;
        });
    }

    protected filterPositions (p: [Market, Balances][]): [Market, Balances][] {

        return p.filter(position => (this.filter === 'active')
            ? !matured(position[0])
            : (this.filter === 'matured')
                ? matured(position[0]) && !archived(position[0])
                : archived(position[0]));
    }

    protected handleSorting (s: PositionsSortOrder = 'balance') {

        if (this.sorting !== s) {

            this.sorting = s;

            this.requestUpdate();
        }
    }

    protected handleFilter (f: PositionsFilter) {

        if (this.filter !== f) {

            this.filter = f;

            this.requestUpdate();
        }
    }

    protected goToMarket (m: Market) {

        const state = this.positionsService.state;

        if (!state.matches('success')) return;

        services.market.send({
            type: 'MARKET.SELECT',
            payload: marketKey(m),
        });

        void router().navigate('/professional');
    }

    protected performAction (m: Market, a: string, t: PositionActionType) {

        const state = this.positionsService.state;

        if (!state.matches('success')) return;

        if (emptyOrZero(a)) {

            notifications.show({
                type: 'info',
                content: 'Please provide a valid amount.',
            });

            return;
        }

        this.positionsService.send({
            type: 'POSITIONS.ACTION',
            payload: {
                type: t,
                market: m,
                amount: expandAmount(a, m.tokens.underlying),
            },
        });
    }

    protected updateTooltips () {

        this.renderRoot.querySelectorAll<TooltipElement>('ui-tooltip').forEach(tooltip => tooltip.connectTooltip());
    }
}
