import { ERRORS } from '../../../constants';
import { WALLET_STATUS, errors, wallet } from '../../../services';
import { notifications } from '../../../shared/components';
import { truncateHash } from '../../../shared/helpers';

/**
 * Display a wallet address in truncated fashion.
 *
 * @param h - the address hash
 * @returns - a truncated version of the original hash
 */
export const displayAddress = (h: string) => {

    return truncateHash(h, 7, 5);
};

/**
 * Copy the user's connected wallet address to clipboard.
 */
export const copyAddress = async () => {

    const walletService = wallet;

    if (walletService.state.status !== WALLET_STATUS.CONNECTED) return;

    try {

        await navigator.clipboard.writeText(walletService.state.connection.account.address);

        notifications.show({
            content: 'Address copied to clipboard.',
            type: 'success',
        });

    } catch (error) {

        const processedError = errors.process(error, ERRORS.COMPONENTS.ACCOUNT.CLIPBOARD);

        notifications.show({
            content: processedError.message,
            type: 'failure',
        });
    }
};
