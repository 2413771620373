import { html } from 'lit';
import { ENV } from '../env/environment';
import { Token } from '../types';

export const ERRORS = {
    HTTP: {
        BAD_RESPONSE: {
            name: 'HTTP.BAD_RESPONSE',
            message: 'Unable to parse response.',
        },
        BAD_REQUEST: {
            name: 'HTTP.BAD_REQUEST',
            message: 'Bad request.',
        },
        UNAUTHORIZED: {
            name: 'HTTP.UNAUTHORIZED',
            message: 'Access not authorized.',
        },
        FORBIDDEN: {
            name: 'HTTP.FORBIDDEN',
            message: 'Access forbidden.',
        },
        NOT_FOUND: {
            name: 'HTTP.NOT_FOUND',
            message: 'Resource not found.',
        },
        INTERNAL: {
            name: 'HTTP.INTERNAL',
            message: 'Internal server error.',
        },
        UNKNOWN: {
            name: 'HTTP.UNKNOWN',
            message: 'Unknown server error.',
        },
        NETWORK: {
            name: 'HTTP.NETWORK',
            message: 'Network error.',
        },
    },
    ETHEREUM: {
        // https://eips.ethereum.org/EIPS/eip-1193#provider-errors
        PROVIDER: {
            REJECTED: {
                name: 'ETHEREUM.PROVIDER.REJECTED',
                message: 'The user rejected the request.',
            },
            UNAUTHORIZED: {
                name: 'ETHEREUM.PROVIDER.UNAUTHORIZED',
                message: 'The requested account and/or method has not been authorized by the user.',
            },
            UNSUPPORTED: {
                name: 'ETHEREUM.PROVIDER.UNSUPPORTED',
                message: 'The requested method is not supported by this Ethereum provider.',
            },
            DISCONNECTED: {
                name: 'ETHEREUM.PROVIDER.DISCONNECTED',
                message: 'The provider is disconnected from all chains.',
            },
            DISCONNECTED_CHAIN: {
                name: 'ETHEREUM.PROVIDER.DISCONNECTED_CHAIN',
                message: 'The provider is disconnected from the requested chain.',
            },
        },
        // https://eips.ethereum.org/EIPS/eip-1474#error-codes
        RPC: {
            PARSE_ERROR: {
                name: 'ETHEREUM.RPC.PARSE_ERROR',
                message: 'Invalid JSON was received by the server.',
            },
            INVALID_REQUEST: {
                name: 'ETHEREUM.RPC.INVALID_REQUEST',
                message: 'The JSON sent is not a valid Request object.',
            },
            METHOD_NOT_FOUND: {
                name: 'ETHEREUM.RPC.METHOD_NOT_FOUND',
                message: 'The method does not exist / is not available.',
            },
            INVALID_PARAMS: {
                name: 'ETHEREUM.RPC.INVALID_PARAMS',
                message: 'Invalid method parameter(s).',
            },
            INVALID_INPUT: {
                name: 'ETHEREUM.RPC.INVALID_INPUT',
                message: 'Missing or invalid parameter(s).',
            },
            RESOURCE_NOT_FOUND: {
                name: 'ETHEREUM.RPC.RESOURCE_NOT_FOUND',
                message: 'Requested resource not found.',
            },
            RESOURCE_NOT_AVAILABLE: {
                name: 'ETHEREUM.RPC.RESOURCE_NOT_AVAILABLE',
                message: 'Requested resource not available.',
            },
            TRANSACTION_REJECTED: {
                name: 'ETHEREUM.RPC.TRANSACTION_REJECTED',
                message: 'Transaction rejected.',
            },
            METHOD_NOT_SUPPORTED: {
                name: 'ETHEREUM.RPC.METHOD_NOT_SUPPORTED',
                message: 'Method is not supported.',
            },
            LIMIT_EXCEEDED: {
                name: 'ETHEREUM.RPC.LIMIT_EXCEEDED',
                message: 'Request limit exceeded.',
            },
            VERSION: {
                name: 'ETHEREUM.RPC.VERSION',
                message: 'Version of JSON-RPC protocol is not supported.',
            },
            INTERNAL: {
                name: 'ETHEREUM.RPC.INTERNAL',
                message: 'Internal JSON-RPC error.',
            },
            UNKNOWN: {
                name: 'ETHEREUM.RPC.UNKNOWN',
                message: 'Unknown JSON-RPC error.',
            },
        },
        TRANSACTION: {
            SERVER: {
                name: 'ETHEREUM.TRANSACTION.SERVER',
                message: 'Server error.',
            },
            TIMEOUT: {
                name: 'ETHEREUM.TRANSACTION.TIMEOUT',
                message: 'Transaction timeout.',
            },
            CALL_EXCEPTION: {
                name: 'ETHEREUM.TRANSACTION.CALL_EXCEPTION',
                message: 'Transaction failed.',
            },
            FUNDS: {
                name: 'ETHEREUM.TRANSACTION.FUNDS',
                message: 'Insufficient funds.',
            },
            NETWORK: {
                name: 'ETHEREUM.TRANSACTION.NETWORK',
                message: 'Network validation error.',
            },
            NONCE: {
                name: 'ETHEREUM.TRANSACTION.NONCE',
                message: 'Nonce expired.',
            },
            UNDERPRICED: {
                name: 'ETHEREUM.TRANSACTION.UNDERPRICED',
                message: 'Transaction underpriced.',
            },
            CANCELLED: {
                name: 'ETHEREUM.TRANSACTION.CANCELLED',
                message: 'Transaction cancelled.',
            },
            REPLACED: {
                name: 'ETHEREUM.TRANSACTION.REPLACED',
                message: 'Transaction replaced.',
            },
            REVERTED: {
                name: 'ETHEREUM.TRANSACTION.REVERTED',
                message: 'Transaction reverted.',
            },
            UNSUPPORTED_OPERATION: {
                name: 'ETHEREUM.TRANSACTION.UNSUPPORTED_OPERATION',
                message: 'Network does not support requested operation.',
            },
            UNKNOWN: {
                name: 'ETHEREUM.TRANSACTION.UNKNOWN',
                message: 'Unknown transaction error.',
            },
        },
        METAMASK: {
            NO_METAMASK: {
                name: 'ETHEREUM.METAMASK.NO_METAMASK',
                message: 'Unable to detect window.ethereum. Make sure you have MetaMask installed.',
            },
            NON_METAMASK: {
                name: 'ETHEREUM.METAMASK.NON_METAMASK',
                message: 'Non-MetaMask window.ethereum detected.',
            },
            CHAIN: {
                name: 'ETHEREUM.METAMASK.CHAIN',
                message: `Please connect your MetaMask to the ${ ENV.networkName } network.`,
            },
        },
    },
    SERVICES: {
        WEBSOCKET: {
            ERROR: {
                name: 'SERVICES.WEBSOCKET.ERROR',
                message: 'Websocket error.',
            },
            PARSE_ERROR: {
                name: 'SERVICES.WEBSOCKET.PARSE_ERROR',
                message: 'Unable to parse message.',
            },
        },
        SWIVEL: {
            FILL_PREVIEW_EXCEEDED: {
                name: 'SERVICES.SWIVEL.FILL_PREVIEW_EXCEEDED',
                message: 'Not enough liquidity, place a limit order instead.',
            },
            FILL_PREVIEW_ORDERS_EXCEEDED: {
                name: 'SERVICES.SWIVEL.FILL_PREVIEW_ORDERS_EXCEEDED',
                message: 'Desired volume exceeds maximum number of orders allowed. Try a smaller amount or place a limit order instead.',
            },
            LIMIT_ORDER_PRINCIPAL: {
                name: 'SERVICES.SWIVEL.LIMIT_ORDER_PRINCIPAL',
                message: 'Insufficient order principal.',
            },
        },
        SSM: {
            STAKE: {
                name: 'SERVICES.SSM.STAKE',
                message: 'Failed to stake.',
            },
        },
        REWARDS: {
            FETCH: {
                name: 'SERVICES.REWARDS.FETCH',
                message: 'Failed to fetch rewards.',
            },
            CLAIM: {
                name: 'SERVICES.REWARDS.CLAIM',
                message: 'Failed to claim rewards.',
            },
        },
    },
    COMPONENTS: {
        APP: {
            METAMASK: {
                name: 'COMPONENTS.APP.METAMASK',
                message: 'Failed to connect to MetaMask.',
            },
        },
        ACCOUNT: {
            CLIPBOARD: {
                name: 'COMPONENTS.ACCOUNT.CLIPBOARD',
                message: 'Failed to copy address to clipboard.',
            },
        },
        MARKETS: {
            DEFAULT: {
                name: 'COMPONENTS.MARKETS.DEFAULT',
                message: 'Failed to fetch markets.',
            },
        },
        BALANCES: {
            DEFAULT: {
                name: 'COMPONENTS.BALANCES.DEFAULT',
                message: 'Failed to fetch balances.',
            },
        },
        ORDER: {
            INSUFFICIENT_BALANCE: {
                name: 'COMPONENTS.ORDER.INSUFFICIENT_BALANCE',
                message: (token: Token) => `Your ${ token.symbol } balance is not sufficient for this order amount.`,
            },
            INSUFFICIENT_PRINCIPAL: {
                FIXED: {
                    name: 'COMPONENTS.ORDER.INSUFFICIENT_PRINCIPAL.FIXED',
                    message: () => html`Your order amount is below the minimum order size. Try increasing the amount or place a market order. <a href="${ ENV.docsUrl }swivel-exchange/minimums-rate-limits-and-fees" target="_blank">More information</a>`,
                },
                AMPLIFIED: {
                    name: 'COMPONENTS.ORDER.INSUFFICIENT_PRINCIPAL.AMPLIFIED',
                    message: () => html`Your order amount is below the minimum order size. Try increasing the amount and/or lowering the price or place a market order. <a href="${ ENV.docsUrl }swivel-exchange/minimums-rate-limits-and-fees" target="_blank">More information</a>`,
                },
                N_TOKEN: {
                    name: 'COMPONENTS.ORDER.INSUFFICIENT_PRINCIPAL.N_TOKEN',
                    message: () => html`Your order amount is below the minimum order size. Try increasing the amount or place a market order. <a href="${ ENV.docsUrl }swivel-exchange/minimums-rate-limits-and-fees" target="_blank">More information</a>`,
                },
                ZC_TOKEN: {
                    name: 'COMPONENTS.ORDER.INSUFFICIENT_PRINCIPAL.ZC_TOKEN',
                    message: () => html`Your order amount is below the minimum order size. Try increasing the amount or place a market order. <a href="${ ENV.docsUrl }swivel-exchange/minimums-rate-limits-and-fees" target="_blank">More information</a>`,
                },
            },
        },
        CHART: {
            DEFAULT: {
                name: 'COMPONENTS.CHART.DEFAULT',
                message: 'Failed to fetch trade data.',
            },
        },
        REWARDS: {
            DEFAULT: {
                name: 'COMPONENTS.REWARDS.DEFAULT',
                message: 'Failed to fetch rewards data.',
            },
        },
    },
    STATE: {
        MARKET: {
            FETCH: {
                name: 'STATE.MARKET.FETCH',
                message: 'Failed to fetch markets.',
            },
            NO_MARKETS: {
                name: 'STATE.MARKET.NO_MARKETS',
                message: 'No markets available.',
            },
            PAUSED: {
                name: 'STATE.MARKET.PAUSED',
                message: 'Market is paused.',
            },
        },
        WALLET: {
            FETCH: {
                name: 'STATE.WALLET.FETCH',
                message: 'Failed to fetch balances.',
            },
        },
        ORDER: {
            FILL_PREVIEW: {
                name: 'STATE.ORDER.FILL_PREVIEW',
                message: 'Failed to fetch orderbook data for the requested amount.',
            },
            ALLOWANCE: {
                name: 'STATE.ORDER.ALLOWANCE',
                message: 'Failed to check allowance.',
            },
            APPROVAL: {
                name: 'STATE.ORDER.APPROVAL',
                message: 'Failed to process approval.',
            },
            SIGN: {
                name: 'STATE.ORDER.SIGN',
                message: 'Failed to sign limit order.',
            },
            SUBMIT: {
                name: 'STATE.ORDER.SUBMIT',
                message: 'Failed to submit order.',
            },
        },
        ORDERBOOK: {
            FETCH: {
                name: 'STATE.ORDERBOOK.FETCH',
                message: 'Failed to fetch orderbook.',
            },
        },
        USERORDERS: {
            ORDERS: {
                name: 'STATE.USERORDERS.ORDERS',
                message: 'Failed to fetch user orders.',
            },
            FILLS: {
                name: 'STATE.USERORDERS.FILLS',
                message: 'Failed to fetch order history.',
            },
        },
        POSITIONS: {
            FETCH: {
                name: 'STATE.POSITIONS.FETCH',
                message: 'Failed to fetch positions.',
            },
            ALLOWANCE: {
                name: 'STATE.POSITIONS.ALLOWANCE',
                message: 'Failed to check allowance.',
            },
            APPROVAL: {
                name: 'STATE.POSITIONS.APPROVAL',
                message: 'Failed to process approval.',
            },
        },
    },
    DEFAULT: {
        name: 'DEFAULT',
        message: 'Unknown error.',
    },
};

export const ERROR_CODES = {
    HTTP: {
        '400': ERRORS.HTTP.BAD_REQUEST,
        '401': ERRORS.HTTP.UNAUTHORIZED,
        '403': ERRORS.HTTP.FORBIDDEN,
        '404': ERRORS.HTTP.NOT_FOUND,
        '500': ERRORS.HTTP.INTERNAL,
        'DEFAULT': ERRORS.HTTP.UNKNOWN,
    },
    ETHEREUM: {
        PROVIDER: {
            '4001': ERRORS.ETHEREUM.PROVIDER.REJECTED,
            '4100': ERRORS.ETHEREUM.PROVIDER.UNAUTHORIZED,
            '4200': ERRORS.ETHEREUM.PROVIDER.UNSUPPORTED,
            '4900': ERRORS.ETHEREUM.PROVIDER.DISCONNECTED,
            '4901': ERRORS.ETHEREUM.PROVIDER.DISCONNECTED_CHAIN,
        },
        RPC: {
            '-32700': ERRORS.ETHEREUM.RPC.PARSE_ERROR,
            '-32600': ERRORS.ETHEREUM.RPC.INVALID_REQUEST,
            '-32601': ERRORS.ETHEREUM.RPC.METHOD_NOT_FOUND,
            '-32602': ERRORS.ETHEREUM.RPC.INVALID_PARAMS,
            '-32603': ERRORS.ETHEREUM.RPC.INTERNAL,
            '-32000': ERRORS.ETHEREUM.RPC.INVALID_INPUT,
            '-32001': ERRORS.ETHEREUM.RPC.RESOURCE_NOT_FOUND,
            '-32002': ERRORS.ETHEREUM.RPC.RESOURCE_NOT_AVAILABLE,
            '-32003': ERRORS.ETHEREUM.RPC.TRANSACTION_REJECTED,
            '-32004': ERRORS.ETHEREUM.RPC.METHOD_NOT_SUPPORTED,
            '-32005': ERRORS.ETHEREUM.RPC.LIMIT_EXCEEDED,
            '-32006': ERRORS.ETHEREUM.RPC.VERSION,
        },
        TRANSACTION: {
            'SERVER_ERROR': ERRORS.ETHEREUM.TRANSACTION.SERVER,
            'TIMEOUT': ERRORS.ETHEREUM.TRANSACTION.TIMEOUT,
            'CALL_EXCEPTION': ERRORS.ETHEREUM.TRANSACTION.CALL_EXCEPTION,
            'INSUFFICIENT_FUNDS': ERRORS.ETHEREUM.TRANSACTION.FUNDS,
            'NONCE_EXPIRED': ERRORS.ETHEREUM.TRANSACTION.NONCE,
            'REPLACEMENT_UNDERPRICED': ERRORS.ETHEREUM.TRANSACTION.UNDERPRICED,
            'UNPREDICTABLE_GAS_LIMIT': ERRORS.ETHEREUM.TRANSACTION.CALL_EXCEPTION,
            'TRANSACTION_REPLACED': ERRORS.ETHEREUM.TRANSACTION.REPLACED,
            'UNSUPPORTED_OPERATION': ERRORS.ETHEREUM.TRANSACTION.UNSUPPORTED_OPERATION,
        },
    },
};
