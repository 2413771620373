import { ElementEvent, ElementEventDetail } from '@swivel-finance/ui/utils/events';
import { Notification } from './types';

export interface NotificationDismissedEventDetail<T extends HTMLElement = HTMLElement> extends ElementEventDetail<T> {
    notification: Notification;
    dismissed: boolean;
}

export class NotificationDismissedEvent<T extends HTMLElement = HTMLElement> extends ElementEvent<T, NotificationDismissedEventDetail<T>> {

    type!: 'sw-notification-dismissed';

    constructor (detail: NotificationDismissedEventDetail<T>, init?: EventInit) {

        super('sw-notification-dismissed', detail, init);
    }
}

/**
 * Add the NotificationDismissEvent to the global HTMLElementEventMap.
 */
declare global {
    interface HTMLElementEventMap {
        'sw-notification-dismissed': NotificationDismissedEvent;
    }
}
