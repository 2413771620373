/**
 * Truncates a Hash string with ellipses in the middle
 *
 * @param h - the hash to truncate
 * @param b - the number of beginning-string characters 
 * @param e - the number of end-string characters 
 * @returns the formatted hash string
 */
export const truncateHash = (h: string, b: number, e: number): string => {

    return `${ h.substr(0, b) }...${ h.substr(-e) }`;
};
