export const SECONDS_PER_DAY = 86_400;

export const SECONDS_PER_YEAR = 31_536_000;

export const DAYS_PER_YEAR = 365;

/**
 * Number of blocks mined per day
 *
 * @remarks
 * Based on the compund docs (https://compound.finance/docs#protocol-math) we
 * assume 13.15 seconds per block which results in 6570 blocks per day.
 * Using this value, the resulting APY calculated from the `supplyRatePerBlock`
 * matches the rates quoted by https://compound.finance/markets.
 */
export const BLOCKS_PER_DAY = 6570;

export const DEFAULT_LOCALE = 'en-US';

/**
 * The default order expiration time in `s`
 */
export const ORDER_EXPIRY_TIME = 14 * SECONDS_PER_DAY;

/**
 * Time until maturity after which a market is considered 'locked' in seconds
 *
 * @remarks
 * We consider 4 days until maturity as locked: 60 * 60 * 24 * 4 = 345600
 * At this point on order's rate change per second becomes relatively high (> 1%/s for prices >= 0.3).
 *
 * @deprecated
 * Use the {@link ConfigService} to retrieve this value:
 * ```
 * import { config } from '/services';
 *
 * const MARKET_LOCKED_TIME = config.cache.global.marketLockedAt;
 * ```
 */
export const MARKET_LOCKED_TIME = 345600;

/**
 * Time since maturity after which a market is considered 'archived' in seconds
 *
 * @remarks
 * We consider 4 weeks after maturity as archived: 60 * 60 * 24 * 7 * 4
 */
export const MARKET_ARCHIVED_TIME = 2419200;

/**
 * The maximum time period for which to fetch fills.
 */
export const MAX_FILL_PERIOD = 7 * SECONDS_PER_DAY;

export const COOKIE_POLICY_URL = '/cookie-policy';

export const PRIVACY_POLICY_URL = '/privacy-policy';
