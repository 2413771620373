// import Bugsnag from '@bugsnag/js';
import { parseSwivelError } from '@swivel-finance/swivel-js';
import { ERRORS, ERROR_CODES } from '../constants';
import { ENV } from '../env/environment';
import { bugsnag } from './consent';
import { logger } from './logger';

export interface ErrorLike {
    name: string;
    message: string;
}

export interface EthereumError {
    code: number;
    message: string;
    data?: unknown;
}

export interface EthersError {
    code: string;
    message: string;
    reason?: string[];
    error?: {
        // this is part of the MetaMask ProviderRpcError
        code?: number;
        message?: string;
        data?: {
            originalError?: {
                code?: number;
                message?: string;
                data?: string;
            };
        };
        // this is part of the JsonRpcProvider error
        error?: {
            body: string;
        };
    };
}

export class ProcessedError extends Error {

    source: unknown;

    /**
     *
     * @param e - an {@link ErrorLike} to configure the instance
     * @param s - an optional source (can be an error or rejection reason)
     */
    constructor (e?: ErrorLike, s?: unknown) {

        const { name, message } = e ?? ERRORS.DEFAULT;

        super(message);

        this.name = name;
        this.source = s;
    }
}

// a list of error names that we don't want to report to bug tracking services
const IGNORED_ERRORS = [
    ERRORS.HTTP.UNAUTHORIZED,
    ERRORS.HTTP.FORBIDDEN,
    ERRORS.ETHEREUM.METAMASK.CHAIN,
    ERRORS.ETHEREUM.PROVIDER.REJECTED,
].map(error => error.name);

export const errors = {
    /**
     * Creates a {@link ProcessedError} from the passed in error object which sanitizes
     * the error message and name, logs the error in testnet deployments and optionally
     * reports the error to bugsnag (via `ENV.bugsnag.enabled`).
     *
     * @param e - the error to process (can be a failed `Response`, an `Error` or an `ErrorLike`)
     * @param o - optional manual override (use override error data instead of inferring it)
     * @param i - ignore reporting the error (if error is in {@link IGNORED_ERRORS} it will be ignored regardless)
     * @returns the {@link ProcessedError} instance
     */
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    process (e?: Response | EthereumError| EthersError | ErrorLike | unknown, o?: ErrorLike, i = false): ProcessedError {

        let processedError: ProcessedError;

        if (isResponse(e)) {

            const code = e.status.toString();
            const error = o
                || ERROR_CODES.HTTP[code as keyof typeof ERROR_CODES.HTTP]
                || ERROR_CODES.HTTP.DEFAULT;

            processedError = new ProcessedError(error, e);

        } else if (isEthereumError(e)) {

            const code = e.code.toString();
            const error = o
                || ERROR_CODES.ETHEREUM.PROVIDER[code as keyof typeof ERROR_CODES.ETHEREUM.PROVIDER]
                || ERROR_CODES.ETHEREUM.RPC[code as keyof typeof ERROR_CODES.ETHEREUM.RPC]
                || ERRORS.ETHEREUM.RPC.UNKNOWN;

            processedError = new ProcessedError(error, e);

        } else if (isEthersError(e)) {

            // check if we have a Swivel `Exception` (custom error)
            const customError = parseSwivelError(e);

            if (customError) {

                processedError = new ProcessedError(customError, e);

            } else {

                const code = e.code;
                const error = o
                    || ERROR_CODES.ETHEREUM.TRANSACTION[code as keyof typeof ERROR_CODES.ETHEREUM.TRANSACTION]
                    || ERRORS.ETHEREUM.TRANSACTION.UNKNOWN;

                // a CALL_EXCEPTION error can happen during an actual call exception or during gas estimation as response
                // to a UNPREDICTABLE_GAS_LIMIT (gas estimation simulates a tx which can lead to a call exception)
                if (errors.is(error, ERRORS.ETHEREUM.TRANSACTION.CALL_EXCEPTION)) {

                    // the original error message is simply 'Transaction failed.'
                    let message = error.message;

                    try {

                        // the UNPREDICTABLE_GAS_LIMIT source error has a nested error which contains the original
                        // rpc call exception as stringified JSON in its' body field - try getting that...
                        // we can be a bit messy here with type assertions, as we don't know if the source error
                        // will indeed be there and we catch and ignore any error that might occurr
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unsafe-member-access
                        const sourceMessage = JSON.parse(e.error!.error!.body).error.message as string;

                        message = sourceMessage
                            ? sourceMessage.charAt(0).toUpperCase() + sourceMessage.slice(1) + '.'
                            : message;

                    } catch {

                        // if we fail to extract the original rpc call error message, we use the default message
                    }

                    error.message = message;
                }

                processedError = new ProcessedError(error, e);
            }

        } else if (isErrorLike(e)) {

            processedError = new ProcessedError(o ?? e, e);

        } else {

            processedError = new ProcessedError(o ?? ERRORS.DEFAULT, e);
        }

        logger.error(e);
        logger.warn(processedError);

        // errors can be processed multiple times to change the error message before it
        // reaches the UI - i.e. a failed request will be processed by the http service,
        // but might be processed a second time by a state machine for a more useful
        // error message - in those cases, we don't want to report the error to bugsnag
        // a second time (so we filter out errors, whose source is itself a processed error)
        if (ENV.bugsnag.enabled
            && !i
            && !(processedError.source instanceof ProcessedError)
            && !IGNORED_ERRORS.includes(processedError.name)) {

            bugsnag.notify(processedError, event => {

                const source = processedError.source;
                const isError = isResponse(source)
                    || isEthereumError(source)
                    || isEthersError(source)
                    || isErrorLike(source);

                // add the source error as metadata to the event (so we have the original error codes, etc.)
                event.addMetadata('source', isError ? source : { reason: source });
            });
        }

        return processedError;
    },
    /**
     * Compares two errors
     *
     * @param e - the error object to check
     * @param o - the other error object to compare to
     * @returns `true` if both error objects have the same `name` and `message`, `false` otherwise
     */
    is (e: unknown, o: ErrorLike): boolean {

        return (e as ErrorLike)?.name === o.name
            && (e as ErrorLike)?.message === o.message;
    },
    /**
     * Checks if an error object is a {@link ProcessedError} instance
     *
     * @param e - the error object to check
     */
    isProcessed (e: unknown): e is ProcessedError {

        return e instanceof ProcessedError;
    },
    /**
     * Updates the message of an {@link ErrorLike} using `RegExp` patterns and `string` values
     *
     * @param e - the {@link ErrorLike} to update
     * @param u - the update patterns and values
     * @returns an new {@link ErrorLike} with the updated message
     */
    updateMessage (e: ErrorLike, u: [RegExp, string][]): ErrorLike {

        let message = e.message;

        u.forEach(([reg, rep]) => message = message.replace(reg, rep));

        return {
            ...e,
            message,
        };
    },
};

const isResponse = (r: unknown): r is Response => {

    return r instanceof Response;
};

const isEthereumError = (e: unknown): e is EthereumError => {

    return typeof (e as EthereumError).code === 'number'
        && typeof (e as EthereumError).message === 'string';
};

const isEthersError = (e: unknown): e is EthersError => {

    return typeof (e as EthereumError).code === 'string'
        && typeof (e as EthereumError).message === 'string';
};

const isErrorLike = (e: unknown): e is ErrorLike => {

    return typeof (e as ErrorLike).name === 'string'
        && typeof (e as ErrorLike).message === 'string';
};
