import { html, nothing } from 'lit';
import { ENV } from '../../env/environment';
import { truncateHash } from '../helpers';

/**
* Creates a template for a link to a transaction's Etherscan record.
*
* @param h - the transaction hash
* @param l - an optional label (default: 'Transaction', can be set to `''`)
* @returns a `lit.TemplateResult` for the transcation and link to Etherscan
*/

export const transaction = (h: string, l = 'Transaction') => {

    return h
        ? html`
        <div class="order-notification-success">
            ${ l ? html`${ l }:` : nothing }
            <a href="${ ENV.etherscanUrl.replace('<TX_ID>', h) }"
                target="_blank"
                rel="noopener">${ truncateHash(h, 4, 4) }</a>
            <ui-icon name="etherscan"></ui-icon>
        </div>`
        : nothing;
};
