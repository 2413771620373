import { Market as MarketAddresses, MarketPlace, Protocols } from '@swivel-finance/swivel-js';
import { ethers } from 'ethers';
import { MARKET_ARCHIVED_TIME } from '../../constants/constants';
import { ERRORS } from '../../constants/errors';
import { PROTOCOL_MAP } from '../../constants/protocols';
import { ENV } from '../../env/environment';
import { config } from '../../services/config';
import { errors } from '../../services/errors';
import { logger } from '../../services/logger';
import { MarketStatus, swivelApi } from '../../services/swivel';
import { token } from '../../services/token';
import { wallet } from '../../services/wallet';
import { CToken, Fill, Market, MarketLike, Protocol, SwivelConfig, Token } from '../../types';
import { annualize, fixed } from '../amount';
import { timeUntilMaturity } from '../maturity';

// we need a local cache for paused markets, as they come from the config
let PAUSED_MARKETS: MarketLike[] = [];

// update local config caches when config is updated
config.subscribe((config) => {

    PAUSED_MARKETS = config.global.paused ?? [];

}, 'CHANGE');

/**
 * Creates a string identifying a market
 *
 * @remarks
 * Concatenates the market's `protocol`, `underlying` and `maturity` to create a unique identifier.
 *
 * @param m - market
 */
export const marketKey = (m: MarketLike): string => {

    return `${ m.protocol }-${ m.underlying }-${ m.maturity }`;
};

/**
 * Returns the `protocol`, `underlying` and `maturity` from a market key
 *
 * @param k - market key
 */
export const fromMarketKey = (k: string): MarketLike => {

    const [protocol, underlying, maturity] = k.split('-');

    return { protocol: parseInt(protocol), underlying, maturity };
};

/**
 * Compares two markets
 *
 * @param m1 - first market
 * @param m2 - second market
 * @returns `true` if the markets have the same `protocol`, `underlying` and `maturity`, `false` otherwise
 */
export const compareMarkets = (m1: MarketLike, m2: MarketLike): boolean => {

    return (m1.protocol === m2.protocol) && (m1.underlying === m2.underlying) && (m1.maturity === m2.maturity);
};

/**
 * Checks if a market is paused
 *
 * @param m - market
 */
export const paused = (m: MarketLike): boolean => {

    return PAUSED_MARKETS.some(market => compareMarkets(market, m));
};

/**
 * Checks if a market is locked
 *
 * @param m - market
 */
export const locked = (m: MarketLike): boolean => {

    return timeUntilMaturity(m.maturity) <= config.cache.global.marketLockedAt;
};

/**
 * Checks if a market is matured
 *
 * @param m - market
 */
export const matured = (m: MarketLike): boolean => {

    return timeUntilMaturity(m.maturity) <= 0;
};

/**
 * Checks if a market is matured
 *
 * @param m - market
 */
export const archived = (m: MarketLike): boolean => {

    return timeUntilMaturity(m.maturity) <= MARKET_ARCHIVED_TIME * -1;
};

/**
 * Fetch a single market
 *
 * @param m - market
 */
export const fetchMarket = async (m: MarketLike): Promise<Market> => {

    // reuse global metamask provider and signer
    const { provider } = await wallet.connection();

    // create the MarketPlace HOC
    const marketPlace = new MarketPlace(ENV.marketplaceAddress, provider);

    try {

        // use the swivel-js MarketPlace HOC to fetch market-related addresses (tokens and vault tracker)
        const addresses = await marketPlace.markets(m.protocol, m.underlying, m.maturity);

        // check if the market has valid addresses and throw if not
        // invalid markets will still return but have 0 addresses
        if (addresses.vaultTracker === ethers.constants.AddressZero) {

            throw new Error('Invalid market.');
        }

        // fetch the token details, last trade price and token config
        const [tokenMap, rateMap, priceMap, tokenConfig] = await Promise.all([
            getTokens([m], [addresses]),
            getRates([m], [addresses]),
            getPrices([m]),
            getConfig(),
        ]);

        const market = assembleMarket(m, addresses, tokenMap, tokenConfig, rateMap, priceMap, PROTOCOL_MAP);

        logger.group('helpers/market').log('fetchMarket:', market);

        return market;

    } catch (error) {

        throw errors.process(error, ERRORS.STATE.MARKET.FETCH);
    }
};

/**
 * Fetches available market underlying/maturity pairs from the Swivel API and constructs full
 * {@link Market} objects by fetching additional market data from the MarketPlace HOC, the
 * market's associated token data from their respective ERC20 contracts as well as the supply
 * and exchange rates of the markets' cToken.
 *
 * @param status - optional market status ('active' | 'archived' | 'matured')
 */
export const fetchMarkets = async (status?: MarketStatus): Promise<Market[]> => {

    // reuse global metamask provider and signer
    const { provider } = await wallet.connection();

    // create the MarketPlace HOC
    const marketPlace = new MarketPlace(ENV.marketplaceAddress, provider);

    try {

        // fetch markets from swivel API
        const markets = await swivelApi.fetchMarkets(status);

        if (!markets.length) {

            throw errors.process(ERRORS.STATE.MARKET.NO_MARKETS);
        }

        // use the swivel-js MarketPlace HOC to fetch market-related addresses (tokens and vault tracker)
        const addresses = await Promise.all(markets.map(market => marketPlace.markets(market.protocol, market.underlying, market.maturity)));

        // fetch the token details, last trade price and token config
        const [tokenMap, rateMap, priceMap, tokenConfig] = await Promise.all([
            getTokens(markets, addresses),
            getRates(markets, addresses),
            getPrices(markets),
            getConfig(),
        ]);

        // put it all together
        const result = markets.map((market, index) => assembleMarket(market, addresses[index], tokenMap, tokenConfig, rateMap, priceMap, PROTOCOL_MAP));

        logger.group('helpers/market').log('fetchMarkets:', result);

        return result;

    } catch (error) {

        throw errors.is(error, ERRORS.STATE.MARKET.NO_MARKETS)
            ? error
            : errors.process(error, ERRORS.STATE.MARKET.FETCH);
    }
};

/**
 * Gets the token information for all tokens (underlying, cToken, zcToken) of markets.
 *
 * @param m - array of swivel API {@link MarketResponse}s
 * @param a - array of corresponding {@link @swivel-finance/swivel-js#Market}s
 * @returns a map of token addresses and their token information
 */
const getTokens = async (m: MarketLike[], a: MarketAddresses[]): Promise<Map<string, Token | CToken>> => {

    const uniqueAddresses = new Set<string>();
    const cTokenAddresses = new Set<string>();

    m.forEach((market, index) => {

        uniqueAddresses.add(market.underlying);
        uniqueAddresses.add(a[index].zcToken);
        uniqueAddresses.add(a[index].cTokenAddr);

        cTokenAddresses.add(a[index].cTokenAddr);
    });

    const tokens = await Promise.all([...uniqueAddresses].map(address => cTokenAddresses.has(address)
        // we don't `fetch` cTokens - they could be anything - we just store the address
        ? { address }
        : token.fetch(address)));

    return new Map(tokens.map(token => ([token.address, token])));
};

/**
 * Interface for a markets optional exchange and interest rates.
 *
 * @internal
 */
interface MarketRates {
    exchangeRate: string | undefined;
    interestRate: string | undefined;
}

/**
 * Gets the exchange rate and interest rate of a market/market's cToken.
 *
 * @param m - array of swivel API {@link MarketResponse}s
 * @param a - array of corresponding {@link @swivel-finance/swivel-js#Market}s
 * @returns a map of market keys and their respective rates
 */
const getRates = async (m: MarketLike[], a: MarketAddresses[]): Promise<Map<string, MarketRates>> => {

    // reuse global metamask provider and signer
    const { provider } = await wallet.connection();

    // create the MarketPlace HOC
    const marketPlace = new MarketPlace(ENV.marketplaceAddress, provider);

    // fetch rates from the MarketPlace, use Promise.allSettled, we accept some rates might fail
    const rates = await Promise.allSettled(m.map((market, index) => {

        // we don't need the interest rate for paused and matured markets
        // we do need the exchange rate to display correct interest in paused markets as well as
        // matured markets that still have unredeemed zcTokens which generate interest post maturity
        if (paused(market) || matured(market)) {

            return Promise.allSettled([
                marketPlace.exchangeRate(market.protocol, market.underlying, market.maturity),
                Promise.resolve(undefined),
            ]);
        }

        // active markets need both rates
        return Promise.allSettled([
            marketPlace.exchangeRate(market.protocol, market.underlying, market.maturity),
            marketPlace.interestRate(market.protocol, a[index].cTokenAddr),
        ]);
    }));

    // `Promise.allSettled` returns a `PromiseSettledResult` which is hard to handle
    // we cast it to a `PromiseFulfilledResult` in order to access the promise value
    type RateResult = PromiseFulfilledResult<[PromiseFulfilledResult<string | undefined>, PromiseFulfilledResult<string | undefined>]>;

    return new Map(m.map((market, index) => ([marketKey(market), {
        exchangeRate: (rates[index] as RateResult)?.value?.[0]?.value,
        interestRate: (rates[index] as RateResult)?.value?.[1]?.value,
    }])));
};

/**
 * Gets the last trades for all markets.
 *
 * @param m - array of swivel API {@link MarketResponse}s
 * @returns a map of market keys and their last trade
 */
const getPrices = async (m: MarketLike[]): Promise<Map<string, string | undefined>> => {

    const trades = await Promise.allSettled(m.map(market => {

        // we don't need the last trade price for paused or matured markets
        if (paused(market) || matured(market)) {

            return Promise.resolve(undefined);
        }

        return swivelApi.fetchLastTrade(market.protocol, market.underlying, market.maturity);
    }));

    // `Promise.allSettled` returns a `PromiseSettledResult` which is hard to handle
    // we cast it to a `PromiseFulfilledResult` in order to access the promise value
    type TradeResult = PromiseFulfilledResult<Fill | undefined>;

    return new Map(m.map((market, index) => ([marketKey(market), (trades[index] as TradeResult)?.value?.price])));
};

/**
 * Gets the token config from the swivel API.
 *
 * @returns a map of token addresses and their `decimals`, `name` and `minPrincipal`
 */
const getConfig = async (): Promise<SwivelConfig> => {

    return config.get();
};

/**
 * Assembles the {@link Market} interface from an underlying/maturity pair, its related token/vault addresses and
 * various maps containing token, price and protocol information.
 *
 * @param market - an underlying/maturity pair
 * @param addresses - the market's token and vault addresses
 * @param tokenMap - a map of token addresses and their ERC20 token info
 * @param tokenConfig - a record of token addresses and their swivel API config (including `minPrincipal`)
 * @param rateMap - a map of market keys and their exchange and interest rates
 * @param priceMap - a map of market keys and their last trade price
 * @param protocolMap - a record of cToken addresses and their associated protocol information
 */
const assembleMarket = (
    market: MarketLike,
    addresses: MarketAddresses,
    tokenMap: Map<string, Token | CToken>,
    tokenConfig: SwivelConfig,
    rateMap: Map<string, MarketRates>,
    priceMap: Map<string, string | undefined>,
    protocolMap: Record<Protocols, Protocol>,
): Market => {

    const key = marketKey(market);

    // use the underlying's minPrincipal as default (other tokens might get individual config in the future)
    const minPrincipal = tokenConfig[market.underlying]?.minPrincipal;
    const lastPrice = priceMap.get(key);
    const { exchangeRate, interestRate } = rateMap.get(key) ?? { exchangeRate: undefined, interestRate: undefined };

    const underlying = {
        ...tokenMap.get(market.underlying) as Token,
        minPrincipal,
    };

    const cToken = {
        ...tokenMap.get(addresses.cTokenAddr) as CToken,
    };

    // infer the name and symbol for zcToken from the underlying (the actual zcToken name might
    // contain the maturity data for easier visibility in wallets - we don't want that in the UI)
    const zcToken = {
        ...tokenMap.get(addresses.zcToken) as Token,
        name: `Principal Token ${ underlying.name }`,
        symbol: `pt${ underlying.symbol }`,
        minPrincipal: tokenConfig[addresses.zcToken]?.minPrincipal ?? minPrincipal,
    };

    // the nToken is not an ERC-20, we infer the entire token info from the underlying
    const nToken = {
        ...underlying,
        name: `Yield Token ${ underlying.name }`,
        symbol: `yt${ underlying.symbol }`,
    };

    return {
        ...market,
        vault: addresses.vaultTracker,
        protocolData: protocolMap[market.protocol],
        tokens: {
            underlying,
            cToken,
            nToken,
            zcToken,
        },
        lastTradePrice: lastPrice,
        // annualize the lastTradePrice into the swivel rate
        swivelRate: lastPrice && fixed(lastPrice).mulUnsafe(annualize(market.maturity)).toString(),
        // FIXME: we might want to delay loading these rates (they can take a while to load...)
        exchangeRate,
        interestRate: validateInterestRate(interestRate) ? interestRate : undefined,
    };
};

/**
 * Validate the interest rate of a compounding token
 *
 * @remarks
 * In certain situations, a misconfigured token could return extreme interest rates,
 * like `Infinity` or other unpredictable things. This method ensures we catch cases
 * like that.
 */
const validateInterestRate = (rate?: string): boolean => {

    // empty or white-space only strings will be turned to 0 by the
    // Number constructor - we want to consider them as invalid
    const empty = /^\s*$/;
    const interestRate = Number(rate);

    return !!rate && !empty.test(rate) && !isNaN(interestRate) && isFinite(interestRate);
};
