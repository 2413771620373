import { FocusListBehavior, ListConfig, SelectEvent } from '@swivel-finance/ui/behaviors/list';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { ERRORS } from '../../constants';
import { MATURITY_DATE_FORMAT, fixed, locked, marketKey, maturityDate, paused } from '../../helpers';
import { amount, errorMessage, marketStatus, status, unit } from '../../shared/templates';
import { services } from '../../state';
import { Market } from '../../types';

/**
 * ListConfig for the markets grid.
 *
 * We unset the orientation to allow list navigation with all arrow keys.
 */
const GRID_CONFIG: Partial<ListConfig> = {
    orientation: undefined,
};

/**
 * Sort markets by swivel rate in descending order.
 *
 * Locked and paused markets are sorted to the end of the list.
 *
 * @param markets - markets to sort
 */
const sortMarkets = (markets: Market[]): Map<string, Market> => {

    const sorted = markets.sort((a, b) => {

        if (locked(a) && !locked(b)) return 1;
        if (!locked(a) && locked(b)) return -1;

        if (paused(a) && !paused(b)) return 1;
        if (!paused(a) && paused(b)) return -1;

        return parseFloat(b.swivelRate || '0') - parseFloat(a.swivelRate || '0');
    });

    return new Map(sorted.map(market => [marketKey(market), market]));
};

const rate = (value?: string) => value
    ? amount(fixed(value).mulUnsafe(fixed(100)).toString(), '%', 2, true)
    : html`<span class="amount"><span class="value">-</span>${ unit('%') }</span>`;

const loadingTemplate = function (this: MarketGridElement) {

    const state = this.marketService.state;

    return status(state.matches('fetching') ? 'loading' : 'initial');
};

const errorTemplate = function (this: MarketGridElement) {

    const { error } = this.marketService.state.context;

    return errorMessage(error || ERRORS.COMPONENTS.MARKETS.DEFAULT.message);
};

const marketTemplate = function (this: MarketGridElement, market: Market, key: string) {

    const state = this.marketService.state;

    const selected = state.context.selected;

    const isLocked = locked(market);
    const isPaused = paused(market);

    const classes = {
        [market.tokens.underlying.symbol]: true,
        locked: isLocked,
        paused: isPaused,
    };

    return html`
    <div class="market-grid-item ${ classMap(classes) }"
        data-value="${ key }"
        aria-selected="${ key === selected }"
        aria-disabled="${ isLocked || isPaused }">
        <div class="symbol">
            <sw-symbol .name=${ market.tokens.underlying.image }></sw-symbol>
            <sw-symbol class="protocol ${ market.protocolData.symbol }" .name=${ market.protocolData.image }></sw-symbol>
        </div>
        <div class="name">
            <span>${ market.tokens.underlying.symbol }</span>
        </div>
        ${ isLocked || isPaused
            ? html`<div class="status">${ marketStatus(isLocked ? 'locked' : 'paused') }</div>`
            : html`<div class="rate">${ rate(market.swivelRate) }</div>`
        }
        <div class="maturity">
            <span>${ maturityDate(market.maturity, MATURITY_DATE_FORMAT.MEDIUM) }</span>
        </div>
    </div>
    `;
};

const template = function (this: MarketGridElement) {

    const state = this.marketService.state;

    const markets = this.markets;

    if (state.matches('initial') || state.matches('fetching')) {

        return loadingTemplate.call(this);
    }

    if (state.matches('error')) {

        return errorTemplate.call(this);
    }

    return html`
    <div class="market-grid-container" @ui-select-item=${ (event: SelectEvent) => this.handleSelectItem(event) } ${ ref(this.listRef) }>
    ${ state.matches('completing') || state.matches('success')
        ? [...markets.entries()].map(([key, market]) => marketTemplate.call(this, market, key))
        : nothing
    }
    ${ state.matches('completing')
        ? status('loading')
        : nothing
    }
    </div>
    `;
};

@customElement('sw-market-grid')
export class MarketGridElement extends LitElement {

    protected marketService = services.market;

    protected listRef: Ref<HTMLUListElement> = createRef();

    protected listBehavior?: FocusListBehavior;

    @state()
    protected markets: Map<string, Market> = new Map();

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
    }

    fetchMarkets () {

        if (this.marketService.state.matches('fetching') || this.marketService.state.matches('completing')) return;

        this.marketService.send('MARKET.FETCH');
    }

    /**
     * Select a market.
     *
     * @param key - market key of the market to select
     */
    selectMarket (key: string) {

        this.marketService.send({
            type: 'MARKET.SELECT',
            payload: key,
        });
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketService.onTransition(this.handleTransition);
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketService.off(this.handleTransition);

        super.disconnectedCallback();
    }

    protected firstUpdated (): void {

        this.listBehavior = new FocusListBehavior(GRID_CONFIG);

        this.updateListBehavior();
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected updateListBehavior () {

        // we want to detach the list behavior before updating the list items
        this.listBehavior?.detach();

        if (!this.listRef.value) return;

        const list = this.listRef.value as HTMLElement;
        const items = list.querySelectorAll<HTMLElement>('.market-grid-item');

        this.listBehavior?.attach(list, items);
    }

    protected handleTransition () {

        this.markets = sortMarkets([...this.marketService.state.context.markets?.values() || []]);

        this.requestUpdate();

        // after the component is updated, update the list behavior
        void this.updateComplete.then(() => this.updateListBehavior());
    }

    protected handleSelectItem (event: SelectEvent) {

        if (!this.marketService.state.matches('success')) return;

        const { current } = event.detail;

        if (current?.index !== undefined) {

            const key = [...this.markets.keys()][current.index];

            this.selectMarket(key);
        }
    }
}
