import { watchAsset } from '@swivel-finance/connect/connection/providers/browser/ethereum-provider';
import { errors } from '../../services/errors';
import { isBrowserConnection, wallet } from '../../services/wallet';
import { Token } from '../../types';

export const addToken = async (t: Token): Promise<void> => {

    // connect the wallet if it's not already connected
    // watching an asset is a user action, so we can prompt a connect
    const connection = await wallet.connect();

    // we can only watch assets in browser wallets
    if (isBrowserConnection(connection)) {

        try {

            // some wallets, like Phantom, don't support `wallet_watchAsset` yet
            // we allow it to fail
            await watchAsset(connection.wallet.provider, {
                type: 'ERC20',
                options: {
                    address: t.address,
                    symbol: t.symbol,
                    decimals: t.decimals,
                },
            });

        } catch (error) {

            errors.process(error);
        }
    }
};
