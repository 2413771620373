import { BugsnagConfig, GoogleAnalyticsConfig } from '@swivel-finance/consent';
import { Version } from '../types';

export type PROCESS_ENVIRONMENT = {
    NODE_ENV?: string;
    APP_VERSION?: string;
    DEBUG?: boolean;
    VERSIONS: Version[];
    API_URL: string;
    OPRAH_URL: string;
    SOCKET_URL: string;
    SOCKET_INTERVAL: number;
    DISCORD_URL: string;
    DOCS_URL: string;
    ETHERSCAN_URL: string;
    LIMIT_AMOUNT: boolean;
    LIMIT_PRICE: boolean;
    STAKING_SLIPPAGE: number;
    CHAIN_ID: number;
    NETWORK_NAME: string;
    SWIVEL_ADDRESS: string;
    MARKETPLACE_ADDRESS: string;
    BALANCER_QUERY_ADDRESS: string;
    STAKED_SWIVEL_ADDRESS: string;
    WETH_ADDRESS: string;
    PREVIEW_MARKETS: boolean;
    BUGSNAG_ENABLED?: boolean;
    BUGSNAG_API_KEY?: string;
    ANALYTICS_ENABLED?: boolean;
    ANALYTICS_MEASUREMENT_ID?: string;
};

// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any
declare var process: {
    env: PROCESS_ENVIRONMENT;
};

export interface Environment {
    appVersion: string;
    appEnvironment: 'production' | 'development';
    debug: boolean;
    versions: Version[];
    apiUrl: string;
    oprahUrl: string;
    socketUrl: string;
    socketInterval: number;
    discordUrl: string;
    docsUrl: string;
    etherscanUrl: string;
    limitAmount: boolean;
    limitPrice: boolean;
    stakingSlippage: number;
    chainId: number;
    networkName: string;
    swivelAddress: string;
    marketplaceAddress: string;
    balancerQueryAddress: string;
    stakedSwivelAddress: string;
    wethAddress: string;
    previewMarkets: boolean;
    bugsnag: BugsnagConfig;
    analytics: GoogleAnalyticsConfig;
}

/**
 * Environment for development
 *
 * @remarks
 * This config uses the Rinkeby test network.
 */
export const ENV: Environment = {
    appVersion: process.env.APP_VERSION ?? '0.0.0',
    appEnvironment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
    debug: process.env.DEBUG ?? process.env.NODE_ENV !== 'production',
    versions: process.env.VERSIONS || [],
    // swivel service urls
    apiUrl: process.env.API_URL,
    oprahUrl: process.env.OPRAH_URL,
    socketUrl: process.env.SOCKET_URL,
    socketInterval: process.env.SOCKET_INTERVAL,
    // static resource urls
    discordUrl: process.env.DISCORD_URL,
    docsUrl: process.env.DOCS_URL,
    etherscanUrl: process.env.ETHERSCAN_URL,
    // configuration settings
    limitAmount: process.env.LIMIT_AMOUNT,
    limitPrice: process.env.LIMIT_PRICE,
    stakingSlippage: process.env.STAKING_SLIPPAGE ?? 0.0025,
    // network settings
    chainId: process.env.CHAIN_ID,
    networkName: process.env.NETWORK_NAME,
    // deployed swivel contract addresses
    swivelAddress: process.env.SWIVEL_ADDRESS,
    marketplaceAddress: process.env.MARKETPLACE_ADDRESS,
    balancerQueryAddress: process.env.BALANCER_QUERY_ADDRESS,
    stakedSwivelAddress: process.env.STAKED_SWIVEL_ADDRESS,
    wethAddress: process.env.WETH_ADDRESS,
    // for deploy feature flags we allow default values which disable them
    previewMarkets: process.env.PREVIEW_MARKETS ?? false,
    // for third-party service configs we allow default values which disable the services
    bugsnag: {
        enabled: process.env.BUGSNAG_ENABLED ?? false,
        apiKey: process.env.BUGSNAG_API_KEY ?? '',
        appVersion: process.env.APP_VERSION ?? '0.0.0',
        // use `staging` releaseStage for testnet deploys
        releaseStage: process.env.CHAIN_ID === 1 ? 'production' : 'staging',
    },
    analytics: {
        enabled: process.env.ANALYTICS_ENABLED ?? false,
        measurementId: process.env.ANALYTICS_MEASUREMENT_ID ?? '',
    },
};
