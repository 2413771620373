/* eslint-disable @typescript-eslint/unbound-method */
import { html } from 'lit';
import { errorMessage } from '../../../shared/templates';
import type { CreateOrderElement } from '../create';
import { orderButtonClass } from '../helpers';

export const orderFooterTemplate = function (this: CreateOrderElement) {

    const state = this.orderService.state;

    const { order, orderType, error } = state.context;
    const type = (orderType === 'limit') ? 'Limit' : 'Market';

    const disabled = !this.canSubmit;

    return html`
    <footer>
        ${ error ? errorMessage(error) : '' }
        <button
            type="button"
            class=${ orderButtonClass(order) }
            ?disabled=${ disabled }
            @click=${ this.submit }>
            ${ this.simple
                ? 'Lend'
                : `Place ${ type } Order`
            }
        </button>
    </footer>
    `;
};
