import { html } from 'lit';
import { amountToken } from '../../../helpers';
import { NotificationType } from '../../../shared/components';
import { tokenBalance, tokenPrice, transaction } from '../../../shared/templates';
import { Market, Order } from '../../../types';
import { orderTypeLabel } from '../helpers';

export const orderNotification = (o: Order, m: Market, a: string, p: string, t: NotificationType, e = '') => {

    const label = orderTypeLabel(o);
    const aToken = amountToken(o, m);
    const pToken = m.tokens.underlying;

    return html`
    <div class="order-notification-content">
        <div class="order-notification-type">
        ${ (t === 'progress')
            ? html`<span>Order processing:</span><span>${ label }</span>`
            : (t === 'success')
                ? html`<span>Order successful:</span><span>${ label }</span>`
                : html`<span>Order failed:</span><span>${ label }</span>`
        }
        </div>
        <div class="order-notification-amount">
            <span>Amount:</span>${ tokenBalance(a, aToken, 2) }
        </div>
        <div class="order-notification-price">
            <span>Price:</span>${ tokenPrice(p, pToken) }
        </div>
        ${ (t === 'failure' && e)
            ? html`<div class="order-notification-error">${ e }</div>`
            : transaction(e)
        }
    </div>
    `;
};
// etherscan template, given a transaction hash