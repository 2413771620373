import { ethers } from 'ethers';
import { html, nothing } from 'lit';
import { ERRORS } from '../../constants';
import { ProcessedError } from '../../services';
import { NotificationType } from '../../shared/components';
import { tokenBalance, transaction } from '../../shared/templates';
import { Token } from '../../types';

export interface StakingAmounts {
    SWIV: {
        amount: string;
        token: Token;
    },
    ETH: {
        amount: string;
        token: Token;
    },
}
export const approvalNotification = (type: NotificationType, amount: string, token: Token, receiptOrError?: ethers.providers.TransactionReceipt | ProcessedError) => {

    return html`
    <div class="stake-notification-content">
        <div class="notification-type">
            ${ (type === 'progress')
                ? html`<span>Checking approval...</span>`
                : (type === 'failure')
                    ? html`<span>Approval failed</span>`
                    : html`<span>Approval successful</span>`
            }
        </div>
        <div class="notification-amount">
            <span>Amount:</span>${ tokenBalance(amount, token, 2) }
        </div>
        ${ (type === 'failure')
            ? html`<div class="notification-error">${ (receiptOrError as ProcessedError)?.message ?? ERRORS.ETHEREUM.TRANSACTION.UNKNOWN }</div>`
            : (type === 'success' && receiptOrError)
                ? transaction((receiptOrError as ethers.providers.TransactionReceipt)?.transactionHash)
                : nothing
        }
    </div>
    `;
};

export const stakingNotification = (type: NotificationType, amounts: StakingAmounts, receiptOrError?: ethers.providers.TransactionReceipt | ProcessedError) => {

    return html`
    <div class="stake-notification-content">
        <div class="notification-type">
            ${ (type === 'progress')
                ? html`<span>Staking...</span>`
                : (type === 'failure')
                    ? html`<span>Staking failed</span>`
                    : html`<span>Staking successful</span>`
            }
        </div>
        <div class="notification-amount">
            <span>Amounts:</span>${ tokenBalance(amounts.SWIV.amount, amounts.SWIV.token, 2) }
        </div>
        <div class="notification-amount">
            <span></span>${ tokenBalance(amounts.ETH.amount, amounts.ETH.token, 2) }
        </div>
        ${ (type === 'failure')
            ? html`<div class="notification-error">${ (receiptOrError as ProcessedError)?.message ?? ERRORS.ETHEREUM.TRANSACTION.UNKNOWN }</div>`
            : (type === 'success' && receiptOrError)
                ? transaction((receiptOrError as ethers.providers.TransactionReceipt)?.transactionHash)
                : nothing
        }
    </div>
    `;
};

export const unstakingNotification = (type: NotificationType, amounts: StakingAmounts, receiptOrError?: ethers.providers.TransactionReceipt | ProcessedError) => {

    return html`
    <div class="stake-notification-content">
        <div class="notification-type">
            ${ (type === 'progress')
                ? html`<span>Withdrawing...</span>`
                : (type === 'failure')
                    ? html`<span>Withdrawing failed</span>`
                    : html`<span>Withdrawing successful</span>`
            }
        </div>
        <div class="notification-amount">
            <span>Amounts:</span>${ tokenBalance(amounts.SWIV.amount, amounts.SWIV.token, 2) }
        </div>
        <div class="notification-amount">
            <span></span>${ tokenBalance(amounts.ETH.amount, amounts.ETH.token, 2) }
        </div>
        ${ (type === 'failure')
            ? html`<div class="notification-error">${ (receiptOrError as ProcessedError)?.message ?? ERRORS.ETHEREUM.TRANSACTION.UNKNOWN }</div>`
            : (type === 'success' && receiptOrError)
                ? transaction((receiptOrError as ethers.providers.TransactionReceipt)?.transactionHash)
                : nothing
        }
    </div>
    `;
};

export const cooldownNotification = (type: NotificationType, amounts: StakingAmounts, receiptOrError?: ethers.providers.TransactionReceipt | ProcessedError) => {

    return html`
    <div class="stake-notification-content">
        <div class="notification-type">
            ${ (type === 'progress')
                ? html`<span>Queueing for Withdrawal...</span>`
                : (type === 'failure')
                    ? html`<span>Queueing failed</span>`
                    : html`<span>Queueing successful</span>`
            }
        </div>
        <div class="notification-amount">
            <span>Amounts:</span>${ tokenBalance(amounts.SWIV.amount, amounts.SWIV.token, 2) }
        </div>
        <div class="notification-amount">
            <span></span>${ tokenBalance(amounts.ETH.amount, amounts.ETH.token, 2) }
        </div>
        ${ (type === 'failure')
            ? html`<div class="notification-error">${ (receiptOrError as ProcessedError)?.message ?? ERRORS.ETHEREUM.TRANSACTION.UNKNOWN }</div>`
            : (type === 'success' && receiptOrError)
                ? transaction((receiptOrError as ethers.providers.TransactionReceipt)?.transactionHash)
                : nothing
        }
    </div>
    `;
};
