import { MarketPlace, Swivel } from '@swivel-finance/swivel-js';
import { html } from 'lit';
import { interpret } from 'xstate';
import { ANALYTICS } from '../constants';
import { ENV } from '../env/environment';
import { compareAddresses, compareMarkets } from '../helpers';
import { analytics } from '../services/consent';
import { InteractivityState, interactivity } from '../services/interactivity';
import { logger as logService } from '../services/logger';
import { SwivelMessage, messages } from '../services/messages';
import { RewardsService } from '../services/rewards';
import { router } from '../services/router';
import { SafetyModuleService } from '../services/safety-module';
import { WALLET_STATUS, wallet } from '../services/wallet';
import { notifications } from '../shared/components/notification';
import { throttle } from '../shared/helpers';
import { machine as marketMachine } from './market';
import { machine as orderMachine } from './order';
import { machine as orderbookMachine } from './orderbook';
import { machine as positionsMachine } from './positions';
import { machine as userOrderMachine } from './user-orders';
import { machine as walletMachine } from './wallet';

/**
 * Fetches based on websocket events are throttled by this timeout to reduce excessive reloads/updates.
 */
const FETCH_TIMEOUT = 3000;

/**
 * Insolvency notifications based on websocket events are throttled by this timeout to reduce excessive notifications.
 */
const INSOLVENCY_TIMEOUT = 2000;

/**
 * Refresh data after 60 seconds of inactivity
 */
const INACTIVITY_THRESHOLD = 60000;

/**
 * Cached contract instances
 */
let swivel: Swivel | undefined;
let marketPlace: MarketPlace | undefined;

let inactive: number | undefined;
let started = false;

export const services = {
    market: interpret(marketMachine),
    wallet: interpret(walletMachine),
    order: interpret(orderMachine),
    orderbook: interpret(orderbookMachine),
    userOrders: interpret(userOrderMachine),
    positions: interpret(positionsMachine),
    start () {

        if (started) return;

        syncMachines();

        this.positions.start();
        this.userOrders.start();
        this.orderbook.start();
        this.order.start();
        this.wallet.start();
        this.market.start();

        interactivity.subscribe(handleInteractivity);
        messages.subscribe(handleMessage);
        wallet.listen('connect', handleWalletConnect);
        wallet.listen('disconnect', handleWalletDisconnect);

        started = true;

        setTimeout(() => {

            refreshRewards();

        }, 10000);
    },
    stop () {

        if (!started) return;

        this.positions.stop();
        this.userOrders.stop();
        this.orderbook.stop();
        this.order.stop();
        this.wallet.start();
        this.market.stop();

        interactivity.unsubscribe(handleInteractivity);
        messages.unsubscribe(handleMessage);
        wallet.unlisten('connect', handleWalletConnect);
        wallet.unlisten('disconnect', handleWalletDisconnect);

        started = false;
    },
};

/**
 * Syncs state machine events
 */
const syncMachines = () => {

    const {
        // account: accountService,
        market: marketService,
        wallet: walletService,
        order: orderService,
        orderbook: orderbookService,
        userOrders: userOrdersService,
        positions: positionsService,
    } = services;

    wallet.subscribe(state => {

        if (state.status === WALLET_STATUS.CONNECTED) {

            const { account, provider, signer } = state.connection;

            swivel = new Swivel(ENV.swivelAddress, signer);
            marketPlace = new MarketPlace(ENV.marketplaceAddress, signer);

            marketService.send({
                type: 'MARKET.INIT',
                payload: {
                    swivel,
                    marketPlace,
                },
            });

            orderService.send({
                type: 'ORDER.INIT',
                payload: {
                    maker: account.address,
                    swivel,
                },
            });

            userOrdersService.send({
                type: 'USERORDERS.INIT',
                payload: {
                    account: account.address,
                    swivel,
                    provider,
                },
            });
        }

        if (state.status === WALLET_STATUS.DISCONNECTED || state.status === WALLET_STATUS.ERROR) {

            swivel = undefined;
            marketPlace = undefined;
        }
    });

    marketService.onTransition((context, event) => {

        if (marketService.state.matches('completing')) {

            // when the market service has fetched the first market
            // we can prime the wallet, order and user-orders services

            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            const address = wallet.state.connection?.account.address!;
            const { markets, selected } = marketService.state.context;

            walletService.send({
                type: 'WALLET.INIT',
                payload: {
                    account: address,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    market: markets.get(selected)!,
                },
            });

            orderService.send({
                type: 'ORDER.MARKET',
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                payload: markets.get(selected)!,
            });

            userOrdersService.send({
                type: 'USERORDERS.MARKET',
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                payload: markets.get(selected)!,
            });

        } else if (marketService.state.matches('success')) {

            // when the market service has fetched all remaining markets
            // we can prime the positions service (token infos are mostly
            // cached now, so things should be faster)

            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            const address = wallet.state.connection?.account.address!;
            const { markets, selected, swivel } = marketService.state.context;

            // the market service also has a success transition when a market is selected
            // in that case we inform the wallet, order and user-orders services
            if (event.type === 'MARKET.SELECT') {

                walletService.send({
                    type: 'WALLET.INIT',
                    payload: {
                        account: address,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        market: markets.get(selected)!,
                    },
                });

                orderService.send({
                    type: 'ORDER.MARKET',
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    payload: markets.get(selected)!,
                });

                userOrdersService.send({
                    type: 'USERORDERS.MARKET',
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    payload: markets.get(selected)!,
                });
            }

            positionsService.send({
                type: 'POSITIONS.INIT',
                payload: {
                    account: address,
                    swivel,
                },
            });

        } else if (marketService.state.matches('error') && wallet.state.status === WALLET_STATUS.CONNECTED) {

            // if we have errors in the market service (e.g. no active markets) we still
            // want to try to fetch all matured and archived markets for positions

            const { account } = wallet.state.connection;

            positionsService.send({
                type: 'POSITIONS.INIT',
                payload: {
                    account: account.address,
                    swivel: swivel!,
                },
            });
        }
    });

    walletService.onTransition((state) => {

        if (state.matches('success')) {

            const { balances, selected } = state.context;

            orderService.send({
                type: 'ORDER.TOKEN',
                payload: {
                    balances,
                    token: balances[selected],
                    tokenType: selected,
                },
            });
        }
    });

    orderService.onTransition((state, event) => {

        if (state.matches({ interactive: 'market' }) || state.matches({ interactive: 'limit' })) {

            const { market, token, tokenType, yieldType, fillPreview } = state.context;

            switch (event.type) {

                case 'ORDER.TOKEN':

                    orderbookService.send({
                        type: 'ORDERBOOK.INIT',
                        payload: {
                            market,
                            token,
                            tokenType,
                            yieldType,
                            fillPreview,
                        },
                    });
                    break;

                case 'ORDER.YIELD':

                    orderbookService.send({
                        type: 'ORDERBOOK.YIELD',
                        payload: yieldType,
                    });

                // fallthrough
                case 'ORDER.TYPE':
                case 'ORDER.VOLUME':
                case 'ORDER.PREVIEW':
                case 'ORDER.PREVIEW.FAILURE':
                case 'ORDER.RESET':

                    orderbookService.send({
                        type: 'ORDERBOOK.PREVIEW',
                        payload: fillPreview,
                    });
                    break;
            }
        }

        // a submitted market order will change the user's balances - except if it errored
        if (state.matches({ interactive: { market: 'submitted' } }) && !state.context.error) {

            walletService.send({
                type: 'WALLET.FETCH',
            });

            positionsService.send({
                type: 'POSITIONS.FETCH',
            });
        }
    });
};

/**
 * Handles connect from the {@link WalletSservice} and starts the {@link MessageService}
 *
 * @remarks
 * The `connect` event occurs when the `WalletService` successfully resolved the connection
 * to an ethereum rpc provider and has obtained a wallet address. At this point we want to
 * connect to the websocket (`MessageService`) and update the UI on relevant messages.
 */
const handleWalletConnect = () => {

    void messages.connect();
};

/**
 * Handles disconnect from the {@link WalletService} and updates state machines accordingly
 *
 * @remarks
 * The `disconnect` event occurs when the `WalletService` was disconnected by the user or
 * if the underlying ethereum rpc provider becomes unable to submit rpc requests.
 */
const handleWalletDisconnect = () => {

    messages.disconnect();
};

/**
 * Handles changes from the {@link InteractivityService} and updates state machines accordingly
 *
 * @param s - the interactivity state
 */
const handleInteractivity = (s: InteractivityState) => {

    if (!s.connected || !s.visible) {

        inactive = Date.now();

    } else {

        if (inactive && (Date.now() - inactive >= INACTIVITY_THRESHOLD)) {

            services.market.send({
                type: 'MARKET.FETCH',
            });

            refreshSafetyModule();
        }

        inactive = undefined;
    }
};

/**
 * Handles messages from the {@link MessageService} (websocket) and updates state machines accordingly
 *
 * @param m - the swivel message
 */
const handleMessage = (m: SwivelMessage) => {

    switch (m.name) {

        case 'CONFIG.CHANGE':

            // refreshing markets is throttled, so it will happen after the
            // config service has initiated a fetch and we'll have a fresh
            // config for the markets
            refreshMarkets();

            break;

        case 'ORDER.CREATE':
        case 'ORDER.CANCEL':
        case 'ORDER.EXPIRE':
        case 'ORDER.FULL':
        case 'ORDER.INSOLVENT.TEMPORARY':
        case 'ORDER.INITIATE':
        case 'ORDER.EXIT':

            if (matchMarket(m)) {

                refreshOrders();
            }
            break;

        case 'ORDER.INSOLVENT':

            if (matchMarket(m)) {

                refreshOrders();
            }
            if (matchMaker(m)) {

                notifyInsolvent();
            }
            break;

        case 'MARKET.CREATE':

            refreshMarkets();

            break;

        case 'MARKET.MATURE':

            // maybe fetch markets - UI should handle mature markets specifically
            refreshMarkets();

            break;

        case 'PROOF.CREATE':
        case 'REWARD.CREATE':

            // rewards events are dispatched for every account, we only want to refresh
            // when the event is for the connected account
            if (matchAccount(m)) {

                refreshRewards();
            }

            break;

        case 'DEPOSIT.CREATE':
        case 'WITHDRAWAL.CREATE':

            refreshSafetyModule();

            break;

        // no need to handle these at the moment - balances are updated where they are changed
        // case 'VAULT.REDEEM':
        // case 'ZCTOKEN.REDEEM':
        // case 'VAULT.NOTIONAL.TRANSFER':
        // case 'ZCTOKEN.TRANSFER':

        //     break;
    }
};

/**
 * Refresh order-related state machines
 *
 * @remarks
 * The events to refetch data for the order-related state machines are static. We can simply wrap
 * them in a method and debounce the method to prevent excessive re-fetching.
 */
const refreshOrders = throttle(() => {

    // we can update the order state machine by sending an empty order update
    // this will re-transition to the same state and re-fetch any `fillPreview`
    services.order.send({
        type: 'ORDER.UPDATE',
        payload: {},
    });

    // fetch orderbook
    services.orderbook.send({
        type: 'ORDERBOOK.FETCH',
    });

    // fetch user orders
    services.userOrders.send({
        type: 'USERORDERS.FETCH',
    });

}, FETCH_TIMEOUT);

/**
 * Refresh market-related state machines
 *
 * @remarks
 * The events to refetch data for the market-related state machines are static. We can simply wrap
 * them in a method and debounce the method to prevent excessive re-fetching.
 */
const refreshMarkets = throttle(() => {

    // fetch markets (don't reset selected market)
    services.market.send({
        type: 'MARKET.FETCH',
    });

}, FETCH_TIMEOUT);

/**
 * Refresh safety-module-related state
 */
const refreshSafetyModule = throttle(async () => {

    const safetyModule = await SafetyModuleService.getInstance();

    await safetyModule.fetch();

}, FETCH_TIMEOUT);

/**
 * Refresh rewards-related state
 */
const refreshRewards = throttle(async () => {

    const rewardsService = RewardsService.getInstance(wallet);

    await rewardsService.fetch();

}, FETCH_TIMEOUT);

/**
 * Show a notification message when a user's orders become insolvent
 */
const notifyInsolvent = throttle(() => {

    const logger = logService.group('notify insolvent');

    notifications.show({
        class: 'order insolvent',
        type: 'info',
        timeout: 10,
        content: () => html`
        <p>
            One or more of your open limit orders have become insolvent and have been removed.
        </p>
        <a href="${ ENV.docsUrl }developers/ubiquitous-language-v3/technical-language#invalid-order" rel="noopener" target="_blank">More information</a>
        `,
    });

    const eventName = ANALYTICS.INSOLVENT.NAME;
    const eventData = ANALYTICS.INSOLVENT.EVENT({
        page: router().activeRoute?.route.id || 'home',
    });

    try {

        analytics.gtag('event', eventName, eventData);

    } catch (error) {

        logger.log(eventName, eventData);
        logger.warn((error as Error).message);
    }

}, INSOLVENCY_TIMEOUT);

/**
 * Checks if the market info in a {@link SwivelMessage} matches the currently selected market
 *
 * @param message - swivel message
 */
const matchMarket = (message: SwivelMessage): boolean => {

    // if no market is selected, return false
    if (!services.market.state.matches('success')) return false;

    const market = message.data?.market;

    if (market) {

        const { markets, selected } = services.market.state.context;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return compareMarkets(market, markets.get(selected)!);
    }

    // if the message doesn't contain market info, assume a market match
    return true;
};

/**
 * Checks if the maker info in a {@link SwivelMessage} matches the current user
 *
 * @param message - swivel message
 */
const matchMaker = (message: SwivelMessage): boolean => {

    // if no account ia connected, return false
    if (wallet.state.status !== WALLET_STATUS.CONNECTED) return false;

    const maker = message.data?.maker;

    if (maker) {

        const { account } = wallet.state.connection;

        return compareAddresses(maker, account.address);
    }

    return false;
};

/**
 * Checks if the aggregateId of a {@link SwivelMessage} matches the current user
 *
 * @param message - swivel message
 */
const matchAccount = (message: SwivelMessage): boolean => {

    // if no account ia connected, return false
    if (wallet.state.status !== WALLET_STATUS.CONNECTED) return false;

    const account = message.aggregateId;

    if (account) {

        const { account: connected } = wallet.state.connection;

        return compareAddresses(account, connected.address);
    }

    return false;
};
