import { microtask, TaskReference } from '@swivel-finance/ui/utils/async';
import { ethers } from 'ethers';
import { ERRORS } from '../constants';
import { ENV } from '../env/environment';
import { confirmTransaction } from '../helpers/wallet';
import { Observable } from '../shared/helpers';
import { Token } from '../types';
import { errors, ProcessedError } from './errors';
import { get } from './http';
import { token } from './token';
import { wallet, WALLET_STATUS, WalletService, WalletState } from './wallet';

const MERKLE_DISTRIBUTOR_ABI = [
    'function token() external view returns (address)',
    'function claim(uint256 index, address account, uint256 amount, bytes32[] calldata merkleProof) external',
];

interface MerkleDistributor {
    /**
     * Returns the address of the token distributed by this contract.
     */
    token (overrides?: ethers.Overrides): Promise<string>;
    /**
     * Claim the given amount of the token to the given address. Reverts if the inputs are invalid.
     */
    claim (index: number, address: string, amount: ethers.BigNumberish, merkleProof: string[], overrides?: ethers.Overrides): Promise<ethers.providers.TransactionResponse>;
}

export interface Claim {
    distributorAddress: string;
    merkleRoot: string;
    claim: {
        index: number;
        amount: string;
        proof: string[];
    };
}

export interface Rewards {
    distributorAddress: string;
    orderbookEarned: string;
    ssmEarned: string;
    earned: string;
    orderbookRedeemable: string;
    ssmRedeemable: string;
    redeemable: string;
}

export interface RetroactiveRewards {
    amount: string;
}

export interface StakingAPRs {
    fees: string;
    pool: string;
    incentives: string;
    staking: string;
}

export interface RewardsState {
    account?: string;
    distributorAddress?: string;
    token?: Token;
    claim?: Claim;
    rewards?: Rewards;
    error?: ProcessedError;
    isFetching: boolean;
    isClaiming: boolean;
    isBlocked: boolean;
}

const initialState: RewardsState = {
    rewards: {
        distributorAddress: '0x0',
        orderbookEarned: '0',
        ssmEarned: '0',
        earned: '0',
        orderbookRedeemable: '0',
        ssmRedeemable: '0',
        redeemable: '0',
    },
    isFetching: false,
    isClaiming: false,
    isBlocked: false,
};

export class RewardsService extends Observable<RewardsState> {

    protected static instance?: RewardsService;

    static getInstance (walletService: WalletService): RewardsService {

        if (!this.instance) {

            this.instance = new RewardsService(walletService);
        }

        return this.instance;
    }

    protected _state: RewardsState = initialState;

    protected _notifyTask?: TaskReference;

    protected _fetchComplete?: Promise<void>;

    protected _isFirstFetch = true;

    protected _claimComplete?: Promise<ethers.providers.TransactionReceipt>;

    protected walletService: WalletService;

    get state (): Readonly<RewardsState> {

        return this._state;
    }

    protected constructor (
        walletService: WalletService,
    ) {

        super();

        this.walletService = walletService;

        this.walletService.subscribe(this.handleWalletChange.bind(this));
    }

    async fetch (): Promise<void> {

        if (this.state.isFetching) return this._fetchComplete;

        if (!this.state.account) return;

        this.updateState({ isFetching: true });

        this._fetchComplete = this.fetchDynamic()
            .then(() => this._isFirstFetch ? this.fetchStatic() : Promise.resolve())
            .then(() => { this._isFirstFetch = !!this.state.error; })
            .finally(() => {
                this._fetchComplete = undefined;
                this.updateState({ isFetching: false });
            });

        return this._fetchComplete;
    }

    async claim (): Promise<ethers.providers.TransactionReceipt> {

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (this.state.isClaiming) return this._claimComplete!;

        if (!this.state.account || !this.state.claim) throw errors.process(ERRORS.SERVICES.REWARDS.CLAIM);

        this.updateState({ isClaiming: true });

        this._claimComplete = rewardsApi.claim(this.state.account, this.state.claim)
            .then(receipt => {
                // optimistically update after successful claim (oprah takes some time to update rewards data)
                this.updateState({
                    claim: undefined,
                    rewards: {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        ...this.state.rewards!,
                        orderbookRedeemable: '0',
                        ssmRedeemable: '0',
                        redeemable: '0',
                    },
                });
                return receipt;
            })
            .finally(() => {
                this._claimComplete = undefined;
                this.updateState({ isClaiming: false });
            });

        return this._claimComplete;
    }

    protected async fetchStatic (): Promise<void> {

        const { distributorAddress } = this.state;

        if (!distributorAddress) return;

        let token: Token | undefined;
        let error: ProcessedError | undefined;

        try {

            token = await rewardsApi.fetchToken(distributorAddress);

        } catch (err) {

            error = errors.process(err, ERRORS.SERVICES.REWARDS.FETCH);
        }

        this.updateState({
            token,
            error,
        });
    }

    protected async fetchDynamic (): Promise<void> {

        const { account } = this.state;

        if (!account) return;

        let distributorAddress: string | undefined;
        let claim: Claim | undefined;
        let rewards: Rewards | undefined;
        let error: ProcessedError | undefined;
        let isBlocked = false;

        try {

            [rewards, claim] = await Promise.all([
                rewardsApi.fetchRewards(account),
                rewardsApi.fetchClaim(account),
            ]);

            distributorAddress = rewards.distributorAddress;

        } catch (err) {

            // if rewards are geo-blocked don't do anything further
            if (errors.is(err, ERRORS.HTTP.FORBIDDEN)) {

                isBlocked = true;
                error = err as ProcessedError;

            } else {

                error = errors.process(err, ERRORS.SERVICES.REWARDS.FETCH);
            }
        }

        this.updateState({
            distributorAddress,
            claim,
            rewards,
            error,
            isBlocked,
        });
    }

    protected handleWalletChange (state: WalletState): void {

        const account = state.connection?.account.address;

        this.updateState({ account });

        if (state.status === WALLET_STATUS.CONNECTED) {

            void this.fetch();
        }
    }

    /**
     * Update the internal state and notify observers.
     */
    protected updateState (state: Partial<RewardsState>): void {

        this._state = { ...this._state, ...state };

        this.scheduleNotify();
    }

    /**
     * Schedule a notify task to run on the next microtask.
     *
     * @remarks
     * Allows multiple state updates to be batched into a single notify call.
     */
    protected scheduleNotify (): void {

        if (this._notifyTask) return;

        this._notifyTask = microtask(() => {

            this.notify(this._state);

            this._notifyTask = undefined;
        });
    }
}

// -----
// MOCKS
// -----

// const readDelay = async () => await delay(() => { /* */ }, 2000).done;

// const writeDelay = async () => await delay(() => { /* */ }, 5000).done;

// const mockResposne = (hash: string) => ({
//     hash,
//     wait: (confirmations?: number) => Promise.resolve({
//         transactionHash: hash,
//         confirmations: confirmations ?? 1,
//         status: 1,
//     } as ethers.providers.TransactionReceipt),
// } as ethers.providers.TransactionResponse);

// TODO: cleanup mocks...

export const rewardsApi = {

    /**
     * Fetch a user's earned and redeemable rewards
     *
     * @param a - the user's account address
     */
    async fetchRewards (a: string): Promise<Rewards> {

        // await readDelay();

        // return {
        //     distributorAddress: '0x0',
        //     orderbookEarned: ethers.utils.parseEther('10').toString(),
        //     ssmEarned: ethers.utils.parseEther('5').toString(),
        //     earned: ethers.utils.parseEther('15').toString(),
        //     orderbookRedeemable: ethers.utils.parseEther('100').toString(),
        //     ssmRedeemable: ethers.utils.parseEther('40').toString(),
        //     redeemable: ethers.utils.parseEther('140').toString(),
        // };

        const endpoint = `${ ENV.oprahUrl }/users/${ a }/rewards`;

        const result = await get<Rewards>(endpoint);

        return result;
    },

    /**
     * Fetch a user's unlockable retroactive rewards
     *
     * @description
     * This endpoint is only used for the first reward cycle.
     *
     * @param a - the user's account address
     */
    async fetchRetroactiveRewards (a: string): Promise<string> {

        const endpoint = `${ ENV.oprahUrl }/users/${ a }/retroactive`;

        const result = await get<RetroactiveRewards>(endpoint);

        return result.amount;
    },

    /**
     * Fetch a user's reward claim
     *
     * @param a - the user's account address
     */
    async fetchClaim (a: string): Promise<Claim | undefined> {

        // await readDelay();

        // return {
        //     distributorAddress: '0x0',
        //     merkleRoot: '0x0',
        //     claim: {
        //         index: 1,
        //         amount: ethers.utils.parseEther('140').toString(),
        //         proof: [],
        //     },
        // };

        const endpoint = `${ ENV.oprahUrl }/users/${ a }/claims`;

        const result = await get<Claim | undefined>(endpoint);

        return result;
    },

    /**
     * Fetch the reward token information from the distributor contract
     *
     * @param a - the merkle distributor address
     */
    async fetchToken (a: string): Promise<Token> {

        // await readDelay();

        // return {
        //     name: 'Swivel Token',
        //     symbol: 'SWIV',
        //     decimals: 18,
        //     address: '0x0',
        //     image: 'swiv',
        // };

        try {

            const { provider, signer } = await wallet.connection();

            const contract = new ethers.Contract(a, MERKLE_DISTRIBUTOR_ABI, provider).connect(signer) as ethers.Contract & MerkleDistributor;

            const address = await contract.token();

            const info = await token.fetch(address);

            return info;

        } catch (error) {

            throw errors.isProcessed(error) ? error : errors.process(error);
        }
    },

    /**
     * Claim a user's rewards
     *
     * @param a - the user's account address
     * @param c - the user's claim
     */
    async claim (a: string, c: Claim): Promise<ethers.providers.TransactionReceipt> {

        // await writeDelay();

        // // throw errors.process(ERRORS.ETHEREUM.TRANSACTION.REVERTED);

        // const response = mockResposne('0xAPPROVE');

        // const receipt = await confirmTransaction(response);

        // return receipt;

        try {

            const { provider, signer } = await wallet.connection();

            const contract = new ethers.Contract(c.distributorAddress, MERKLE_DISTRIBUTOR_ABI, provider).connect(signer) as ethers.Contract & MerkleDistributor;

            const index = c.claim.index;
            const proof = c.claim.proof;
            const amount = ethers.BigNumber.from(c.claim.amount);

            const tx = await contract.claim(index, a, amount, proof);

            const receipt = await confirmTransaction(tx);

            return receipt;

        } catch (error) {

            throw errors.isProcessed(error) ? error : errors.process(error);
        }
    },

    /**
     * Fetch the staking APRs of the Swivel Safety Module
     * @returns
     */
    async fetchStakingAPRs (): Promise<StakingAPRs> {

        const endpoint = `${ ENV.oprahUrl }/aprs/ssm/averages/weekly`;

        const result = await get<StakingAPRs>(endpoint);

        // clear out aprs that don't parse to numbers (e.g. '+Inf')
        (Object.keys(result) as (keyof StakingAPRs)[]).forEach(key => {

            if (isNaN(parseFloat(result[key]))) {

                result[key] = '';
            }
        });

        return result;
    },
};
