import { Route, RouteMatch } from '../services/router';
import { services } from '../state';
import { COOKIE_POLICY_ROUTE_ID, HOME_ROUTE_ID, NOT_FOUND_ROUTE_ID, POSITIONS_ROUTE_ID, PRIVACY_POLICY_ROUTE_ID, PROFESSIONAL_ROUTE_ID, STAKING_ROUTE_ID } from './ids';
import { markActiveRoute } from './mark-active';

export const COOKIE_POLICY_ROUTE: Route = {
    id: COOKIE_POLICY_ROUTE_ID,
    title: 'Cookie Policy',
    pattern: /^\/cookie-policy\/?(?:\?[^#]*)?(?:#.*)?$/,
    enter: markActiveRoute,
};

export const PRIVACY_POLICY_ROUTE: Route = {
    id: PRIVACY_POLICY_ROUTE_ID,
    title: 'Privacy Policy',
    pattern: /^\/privacy-policy\/?(?:\?[^#]*)?(?:#.*)?$/,
    enter: markActiveRoute,
};

export const STAKING_ROUTE: Route = {
    id: STAKING_ROUTE_ID,
    title: 'Swivel Exchange Staking',
    pattern: /^\/staking\/?(\?[^#]*)?(?:#.*)?$/,
    enter: markActiveRoute,
};

export const POSITIONS_ROUTE: Route = {
    id: POSITIONS_ROUTE_ID,
    title: 'Swivel Exchange Positions',
    pattern: /^\/positions\/?(\?[^#]*)?(?:#.*)?$/,
    enter: markActiveRoute,
};

export const PROFESSIONAL_ROUTE: Route = {
    id: PROFESSIONAL_ROUTE_ID,
    title: 'Swivel Exchange Professional',
    pattern: /^\/professional\/?(?:\?[^#]*)?(?:#.*)?$/,
    enter: (match: RouteMatch) => {
        markActiveRoute(match);
        // re-fetch markets
        services.market.send('MARKET.FETCH');
    },
};

export const HOME_ROUTE: Route = {
    id: HOME_ROUTE_ID,
    title: 'Swivel Exchange',
    pattern: /^\/?(?:\?[^#]*)?(?:#.*)?$/,
    enter: (match: RouteMatch) => {
        markActiveRoute(match);
        // ensure we have the underlying selected when switching to simplified UI
        services.wallet.send({ type: 'WALLET.SELECT', payload: 'underlying' });
        // ensure we are in market order mode when switching to simplified UI
        services.order.send({ type: 'ORDER.TYPE', payload: 'market' });
        // ensure we are in fixed yield mode when switching to simplified UI
        services.order.send({ type: 'ORDER.YIELD', payload: 'fixed' });
        // re-fetch markets
        services.market.send('MARKET.FETCH');
    },
};

export const NOT_FOUND_ROUTE: Route = {
    id: NOT_FOUND_ROUTE_ID,
    title: '404',
    pattern: /.*/,
    redirect: '/',
};

export const ROUTES = [
    COOKIE_POLICY_ROUTE,
    PRIVACY_POLICY_ROUTE,
    STAKING_ROUTE,
    POSITIONS_ROUTE,
    PROFESSIONAL_ROUTE,
    HOME_ROUTE,
    NOT_FOUND_ROUTE,
];
