import { utils } from 'ethers';
import { ORDER_EXPIRY_TIME } from '../../constants/constants';
import { config } from '../../services/config';
import { serviceLocator } from '../../services/service-locator';
import { TIME_SERVICE } from '../../services/time';
import { Order } from '../../types';

/**
 * Adds a `key` and `expiry` field to an order.
 *
 * @param o - the order to finalize
 * @param t - the order creation time in `ms`, e.g.: `Date.now()`
 * @returns a finalized order with `key` and `expiry` set
 */
export const finalizeOrder = (o: Order, t?: number): Order => {

    const timer = serviceLocator.get(TIME_SERVICE);

    t ??= timer.time();

    return {
        ...o,
        key: createOrderKey(o.maker, t),
        expiry: createOrderExpriry(o.maturity, t),
    };
};

/**
 * Creates a unique order key.
 *
 * @param a - the address of the ordering account
 * @param t - the order creation time in `ms`, e.g.: `Date.now()`
 * @returns a keccak256 hashed unique order key
 */
export const createOrderKey = (a: string, t: number): string => {

    // concatenate account hash and timestamp
    const key = `${ a }${ t }`;

    return utils.keccak256(utils.toUtf8Bytes(key));
};

/**
 * Creates an order expiry timestamp in seconds.
 *
 * @param m - the maturity data of the market is `s`
 * @param t - the order creation time in `ms`, e.g.: `Date.now()`
 * @returns the expiry of the order in seconds
 */
export const createOrderExpriry = (m: string, t: number): string => {

    const MARKET_LOCKED_TIME = config.cache.global.marketLockedAt;
    const maturityTime = parseInt(m);
    const creationTime = Math.round(t / 1000);
    const expiryLimit = maturityTime - MARKET_LOCKED_TIME;

    // the threshold is the time when we start reducing the expiryTime
    // to compensate for the increasing rate changes when approaching maturity
    // if an order was created within the threshold time to market lockdown
    // we half the difference, ensuring that our maximum expiryTime will be the
    // market lockdown time itself
    const threshold = ORDER_EXPIRY_TIME * 2 - MARKET_LOCKED_TIME;

    // the default order expiry is 2 weeks from the creationTime
    let expiryTime = creationTime + ORDER_EXPIRY_TIME;

    const diff = expiryLimit - creationTime;

    if (diff <= threshold) {

        // if for some reason an order gets created after the market lockdown time
        // we set the expiryLimit as the expiryTime (this will be in the past now)
        if (diff < 0) {

            expiryTime = expiryLimit;

        } else {

            expiryTime = creationTime + Math.round(diff / 2);
        }
    }

    return expiryTime.toString();
};
