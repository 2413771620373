import { utils } from 'ethers';
import { errors } from '../../services/errors';

/**
 * Compare two ethereum addresses.
 *
 * @param a - an address
 * @param o - another address to compare
 * @returns `true` if the addresses are equal, `false` otherwise
 */
export const compareAddresses = (a: string, o: string): boolean => {

    try {

        const address = utils.getAddress(a);
        const other = utils.getAddress(o);

        return address === other;

    } catch (error) {

        errors.process(error);
    }

    return false;
};
