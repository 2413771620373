import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { notifications } from './notification-service';
import { Notification } from './types';

let notificationId = 0;

let notificationOutletId = 0;

const notificationOutletTemplate = function (this: NotificationOutletElement) {

    const notifications = this.notifications.entries();

    return html`${ repeat(notifications, (entry) => entry[0], ([id, notification]) => html`
        <sw-notification id=${ id }
            class=${ notification.class }
            .icon=${ notification.icon }
            .type=${ notification.type }
            .timeout=${ notification.timeout }
            .dismissable=${ notification.dismissable }
            .content=${ notification.content }></sw-notification>`) }`;
};

@customElement('sw-notification-outlet')
export class NotificationOutletElement extends LitElement {

    protected notifications = new Map<string, Notification>();

    show (n: Notification): string {

        const id = n.id || `sw-notification-${ notificationId++ }`;

        this.notifications.set(id, n);

        this.requestUpdate();

        return id;
    }

    dismiss (i: string) {

        this.notifications.delete(i);

        this.requestUpdate();
    }

    refresh (i: string, n: Notification) {

        const notification = this.notifications.get(i);

        if (notification) this.notifications.set(i, { ...notification, ...n });

        this.requestUpdate();
    }

    connectedCallback () {

        super.connectedCallback();

        this.id = this.id || `sw-notification-outlet-${ notificationOutletId++ }`;

        notifications.connect(this);
    }

    disconnectedCallback () {

        notifications.disconnect(this);
    }

    createRenderRoot () {

        return this;
    }

    render () {

        return notificationOutletTemplate.apply(this);
    }
}
