import { FixedNumber, utils } from 'ethers';
import { SECONDS_PER_YEAR } from '../../constants/constants';
import { Token } from '../../types';
import { timeUntilMaturity } from '../maturity';

const EMPTY = /^\s*$/;

const EMPTY_OR_ZERO = /^(0|\s|\.)*$/;

/**
 * Checks if a string value is empty.
 *
 * @param v - the value to test
 * @returns `true` if the value is empty, `false` otherwise
 */
export const empty = (v: string | undefined): boolean => v === undefined || EMPTY.test(v);

/**
 * Checks if a string value is empty or represents zero.
 *
 * @param v - the value to test
 * @returns `true` if the value is empty or zero, `false` otherwise
 */
export const emptyOrZero = (v: string | undefined): boolean => v === undefined || EMPTY_OR_ZERO.test(v);

/**
 * Expands an amount to its smallest token denomination.
 *
 * @param a - the amount
 * @param d - the number of decimals or a {@link Token}
 * @returns the amount in its smallest denomination
 */
export const expandAmount = (a: string, d: number | Token): string => {

    return emptyOrZero(a)
        ? empty(a)
            ? ''
            : '0'
        : utils.parseUnits(a, (typeof d === 'number') ? d : d.decimals).toString();
};

/**
 * Contracts an amount to its base token denomination.
 *
 * @param a - the amount
 * @param d - the number of decimals or a {@link Token}
 * @returns the amount in its base denomination
 */
export const contractAmount = (a: string, d: number | Token): string => {

    return emptyOrZero(a)
        ? empty(a)
            ? ''
            : '0'
        : trim(utils.formatUnits(a, (typeof d === 'number') ? d : d.decimals));
};

/**
 * Compares two amounts.
 *
 * @param a - amount a
 * @param b - amount b
 * @param d - the number of decimals or a {@link Token}
 * @returns `-1` if a is smaller than b, `1` if a is bigger than b, `0` if a and b are equal
 */
export const compareAmounts = (a: string, b: string, d: number | Token): number => {

    const amount1 = utils.parseUnits(emptyOrZero(a) ? '0' : a, (typeof d === 'number') ? d : d.decimals);
    const amount2 = utils.parseUnits(emptyOrZero(b) ? '0' : b, (typeof d === 'number') ? d : d.decimals);

    return amount1.lt(amount2) ? -1 : amount1.gt(amount2) ? 1 : 0;
};

/**
 * Calculates a fraction of an amount.
 *
 * @param a - the amount
 * @param f - a fraction (0 to 1)
 * @param d - the number of decimals or a {@link Token}
 * @returns the specified fraction of the amount
 */
export const partialAmount = (a: string, f: number, d: number | Token): string => {

    const decimals = (typeof d === 'number') ? d : d.decimals;

    return fixed(a).mulUnsafe(fixed(f)).round(decimals).toString();
};

/**
 * Calculates the fraction of two amounts.
 *
 * @param a - amount a
 * @param b - amount b
 * @param d - the number of decimals or a {@link Token}
 * @returns the fraction of `amount a / amount b`
 */
export const fraction = (a: string, b: string, d: number | Token): number => {

    const decimals = (typeof d === 'number') ? d : d.decimals;

    return (emptyOrZero(a) || emptyOrZero(b))
        ? 0
        : fixed(a).divUnsafe(fixed(b)).round(decimals).toUnsafeFloat();
};

/**
 * Creates an `ethers.FixedNumber`.
 *
 * @remarks
 * This helper shortens the call to `FixedNumber.from(value, format)` and ensures
 * that the provided `value` doesn't have more decimals than the `format` allows.
 * `FixedNumber.from()` does not correct this automatically.
 *
 * @param n - the number to convert to `ethers.FixedNumber`
 * @param d - the number of decimals (max 18)
 * @returns the `ethers.FixedNumber`
 */
export const fixed = (n: string | number, d = 18): FixedNumber => {

    const number = n.toString();

    if (number.length > d) {

        const frac = number.split('.')[1] as string | undefined;

        if (frac && frac.length > d) {

            const frmt = format(frac.length);

            return FixedNumber.from(number, frmt).round(d).toFormat(format(d));
        }
    }

    return FixedNumber.from(number, format(d));
};

/**
 * Trims trailing zeros from the fractional part of a number.
 *
 * @param n - the number to trim
 * @returns the trimmed number
 */
export const trim = (n: string): string => {

    // we need to use capture groups here, as Safari doesn't support lookbehinds in regular expressions
    // the first regexp removes trailing `0`s after a decimal point
    // the second regexp removes dangling decimal points
    return n.replace(/(\.\d*?)0*$/, '$1').replace(/\.$/, '');
};

/**
 * Adds trailing zeros to the fractional part of a number.
 *
 * @param n - the number to pad
 * @param d - the amount of decimals to pad
 * @returns
 */
export const pad = (n: string, d = 2): string => {

    const [int, frac] = n.split('.');
    const pad = Math.max(d - (frac?.length ?? 0), 0);

    return (pad > 0)
        ? `${ int }.${ frac || '' }${ new Array(pad).fill('0').join('') }`
        : n;
};

/**
 * Rounds a number to the specified number of decimals and trims or pads trailing zeros.
 *
 * @param n - the number to round
 * @param d - the number of decimals to round to
 * @param p - pad the result with trailing zeros to have a fixed precision (defaults to `false`)
 * @returns the rounded number
 */
export const round = (n: string | number, d = 2, p = false): string => {

    return p
        ? pad(fixed(n, d).toString(), d)
        : trim(fixed(n, d).toString());
};

/**
 * Calculates the annualization factor for effective rates based on a maturity.
 *
 * @param m - maturity
 * @param d - number of decimals for underlying (depends on token, default: `18`)
 * @returns the annualization factor
 */
export const annualize = (m: string, d = 18): FixedNumber => {

    return fixed(SECONDS_PER_YEAR)
        .divUnsafe(fixed(timeUntilMaturity(m)))
        .round(d)
        .toFormat(format(d));
};

/**
 * Calculates the de-annualization factor for annual rates based on a maturity.
 *
 * @param m - maturity
 * @param d - number of decimals for underlying (depends on token, default: `18`)
 * @returns the deannualization factor
 */
export const deannualize = (m: string, d = 18): FixedNumber => {

    return fixed(timeUntilMaturity(m))
        .divUnsafe(fixed(SECONDS_PER_YEAR))
        .round(d)
        .toFormat(format(d));
};

/**
 * Creates an `ethers.FixedNumber` format string.
 *
 * @remarks
 * https://docs.ethers.io/v5/api/utils/fixednumber/#FixedFormat
 *
 * @param d - the number of decimals
 * @returns the format string
 */
const format = (d: number): string => `fixed128x${ d }`;
