import { Balance, Balances, Market, Order, Token } from '../../types';
import { inferTokenType } from './inference';

/**
 * Resolves the {@link Token} which represents the order's `amount`.
 *
 * @param o - the order
 * @param m - the market associated with the order
 */
export const amountToken = (o: Order, m: Market): Token => {

    const token = inferTokenType(o);

    return m.tokens[token];
};

/**
 * Resolves the {@link Balance} which represents the order's `amount`.
 *
 * @param o - the order
 * @param b - the balances associated with the order
 */
export const amountBalance = (o: Order, b: Balances): Balance => {

    return b[inferTokenType(o)];
};

/**
 * Resolves the {@link Token} which represents the order's `price`.
 *
 * @remarks
 * The price of an order always refers to the market's underlying.
 *
 * @param o - the order
 * @param m - the market associated with the order
 */
export const priceToken = (o: Order, m: Market): Token => {

    return m.tokens.underlying;
};
