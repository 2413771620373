import { html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { inferOrderSide, invertOrderSide, invertPrice, locked, orderFixedAPY, paused } from '../../helpers';
import { amount as amountTemplate, amountLabel, balance as balanceTemplate, price as priceTemplate, status } from '../../shared/templates';
import { services } from '../../state';
import { OrderbookEvent } from '../../state/orderbook';
import { OrderbookEntry, Token } from '../../types';
import { orderbookEntries, orderbookSpread, orderSideClass } from './helpers';
import { orderLockTemplate } from './templates/order-lock';

type OrderbookState = typeof services.orderbook.state;

/**
 * Creates the orderbook header template.
 *
 * @param t - the currently traded token
 * @param u - the market's underlying token
 */
const headerTemplate = (t?: Token, u?: Token) =>
    html`<div class="header">
        ${ amountLabel('Volume', t?.symbol) }
        ${ amountLabel('Price', u?.symbol) }
        ${ amountLabel('Rate', '%') }
    </div>`;

/**
 * Creates the orderbook spread divider template.
 *
 * @param s - the spread
 * @param r - the spread-rate
 */
const spreadTemplate = function (this: OrderbookElement, s: string, r: string) {

    return html`<div class="spread">
        <span class="label">Spread</span>
        ${ priceTemplate(s, undefined, true) }
        ${ amountTemplate(r, undefined, undefined, true) }
    </div>`;
};

/**
 * Creates an orderbook entry template
 *
 * @param e - the {@link OrderbookEntry}
 */
const entryTemplate = function (this: OrderbookElement, e: OrderbookEntry) {

    const state = this.orderbookService.state;

    if (!state.matches('fetching') && !state.matches('success')) return html``;

    const { market, token, tokenType, yieldType, fillPreview } = state.context;
    const { orders, meta } = e;

    const volume = (tokenType === 'underlying' && yieldType === 'floating')
        ? meta.premiumAvailable
        : meta.principalAvailable;

    const price = (tokenType === 'zcToken')
        ? invertPrice(meta.price, token.decimals)
        : meta.price;

    const side = (tokenType === 'zcToken')
        ? invertOrderSide(inferOrderSide(orders[0].order))
        : inferOrderSide(orders[0].order);

    const rate = orderFixedAPY(meta.price, market.maturity, token.decimals);

    const filled = fillPreview && fillPreview.orders.find(
        previewOrderWithMeta => orders.some(
            entryOrderWithMeta => entryOrderWithMeta.order.key === previewOrderWithMeta.order.key,
        ),
    );

    return html`<div class="entry ${ orderSideClass(side) } ${ filled ? 'filled' : '' }">
        <span class="volume">${ balanceTemplate(volume, token, this.precision, true) }</span>
        <span class="price">${ priceTemplate(price, undefined, true) }</span>
        <span class="rate">${ amountTemplate(rate, undefined, undefined, true) }</span>
    </div>`;
};

const titleTemplate = function (this: OrderbookElement) {

    const state = this.orderbookService.state;

    // show loader only, if no loaders are shown in the orderbook entries region
    const showLoader = state.matches('fetching')
        && this.receivingPremium.length > 0
        && this.payingPremium.length > 0;

    return html`<h2>Orderbook${ showLoader ? status('loading') : '' }</h2>`;
};

const template = function (this: OrderbookElement) {

    const state = this.orderbookService.state;
    const { market, token } = this.orderbookService.state.context;

    // a key function for the repeat directive which returns the key of the first order in the stack
    const keyFn = (entry: OrderbookEntry) => entry.orders[0]?.order.key;

    return html`

    ${ titleTemplate.call(this) }

    ${ headerTemplate(token, market?.tokens.underlying) }

    <section class=${ orderSideClass('receivingPremium') }>
        ${ (state.matches('initial') || state.matches('fetching') && this.receivingPremium.length === 0)
            ? status(state.matches('initial') ? 'initial' : 'loading')
            : html`<div class="entries">
                ${ repeat(this.receivingPremium, keyFn, entry => entryTemplate.call(this, entry)) }
            </div>`
        }
    </section>

    ${ spreadTemplate.call(this, this.spread, this.spreadRate) }

    <section class=${ orderSideClass('payingPremium') }>
        ${ (state.matches('initial') || state.matches('fetching') && this.payingPremium.length === 0)
            ? status(state.matches('initial') ? 'initial' : 'loading')
            : html`<div class="entries">
                ${ repeat(this.payingPremium, keyFn, entry => entryTemplate.call(this, entry)) }
            </div>`
        }
    </section>

    ${ this.locked ? orderLockTemplate() : '' }
    `;
};

@customElement('sw-orderbook')
export class OrderbookElement extends LitElement {

    protected orderbookService = services.orderbook;

    @state()
    receivingPremium: OrderbookEntry[] = [];

    @state()
    payingPremium: OrderbookEntry[] = [];

    @state()
    spread = '0';

    @state()
    spreadRate = '0';

    @state()
    precision = 4;

    @property({
        attribute: true,
        reflect: true,
        type: Boolean,
    })
    locked = false;

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.orderbookService.onTransition(this.handleTransition);
    }

    disconnectedCallback () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.orderbookService.off(this.handleTransition);

        super.disconnectedCallback();
    }

    createRenderRoot () {

        return this;
    }

    render () {

        return template.apply(this);
    }

    updated () {

        // make sure the smallest price is always visible on update
        const list = this.renderRoot.querySelector('.receiving-premium .entries');

        if (list) {

            const height = list.scrollHeight ?? 0;

            list.scrollTop = height;
        }
    }

    protected updateOrders () {

        // by moving the orders from the context to the component state,
        // it stabilizes the rendering of the order lists (no more visible reordering during render)
        // this has probably to do with change detection, which stores a previous version
        // of the orders for comparison, while that doesn't happen when we take orders
        // directly from the state context in the template functions
        if (!this.orderbookService.state.matches('initial')) {

            const { payingPremium = [], receivingPremium = [], tokenType } = this.orderbookService.state.context;

            this.receivingPremium = (tokenType !== 'zcToken')
                ? orderbookEntries([...receivingPremium].reverse())
                : orderbookEntries([...payingPremium].reverse());

            this.payingPremium = (tokenType !== 'zcToken')
                ? orderbookEntries(payingPremium)
                : orderbookEntries(receivingPremium);
        }
    }

    protected updateSpread () {

        if (this.orderbookService.state.matches('success')) {

            const { payingPremium, receivingPremium } = this.orderbookService.state.context;

            const { spread, spreadRate } = orderbookSpread(receivingPremium, payingPremium);

            this.spread = spread;
            this.spreadRate = spreadRate;
        }
    }

    protected handleTransition (state: OrderbookState, event: OrderbookEvent) {

        // no orderbook was fetched for these events
        const noFetch = event.type === 'ORDERBOOK.YIELD' || event.type === 'ORDERBOOK.PREVIEW';

        if (!state.matches('initial') && !noFetch) {

            this.updateOrders();
            this.updateSpread();
        }

        // check if market is locked or paused on each transition
        this.locked = state.matches('success')
            ? locked(state.context.market) || paused(state.context.market)
            : false;

        this.requestUpdate();
    }
}
