import { Market, Order, OrderSide, Token, TokenType, YieldType } from '../../types';
import { emptyOrZero, fixed, round } from '../amount';
import { invertPrice, orderFixedAPY, orderFloatingAPY, orderFloatingProfit, orderPremium, orderPrice, orderPrincipal } from './calculation';

/**
 * Infer the {@link TokenType} from an order
 *
 * @param o - the order
 */
export const inferTokenType = (o: { exit: boolean; vault: boolean; }): TokenType => {

    return o.exit
        ? o.vault
            ? 'nToken'
            : 'zcToken'
        : 'underlying';
};

/**
 * Infer the {@link YieldType} from an order
 *
 * @param o - the order
 */
export const inferYieldType = (o: { exit: boolean; vault: boolean; }): YieldType | undefined => {

    return !o.exit
        ? !o.vault
            ? 'fixed'
            : 'floating'
        : undefined;
};

/**
 * Infer the {@link OrderSide} of an order on the orderbook.
 *
 * @param o - the order
 */
export const inferOrderSide = (o: { exit: boolean; vault: boolean; }): OrderSide => {

    return (o.exit && o.vault || !o.exit && !o.vault)
        // selling nTokens
        ? 'receivingPremium'
        // buying nTokens
        : 'payingPremium';
};

/**
 * Inverts the specified {@link OrderSide}.
 *
 * @param s - the order side to invert
 * @returns the opposite `OrderSide` of the specified one
 */
export const invertOrderSide = (s: OrderSide): OrderSide => {

    return (s === 'payingPremium') ? 'receivingPremium' : 'payingPremium';
};

/**
 * Infer an order's exit/vault configuration from a {@link TokenType} and a {@link YieldType}
 *
 * @param t - the `TokenType`
 * @param y - the `YieldType`
 * @returns an object containing the appropriate `exit` and `vault` flags
 */
export const inferExitVault = (t: TokenType, y: YieldType): { exit: boolean; vault: boolean; } => {

    return (t === 'underlying')
        ? (y === 'fixed')
            ? { exit: false, vault: false }
            : { exit: false, vault: true }
        : (t === 'nToken')
            ? { exit: true, vault: true }
            : { exit: true, vault: false };
};

/**
 * Infers an order's `amount` based on its exit/vault configuration.
 *
 * @remarks
 * For `vaultInitiates` (floating yield orders) the amount represents the order premium.
 * For all other orders the amount represents the order principal.
 *
 * @param o - the order
 */
export const inferAmount = (o: Order): string => {

    return (!o.exit && o.vault)
        // vaultInitiate
        ? o.premium
        // other orders
        : o.principal;
};

/**
 * Infers an order's `price` based on its exit/vault configuration.
 *
 * @remarks
 * For `zcTokenExits` the `price` is the ratio of `zcToken` spent per `underlying` received.
 * To represent this, we need to 'invert' the price.
 *
 * @param o - the order
 * @param m - the market associated with the order
 */
export const inferPrice = (o: Order, m: Market): string => {

    if (emptyOrZero(o.principal) || emptyOrZero(o.premium)) return '';

    const decimals = m.tokens.underlying.decimals;

    const price = orderPrice(o.principal, o.premium, decimals);

    return (o.exit && !o.vault)
        // zcTokenExit
        ? invertPrice(price, decimals)
        // other orders
        : price;
};

/**
 * Infers the amount received (yielded) from a `limit` order.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @returns an object containing the received amount and its associated token
 */
export function inferYield (o: Order, m: Market): { received?: string; token?: Token; };
/**
 * Infers the amount received (yielded) from a `market` order.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param v - the order volume
 * @param p - the effective price
 * @returns an object containing the received amount and its associated token
 */
export function inferYield (o: Order, m: Market, v: string, p: string): { received?: string; token?: Token; };
export function inferYield (o: Order, m: Market, v?: string, p?: string): { received?: string; token?: Token; } {

    let received: string | undefined;
    let token: Token | undefined;

    const isValid = !emptyOrZero(o.principal) && !emptyOrZero(o.premium) || !emptyOrZero(v) && !emptyOrZero(p);
    const isZcTokenExit = !o.vault && o.exit;
    const isVaultInitiate = o.vault && !o.exit;

    if (isValid) {

        if (isZcTokenExit) {

            // `zcTokenExits` ('Selling zcTokens') yield the `principal` minus the `premium` in underlying
            token = m.tokens.underlying;

            const principal = (v === undefined || p === undefined)
                // limit
                ? o.principal
                // market: the `principal` is the `volume`
                : v;

            const premium = (v === undefined || p === undefined)
                // limit
                ? o.premium
                // market: the `premium` needs to be calculated from the `volume` and `effectivePrice`
                : orderPremium(v, p);

            // ethers.FixedNumber.toString() will always return a trailing decimal zero which we need to remove
            received = round(fixed(principal).subUnsafe(fixed(premium)).toString(), 0);

        } else if (isVaultInitiate) {

            // `vaultInitiates` ('Floating Yield') yield the `principal` in nTokens
            token = m.tokens.nToken;

            received = (v === undefined || p === undefined)
                // limit
                ? o.principal
                // market: the `principal` needs to be calculated from the `volume` and `effectivePrice`
                : orderPrincipal(v, p);

        } else {

            // `zcTokenInitiates` and `vaultExits` ('Selling nTokens' and 'Fixed Yield') yield the `premium` in underlying
            token = m.tokens.underlying;

            received = (v === undefined || p === undefined)
                // limit
                ? o.premium
                // market: the `premium` needs to be calculated from the `volume` and `effectivePrice`
                : orderPremium(v, p);
        }
    }

    return {
        received,
        token,
    };
}

/**
 * Infers the rate (APY) from a `limit` order.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @returns the calculated APY for `floating` or `fixed` yield orders, undefined otherwise
 */
export function inferRate (o: Order, m: Market): string | undefined;
/**
 * Infers the rate (APY) from a `market` order.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param v - the order volume
 * @param p - the effective price
 * @returns the calculated APY for `floating` or `fixed` yield orders, undefined otherwise
 */
export function inferRate (o: Order, m: Market, v: string, p: string): string | undefined;
export function inferRate (o: Order, m: Market, v?: string, p?: string): string | undefined {

    let rate: string | undefined;

    const isValid = !emptyOrZero(o.principal) && !emptyOrZero(o.premium) || !emptyOrZero(v) && !emptyOrZero(p);
    const yieldType = inferYieldType(o);
    const d = m.tokens.underlying.decimals;

    if (isValid) {

        const principal = (v === undefined || p === undefined)
            // limit
            ? o.principal
            // market: the `principal` is the `volume`
            : (yieldType === 'floating') ? orderPrincipal(v, p) : v;

        const premium = (v === undefined || p === undefined)
            // limit
            ? o.premium
            // market: the `premium` needs to be calculated from the `volume` and `effectivePrice`
            : (yieldType === 'floating') ? v : orderPremium(v, p);

        // `fixed` yield orders have a fixed rate
        if (yieldType === 'fixed') {

            rate = orderFixedAPY(principal, premium, m.maturity, d);
        }

        // `floating` yield orders have a floating rate based on the cToken supplyRate
        if (yieldType === 'floating' && m.interestRate) {

            rate = orderFloatingAPY(principal, premium, m.maturity, m.interestRate, d);
        }
    }

    return rate;
}

/**
 * Infers the profit (PnL) from a floating yield `limit` order.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @returns the calculated PnL for `floating` yield orders, undefined otherwise
 */
export function inferProfit (o: Order, m: Market): string | undefined;
/**
 * Infers the profit (PnL) from a floating yield `market` order.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param v - the order volume
 * @param p - the effective price
 * @returns the calculated PnL for `floating` yield orders, undefined otherwise
 */
export function inferProfit (o: Order, m: Market, v: string, p: string): string | undefined;
export function inferProfit (o: Order, m: Market, v?: string, p?: string): string | undefined {

    let profit: string | undefined;

    const isValid = !emptyOrZero(o.principal) && !emptyOrZero(o.premium) || !emptyOrZero(v) && !emptyOrZero(p);
    const yieldType = inferYieldType(o);

    if (isValid && yieldType === 'floating' && m.interestRate) {

        const principal = (v === undefined || p === undefined)
            // limit
            ? o.principal
            // market: the `principal` needs to be calculated from the `volume` and `effectivePrice`
            : orderPrincipal(v, p);

        const premium = (v === undefined || p === undefined)
            // limit
            ? o.premium
            // market: the `premium` is the `volume`
            : v;

        profit = orderFloatingProfit(principal, premium, m.maturity, m.interestRate);
    }

    return profit;
}

/**
 * Infers the fee of a `limit` order.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @returns an object containing the fee amount and its associated token
 */
export function inferFee (o: Order, m: Market): { fee?: string; token?: Token; };
/**
 * Infers the fee of a `market` order.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param v - the order volume
 * @param p - the effective price
 * @returns an object containing the fee amount and its associated token
 */
export function inferFee (o: Order, m: Market, v: string, p: string): { fee?: string; token?: Token; };
export function inferFee (o: Order, m: Market, v?: string, p?: string): { fee?: string; token?: Token; } {

    let received: string | undefined;
    let fee: string | undefined;
    let token: Token | undefined;

    const isZcTokenExit = !o.vault && o.exit;
    const isVaultInitiate = o.vault && !o.exit;

    // for limit orders the fee is 0
    if (v === undefined || p === undefined) {

        ({ token } = inferYield(o, m));
        fee = '0';

    } else {

        ({ received, token } = inferYield(o, m, v, p));

        // if we don't have an inferred yield the order was probably invalid
        if (received === undefined || token === undefined) {

            fee = '0';

        } else {

            if (isVaultInitiate) {

                fee = round(fixed(1 / 150).mulUnsafe(fixed(received)).toString(), 0);

            } else if (isZcTokenExit) {

                fee = round(fixed(1 / 200).mulUnsafe(fixed(received)).toString(), 0);

            } else {

                fee = round(fixed(1 / 100).mulUnsafe(fixed(received)).toString(), 0);
            }
        }
    }

    return { fee, token };
}
