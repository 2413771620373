import { dialogContent, DialogElement, dialogFooter } from '@swivel-finance/ui/elements/dialog';
import { ToggleChangeEvent } from '@swivel-finance/ui/elements/toggle';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { preferences } from '../../services/preferences';
import { amount, PRICE_DECIMALS, PRICE_PADDING } from '../../shared/templates';

const template = function (this: OversizedOrderDialogElement) {

    return html`
    <div class="dialog-foreground">
        <header class="ui-dialog-header">
            <ui-icon name="exclamation"></ui-icon>
            <button type="button" aria-label="close dialog" @click=${ () => void this.dismiss(true) }>
                <ui-icon name="times"></ui-icon>
            </button>
            <h2 class="ui-dialog-title" id="${ this.id }-title">
                ${ this.status === 'high'
                    ? html`The price you set seems a bit high.`
                    : html`The price you set seems a bit low.`
                }
            </h2>
        </header>
        ${ dialogContent(html`
        <p>Please confirm you want to complete this trade:</p>
        <dl>
            <dt>Last trade price:</dt>
            <dd>${ amount(this.lastTradePrice, undefined, PRICE_DECIMALS, PRICE_PADDING) }</dd>
            <dt>Your trade price:</dt>
            <dd>${ amount(this.currentTradePrice, undefined, PRICE_DECIMALS, PRICE_PADDING) }</dd>
        </dl>
        <label>
            Don't show this message again
            <ui-checkbox name="disable-warning"
                .checked=${ preferences.get('disableOversizedWarning') }
                @ui-toggle-changed=${ (event: ToggleChangeEvent) => this.handleChange(event) }></ui-checkbox>
        </label>`) }
        ${ dialogFooter(html`
        <button type="button" @click=${ () => this.cancel(true) }>Cancel</button>
        <button type="button" class="primary" @click=${ () => this.confirm(true) }>Place Order</button>`) }
    </div>
    `;
};

@customElement('sw-oversized-order-dialog')
export class OversizedOrderDialogElement extends DialogElement<boolean> {

    result = true;

    @property()
    lastTradePrice = '0';

    @property()
    currentTradePrice = '0';

    @property()
    status: 'high' | 'low' = 'high';

    constructor (properties?: { lastTradePrice: string; currentTradePrice: string; status: 'high' | 'low'; }) {

        super();

        if (properties) {

            this.status = properties.status;
            this.lastTradePrice = properties.lastTradePrice;
            this.currentTradePrice = properties.currentTradePrice;
        }
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleChange (event: ToggleChangeEvent) {

        preferences.set('disableOversizedWarning', event.detail.checked);
    }
}
