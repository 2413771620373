import { html } from 'lit';
import { tokenSymbol } from '../shared/templates/amounts';
import { Balances, Token } from '../types';

export const TOOLTIPS = {
    HEADER: {
        WALLET: 'Click to copy address to clipboard.',
        REWARDS: {
            EARNED: (token?: Token) => html`The amount of ${ tokenSymbol(token) } tokens earned during the current reward cycle.
These will become redeemable at the beginning of the next reward cycle.`,
            REDEEMABLE: (token?: Token) => html`The amount of ${ tokenSymbol(token) } tokens earned during previous reward cycles.
These can be redeemed immediately.`,
        },
    },
    PROFESSIONAL: {
        ORDERBOOK: {
            HEADER: (token: string) => html`Orderbook for ${ token }`,
        },
        ORDER_INPUT: {
            FIXED_YIELD_TAB: (zcToken: string, nToken: string, underlying: string) => html`<strong>Lend ${ underlying } and earn a fixed-yield.</strong>
<p>Enter an amount to lend for an immediate yield. We split ${ underlying } into ${ nToken } + ${ zcToken } then sell
    your ${ nToken }.</p>`,
            AMPLIFIED_YIELD_TAB: (nToken: string) => html`<strong>Purchase ${ nToken } to earn variable interest.</strong>
<p>Enter the amount of premium you would like to spend and receive floating-yield exposure.</p>`,
            SELL_NTOKENS: (nToken: string) => html`<strong>Sell your ${ nToken } back to the market</strong>
<p>Enter the amount of ${ nToken } you would like to sell.</p>`,
            SELL_ZCTOKENS: (nToken: string, zcToken: string, underlying: string) => html`<strong>Sell your ${ zcToken } back to the market</strong>
<p>Enter an amount of ${ zcToken } to sell. We buy ${ nToken } for you, and combine it with ${ zcToken } to return your
    ${ underlying }.</p>`,
            LIMIT_TOGGLE: 'Place a limit order to set your price, or a market order to take the best price available.',
            INTEREST_YIELDED: (token: string) => html`The amount of ${ token } immediately yielded.`,
            FIXED_APY: 'Estimated based on the immediate premium payment.',
            ESTIMATED_APY: 'Estimated yield to maturity based on current rates.',
            ESTIMATED_PNL: 'Estimated profit to maturity based on current rates.',
            NO_RATE: 'We currently can\'t show any estimations, current rates are unavailable.',
            NTOKEN_PURCHASED: (token: string) => html`The amount of ${ token } generating interest until maturity.`,
            PRICE_ZCTOKENS: (token: string, underlying: string) => html`Converted based on the price paid to purchase ${ token } and redeem ${ underlying }.`,
        },
        ORDERS: {
            REWARDS: html`<div>Earn <span class="unit">SWIV</span> rewards when your orders are filled.</div>`,
            ORDERS_TAB: 'Your orders filtered by their current status. Orders expire after two weeks.',
            TRADES_TAB: 'Your trades and their individual fills. This includes both your market orders and filled limit orders.',
            INSOLVENT: html`<p>This order is marked as temporarily insolvent and will be resolved within 2 minutes.</p><p>Visit our docs for more information.</p>`,
            CANCELLING: (isCancelling: boolean, isFull: boolean) => {
                return isCancelling
                    ? 'This order is currently being cancelled.'
                    : isFull
                        ? 'This order has been filled and can no longer be cancelled.'
                        : 'Cancel this order.';
            },
        },
    },
    NOTIFICATIONS: {
        ETHERSCAN: (txHash: string) => html`View Etherscan record for ${ txHash }`,
    },
    POSITIONS: {
        FILTERS: {
            ACTIVE: 'Show positions that will mature at a future date.',
            MATURED: 'Show positions that have recently matured.',
            ARCHIVED: 'Show positions that matured 2 or more weeks ago.',
        },
        POSITIONS: {
            MARKET: function name (market: string, underlying: string, date: string) {
                return `${ underlying } lent on ${ market } protocol, maturing on ${ date }`;
            },
            SPLIT: (b: Balances) => `Deposit and split your ${ b.underlying.symbol } into ${ b.zcToken.symbol } and ${ b.nToken.symbol }.`,
            COMBINE: (b: Balances) => `Combine equal amounts of ${ b.zcToken.symbol } and ${ b.nToken.symbol } to redeem your ${ b.underlying.symbol } early.`,
            REDEEM: (b: Balances) => `Redeem your ${ b.underlying.symbol } deposit.`,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            REDEEM_INTEREST: (b: Balances) => 'Redeem your earned interest.',
        },
        HEADER: {
            NTOKEN_BALANCE: 'The amount of underlying generating you interest.',
            ZCTOKEN_BALANCE: 'The amount of underlying you can redeem upon maturity.',
            INTEREST: 'The amount of interest you can claim today.',
        },
    },
};
