import { FocusTrap } from '@swivel-finance/ui/behaviors/focus';
import { FocusListBehavior, ListBehavior, SelectEvent } from '@swivel-finance/ui/behaviors/list';
import { OpenChangeEvent, OverlayBehavior, OverlayTriggerBehavior } from '@swivel-finance/ui/behaviors/overlay';
import { PositionBehavior } from '@swivel-finance/ui/behaviors/position';
import { POSITION_CONFIG_CONNECTED } from '@swivel-finance/ui/elements/constants';
import { cancel } from '@swivel-finance/ui/utils/events';
import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { createRef, Ref, ref } from 'lit/directives/ref.js';
import { ERRORS } from '../../constants';
import { ENV } from '../../env/environment';
import { emptyOrZero, expandAmount, redeemable } from '../../helpers';
import { ProcessedError } from '../../services';
import { faucet } from '../../services/faucet';
import { notifications } from '../../shared/components';
import { balance, status, tokenBalance, tokenSymbol } from '../../shared/templates';
import { services } from '../../state';
import { Balance, Balances, Market, TokenType } from '../../types';

type WalletState = typeof services.wallet.state;

const tokenOrder: TokenType[] = ['underlying', 'nToken', 'zcToken'];

const labelTemplate = (market: Market, balance: Balance) =>
    html`<span class="label">${ balance.symbol === market.tokens.underlying.symbol ? 'Lend' : 'Sell' }</span>`;

const loadingTemplate = function (this: BalanceSelectorElement) {

    const state = this.walletService.state;

    return html`
    <div class="balance-item">
        <span class="identifier">
            ${ status(state.matches('fetching') ? 'loading' : 'initial') }
        </span>
        <span class="extra">
            ${ state.matches('fetching') ? 'Updating balances...' : 'Not connected...' }
        </span>
    </div>
    `;
};

const errorTemplate = function (this: BalanceSelectorElement) {

    const { error } = this.walletService.state.context;

    return html`
    <div class="balance-item">
        <span class="identifier">${ error || ERRORS.COMPONENTS.BALANCES.DEFAULT.message }</span>
        <span class="extra">Click to update balances.</span>
    </div>
    `;
};

const successTemplate = function (this: BalanceSelectorElement, market: Market, balances: Balances, current: Balance) {

    return html`
    <div class="balance-item">
        <div class="identifier">
            ${ labelTemplate(market, current) }
            ${ tokenSymbol(current) }
            ${ balance(current.balance, current, this.precision, true) }
        </div>
        <div class="extra">
            <span>Redeemable</span>
            ${ tokenBalance(redeemable(market, balances.vault), balances.underlying, 4, true) }
        </div>
    </div>
    `;
};

const balanceTemplate = function (this: BalanceSelectorElement, market: Market, current: Balance) {

    return html`
    <div class="balance-item">
        <div class="identifier">
            ${ labelTemplate(market, current) }
            ${ tokenSymbol(current) }
            ${ balance(current.balance, current, this.precision, true) }
        </div>
    </div>
    `;
};

const template = function (this: BalanceSelectorElement) {

    const state = this.walletService.state;

    const classes = {
        loading: state.matches('initial') || state.matches('fetching'),
        error: state.matches('error'),
    };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const market = state.context.market!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const balances = state.context.balances!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const selected = state.context.selected!;

    return html`
    <button type="button"
        class=${ classMap(classes) }
        @click=${ (event: MouseEvent) => this.handleClick(event) }
        ${ ref(this.triggerRef) }>
    ${ state.matches('initial') || state.matches('fetching')
            ? loadingTemplate.call(this)
            : state.matches('error')
                ? errorTemplate.call(this)
                : html`
                ${ successTemplate.call(this, market, balances, balances[selected]) }
                <div class="toggle">
                    <ui-icon name="chevron"></ui-icon>
                </div>
                `
    }
    </button>
    <div class="sw-balance-selector-overlay" ${ ref(this.overlayRef) }>

        <ul class="sw-balance-selector-listbox" ${ ref(this.listRef) }
            @ui-select-item=${ (event: SelectEvent) => this.handleSelectionChange(event) }>
        ${ state.matches('success')
            ? tokenOrder.map(token => html`
            <li role="menuitemradio" data-value="${ token }" aria-checked=${ token === selected }>
                ${ balanceTemplate.call(this, market, balances[token]) }
            </li>`)
            : nothing
        }
        </ul>
        ${ state.matches('success') && this.enableFaucet
            ? html`
            <form class="faucet" @submit=${ (event: Event) => this.handleFaucet(event) } ${ ref(this.formRef) }>
                <sw-token-input .name=${ 'amount' } .token=${ balances.underlying } .max=${ '100' }></sw-token-input>
                <button type="submit" class="primary">Faucet</button>
            </form>
            `
            : nothing
        }
    </div>
    `;
};

@customElement('sw-balance-selector')
export class BalanceSelectorElement extends LitElement {

    protected readonly enableFaucet = (ENV.chainId !== 1);

    protected walletService = services.wallet;

    protected triggerRef: Ref<HTMLButtonElement> = createRef();

    protected overlayRef: Ref<HTMLElement> = createRef();

    protected listRef: Ref<HTMLUListElement> = createRef();

    protected formRef: Ref<HTMLFormElement> = createRef();

    protected triggerBehavior?: OverlayTriggerBehavior;

    protected overlayBehavior?: OverlayBehavior;

    protected focusBehavior?: FocusTrap;

    protected listBehavior?: ListBehavior;

    @property({ type: Number })
    precision = 2;

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
    }

    selectToken (t: TokenType) {

        if (this.walletService.state.context.selected === t) return;

        this.walletService.send({
            type: 'WALLET.SELECT',
            payload: t,
        });
    }

    fetchBalances () {

        if (this.walletService.state.matches('fetching')) return;

        this.walletService.send('WALLET.FETCH');
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.walletService.onTransition(this.handleTransition);
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.walletService.off(this.handleTransition);

        this.listBehavior?.detach();
        this.overlayBehavior?.detach();
        this.triggerBehavior?.detach();

        this.overlayRef.value?.remove();

        super.disconnectedCallback();
    }

    protected firstUpdated (): void {

        // we only create the list behavior instance here, we'll attach/detach it when
        // the overlay is shown/hidden so it's only active while the list is visible
        this.listBehavior = new FocusListBehavior({
            role: 'menu',
            itemRole: 'menuitemradio',
        });

        // we want a reference of the focus behavior, so we can update it when list entries change
        this.focusBehavior = new FocusTrap();

        this.triggerBehavior = new OverlayTriggerBehavior();

        this.overlayBehavior = new OverlayBehavior({
            triggerBehavior: this.triggerBehavior,
            focusBehavior: this.focusBehavior,
            positionBehavior: new PositionBehavior({
                ...POSITION_CONFIG_CONNECTED,
                origin: this.triggerRef.value as HTMLElement,
                alignment: {
                    origin: {
                        horizontal: 'center',
                        vertical: 'start',
                    },
                    target: {
                        horizontal: 'center',
                        vertical: 'start',
                    },
                },
                width: 'origin',
                minHeight: 'origin',
            }),
            animated: true,
        });

        this.overlayBehavior.attach(this.overlayRef.value as HTMLElement);
        this.triggerBehavior.attach(this.triggerRef.value as HTMLElement, this.overlayBehavior);

        this.overlayRef.value?.addEventListener('ui-open-changed', this.handleOpenChange.bind(this));
    }

    protected updated (): void {

        void this.overlayBehavior?.update();
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected updateListBehavior () {

        // in case the overlay is closed we want to detach the list behavior
        // we also want to detach it before updating the list items
        this.listBehavior?.setActive(this.getIndex(this.walletService.state.context.selected));
        this.listBehavior?.detach();

        if (this.overlayBehavior && !this.overlayBehavior.hidden) {

            const list = this.listRef.value as HTMLElement;
            const items = list.querySelectorAll('li');
            const selected = this.getIndex(this.walletService.state.context.selected);

            this.listBehavior?.attach(list, items);
            this.listBehavior?.setSelected(selected);
            this.listBehavior?.setActive(selected, true);
        }

        // update the focus behavior after the list items were updated (they need to be in the tab sequence)
        this.focusBehavior?.update();
    }

    protected handleTransition (state: WalletState) {

        this.requestUpdate();

        // if we transition to a non-success state, the popup should close
        if (!state.matches('success')) {

            void this.overlayBehavior?.hide();
        }

        // in any case, after the component is updated, update the list behavior
        void this.updateComplete.then(() => this.updateListBehavior());
    }

    protected handleClick (e: MouseEvent) {

        if (!this.walletService.state.matches('success')) {

            e.stopImmediatePropagation();

            if (this.walletService.state.matches('error')) {

                this.fetchBalances();
            }
        }
    }

    protected handleOpenChange (event: OpenChangeEvent) {

        this.updateListBehavior();

        if (event.detail.open) {

            (this.renderRoot as HTMLElement).classList.add('open');

        } else {

            (this.renderRoot as HTMLElement).classList.remove('open');
        }
    }

    protected handleSelectionChange (e: SelectEvent) {

        const { change, current } = e.detail;

        if (change && current?.index !== undefined) {

            this.selectToken(this.getToken(current.index));
        }

        // close the popup on selection
        void this.overlayBehavior?.hide(true);
    }

    protected async handleFaucet (e: Event) {

        cancel(e);

        const state = this.walletService.state;

        if (!state.matches('success')) return;

        const owner = state.context.account;
        const token = state.context.market.tokens.underlying;

        let amount = new FormData(this.formRef.value).get('amount') as string;

        if (emptyOrZero(amount)) {

            notifications.show({
                type: 'failure',
                content: 'Please enter a valid amount to faucet.',
            });

        } else {

            amount = expandAmount(amount, token);

            const notification = notifications.show({
                type: 'progress',
                content: () => html`Fauceting ${ tokenBalance(amount, token) }`,
                dismissable: false,
            });

            try {

                await faucet.allocate(token.address, owner, amount);

                notifications.update(notification, {
                    type: 'success',
                    content: () => html`Fauceting ${ tokenBalance(amount, token) } successful.`,
                    dismissable: true,
                });

                this.walletService.send({
                    type: 'WALLET.FETCH',
                });

            } catch (error) {

                notifications.update(notification, {
                    type: 'failure',
                    content: () => html`Fauceting ${ tokenBalance(amount, token) } failed: ${ (error as ProcessedError).message || ERRORS.DEFAULT }`,
                    dismissable: true,
                });
            }
        }
    }

    protected getIndex (t?: TokenType): number {

        return tokenOrder.indexOf(t ?? 'underlying');
    }

    protected getToken (i?: number): TokenType {

        return tokenOrder[i ?? 0];
    }
}
