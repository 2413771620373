import { DEFAULT_LOCALE, SECONDS_PER_DAY } from '../../constants/constants';
import { serviceLocator } from '../../services/service-locator';
import { TIME_SERVICE } from '../../services/time';

/**
 * Locale dependent date formatters for maturity dates.
 */
export const MATURITY_DATE_FORMAT = {
    LONG: new Intl.DateTimeFormat(DEFAULT_LOCALE, { dateStyle: 'long' }),
    MEDIUM: new Intl.DateTimeFormat(DEFAULT_LOCALE, { dateStyle: 'medium' }),
    SHORT: new Intl.DateTimeFormat(DEFAULT_LOCALE, { dateStyle: 'short' }),
};

/**
 * Formats a maturity timestamp as human-readable date.
 *
 * @param m - maturity in seconds
 * @param f - optional `Intl.DateTimeFormat`
 */
export const maturityDate = (m: string, f = MATURITY_DATE_FORMAT.LONG): string => {

    const date = new Date(parseInt(m) * 1000);

    return f.format(date);
};

/**
 * Gets the number of days until maturity.
 *
 * @param m - maturity
 */
export const maturityDays = (m: string): number => {

    return Math.round(timeUntilMaturity(m) / SECONDS_PER_DAY);
};

/**
 * Gets the number of seconds until maturity.
 *
 * @param m - maturity
 */
export const timeUntilMaturity = (m: string): number => {

    const timer = serviceLocator.get(TIME_SERVICE);

    const now = parseInt(timer.timestamp());
    const maturity = parseInt(m);

    return Math.round(maturity - now);
};
