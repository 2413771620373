import { OpenChangeEvent, OverlayConfig } from '@swivel-finance/ui/behaviors/overlay';
import { AccordionElement } from '@swivel-finance/ui/elements/collapsible/accordion';
import { CollapsibleElement } from '@swivel-finance/ui/elements/collapsible/collapsible';
import { DIALOG_CONFIG_NON_MODAL, DialogElement, dialogContent, dialogFooter, dialogHeader } from '@swivel-finance/ui/elements/dialog';
import { animationsDone } from '@swivel-finance/ui/utils/dom';
import { LitElement, html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { ERRORS, TOOLTIPS } from '../../constants';
import { ENV } from '../../env/environment';
import { addToken } from '../../helpers';
import { ProcessedError, errors } from '../../services/errors';
import { RewardsService, RewardsState } from '../../services/rewards';
import { wallet } from '../../services/wallet';
import { notifications } from '../../shared/components';
import { amountLabel, errorMessage, loader, status, tokenBalance, tokenSymbol, transaction } from '../../shared/templates';
import { Token } from '../../types';
import { RequestRewardsEvent } from './events';

const DIALOG_CONFIG: OverlayConfig = DIALOG_CONFIG_NON_MODAL;

const notificationTemplate = (
    status: 'pending' | 'success' | 'failure',
    amount: string,
    token: Token,
    hashOrError?: string | ProcessedError,
) => html`
    <div class="rewards-status">
    ${ (status === 'pending')
        ? 'Claiming rewards...'
        : (status === 'success')
            ? 'Successfully claimed rewards'
            : 'Failed to claim rewards'
    }
    </div>
    <div class="rewards-amount">${ tokenBalance(amount, token) }</div>
    ${ hashOrError instanceof ProcessedError
        ? html`<div class="rewards-error">${ hashOrError.message || ERRORS.DEFAULT.message }</div>`
        : hashOrError
            ? transaction(hashOrError)
            : nothing
    }
`;

const template = function (this: RewardsElement) {

    const { isBlocked, isClaiming, isFetching, token, claim, rewards, error } = this.rewardsService.state;

    return isBlocked
        ? nothing
        : html`
        <button type="button"
            class="${ claim ? 'has-redeemable' : error ? 'has-error' : '' }"
            aria-controls="rewards-dialog">
            ${ isFetching
                ? loader()
                : html`<ui-icon name="swivel"></ui-icon><span>Rewards</span>`
            }
        </button>

        <ui-dialog class="sw-rewards-dialog" id="rewards-dialog" @ui-open-changed=${ (event: OpenChangeEvent) => this.handleOpenChange(event) } ${ ref(this.dialogRef) }>
            ${ dialogHeader('rewards-dialog', 'Claim your rewards') }
            ${ error
                ? [
                    dialogContent(isFetching ? loader() : errorMessage(error.message)),
                    dialogFooter(html`<button type="button" class="primary" ?disabled=${ isFetching } @click=${ () => this.refresh() }>Retry</button>`),
                ]
                : [
                    dialogContent(html`
                    <div class="description">

                        <ui-accordion ${ ref(this.accordionRef) }>
                            <ui-collapsible>
                                <h3 data-part="header">
                                    <span data-part="trigger">Oderbook Rewards <ui-icon name="chevron"></ui-icon></span>
                                </h3>
                                <p data-part="region">
                                    Provide liquidity by placing limit orders on the orderbook. If your limit
                                    orders get filled, you earn rewards. Earned rewards are accrued over a
                                    4 week rewards-cycle and become redeemable at the beginning of the next cycle.
                                </p>
                            </ui-collapsible>
                            <ui-collapsible>
                                <h3 data-part="header">
                                    <span data-part="trigger">Swivel Safety Module Rewards <ui-icon name="chevron"></ui-icon></span>
                                </h3>
                                <p data-part="region">
                                    Stake your SWIV in the Swivel Safety Module to earn fees and add more security to
                                    the protocol. Earn rewards for every day you stake your SWIV. Rewards are accrued
                                    over a 4 week rewards-cycle and become redeemable at the beginning of the next
                                    cycle.
                                    <br/>
                                    <a href="${ ENV.docsUrl }swivel-safety-module-ssm" target="_blank" rel="noopener">
                                        Learn more about the Swivel Safety Module and the risks involved.
                                    </a>
                                </p>
                            </ui-collapsible>
                        </ui-accordion>

                        <p>
                            Read our <a href="${ ENV.docsUrl }incentives" target="_blank" rel="noopener">docs</a>
                            for more information on rewards.
                        </p>
                    </div>
                    <div class="main">
                        <div class="earned">
                            <span class="label">
                                ${ amountLabel('Earned') }
                                <ui-icon name="question" aria-describedby="rewards-earned-tooltip"></ui-icon>
                                <ui-tooltip id="rewards-earned-tooltip">
                                    <p>
                                        ${ TOOLTIPS.HEADER.REWARDS.EARNED(token) }
                                    </p>
                                </ui-tooltip>
                            </span>
                            ${ isFetching || !rewards || !token
                                ? status('loading')
                                : tokenBalance(rewards.earned, token)
                            }
                        </div>
                        <div class="earned secondary">
                            <span class="label">
                                ${ amountLabel('Orderbook') }
                            </span>
                            ${ isFetching || !rewards || !token
                                ? status('loading')
                                : tokenBalance(rewards.orderbookEarned ?? '0', token)
                            }
                        </div>
                        <div class="earned secondary">
                            <span class="label">
                                ${ amountLabel('SSM') }
                            </span>
                            ${ isFetching || !rewards || !token
                                ? status('loading')
                                : tokenBalance(rewards.ssmEarned ?? '0', token)
                            }
                        </div>
                        <div class="redeemable">
                            <span class="label">
                                ${ amountLabel('Redeemable') }
                                <ui-icon name="question" aria-describedby="rewards-redeemable-tooltip"></ui-icon>
                                <ui-tooltip id="rewards-redeemable-tooltip">
                                    <p>
                                        ${ TOOLTIPS.HEADER.REWARDS.REDEEMABLE(token) }
                                    </p>
                                </ui-tooltip>
                            </span>
                            ${ isFetching || !rewards || !token
                                ? status('loading')
                                : tokenBalance(rewards.redeemable, token)
                            }
                        </div>
                        <div class="redeemable secondary">
                            <span class="label">
                                ${ amountLabel('Orderbook') }
                            </span>
                            ${ isFetching || !rewards || !token
                                ? status('loading')
                                : tokenBalance(rewards.orderbookRedeemable ?? '0', token)
                            }
                        </div>
                        <div class="redeemable secondary">
                            <span class="label">
                                ${ amountLabel('SSM') }
                            </span>
                            ${ isFetching || !rewards || !token
                                ? status('loading')
                                : tokenBalance(rewards.ssmRedeemable ?? '0', token)
                            }
                        </div>
                    </div>`),
                    dialogFooter(html`
                    ${ token
                        ? html`<button type="button" class="link" @click=${ () => this.handleAddToken() }>Add ${ tokenSymbol(token) } token to wallet</button>`
                        : nothing
                    }
                    <button type="button" class="primary" ?disabled=${ !claim || isClaiming || isFetching } @click=${ () => this.handleClaim() }>Claim</button>`),
                ]
            }
        </ui-dialog>
        `;
};

@customElement('sw-rewards')
export class RewardsElement extends LitElement {

    protected rewardsService: RewardsService;

    protected dialogRef: Ref<DialogElement> = createRef();

    protected accordionRef: Ref<AccordionElement> = createRef();

    constructor () {

        super();

        this.rewardsService = RewardsService.getInstance(wallet);

        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleRequestRewards = this.handleRequestRewards.bind(this);
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.rewardsService.subscribe(this.handleStateChange);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        document.body.addEventListener(RequestRewardsEvent.type, this.handleRequestRewards);

        void this.refresh();
    }

    disconnectedCallback () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.rewardsService.unsubscribe(this.handleStateChange);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        document.body.removeEventListener(RequestRewardsEvent.type, this.handleRequestRewards);

        super.disconnectedCallback();
    }

    protected createRenderRoot () {

        return this;
    }

    protected render () {

        return template.call(this);
    }

    protected firstUpdated () {

        // setting the dialog config will cause behaviors in the dialog to be re-attached
        // this will cause an opened dialog to close and re-open, so ideally we only want to set it once
        // we use the firstUpdated callback for this, as it's triggered once after the initial render
        void this.updateComplete.then(() => {

            if (this.dialogRef.value) {

                this.dialogRef.value.config = DIALOG_CONFIG;
            }
        });
    }

    protected async refresh () {

        await this.rewardsService.fetch();
    }

    protected close () {

        void this.dialogRef.value?.hide(true);
    }

    protected async handleOpenChange (event: OpenChangeEvent): Promise<void> {

        if (event.detail.target === this.dialogRef.value && event.detail.open) {

            const collapsibles = this.dialogRef.value.querySelectorAll<CollapsibleElement>('ui-collapsible');

            collapsibles?.forEach((collapsible) => {

                collapsible.removeAttribute('style');
                collapsible.updateHeight();
            });

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
            (this.dialogRef.value as any)?.positionBehavior.update();
        }

        if (this.accordionRef.value?.contains(event.detail.target)) {

            await animationsDone(this.accordionRef.value);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
            (this.dialogRef.value as any)?.positionBehavior.update();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected handleStateChange (state: Readonly<RewardsState>): void {

        this.requestUpdate();
    }

    protected async handleClaim () {

        const { isClaiming, claim, rewards, token } = this.rewardsService.state;

        if (isClaiming || !claim || !rewards || !token) return;

        const notification = notifications.show({
            type: 'progress',
            class: 'rewards-notification',
            dismissable: false,
            content: () => notificationTemplate('pending', rewards.redeemable, token),
        });

        // if we want to keep the dialog open, we should focus it to prevent a focus loss
        // this.dialogRef.value?.focus();

        this.close();

        try {

            const receipt = await this.rewardsService.claim();

            notifications.update(notification, {
                type: 'success',
                dismissable: true,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                content: () => notificationTemplate('success', rewards.redeemable, token, receipt.transactionHash),
            });

        } catch (error) {

            const processedError = errors.isProcessed(error) ? error : errors.process(error);

            notifications.update(notification, {
                type: 'failure',
                dismissable: true,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                content: () => notificationTemplate('failure', rewards.redeemable, token, processedError),
            });

            // update rewards data after failed claim (might be stale)
            void this.refresh();
        }
    }

    protected async handleAddToken () {

        const { token } = this.rewardsService.state;

        if (!token) return;

        await addToken(token);
    }

    protected handleRequestRewards (event?: RequestRewardsEvent) {

        event?.stopPropagation();

        void this.dialogRef.value?.show();
    }
}
