/* eslint-disable @typescript-eslint/ban-types */
import { MarketPlace, Swivel } from '@swivel-finance/swivel-js';
import { assign, createMachine, DoneInvokeEvent, MachineConfig, MachineOptions, TransitionsConfig } from 'xstate';
import { ERRORS } from '../constants';
import { ENV } from '../env/environment';
import { fetchMarket, fetchMarkets, fromMarketKey, marketKey, matured, paused } from '../helpers';
import { errors } from '../services/errors';
import { preferences } from '../services/preferences';
import { swivelApi } from '../services/swivel';
import { Market } from '../types';
import { EmptyContext } from './types';

export interface MarketContext {
    markets?: Map<string, Market>;
    selected?: string;
    swivel?: Swivel;
    marketPlace?: MarketPlace;
    error?: string;
}

export interface MarketSchema {
    states: {
        initial: {};
        fetching: {};
        completing: {};
        success: {};
        error: {};
    };
}

export type MarketState =
    {
        value: 'initial';
        context: EmptyContext<MarketContext>;
    }
    | {
        value: 'fetching';
        context: MarketContext & {
            swivel: Swivel;
            marketPlace: MarketPlace;
        };
    }
    | {
        value: 'completing' | 'success';
        context: MarketContext & {
            markets: Map<string, Market>;
            selected: string;
            swivel: Swivel;
            marketPlace: MarketPlace;
            error: undefined;
        };
    }
    | {
        value: 'error';
        context: MarketContext & {
            markets: undefined;
            selected: undefined;
            error: string;
        };
    };

export type MarketInitEvent = { type: 'MARKET.INIT'; payload: { swivel: Swivel; marketPlace: MarketPlace; }; };
export type MarketFetchEvent = { type: 'MARKET.FETCH'; };
export type MarketSelectEvent = { type: 'MARKET.SELECT'; payload: string; };

export type MarketEvent =
    MarketInitEvent
    | MarketFetchEvent
    | MarketSelectEvent;

const init: TransitionsConfig<MarketContext, MarketEvent> = {
    'MARKET.INIT': {
        actions: 'INIT',
        target: 'fetching',
    },
};

const fetch: TransitionsConfig<MarketContext, MarketEvent> = {
    'MARKET.FETCH': {
        target: 'fetching',
    },
};

const initialContext: MarketContext = {};

export const machineConfig: MachineConfig<MarketContext, MarketSchema, MarketEvent> = {
    context: initialContext,
    initial: 'initial',
    states: {
        initial: {
            on: {
                ...init,
            },
        },
        fetching: {
            invoke: {
                id: 'fetchMarkets',
                src: 'fetchMarkets',
                onDone: {
                    target: 'completing',
                    actions: ['FETCH_MARKET_SUCCESS', 'STORE_LAST_MARKET'],
                },
                onError: {
                    target: 'error',
                    actions: 'FETCH_MARKETS_FAILURE',
                },
            },
        },
        completing: {
            invoke: {
                id: 'completeMarkets',
                src: 'completeMarkets',
                onDone: {
                    target: 'success',
                    actions: 'FETCH_MARKETS_SUCCESS',
                },
                onError: {
                    target: 'error',
                    actions: 'FETCH_MARKETS_FAILURE',
                },
            },
        },
        success: {
            on: {
                ...init,
                ...fetch,
                'MARKET.SELECT': {
                    actions: ['SELECT_MARKET', 'STORE_LAST_MARKET'],
                },
            },
        },
        error: {
            on: {
                ...init,
                ...fetch,
            },
        },
    },
};

export const machineOptions: Partial<MachineOptions<MarketContext, MarketEvent>> = {
    services: {
        fetchMarkets: async (context): Promise<Market> => {

            try {

                const selectedMarket = context.selected ? fromMarketKey(context.selected) : undefined;

                const lastMarket = selectedMarket ?? preferences.get('lastMarket');

                // if we have a `lastMarket` we try to fetch it
                if (lastMarket) {

                    // make sure our last market isn't matured or paused by now
                    if (matured(lastMarket) || paused(lastMarket)) {

                        preferences.delete('lastMarket');

                    } else {

                        try {

                            // fetching the lastMarket may actually fail: e.g. the market has matured since the last
                            // visit and is no longer fetchable, the market was removed from the BE, or when switching
                            // environments during local development (the url stays and so does the localStorage, but
                            // markets are different in different environments)
                            return await fetchMarket(lastMarket);

                        } catch (error) {

                            // if it fails, we delete the `lastMarket` from the localStorage and continue
                            preferences.delete('lastMarket');
                        }
                    }
                }

                // fetch the first active market returned from the BE
                const activeMarket = (await swivelApi.fetchMarkets('active'))[0];

                if (!activeMarket) throw errors.process(ERRORS.STATE.MARKET.NO_MARKETS);

                return await fetchMarket(activeMarket);


            } catch (error) {

                throw errors.isProcessed(error) ? error : errors.process(error, ERRORS.STATE.MARKET.FETCH);
            }
        },
        completeMarkets: async (): Promise<Market[]> => {

            try {

                return await fetchMarkets(ENV.previewMarkets ? 'preview' : 'active');

            } catch (error) {

                throw errors.isProcessed(error) ? error : errors.process(error, ERRORS.STATE.MARKET.FETCH);
            }
        },
    },
    actions: {
        INIT: assign((context, event) => ({
            ...context,
            ...(event as MarketInitEvent).payload,
        })),
        FETCH_MARKET_SUCCESS: assign((context, event) => {

            const market = (event as DoneInvokeEvent<Market>).data;
            const selected = marketKey(market);

            const markets = new Map([[selected, market]]);

            return {
                ...context,
                markets,
                selected,
                error: undefined,
            };
        }),
        FETCH_MARKETS_SUCCESS: assign((context, event) => {

            // create a map with market keys from the response
            const markets = new Map((event as DoneInvokeEvent<Market[]>).data.map(market => ([marketKey(market), market])));
            const previous = context.selected;

            // if a previous market was selected and it's in the market data, keep it selected
            const selected = previous && markets.has(previous)
                ? previous
                : [...markets.keys()][0];

            return {
                ...context,
                markets,
                selected,
                error: undefined,
            };
        }),
        FETCH_MARKETS_FAILURE: assign((context, event) => ({
            ...context,
            markets: undefined,
            selected: undefined,
            error: (event as DoneInvokeEvent<Error>).data.message,
        })),
        SELECT_MARKET: assign((context, event) => {

            const { payload } = event as MarketSelectEvent;

            // don't allow selecting a market which doesn't exist in the context
            const selected = context.markets?.has(payload)
                ? payload
                : context.selected;

            return {
                ...context,
                selected,
            };
        }),
        STORE_LAST_MARKET: (context) => {

            if (context.selected) {

                preferences.set('lastMarket', fromMarketKey(context.selected));
            }
        },
    },
};

export const machine = createMachine<MarketContext, MarketEvent, MarketState>(machineConfig, machineOptions);
