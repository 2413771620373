import { BigNumber } from 'ethers';
import { DEFAULT_LOCALE } from '../../../constants';
import { fixed } from '../../../helpers';
import { logger as loggerService } from '../../../services/logger';
import { PartialFill, Order, OrderMeta, TokenType, UserFills } from '../../../types';

const logger = loggerService.group('order history helpers');

export type FillYield = 'principal' | 'premium';

export interface SyntheticFill extends PartialFill {
    exit: boolean;
    vault: boolean;
    principal: string;
    premium: string;
    received: FillYield;
    token: TokenType;
    time: string;
}

export interface SyntheticMarketOrder {
    type: 'market';
    received: FillYield;
    token: TokenType;
    fills: SyntheticFill[];
    price: string;
    // we'll use a fill's `transactionHash` as the synthetic market order's key
    key: string;
    vault: boolean;
    exit: boolean;
    principal: string;
    premium: string;
    time: string;
}

export interface SyntheticLimitOrder {
    type: 'limit';
    received: FillYield;
    token: TokenType;
    fills: SyntheticFill[];
    price: string;
    premiumAvailable?: string;
    principalAvailable?: string;
    premiumFilled: string;
    principalFilled: string;
    key: string;
    maker: string;
    vault: boolean;
    exit: boolean;
    principal: string;
    premium: string;
    time: string;
}

/**
 * Interface for a synthetic order.
 *
 * @remarks
 * Market orders don't exist as orders on the backend. They exist solely as fills. To enable users
 * to better understand their fill history, we create synthetic market orders to represent the action
 * a user has performed.
 */
export type SyntheticOrder = SyntheticMarketOrder | SyntheticLimitOrder;

const fillTimeFormatter = new Intl.DateTimeFormat(DEFAULT_LOCALE, {
    dateStyle: 'short',
    timeStyle: 'short',
    hour12: false,
});

/**
 * Create the trade history data from the {@link UserFills}.
 *
 * @param a - the user's account
 * @param f - the user's fill data
 * @returns an array of synthetic limit/market orders ordered by time, containing the associated fills
 */
export const makeTradeHistory = (a: string, f: UserFills): SyntheticOrder[] => {

    const orders = [] as SyntheticOrder[];

    // flatten and sort all fills from newest to oldest
    const fills = Object.keys(f)
        .reduce(
            (acc, key) => {
                return acc
                    .concat(f[key].initiates.map(fill => makeFill(a, f[key].order, fill, false)))
                    .concat(f[key].exits.map(fill => makeFill(a, f[key].order, fill, true)));
            },
            [] as SyntheticFill[],
        )
        .sort((a, b) => b.created - a.created);

    // process fills and group them in synthetic orders
    fills.forEach(fill => makeOrder(a, f[fill.key].order, f[fill.key].meta, fill, orders));

    // process sell-zc-token limit orders to correct the POV
    (orders.filter(order => order.exit && !order.vault && order.type === 'limit') as SyntheticLimitOrder[]).forEach((order) => {

        order.price = (1 - Number(order.price)).toString();
        order.premium = BigNumber.from(order.principal).sub(BigNumber.from(order.premium)).toString();
        order.premiumFilled = BigNumber.from(order.principalFilled).sub(BigNumber.from(order.premiumFilled)).toString();

        order.fills.forEach(fill => {
            fill.price = (1 - Number(fill.price)).toString();
            fill.premium = BigNumber.from(fill.principal).sub(BigNumber.from(fill.premium)).toString();
        });
    });

    // TODO: remove when stable
    logger.log(orders);

    return orders;
};

const makeOrder = (a: string, o: Order, m: OrderMeta, f: SyntheticFill, orders: SyntheticOrder[]) => {

    // if the user is both the sender and maker, the fill will be duplicated and displayed
    // in both a SyntheticMarketOrder and a SyntheticLimitOrder

    if (f.sender === a) {

        makeMarketOrder(a, o, f, orders);
    }

    if (o.maker === a) {

        makeLimitOrder(a, o, m, f, orders);
    }
};

const makeMarketOrder = (a: string, o: Order, f: SyntheticFill, orders: SyntheticOrder[]) => {

    // market orders don't exist as orders on the backend, we create a synthetic market order
    // to represent a user's order when placing a market order and group the corresponding fills
    const order: SyntheticMarketOrder = orders.find(order => order.type === 'market' && order.key === f.transactionHash) as SyntheticMarketOrder | undefined
        ?? orders[orders.push({
            type: 'market',
            // we use the fill's `transactionHash` as order key: all fills belonging
            // to the same 'market' order will have the same `transactionHash`
            key: f.transactionHash,
            // each fill belonging to the same transaction will have the same
            // `received`, `token`, `exit` and `vault` configuration
            received: f.received,
            token: f.token,
            exit: f.exit,
            vault: f.vault,
            // the newest fill will dictate the 'market' order's timestamp
            time: f.time,
            // we'll store all individual fills here
            fills: [],
            // we have to derive the principal, premium and price of the 'market' order
            // from the individuals fills
            principal: '0',
            premium: '0',
            price: '0',
        }) - 1] as SyntheticMarketOrder;

    order.fills.push(f);
    // each fill increases the synthetic order's principal and premium
    order.principal = BigNumber.from(order.principal).add(f.principal).toString();
    order.premium = BigNumber.from(order.premium).add(f.premium).toString();
    // we need to calculate the synthetic order's effective price (similar to what fill preview does)
    order.price = order.fills.reduce(
        (acc, fill) => acc.addUnsafe(fixed(fill.premium).divUnsafe(fixed(order.premium)).mulUnsafe(fixed(fill.price))),
        fixed(0),
    ).toString();
};

const makeLimitOrder = (a: string, o: Order, m: OrderMeta, f: SyntheticFill, orders: SyntheticOrder[]) => {

    // limit orders do exist on the backend, we create a synthetic limit order to
    // add additional information and to group the corresponding fills
    const order: SyntheticLimitOrder = orders.find(order => order.type === 'limit' && order.key === f.key) as SyntheticLimitOrder | undefined
        ?? orders[orders.push({
            ...o,
            ...m,
            type: 'limit',
            // we simply initialize the required fields, we'll set the correct values below
            received: f.received,
            token: f.token,
            time: f.time,
            fills: [],
            principalFilled: '0',
            premiumFilled: '0',
        }) - 1] as SyntheticLimitOrder;

    const [received, token]: [FillYield, TokenType] = order.exit
        ? order.vault
            ? ['premium', 'nToken']
            : ['premium', 'zcToken']
        : order.vault
            ? ['principal', 'nToken']
            : ['premium', 'underlying'];

    // we create a 'limit' version of the market fill (it could be part of a synthetic market order)
    // which is modified to reflect the point of view of its limit order
    const fill = makeLimitFill(order, f);

    order.fills.push(fill);
    order.received = received;
    order.token = token;
    order.principalFilled = BigNumber.from(order.principalFilled).add(fill.principal).toString();
    order.premiumFilled = BigNumber.from(order.premiumFilled).add(fill.premium).toString();
};

const makeFill = (a: string, o: Order, f: PartialFill, e: boolean): SyntheticFill => {

    const exit = e;
    // if an order and its fill have different exit setting, then vault is the same
    // otherwise the vault setting is mirrored
    const vault = o.exit !== exit ? o.vault : !o.vault;

    // fills have amount/filled instead of principal/premium and the mapping depends on
    // the fill's exit/vault configuration, as does the `received` and `token` type
    const [principal, premium, price, received, token]: [string, string, string, FillYield, TokenType] = exit
        ? vault
            // sell-n-tokens fill
            ? [f.amount, f.filled, f.price, 'premium', 'nToken']
            // sell-zc-tokens fill
            : [f.filled, BigNumber.from(f.filled).sub(BigNumber.from(f.amount)).toString(), (1 - Number(f.price)).toString(), 'premium', 'zcToken']
        : vault
            // amplified-yield fill
            ? [f.filled, f.amount, f.price, 'principal', 'nToken']
            // fixed-yield fill
            : [f.amount, f.filled, f.price, 'premium', 'underlying'];

    return {
        ...f,
        exit,
        vault,
        principal,
        premium,
        price,
        received,
        token,
        time: fillTimeFormatter.format(new Date(f.created * 1000)),
    };
};

const makeLimitFill = (o: SyntheticLimitOrder, f: SyntheticFill): SyntheticFill => {

    const [principal, premium, price, received, token]: [string, string, string, FillYield, TokenType] = f.exit
        ? f.vault
            // sell-n-tokens fill (can fill a sell-zc-token or amplified-yield limit order)
            ? [f.amount, f.filled, f.price, o.exit ? 'premium' : 'principal', o.exit ? 'zcToken' : 'nToken']
            // sell-zc-tokens fill (can fill a sell-n-token or fixed-yield limit order)
            : [f.filled, f.amount, (1 - Number(f.price)).toString(), 'premium', o.exit ? 'nToken' : 'underlying']
        : f.vault
            // amplified-yield fill (can fill a sell-n-token or fixed-yield limit order)
            ? [f.filled, f.amount, f.price, 'premium', o.exit ? 'nToken' : 'underlying']
            // fixed-yield fill (can fill a sell-zc-token or amplified-yield limit order)
            : [f.amount, f.filled, f.price, o.exit ? 'premium' : 'principal', o.exit ? 'zcToken' : 'nToken'];

    return {
        ...f,
        principal,
        premium,
        price,
        received,
        token,
    };
};
