// it's crucial to import config first - it configures our service locator
import './config';

import { checkConsent, consent, theme } from './services';
import './components/app/app';

// start the theme service
theme.start();

// start the consent service
consent.start();

// check for consent on load
window.addEventListener('load', checkConsent);
