import { BigNumber } from 'ethers';
import { Balances, Market, Order, OrderType } from '../../types';
import { emptyOrZero, fixed, round } from '../amount';
import { orderPrice } from './calculation';
import { amountBalance, amountToken } from './resolution';

/**
 * Checks if a user's token balance is sufficient for an order's `amount` input value.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param b - the user's token balances (linked to the market's tokens)
 * @param a - the order amount
 * @returns `false` if the balance is insufficient, `true` otherwise
 */
export const checkBalance = (o: Order, m: Market, b: Balances, a: string): boolean => {

    if (emptyOrZero(a)) return true;

    // get the balance represented by the amount
    const balance = amountBalance(o, b);

    const amount = BigNumber.from(a);

    return !amount.gt(balance.balance);
};

/**
 * Checks if a limit order's principal is above the `minPrincipal` defined by the BE's config.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param t - the order type ('limit' or 'market')
 * @returns `false` if the order principal is below minimum, `true` otherwise
 */
export const checkMinimum = (o: Order, m: Market, t: OrderType): boolean => {

    // only check minimum for limit orders
    if (t === 'market' || emptyOrZero(o.principal)) return true;

    // get the principal token (fixed and amplified yield orders use the underlying, for sell orders it's the nToken or zcToken)
    const token = amountToken(o, m);

    // get the minimum principal for the token (this will for now be the underlying's `minPremium`)
    const minimum = token.minPrincipal as string;

    // return if we don't have a minimum
    if (emptyOrZero(minimum)) return true;

    const principal = BigNumber.from(o.principal);

    return !principal.lt(minimum);
};

/**
 * Checks if a limit order is outsized.
 *
 * @remarks
 * A limit order is considered outsized if its price is significantly lower or greater than the market's
 * last trade price. The limits are:
 * ```
 * lastTradePrice * 0.5 < orderPrice < lastTradePrice * 2
 * ```
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param t - the order type ('limit' or 'market')
 * @returns an object containing the `lastTradePrice` and `currentTradePrice` if outsized, `undefined` otherwise
 */
export const checkOutsized = (o: Order, m: Market, t: OrderType): { lastTradePrice: string; currentTradePrice: string; status: 'high' | 'low' } | undefined => {

    if (t === 'market') return;

    // we use a constant precision for price comparisons - FixedNumber has no built-in comparison methods,
    // so we need to cast the FixedNumbers to JavaScript floats (which don't support 18 decimals)
    const precision = 8;
    const lastTradePrice = m.lastTradePrice;
    const currentTradePrice = orderPrice(o.principal, o.premium);

    if (lastTradePrice) {

        // we use `orderPrice` to determine the price - we don't want the inferred sell zcToken price (1 - price)
        const price = parseFloat(round(currentTradePrice, precision));
        const low = parseFloat(fixed(lastTradePrice).mulUnsafe(fixed(0.5)).round(precision).toString());
        const high = parseFloat(fixed(lastTradePrice).mulUnsafe(fixed(2)).round(precision).toString());

        const status = (price < low)
            ? 'low'
            : (price > high)
                ? 'high'
                : undefined;

        if (status === 'high' || status === 'low') return {
            status,
            lastTradePrice,
            currentTradePrice,
        };
    }
};
