import { ERRORS } from '../constants';
import { errors } from './errors';
import { logger as logService } from './logger';

const logger = logService.group('http');

export const DEFAULT_REQUEST_HEADERS = {
    ACCEPT: {
        'accept': 'application/json',
    },
    CONTENT_TYPE: {
        'content-type': 'application/json',
    },
};

export const DEFAULT_REQUEST_INIT: Record<'GET' | 'POST', RequestInit> = {
    GET: {
        method: 'GET',
        headers: DEFAULT_REQUEST_HEADERS.ACCEPT,
    },
    POST: {
        method: 'POST',
        headers: {
            ...DEFAULT_REQUEST_HEADERS.ACCEPT,
            ...DEFAULT_REQUEST_HEADERS.CONTENT_TYPE,
        },
    },
};

export type GetPayload = string | string[][] | Record<string, string | number | boolean> | URLSearchParams;

export type PostPayload = unknown;

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export type RequestPayload = GetPayload | PostPayload;

export type RequestMethod = 'GET' | 'POST';

/**
 * Creates a new `Request` instance for usage with `fetch()`.
 *
 * @remarks
 * `request()` provides a simpler interface for the `fetch()` API by creating `Request` instances
 * with predefined default headers, which can be passed directly to `fetch()`. It also allows us
 * to type request payloads depending on the request method and to encode those payloads either
 * as query parameters or stringified JSON bodies. Additional `RequestInit` properties can be
 * provided as well, e.g. an `AbortSignal` or cache settings.
 *
 * @param u - the request url
 * @param m - the request method
 * @param p - an optional request payload
 * @param i - an optional `RequestInit` object
 */
export function request (u: string, m: 'GET', p?: GetPayload, i?: RequestInit): Request;
export function request (u: string, m: 'POST', p?: PostPayload, i?: RequestInit): Request;
export function request (u: string, m: RequestMethod, p?: RequestPayload, i?: RequestInit): Request {

    const url = new URL(u);
    const init = {
        ...DEFAULT_REQUEST_INIT[m],
        ...i,
    };

    if (m === 'GET') {

        if (p) {

            // TypeScript only allows Record<string, string>, however Record<string, string | number | boolean>
            // is implicitly converted and can be used just fine
            url.search = new URLSearchParams(p as string | string[][] | Record<string, string> | URLSearchParams).toString();
        }

    } else {

        if (p !== undefined) {

            init.body = JSON.stringify(p);
        }
    }

    return new Request(url.toString(), init);
}

/**
 * Parses a `Response` instance, handles errors and returns the response data.
 *
 * @remarks
 * `response()` simplifies the handling of `Response` instances received from `fetch()`.
 * It checks the response status and throws appropriate errors if necessary. It also
 * checks response headers to determine how to parse the response data and skips empty
 * response bodies.
 *
 * @param res - the `Response` instance
 * @param req - the `Request` instance creating the response (optional)
 */
export async function response<T> (res: Response, req?: Request): Promise<T> {

    if (!res.ok) {

        if (res.status >= 400) {

            throw errors.process(res);
        }
    }

    try {

        const contentLength = res.headers.get('content-length');
        const contentType = res.headers.get('content-type') || req?.headers.get('accept');

        // check `204 No Content` status and content length to prevent parsing errors of empty responses
        if (res.status === 204 || contentLength === '0') {

            return undefined as unknown as T;
        }

        if (contentType) {

            if (/application\/json/.test(contentType)) {

                return await res.json() as T;
            }

            if (/text\//.test(contentType)) {

                return await res.text() as unknown as T;
            }
        }

        logger.warn(`response parsing skipped: content-type '${ contentType || 'null' }' not supported`);

        return undefined as unknown as T;

    } catch (error) {

        throw errors.process(ERRORS.HTTP.BAD_RESPONSE);
    }
}

/**
 * Performs a 'GET' request using `fetch()` and returns the parsed response data.
 *
 * @param u - the request url
 * @param p - an optional request payload
 * @param i - an optional `RequestInit` object
 */
export async function get<T> (u: string, p?: GetPayload, i?: RequestInit): Promise<T> {

    let res: Response;

    const req = request(u, 'GET', p, i);

    try {

        res = await fetch(req);

    } catch (error) {

        // fetch will only reject on network errors (e.g. no connection, DNS errors)
        throw errors.process(ERRORS.HTTP.NETWORK);
    }

    return await response(res, req);
}

/**
 * Performs a 'POST' request using `fetch()` and returns the parsed response data.
 *
 * @param u - the request url
 * @param p - an optional request payload
 * @param i - an optional `RequestInit` object
 */
export async function post<T> (u: string, p?: PostPayload, i?: RequestInit): Promise<T> {

    let res: Response;

    const req = request(u, 'POST', p, i);

    try {

        res = await fetch(req);

    } catch (error) {

        // fetch will only reject on network errors (e.g. no connection, DNS errors)
        throw errors.process(ERRORS.HTTP.NETWORK);
    }

    return await response(res, req);
}

/**
 * An `http`-namespaced export of this module's methods.
 */
export const http = {
    get,
    post,
    request,
    response,
};

export default http;
