import { WalletService, getEthereumProvider, getEthereumProviderIdentifier, getEthereumProviders, isBrowserConnection } from '@swivel-finance/connect';
import { BrowserConnectionProvider } from '@swivel-finance/connect/connection/providers/browser/provider';
import { ENV } from '../env/environment';
import { errors } from './errors';
import { interactivity } from './interactivity';
import { preferences } from './preferences';

/**
 * The `WalletService` instance.
 *
 * @remarks
 * We use the `BrowserConnectionProvider` to connect to a browser wallet.
 */
const wallet = new WalletService({
    connectionProvider: new BrowserConnectionProvider({
        chainId: ENV.chainId,
    }),
});

/**
 * Handle wallet connection events.
 *
 * @remarks
 * When a wallet connects, we store the `walletIdentifier` in the preferences.
 * This allows us to auto-connect to the same wallet on page reload.
 * We also set the `autoConnect` preference to `true` to indicate that the user
 * has previously connected to a wallet and we should auto-connect on page load.
 */
wallet.listen('connect', (connection) => {

    if (isBrowserConnection(connection)) {

        const walletIdentifier = getEthereumProviderIdentifier(connection.wallet);

        if (walletIdentifier) {

            preferences.set('walletIdentifier', walletIdentifier);
        }
    }

    preferences.set('autoConnect', true);
});

/**
 * Handle wallet disconnection events.
 *
 * @remarks
 * When a wallet disconnects, we refresh the page to clear any state.
 * We don't update the `autoConnect` preference, as we can't be sure if the user
 * has disconnected on purpose or if the wallet has disconnected due to an error.
 */
wallet.listen('disconnect', (error) => {

    if (error) errors.process(error);

    refresh();
});

/**
 * Handle wallet account change events.
 *
 * @remarks
 * When a wallet account changes, we refresh the page to clear any state. The
 * `autoConnect` preference will take care of auto-connecting to the same wallet
 * on page load (with the newly connected account).
 *
 * If the page that captures the event is not visible, it indicates that the
 * account change happened in a different tab. In this case, we disconnect the
 * wallet and refresh the page when it becomes active again.
 */
wallet.listen('accountsChanged', () => {

    if (!interactivity.visible) {

        void wallet.disconnect();

        return;
    }

    refresh();
});

/**
 * Handle wallet chain change events.
 *
 * @remarks
 * When a wallet chain changes, we refresh the page to clear any state. The
 * `autoConnect` preference will take care of auto-connecting to the same wallet
 * on page load (with the newly connected chain).
 *
 * If the page that captures the event is not visible, it indicates that the
 * chain change happened in a different tab. In this case, we disconnect the
 * wallet and refresh the page when it becomes active again.
 */
wallet.listen('chainChanged', () => {

    if (!interactivity.visible) {

        void wallet.disconnect();

        return;
    }

    refresh();

    // we could also check which chain the user has changed to and redirect
    // to a matching deployment...
});

/**
 * Handle page load events.
 *
 * @remarks
 * When the page loads, we check if the `autoConnect` preference is set to `true`.
 * If so, we try to auto-connect to the last connected wallet.
 *
 */
// eslint-disable-next-line @typescript-eslint/no-misused-promises
window.addEventListener('load', async () => {

    const autoConnect = preferences.get('autoConnect');

    if (autoConnect) {

        // the `walletIdentifier` is not gonna exactly match any wallet in the detected
        // providers as its `uuid` is re-generated on each page load
        // we use it fuzzy-match a provider and recreate the identifier to match exactly
        let walletIdentifier = preferences.get('walletIdentifier') ?? undefined;

        // get the available browser wallet providers
        const providers = await getEthereumProviders();

        // get the last connected browser wallet provider by fuzzy-matching the stored identifier
        // this also makes sure we don't accidentally connect to a malicious wallet
        const provider = getEthereumProvider(providers, walletIdentifier);

        // recreate the identifier to match the provider exactly
        walletIdentifier = getEthereumProviderIdentifier(provider);

        // if we were able to find a matching provider, connect the wallet service
        if (walletIdentifier) {

            await wallet.connect({ walletIdentifier });
        }
    }
});

/**
 * Refresh the page.
 *
 * @remarks
 * If the page is not visible, we subscribe to the `interactivity` service to
 * refresh the page when it becomes visible. This helps us avoid refreshing a
 * page that is not visible (e.g. when the user is on a different tab) and
 * prevents the inactive page from requesting wallet interactions at load.
 */
const refresh = () => {

    if (interactivity.visible) {

        window.location.reload();

    } else {

        interactivity.subscribe(state => {

            if (state.visible) window.location.reload();
        });
    }
};

export {
    WALLET_STATUS,
    Connection as WalletConnection,
    WalletService,
    WalletState,
    getEthereumProvider, getEthereumProviderIdentifier, getEthereumProviders, isBrowserConnection,
} from '@swivel-finance/connect';

export {
    EIP6963ProviderDetail,
    EIP6963ProviderInfo,
} from '@swivel-finance/connect/connection/providers/browser/ethereum-provider/eip-6963';

export { wallet };
