/* eslint-disable @typescript-eslint/unbound-method */
import { Subscriber } from '../types/subscriber';

export interface InteractivityState {
    focused: boolean;
    visible: boolean;
    connected: boolean;
}

export class InteractivityService {

    get focused (): boolean {

        return document.hasFocus();
    }

    get visible (): boolean {

        return document.visibilityState === 'visible';
    }

    get connected (): boolean {

        return window.navigator.onLine;
    }

    /**
     * Stores subscribers and prevents duplicate subscriptions
     */
    protected subscribers = new Set<Subscriber<InteractivityState>>();

    constructor () {

        this.handleFocusChange = this.handleFocusChange.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        this.handleConnectivityChange = this.handleConnectivityChange.bind(this);

        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        window.addEventListener('offline', this.handleConnectivityChange);
        window.addEventListener('online', this.handleConnectivityChange);
        window.addEventListener('focus', this.handleFocusChange);
        window.addEventListener('blur', this.handleFocusChange);
    }

    /**
     * Subscribe to the interactivity service
     *
     * @param s - subscriber callback
     */
    subscribe (s: Subscriber<InteractivityState>): void {

        this.subscribers.add(s);
    }

    /**
     * Unsubscribe from the interactivity service
     *
     * @param s - subscriber callback
     * @returns `true` if subscriber was unsubscribed, `false` otherwise
     */
    unsubscribe (s: Subscriber<InteractivityState>): boolean {

        return this.subscribers.delete(s);
    }

    protected notify () {

        this.subscribers.forEach(subscriber => subscriber({
            focused: this.focused,
            visible: this.visible,
            connected: this.connected,
        }));
    }

    protected handleVisibilityChange () {

        this.notify();
    }

    protected handleConnectivityChange () {

        this.notify();
    }

    protected handleFocusChange () {

        this.notify();
    }
}

export const interactivity = new InteractivityService();
