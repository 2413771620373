/* eslint-disable @typescript-eslint/ban-types */
import { assign, createMachine, DoneInvokeEvent, MachineConfig, MachineOptions, StateSchema, TransitionsConfig } from 'xstate';
import { ERRORS } from '../constants';
import { errors } from '../services/errors';
import { swivelApi } from '../services/swivel';
import { Balance, FillPreview, Market, Orderbook, TokenType, YieldType } from '../types';
import { EmptyContext } from './types';

export interface OrderbookInit {
    market: Market;
    token: Balance;
    tokenType: TokenType;
    yieldType: YieldType;
    fillPreview?: FillPreview;
}

export interface OrderbookContext extends Partial<OrderbookInit>, Partial<Orderbook> {
    error?: string;
}

export interface OrderbookSchema extends StateSchema<OrderbookContext> {
    states: {
        initial: {},
        fetching: {},
        success: {},
        error: {},
    };
}

export type OrderbookState =
    {
        value: 'initial';
        context: EmptyContext<OrderbookContext>;
    }
    | {
        value: 'fetching';
        context: OrderbookContext & OrderbookInit;
    }
    | {
        value: 'success';
        context: OrderbookContext & OrderbookInit & Orderbook & {
            error: undefined;
        };
    }
    | {
        value: 'error';
        context: OrderbookContext & {
            error: string;
        };
    };

export type OrderbookInitEvent = { type: 'ORDERBOOK.INIT'; payload: OrderbookInit; };
export type OrderbookYieldEvent = { type: 'ORDERBOOK.YIELD'; payload: YieldType; };
export type OrderbookPreviewEvent = { type: 'ORDERBOOK.PREVIEW'; payload: FillPreview | undefined; };
export type OrderbookFetchEvent = { type: 'ORDERBOOK.FETCH'; };

export type OrderbookEvent =
    OrderbookInitEvent
    | OrderbookYieldEvent
    | OrderbookPreviewEvent
    | OrderbookFetchEvent;

const init: TransitionsConfig<OrderbookContext, OrderbookEvent> = {
    'ORDERBOOK.INIT': [
        {
            actions: 'INIT',
            target: 'fetching',
            cond: 'shouldFetch',
        },
        {
            actions: 'SET_TOKEN',
        },
    ],
};

const setYield: TransitionsConfig<OrderbookContext, OrderbookEvent> = {
    'ORDERBOOK.YIELD': {
        actions: 'SET_YIELD',
    },
};

const setPreview: TransitionsConfig<OrderbookContext, OrderbookEvent> = {
    'ORDERBOOK.PREVIEW': {
        actions: 'SET_PREVIEW',
    },
};

const fetch: TransitionsConfig<OrderbookContext, OrderbookEvent> = {
    'ORDERBOOK.FETCH': {
        target: 'fetching',
    },
};

const initialContext: OrderbookContext = {};

const initialOrderbook: Partial<Orderbook> = {
    receivingPremium: [],
    payingPremium: [],
    nonce: undefined,
    timestamp: undefined,
};

export const machineConfig: MachineConfig<OrderbookContext, OrderbookSchema, OrderbookEvent> = {
    context: initialContext,
    initial: 'initial',
    states: {
        initial: {
            on: {
                ...init,
            },
        },
        fetching: {
            invoke: {
                id: 'fetchOrderbook',
                src: 'fetchOrderbook',
                onDone: {
                    target: 'success',
                    actions: 'FETCH_ORDERBOOK_SUCCESS',
                },
                onError: {
                    target: 'error',
                    actions: 'FETCH_ORDERBOOK_FAILURE',
                },
            },
            on: {
                ...setYield,
                ...setPreview,
            },
        },
        success: {
            on: {
                ...init,
                ...setYield,
                ...setPreview,
                ...fetch,
            },
        },
        error: {
            on: {
                ...init,
                ...fetch,
            },
        },
    },
};

export const machineOptions: Partial<MachineOptions<OrderbookContext, OrderbookEvent>> = {
    services: {
        fetchOrderbook: async (context): Promise<Orderbook> => {

            try {

                const market = context.market as Market;

                // fetch orderbook from swivel API
                return await swivelApi.fetchOrderbook(market.protocol, market.underlying, market.maturity);

            } catch (error) {

                throw errors.process(error, ERRORS.STATE.ORDERBOOK.FETCH);
            }
        },
    },
    guards: {
        shouldFetch: (context, event) => {
            return context.market !== (event as OrderbookInitEvent).payload.market || !!context.error;
        },
    },
    actions: {
        INIT: assign((context, event) => ({
            ...context,
            // reset orderbook data on INIT events
            ...initialOrderbook,
            // reset fillPreview on INIT events
            fillPreview: undefined,
            // reset errors on INIT events
            error: undefined,
            ...(event as OrderbookInitEvent).payload,
        })),
        SET_TOKEN: assign((context, event) => ({
            ...context,
            token: (event as OrderbookInitEvent).payload.token,
            tokenType: (event as OrderbookInitEvent).payload.tokenType,
            fillPreview: undefined,
        })),
        SET_YIELD: assign((context, event) => ({
            ...context,
            yieldType: (event as OrderbookYieldEvent).payload,
            fillPreview: undefined,
        })),
        SET_PREVIEW: assign((context, event) => ({
            ...context,
            fillPreview: (event as OrderbookPreviewEvent).payload,
        })),
        FETCH_ORDERBOOK_SUCCESS: assign((context, event) => ({
            ...context,
            ...(event as DoneInvokeEvent<Orderbook>).data,
            error: undefined,
        })),
        FETCH_ORDERBOOK_FAILURE: assign((context, event) => ({
            ...context,
            // reset orderbook data on FAILURE events
            ...initialOrderbook,
            // reset fillPreview on FAILURE events
            fillPreview: undefined,
            error: (event as DoneInvokeEvent<Error>).data.message,
        })),
    },
};

export const machine = createMachine<OrderbookContext, OrderbookEvent, OrderbookState>(machineConfig, machineOptions);
