/* eslint-disable @typescript-eslint/unbound-method */
import { Protocols } from '@swivel-finance/swivel-js';
import { ERRORS, VERSION } from '../constants';
import { ENV } from '../env/environment';
import { Subscriber } from '../types/subscriber';
import { errors } from './errors';
import { InteractivityState, interactivity } from './interactivity';
import { logger as logService } from './logger';
import { serviceLocator } from './service-locator';
import { TIME_SERVICE } from './time';

interface SNSMessage {
    EventSource: string;
    EventVersion: string;
    EventSubscriptionArn: string;
    Sns: {
        Type: string;
        MessageId: string;
        TopicArn: string;
        Subject: null;
        Message: string;
        Timestamp: string;
        SignatureVersion: string;
        Signature: string;
        SigningCertUrl: string;
        UnsubscribeUrl: string;
        MessageAttributes: unknown;
    };
}

export const PONG = 'PONG';

export type PongEvent = typeof PONG;

export type SwivelEvent =
    'CONFIG.CHANGE'
    |'ORDER.CREATE'
    | 'ORDER.CANCEL'
    | 'ORDER.INITIATE'
    | 'ORDER.EXIT'
    | 'ORDER.EXPIRE'
    | 'ORDER.FULL'
    | 'ORDER.INSOLVENT'
    | 'ORDER.INSOLVENT.TEMPORARY'
    | 'MARKET.CREATE'
    | 'MARKET.CHANGE'
    | 'MARKET.MATURE'
    | 'VAULT.REDEEM'
    | 'ZCTOKEN.REDEEM'
    | 'VAULT.NOTIONAL.TRANSFER'
    | 'ZCTOKEN.TRANSFER'
    | 'SAMPLE.OHLCV.CREATE'
    | 'REWARD.CREATE'
    // SWIV was deposited into SSM
    | 'DEPOSIT.CREATE'
    // SWIV was withdrawn from SSM
    | 'WITHDRAWAL.CREATE'
    // proof was created for merkle tree - rewards are now claimable
    | 'PROOF.CREATE';

export interface SwivelMessage {
    name: SwivelEvent | PongEvent;
    aggregateId?: string;
    origin: string;
    version: string;
    timestamp: number;
    data?: {
        maker?: string;
        market?: {
            protocol: Protocols;
            underlying: string;
            maturity: string;
        };
    };
}

const WILDCARD = '*';

const logger = logService.group('messages');

/**
 * The MessagesService class
 */
export class MessageService {

    protected connectionPromise?: Promise<void>;

    protected keepAlive?: number;

    protected socket?: WebSocket;

    protected interactivity = interactivity;

    protected timer = serviceLocator.get(TIME_SERVICE);

    /**
     * Stores subscribers and prevents duplicate subscriptions
     */
    protected subscribers = new Set<Subscriber<SwivelMessage>>();

    /**
     * Stores subscribed topics with their subscriber
     */
    protected topics = new WeakMap<Subscriber<SwivelMessage>, Set<string>>();

    constructor () {

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleInteractivityChange = this.handleInteractivityChange.bind(this);
    }

    /**
     * Subscribe to the messages service with optional topics
     *
     * @param s - subscriber callback
     * @param t - optional topic(s)
     */
    subscribe (s: Subscriber<SwivelMessage>, t?: (SwivelEvent | PongEvent) | (SwivelEvent | PongEvent)[]): void {

        const topics = ([] as string[]).concat(t ?? []);
        const subscribedTopics = this.topics.get(s) ?? new Set();

        if (!topics.length) {

            subscribedTopics.add(WILDCARD);

        } else {

            topics.forEach(topic => subscribedTopics.add(topic));
        }

        this.subscribers.add(s);
        this.topics.set(s, subscribedTopics);
    }

    /**
     * Unsubscribe from the messages service
     *
     * @param s - subscriber callback
     * @param t - optional topic(s)
     * @returns `true` if subscriber was unsubscribed, `false` otherwise
     */
    unsubscribe (s: Subscriber<SwivelMessage>, t?: (SwivelEvent | PongEvent) | (SwivelEvent | PongEvent)[]): boolean {

        const topics = ([] as string[]).concat(t ?? []);
        const subscribedTopics = this.topics.get(s);

        if (!topics.length) {

            return this.subscribers.delete(s) || this.topics.delete(s);
        }

        let unsubscribed = false;

        topics.forEach(topic => unsubscribed = subscribedTopics?.delete(topic) || unsubscribed);

        if (!subscribedTopics?.size) {

            return this.subscribers.delete(s) || this.topics.delete(s);
        }

        return unsubscribed;
    }

    connected (): boolean {

        return this.socket?.readyState === WebSocket.OPEN;
    }

    connect (): Promise<void> {

        const readyState = this.socket?.readyState;

        switch (readyState) {

            case WebSocket.CONNECTING:

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this.connectionPromise!;

            case WebSocket.OPEN:

                return Promise.resolve();

            default:

                this.disconnect();

                this.connectionPromise = new Promise(resolve => {

                    logger.log('connect new instance...');

                    // we send a version param to the websocket to receive the correct messages
                    this.socket = new WebSocket(`${ ENV.socketUrl }?version=${ VERSION }`);

                    // resolve the connectionPromise when websocket is open
                    this.socket.onopen = () => resolve();

                    this.socket.addEventListener('open', this.handleOpen);
                    this.socket.addEventListener('close', this.handleClose);
                    this.socket.addEventListener('message', this.handleMessage);
                    this.socket.addEventListener('error', this.handleError);

                    this.interactivity.subscribe(this.handleInteractivityChange);

                    // keep the websocket connection alive
                    this.keepAlive = window.setInterval(() => void this.ping(), ENV.socketInterval);
                });

                return this.connectionPromise;
        }
    }

    disconnect () {

        logger.log('disconnect...');

        if (this.socket?.readyState === WebSocket.CONNECTING || this.socket?.readyState === WebSocket.OPEN) {

            this.socket?.close();
        }

        this.socket?.removeEventListener('open', this.handleOpen);
        this.socket?.removeEventListener('close', this.handleClose);
        this.socket?.removeEventListener('message', this.handleMessage);
        this.socket?.removeEventListener('error', this.handleError);

        this.interactivity.unsubscribe(this.handleInteractivityChange);

        window.clearInterval(this.keepAlive);

        this.socket = undefined;
    }

    protected async ping () {

        const data = JSON.stringify({
            action: 'ping',
            message: 'ping',
        });

        // if we somehow disconnected, reconnect
        if (!this.connected()) {

            await this.connect();
        }

        this.socket?.send(data);
    }

    protected handleOpen (e: Event) {

        logger.log('open: ', e);
    }

    protected handleClose (e: CloseEvent) {

        logger.log('close: ', e);
    }

    protected handleMessage (e: MessageEvent) {

        const message = this.parseMessage(e);

        logger.log('message: ', message);

        if (!message) return;

        this.subscribers.forEach(subscriber => {

            if (this.matchTopic(subscriber, message.name)) subscriber(message);
        });
    }

    protected handleError (e: Event) {

        errors.process(e, ERRORS.SERVICES.WEBSOCKET.ERROR);
    }

    protected handleInteractivityChange (s: InteractivityState) {

        logger.log('interactivity change: %o, connected: %s', s, this.connected());

        const shouldConnect = s.connected && s.visible && !this.connected();

        if (shouldConnect) {

            void this.connect();
        }
    }

    /**
     * Parse a message received over the websocket connection
     *
     * @param e - websocket message event
     */
    protected parseMessage (e: MessageEvent): SwivelMessage | undefined {

        let message: SwivelMessage | undefined;

        if (e.data === 'pong') {

            // we create a special message for the keepAlive 'pong' message for debugging purposes
            message = { name: PONG, timestamp: parseInt(this.timer.timestamp()) } as SwivelMessage;

        } else {

            try {

                const data = JSON.parse(e.data as string) as SNSMessage[];

                message = JSON.parse(data[0].Sns.Message) as SwivelMessage;

            } catch (error) {

                errors.process(error, ERRORS.SERVICES.WEBSOCKET.PARSE_ERROR);
            }
        }

        return message;
    }

    /**
     * Matches a subscriber's topics with a message's name
     *
     * @remarks
     * Topic matching is very simple: '*' means any message, otherwise it's a simple `String.startsWith` check, e.g.:
     * - topic 'ORDER' matches message name 'ORDER.CREATE' | 'ORDER.INITIATE' |...
     * - topic 'ORDER.CREATE' matches message name 'ORDER.CREATE'
     *
     * @param s - subscriber callback
     * @param n - message name
     * @returns `true` if the subscriber's topics match the message's name, `false` otherwise
     */
    protected matchTopic (s: Subscriber<SwivelMessage>, n: string): boolean {

        if (!this.topics.has(s)) return false;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const topics = this.topics.get(s)!;

        // we hide the keepAlive 'PONG' message from wildcard subscriptions - only explicit subscriptions will get it
        return (n !== PONG && topics.has('*')) || [...topics.values()].some(topic => n.startsWith(topic));
    }
}

export const messages = new MessageService();
