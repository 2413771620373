import { BigNumber } from 'ethers';
import { PositionActionType } from '../../../state/positions';
import { Balance, Balances } from '../../../types';

/**
 * Returns the applicable balance for a split/combine/redeem action within a market
 *
 * @param a - action type
 * @param b - market balances
 */
export const actionBalance = (a: PositionActionType, b: Balances): Balance => {

    switch (a) {

        case 'split':

            return b.underlying;

        case 'combine':

            return BigNumber.from(b.nToken.balance).lt(b.zcToken.balance)
                ? b.nToken
                : b.zcToken;

        case 'redeem':

            return b.zcToken;

        case 'redeemInterest':

            return {
                ...b.underlying,
                balance: b.vault.redeemable,
            };
    }
};
