import { TourElement, TOUR_CANCEL_EVENT, TOUR_END_EVENT } from '../components/tour/tour';
import { ANALYTICS } from '../constants';
import { analytics } from './consent';
import { logger as logService } from './logger';
import { preferences } from './preferences';
import { router as routerService, RouterService } from './router';

let element: TourElement | undefined;
let router: RouterService;

const logger = logService.group('tour service');
const root = document.body;

const TOUR_STARTED_EVENT = 'TOUR_STARTED';
const TOUR_ENDED_EVENT = 'TOUR_ENDED';
const TOUR_CANCELLED_EVENT = 'TOUR_CANCELLED';

type TourEvent = typeof TOUR_STARTED_EVENT
    | typeof TOUR_ENDED_EVENT
    | typeof TOUR_CANCELLED_EVENT;

/**
 * Send custom events to analytics for tracking the tour engagement
 */
const notifyAnalytics = (event: TourEvent) => {

    const name = ANALYTICS[event].NAME;
    const data = ANALYTICS[event].EVENT({
        page: router.activeRoute?.route.id || 'home',
    });

    try {

        analytics.gtag('event', name, data);

    } catch (error) {

        logger.log(name, data);
        logger.warn((error as Error).message);
    }
};

/**
 * Record tour events and send them to a remote tracking service.
 */
const recordTourEvent = (event: TourEvent) => {

    notifyAnalytics(event);
};

const startTour = () => {

    if (element) return;

    element = new TourElement();

    element.addEventListener(TOUR_END_EVENT, endTour, { once: true });
    element.addEventListener(TOUR_CANCEL_EVENT, cancelTour, { once: true });

    root.append(element);

    recordTourEvent(TOUR_STARTED_EVENT);
};

const endTour = () => {

    if (!element) return;

    element.addEventListener(TOUR_END_EVENT, endTour, { once: true });
    element.addEventListener(TOUR_CANCEL_EVENT, cancelTour, { once: true });

    element.remove();

    element = undefined;

    recordTourEvent(TOUR_ENDED_EVENT);
};

const cancelTour = () => {

    if (!element) return;

    element.addEventListener(TOUR_END_EVENT, endTour, { once: true });
    element.addEventListener(TOUR_CANCEL_EVENT, cancelTour, { once: true });

    element.remove();

    element = undefined;

    recordTourEvent(TOUR_CANCELLED_EVENT);
};

/**
 * Process tour on load
 *
 * @remarks
 * We want to wait for the app component to initialize the router and subscribe
 * to route changes to cancel the tour. We will also check preferences and
 * subscribe to preferences changes which might lead to triggering a tour.
 */
window.addEventListener('load', () => {

    router = routerService();

    // when the active route changes, we cancel the tour
    router.subscribe(cancelTour);

    // when a first time visit to a page happens, we trigger the tour
    preferences.subscribe(
        value => value && startTour(),
        ['homeFirstVisit', 'professionalFirstVisit', 'positionsFirstVisit'],
    );
});

export const tour = {

    start: startTour,

    end: endTour,

    cancel: cancelTour,
};
