/**
 * The current version supported by this codebase.
 *
 * @remarks
 * The current version relates to a {@link Version.id} of the `versions` array
 * configured through the {@link Environment}. The version is hard-coded in the
 * codebase, as the codebase defines, which version of the Swivel Protocol is
 * supported, e.g. `2` for 'v2', `3` for 'v3'.
 * The `versions` array of the {@link Environment} only defines the available
 * versions to be displayed by the {@link VersionSelectorElement}.
 */
export const VERSION = 4;
