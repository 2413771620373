import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { balance, errorMessage, status, tokenSymbol } from '../../shared/templates';
import { services } from '../../state';
import { TokenType } from '../../types';

const template = function (this: BalanceElement) {

    const state = this.walletService.state;

    if (state.matches('initial') || state.matches('fetching')) {

        return status(state.matches('fetching') ? 'loading' : 'initial');

    } else if (state.matches('error')) {

        return errorMessage(state.context.error);

    } else if (state.matches('success')) {

        const selectedBalance = (this.type === 'eth')
            ? state.context.balance
            : state.context.balances[state.context.selected];

        return html`
        ${ tokenSymbol(selectedBalance) }${ balance(selectedBalance.balance, selectedBalance, this.decimals) }
        `;
    }
};

@customElement('sw-balance')
export class BalanceElement extends LitElement {

    protected walletService = services.wallet;

    @property({
        attribute: true,
        reflect: true,
    })
    type: TokenType | 'eth' = 'eth';

    @property({
        attribute: true,
        reflect: true,
        type: Number,
    })
    decimals = 2;

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.walletService.onTransition(this.handleTransition);
    }

    disconnectedCallback () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.walletService.off(this.handleTransition);

        super.disconnectedCallback();
    }

    protected createRenderRoot () {

        return this;
    }

    protected render () {

        return template.apply(this);
    }

    protected updated () {

        if (this.walletService.state.matches('initial')) {

            (this.renderRoot as HTMLElement).classList.add('empty');

        } else {

            (this.renderRoot as HTMLElement).classList.remove('empty');
        }
    }

    protected handleTransition () {

        this.requestUpdate();
    }
}
