/* eslint-disable @typescript-eslint/ban-types */
import { Provider } from '@ethersproject/abstract-provider';
import { Swivel } from '@swivel-finance/swivel-js';
import { assign, createMachine, DoneInvokeEvent, MachineOptions, TransitionsConfig } from 'xstate';
import { ERRORS, MAX_FILL_PERIOD } from '../constants';
import { confirmTransaction } from '../helpers';
import { errors } from '../services/errors';
import { swivelApi } from '../services/swivel';
import { notifications } from '../shared/components/notification';
import { Market, OrderStatus, OrderWithMeta, UserFills } from '../types';
import { EmptyContext } from './types';

interface FetchResult {
    orders: OrderWithMeta[];
    fills: UserFills;
}

export interface UserOrdersInit {
    account: string;
    swivel: Swivel;
    // TODO: we might not need the provider any longer...
    provider: Provider;
}

export type UserOrdersView = 'orders' | 'fills';

export type UserOrdersFilter = 'open' | 'closed' | 'all';

/**
 * A mapping of {@link UserOrdersFilter} to {@link OrderType}
 */
export const USER_ORDERS_FILTERS: Record<UserOrdersFilter, OrderStatus[]> = {
    'open': [
        'INSOLVENT_TEMPORARY',
        'VALID',
    ],
    'closed': [
        'CANCELLED',
        'EXPIRED',
        'FULL',
        'INSOLVENT',
    ],
    'all': [
        'CANCELLED',
        'EXPIRED',
        'FULL',
        'INSOLVENT',
        'INSOLVENT_TEMPORARY',
        'VALID',
    ],
};

export interface UserOrdersContext extends Partial<UserOrdersInit> {
    market?: Market;
    view?: UserOrdersView;
    orders?: OrderWithMeta[];
    cancellingOrders?: Set<OrderWithMeta>;
    ordersFilter?: UserOrdersFilter;
    ordersPage?: number;
    fills?: UserFills,
    fillsPage?: number;
    error?: string;
}

export type UserOrdersState =
    {
        value: 'initial';
        context: EmptyContext<UserOrdersContext>;
    }
    | {
        value: 'initialized';
        context: UserOrdersContext & UserOrdersInit;
    }
    | {
        value: 'fetching';
        context: UserOrdersContext & UserOrdersInit & {
            market: Market;
            view: UserOrdersView,
            ordersFilter: UserOrdersFilter,
            ordersPage: number;
            fillsPage: number;
        };
    }
    | {
        value: 'success' | 'cancelling';
        context: UserOrdersContext & UserOrdersInit & {
            market: Market;
            view: UserOrdersView,
            orders: OrderWithMeta[];
            cancellingOrders: Set<OrderWithMeta>;
            ordersFilter: UserOrdersFilter,
            ordersPage: number;
            fills: UserFills;
            fillsPage: number;
            error: undefined;
        };
    }
    | {
        value: 'error';
        context: UserOrdersContext & {
            error: string;
        };
    };

export type UserOrdersInitEvent = { type: 'USERORDERS.INIT'; payload: UserOrdersInit; };
export type UserOrdersMarketEvent = { type: 'USERORDERS.MARKET'; payload: Market; };
export type UserOrdersCancelEvent = { type: 'USERORDERS.CANCEL'; payload: OrderWithMeta; };
export type UserOrdersFetchEvent = { type: 'USERORDERS.FETCH'; };
export type UserOrdersViewEvent = { type: 'USERORDERS.VIEW'; payload: UserOrdersView; };
export type UserOrdersFilterEvent = { type: 'USERORDERS.FILTER'; payload: UserOrdersFilter; };
export type UserOrdersPaginateEvent = { type: 'USERORDERS.PAGINATE'; payload: number; };

export type UserOrdersEvent =
    UserOrdersInitEvent
    | UserOrdersMarketEvent
    | UserOrdersCancelEvent
    | UserOrdersFetchEvent
    | UserOrdersViewEvent
    | UserOrdersFilterEvent
    | UserOrdersPaginateEvent;

const init: TransitionsConfig<UserOrdersContext, UserOrdersEvent> = {
    'USERORDERS.INIT': {
        actions: 'INIT',
        target: 'initialized',
    },
};

const setMarket: TransitionsConfig<UserOrdersContext, UserOrdersEvent> = {
    'USERORDERS.MARKET': {
        actions: 'SET_MARKET',
        target: 'fetching',
    },
};

const cancelOrder: TransitionsConfig<UserOrdersContext, UserOrdersEvent> = {
    'USERORDERS.CANCEL': {
        actions: 'CANCEL_ORDER',
        target: 'cancelling',
        cond: 'isCancellable',
    },
};

const fetch: TransitionsConfig<UserOrdersContext, UserOrdersEvent> = {
    'USERORDERS.FETCH': {
        target: 'fetching',
    },
};

const setView: TransitionsConfig<UserOrdersContext, UserOrdersEvent> = {
    'USERORDERS.VIEW': {
        actions: 'SET_VIEW',
        target: 'success',
    },
};

const setFilter: TransitionsConfig<UserOrdersContext, UserOrdersEvent> = {
    'USERORDERS.FILTER': {
        actions: 'SET_FILTER',
        target: 'success',
    },
};

const paginate: TransitionsConfig<UserOrdersContext, UserOrdersEvent> = {
    'USERORDERS.PAGINATE': [
        {
            actions: 'PAGINATE_ORDERS',
            target: 'success',
            cond: 'ordersView',
        },
        {
            actions: 'PAGINATE_FILLS',
            target: 'fetching',
            cond: 'fillsView',
        },
    ],
};

const initialContext: UserOrdersContext = {
    view: 'orders',
    ordersFilter: 'open',
    ordersPage: 0,
    fillsPage: 0,
};

export const machineOptions: Partial<MachineOptions<UserOrdersContext, UserOrdersEvent>> = {
    actions: {
        INIT: assign((context, event) => ({
            ...context,
            ...(event as UserOrdersInitEvent).payload,
        })),
        SET_MARKET: assign((context, event) => ({
            ...context,
            market: (event as UserOrdersMarketEvent).payload,
            orders: [],
            cancellingOrders: new Set(),
            error: undefined,
        })),
        CANCEL_ORDER: assign((context, event) => {

            context.cancellingOrders?.add((event as UserOrdersCancelEvent).payload);

            return { ...context };
        }),
        SET_VIEW: assign((context, event) => ({
            ...context,
            view: (event as UserOrdersViewEvent).payload,
        })),
        SET_FILTER: assign((context, event) => ({
            ...context,
            ordersFilter: (event as UserOrdersFilterEvent).payload,
            // reset the page if the filter changes
            ordersPage: 0,
        })),
        PAGINATE_ORDERS: assign((context, event) => ({
            ...context,
            ordersPage: (event as UserOrdersPaginateEvent).payload,
        })),
        PAGINATE_FILLS: assign((context, event) => ({
            ...context,
            fillsPage: (event as UserOrdersPaginateEvent).payload,
        })),
        FETCH_SUCCESS: assign((context, event) => ({
            ...context,
            ...(event as DoneInvokeEvent<FetchResult>).data,
            error: undefined,
        })),
        FETCH_FAILURE: assign((context, event) => ({
            ...context,
            error: (event as DoneInvokeEvent<Error>).data.message,
        })),
        CANCEL_USERORDERS_SUCCESS: assign((context, event) => {

            const cancelled = (event as DoneInvokeEvent<OrderWithMeta>).data;

            context.cancellingOrders?.delete(cancelled);

            // confirmation of cancellation from messages service might take a second,
            // optimistically update local state and remove the cancelled transaction
            return {
                ...context,
                orders: context.orders?.filter(order => order.order.key !== cancelled.order.key),
            };
        }),
        CANCEL_USERORDERS_FAILURE: assign((context, event) => {

            context.cancellingOrders?.delete((event as DoneInvokeEvent<OrderWithMeta>).data);

            return { ...context };
        }),
    },
    guards: {
        // an order is cancellable if it is not already in the set of cancelling orders
        isCancellable: (context, event) => {

            const order = (event as UserOrdersCancelEvent).payload;

            return !context.cancellingOrders?.has(order);
        },
        // are we in the orders view?
        ordersView: (context) => context.view === 'orders',
        // are we in the fills view?
        fillsView: (context) => context.view === 'fills',
    },
    services: {
        fetch: async (context): Promise<FetchResult> => {

            let failed!: 'orders' | 'fills';

            try {

                const { account, market, fillsPage } = context;

                const now = Math.floor(Date.now() / 1000);
                const end = now - (fillsPage ?? 0) * MAX_FILL_PERIOD;
                const start = end - MAX_FILL_PERIOD;

                const result = await Promise.all([
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    swivelApi.fetchUserOrders(account!, market!.protocol, market!.underlying, market!.maturity)
                        // catch request failure to mark failure path for error processing
                        .catch(reason => { failed = 'orders'; return Promise.reject(reason); }),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    swivelApi.fetchUserFills(account!, market!.protocol, market!.underlying, market!.maturity, end, start)
                        // catch request failure to mark failure path for error processing
                        .catch(reason => { failed = 'fills'; return Promise.reject(reason); }),
                ]);

                const orders = result[0].orders;
                const fills = result[1];

                // fix the status field on older orders which are `FULL` but marked as `EXPIRED`
                // this is due to how expirator was treating orders past their expiration time
                // before introducing this feature
                orders.forEach(order => {

                    if (order.meta.principalAvailable === '0' && order.meta.status === 'EXPIRED') {
                        order.meta.status = 'FULL';
                    }
                });

                return { orders, fills };

            } catch (error) {

                throw errors.process(
                    error,
                    // with the failure path marked, we now know which request failed
                    (failed === 'orders') ? ERRORS.STATE.USERORDERS.ORDERS : ERRORS.STATE.USERORDERS.FILLS,
                );
            }
        },
        cancelOrder: async (context, event): Promise<OrderWithMeta> => {

            const { swivel } = context;
            const order = (event as UserOrdersCancelEvent).payload;

            const notification = notifications.show({
                content: 'Canceling order...',
                type: 'progress',
                dismissable: false,
            });

            try {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const tx = await swivel!.cancel([order.order]);

                await confirmTransaction(tx);

                notifications.update(notification, {
                    content: 'Order canceled successfully.',
                    type: 'success',
                    dismissable: true,
                });

            } catch (error) {

                const processedError = errors.isProcessed(error) ? error : errors.process(error);

                notifications.update(notification, {
                    content: `Canceling order failed: ${ processedError.message }`,
                    type: 'failure',
                    dismissable: true,
                });

                // we throw the actual order, so the state machine can clean up cancellation state for this order
                throw order;
            }

            return order;
        },
    },
};


export const machine = createMachine<UserOrdersContext, UserOrdersEvent, UserOrdersState>({
    context: initialContext,
    initial: 'initial',
    states: {
        initial: {
            on: {
                ...init,
            },
        },
        initialized: {
            on: {
                ...init,
                ...setMarket,
            },
        },
        fetching: {
            invoke: {
                id: 'fetch',
                src: 'fetch',
                onDone: {
                    target: 'success',
                    actions: 'FETCH_SUCCESS',
                },
                onError: {
                    target: 'error',
                    actions: 'FETCH_FAILURE',
                },
            },
        },
        cancelling: {
            invoke: {
                id: 'cancelOrder',
                src: 'cancelOrder',
                onDone: {
                    target: 'success',
                    actions: 'CANCEL_USERORDERS_SUCCESS',
                },
                onError: {
                    target: 'success',
                    actions: 'CANCEL_USERORDERS_FAILURE',
                },
            },
        },
        success: {
            on: {
                ...init,
                ...fetch,
                ...setMarket,
                ...cancelOrder,
                ...setView,
                ...setFilter,
                ...paginate,
            },
        },
        error: {
            on: {
                ...init,
                ...fetch,
                ...setMarket,
            },
        },
    },
}, machineOptions);
