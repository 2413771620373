/* eslint-disable @typescript-eslint/no-explicit-any */
export type Constructor<T = unknown> = new (...args: any[]) => T;

export const isConstructor = (constructorFn: unknown): constructorFn is Constructor => {

    return constructorFn instanceof Function
        && !!constructorFn.prototype
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        && constructorFn.prototype.constructor === constructorFn;
};

export interface ServiceFactory<T = unknown> {
    (): T;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class ServiceIdentifier<T = unknown> {

    protected static idCache = new Map<string, ServiceIdentifier>();

    static get<T = unknown> (s: string | Constructor<T>): ServiceIdentifier<T> {

        const id = isConstructor(s) ? s.name : s;

        if (!this.idCache.has(id)) {

            this.idCache.set(id, new ServiceIdentifier(id));
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.idCache.get(id)!;
    }

    protected constructor (public readonly id: string) { }
}

export class ServiceLocator {

    protected registry = new Map<ServiceIdentifier, ServiceFactory>();

    register<T> (s: Constructor<T> | ServiceIdentifier<T>, f: ServiceFactory<T>) {

        const id = isConstructor(s) ? ServiceIdentifier.get(s) : s;

        this.registry.set(id, f);
    }

    has<T> (s: Constructor<T> | ServiceIdentifier<T>): boolean {

        const id = isConstructor(s) ? ServiceIdentifier.get(s) : s;

        return this.registry.has(id);
    }

    get<T> (s: Constructor<T> | ServiceIdentifier<T>): T {

        const identifier = isConstructor(s) ? ServiceIdentifier.get(s) : s;

        const factory = this.registry.get(identifier) as ServiceFactory<T> | undefined;

        if (!factory) throw new Error(`ServiceLocator doesn't have a factory for the requested service '${ identifier.id }'`);

        return factory();
    }
}

export const serviceLocator = new ServiceLocator();
