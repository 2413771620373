import { LIST_CONFIG_MENU, selectionAttribute } from '@swivel-finance/ui/behaviors/list';
import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { ListBoxElement } from '@swivel-finance/ui/elements/listbox';
import { ListItemElement } from '@swivel-finance/ui/elements/listitem';
import { POPUP_CONFIG_MENU, PopupConfig, PopupElement } from '@swivel-finance/ui/elements/popup';
import { setAttribute } from '@swivel-finance/ui/utils/dom';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { ARBITRUM, ARBITRUM_RINKEBY, PUBLIC_NETWORKS, currentNetwork, fallbackNetwork } from '../../constants/networks';
import { emptyOrZero } from '../../helpers';
import { preferences } from '../../services/preferences';
import { EIP6963ProviderDetail, WALLET_STATUS, WalletState, getEthereumProvider, getEthereumProviderIdentifier, getEthereumProviders, wallet } from '../../services/wallet';
import { notifications } from '../../shared/components';
import { loader } from '../../shared/templates';
import { services } from '../../state';
import { Network } from '../../types';
import { copyAddress, displayAddress, handleAccountError } from './helpers';

const NETWORK_SELECTOR_CONFIG: PopupConfig = {
    ...POPUP_CONFIG_MENU,
    position: {
        ...POPUP_CONFIG_MENU.position,
        alignment: {
            origin: {
                horizontal: 'end',
                vertical: 'end',
            },
            target: {
                horizontal: 'end',
                vertical: 'start',
            },
            offset: {
                vertical: 'var(--line-height)',
            },
        },
    },
};

const isEIP6963ProviderDetail = (provider: unknown): provider is EIP6963ProviderDetail => {

    return typeof (provider as EIP6963ProviderDetail).provider === 'object'
        && typeof (provider as EIP6963ProviderDetail).info === 'object';
};

const arbitrumNotification = (network: Network) => {

    const link = network.links?.[0].url ?? '';

    return html`
    <p>
        <a href="${ link }" target="_blank" rel="noreferrer">Arbitrum Token Bridge</a>
        <ui-icon name="link"></ui-icon>
    </p>
    <p>Deposit tokens to the Arbitrum newtork.</p>
    `;
};

const template = function (this: NetworkSelectorElement) {

    const state = wallet.state;

    const current = currentNetwork(state.connection?.network.chainId);
    const fallback = fallbackNetwork(state.connection?.network.chainId);
    const networks = this.networks.filter(network => network !== current);

    const status = state.status === WALLET_STATUS.CONNECTED
        ? 'connected'
        : state.status === WALLET_STATUS.CONNECTING || state.status === WALLET_STATUS.DISCONNECTING
            ? 'loading'
            : 'disconnected';

    const buttonLabel = state.status === WALLET_STATUS.CONNECTED
        ? state.connection.account.ensAddress || displayAddress(state.connection.account.address)
        : 'Connect Wallet';

    const buttonIcon = state.status === WALLET_STATUS.CONNECTED
        ? current?.icon ?? fallback.icon
        : current?.icon ?? 'wallet';

    const listboxLabel = state.status === WALLET_STATUS.CONNECTED
        ? 'select a different network or disconnect your wallet'
        : 'connect your wallet or select a different network';

    const networkIcon = state.status === WALLET_STATUS.CONNECTED
        ? current?.icon ?? fallback.icon
        : current?.icon ?? 'rejected';

    const networkName = state.status === WALLET_STATUS.CONNECTED
        ? current?.name ?? fallback.name
        : current?.name ?? 'Not Conncted';

    return html`
    <ui-popup .config=${ NETWORK_SELECTOR_CONFIG } ${ ref(this.popupRef) }>
        <button class="sw-network-selector-trigger ${ status === 'disconnected' ? 'primary' : '' } ${ status }"
            type="button"
            data-part="trigger"
            aria-label="wallet options"
            .disabled=${ status === 'loading' }>
            ${ status === 'loading'
                ? loader()
                : html`
                <span class="logo"><ui-icon name="${ buttonIcon }"></ui-icon></span>
                <span class="address">${ buttonLabel }</span>
                `
        }
        </button>
        <ui-listbox
            class="sw-network-selector-overlay"
            data-part="overlay"
            aria-label="${ listboxLabel }"
            .config=${ LIST_CONFIG_MENU }
            @ui-value-changed=${ (e: ValueChangeEvent) => this.handleValueChange(e) }
            ${ ref(this.listboxRef) }>

            <div class="sw-network-current ${ status }">
                <span class="logo"><ui-icon name="${ networkIcon }"></ui-icon></span>
                <span class="name">${ networkName }</span>
                <span class="connection-indicator ${ status }"></span>
            </div>

            ${ status === 'connected' && current?.links?.length
                ? html`
                <div class="sw-network-links">
                ${ current.links.map(link => html`<ui-listitem class="sw-network-link" role="link" .value=${ link.url }>${ link.label }<ui-icon name="link"></ui-icon></ui-listitem>`) }
                </div>
                `
                : nothing
            }

            ${ status === 'disconnected'
                ? html`
                ${ this.providers.length > 1
                    ? html`
                    <div class="sw-network-wallets">
                    ${
                        this.providers.map(provider => html`
                        <div class="sw-network-wallets">
                            <ui-listitem class="sw-network-wallet" role="menuitemradio" .value=${ provider } aria-checked="${ this.selectedProvider === provider }">
                                <img class="icon" src="${ provider.info.icon }" alt="${ provider.info.name }" />
                                <span class="name">${ provider.info.name }</span>
                            </ui-listitem>
                        </div>
                        `)
                    }
                    </div>
                    `
                    : nothing
                }

                <div class="sw-network-actions ${ status }">
                    <ui-listitem class="sw-network-action" .value=${ 'connect' } aria-disabled="${ !this.selectedProvider }">
                        <ui-icon name="wallet"></ui-icon>Connect Wallet
                    </ui-listitem>
                </div>
                `
                : nothing
            }

            ${ networks.length
                ? html`
                <div class="sw-network-list">
                    ${ networks.map(network => html`
                    <ui-listitem .value=${ network }>
                        <ui-icon name="${ network.icon }"></ui-icon>${ network.name }
                    </ui-listitem>
                    `) }
                </div>
                `
                : nothing
            }

            ${ status === 'connected'
                ? html`
                <div class="sw-network-actions ${ status }">
                    <ui-listitem class="sw-network-action" .value=${ 'copy' }>
                        <ui-icon name="copy"></ui-icon>Copy Address
                    </ui-listitem>
                    <ui-listitem class="sw-network-action" .value=${ 'disconnect' }>
                        <ui-icon name="times"></ui-icon>Disconnect Wallet
                    </ui-listitem>
                </div>
                `
                : nothing
            }
        </ui-listbox>
    </ui-popup>
    `;
};

@customElement('sw-network-selector')
export class NetworkSelectorElement extends LitElement {

    protected walletService = services.wallet;

    protected providers: EIP6963ProviderDetail[] = [];

    protected selectedProvider?: EIP6963ProviderDetail;

    protected popupRef = createRef<PopupElement>();

    protected listboxRef = createRef<ListBoxElement>();

    @property()
    networks: Network[] = PUBLIC_NETWORKS;

    constructor () {

        super();

        this.handleWalletState = this.handleWalletState.bind(this);
    }

    connectedCallback () {

        super.connectedCallback();

        void this.updateProviders();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        wallet.subscribe(this.handleWalletState);
    }

    disconnectedCallback () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        wallet.unsubscribe(this.handleWalletState);

        super.disconnectedCallback();
    }

    connect () {

        if (wallet.state.status === WALLET_STATUS.CONNECTING) return;

        void this.updateProviders()
            .then(() => wallet.connect({
                walletIdentifier: getEthereumProviderIdentifier(this.selectedProvider),
            }));
    }

    disconnect () {

        if (wallet.state.status !== WALLET_STATUS.CONNECTED) return;

        // when manually disconnecting, we set the `autoConnect` preference to `false`
        preferences.set('autoConnect', false);

        void wallet.disconnect();
    }

    copyAddress () {

        if (wallet.state.status !== WALLET_STATUS.CONNECTED) return;

        void copyAddress();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleWalletState (state: WalletState) {

        handleAccountError(state.error?.message);

        this.handleNetwork();

        this.requestUpdate();
    }

    protected async handleValueChange (event: ValueChangeEvent) {

        const value = event.detail.current;

        if (isEIP6963ProviderDetail(value)) {

            this.selectedProvider = value;

            this.requestUpdate();

            return;
        }

        await this.hidePopup();

        this.resetSelection();

        switch (value) {

            case 'copy':
                this.copyAddress();
                break;

            case 'connect':
                this.connect();
                break;

            case 'disconnect':
                this.disconnect();
                break;

            default:

                if (typeof value === 'string') {

                    // if value is a string, but not 'copy', 'connect' or 'disconnect',
                    // it's a link to be opened in a new tab
                    window.open(value, '_blank', 'noreferrer');

                } else if ((value as Network).url) {

                    // if value is a `Network` we open its url in this tab
                    location.href = (value as Network).url;
                }
                break;
        }
    }

    protected handleNetwork () {

        if (wallet.state.status !== WALLET_STATUS.CONNECTED) return;

        const chainId = wallet.state.connection.network.chainId;

        const network = (chainId === ARBITRUM.chainId)
            ? ARBITRUM
            : (chainId === ARBITRUM_RINKEBY.chainId)
                ? ARBITRUM_RINKEBY
                : undefined;

        if (network === ARBITRUM || network === ARBITRUM_RINKEBY) {

            // update the `arbitrumFirstVisit` preference when arbitrum network is loaded
            preferences.set('arbitrumFirstVisit', preferences.get('arbitrumFirstVisit') === null ? true : false);

            // if this is not the first visit, don't show a notification
            if (preferences.get('arbitrumFirstVisit') !== true) return;

            const balanceHandler = (state: typeof this.walletService.state) => {

                if (state.matches('success')) {

                    if (emptyOrZero(state.context.balance.balance)) {

                        notifications.show({
                            class: 'network-arbitrum',
                            icon: 'arbitrum',
                            timeout: 10,
                            content: () => arbitrumNotification(network),
                        });
                    }

                    this.walletService.off(balanceHandler);
                }
            };

            if (!this.walletService.state.matches('success')) {

                this.walletService.onTransition(balanceHandler);

            } else {

                balanceHandler(this.walletService.state);
            }
        }
    }

    protected async updateProviders (): Promise<void> {

        // get the available browser wallet providers
        this.providers = await getEthereumProviders();

        // if we have don't have multiple providers, use the one installed
        if (this.providers.length <= 1) {

            this.selectedProvider = this.providers[0];

        } else {

            // get the last connected browser wallet provider
            const identifier = this.selectedProvider
                ? getEthereumProviderIdentifier(this.selectedProvider)
                : preferences.get('walletIdentifier') ?? undefined;

            // check if the last connected browser wallet provider is still available
            this.selectedProvider = getEthereumProvider(this.providers, identifier);
        }

        this.requestUpdate();
    }

    protected resetSelection () {

        this.listboxRef.value?.querySelectorAll<ListItemElement>('ui-listitem')
            .forEach(item => setAttribute(item, selectionAttribute(item), false));
    }

    protected async hidePopup () {

        await this.popupRef.value?.hide(true);
    }
}
