import { ARROW_LEFT, ARROW_RIGHT, ESCAPE, TAB } from '@swivel-finance/ui/utils';
import { html, LitElement, nothing } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { TOURS, TourStop } from '../../constants';
import { router, RouterService } from '../../services/router';

export const TOUR_END_EVENT = 'tour-end';
export const TOUR_CANCEL_EVENT = 'tour-cancel';

const TOUR_POSITION_PADDING = 32;

/**
 * Get the tour stops for the active route.
 *
 * @param r - the active route
 * @returns the tour stops for the active route
 */
const determineTour = (r: string | undefined): TourStop[] | undefined => {

    switch (r) {
        // disable the tour on the simplified page
        // case 'home':
        //     return TOURS.SIMPLIFIED;
        case 'professional':
            return TOURS.PROFESSIONAL;
        case 'positions':
            return TOURS.POSITIONS;
        default:
            return undefined;
    }
};

/**
 * Get the measurements of a reference element
 *
 * @param s - an element selector
 * @returns a tuple of element measurements
 */
const getLandmarkPosition = (s: string): [number, number, number, number, number, number] | undefined => {

    const reference = document.querySelector<HTMLElement>(s);

    if (!reference) return;

    const clientWidth = document.documentElement.clientWidth;

    const box = reference.getBoundingClientRect();

    return [box.left, box.top, box.right, box.bottom, box.width, clientWidth - box.left];
};

const correctVerticalPosition = function (oB: number, oT: number, h: number, p?: string) {

    const vAdjust = p
        ? p === 'below'
            ? oB + TOUR_POSITION_PADDING / 2
            : oT - h - TOUR_POSITION_PADDING / 2
        : TOUR_POSITION_PADDING;

    return vAdjust;
};

const correctHorizontalPosition = function (w: number, oL: number, oR: number, eW: number, hA: number) {

    let hAdjust = TOUR_POSITION_PADDING;
    // Center arrow position by default
    let aP = w / 2 - TOUR_POSITION_PADDING / 2;

    const roomToRight = w < hA;
    const roomToLeft = (oL + eW / 2 - w / 2) > 0;

    if (roomToRight && roomToLeft) {
        // Center placement by default
        hAdjust = oL + eW / 2 - w / 2;
    } else if (!roomToRight) {
        // if space is tight on the right, adjust left & adjust arrow position
        hAdjust = oR - TOUR_POSITION_PADDING - w;
        aP = w / 2 + TOUR_POSITION_PADDING * 2;
    } else if (!roomToLeft) {
        // if space is tight on the left, adjust right & adjust arrow position
        hAdjust = oL + TOUR_POSITION_PADDING;
        aP = w / 2 - TOUR_POSITION_PADDING * 2;
    }

    return { hAdjust, aP };
};

interface TourStopAdjustments {
    positionAdjust: string;
    arrowClass: 'up-arrow' | 'down-arrow' | undefined;
}

const positionAdjustments = (stop: TourStop, size: { height: number; width: number; }): TourStopAdjustments => {

    const position = getLandmarkPosition(stop.selector);
    let positionAdjust = 'top: 0; left: 0; transform: translate(calc(50vw - 50%), 25vh);';
    let arrowClass = undefined as 'down-arrow' | 'up-arrow' | undefined;

    if (position) {

        const [offsetLeft, offsetTop, offsetRight, offsetBottom, elementWidth, horizontalAdjustment] = position;

        const vAdjust = correctVerticalPosition(offsetBottom, offsetTop, size.height, stop.position);
        const { hAdjust, aP } = correctHorizontalPosition(size.width, offsetLeft, offsetRight, elementWidth, horizontalAdjustment);

        positionAdjust = `top: ${ vAdjust }px; left: ${ hAdjust }px; --arrow-location: ${ aP }px;`;

        arrowClass = stop.position
            ? stop.position === 'above'
                ? 'down-arrow'
                : 'up-arrow'
            : undefined;
    }

    return { positionAdjust, arrowClass };
};

const template = function (this: TourElement) {

    const step = this.currentStop + 1;

    const totalSteps = this.currentTour && this.currentTour.length || this.currentStop;

    return this.currentTour && html`
        <div class="tour-stop"  @keydown=${ (e: KeyboardEvent) => this.handleKeydown(e) }>
            <div class="tour-step">
                ${ `${ step } / ${ totalSteps }` }
                <button type="button" aria-label="cancel" @click=${ () => this.cancel() }>
                    <ui-icon name="times"></ui-icon>
                </button>
            </div>
            ${ this.currentTour[this.currentStop].content() }
            <div class="tour-controls">
                ${ this.currentStop > 0 && html`<button type="button" class="back" @click=${ () => this.handleBack() }>Back</button>` || html`` }
                ${ html`<button type="button" class="initiate advance" @click=${ () => this.handleNext() }>${ this.currentStop < this.currentTour.length - 1 ? 'Next' : 'Done' }</button>` }
            </div>
        </div>
        ` || nothing;
};


@customElement('sw-tour')
export class TourElement extends LitElement {

    protected routerRef: RouterService;

    protected activePage?: string;

    protected focusableElements: NodeList | undefined = undefined;

    protected focusedIndex = 0;

    protected tourStopSize = { height: 0, width: 0 };

    @state() currentTour: TourStop[] | undefined = undefined;

    @state() currentStop = 0;

    @query('.tour-stop') tourDiv!: HTMLElement | null;

    constructor () {

        super();

        this.routerRef = router();

        this.activePage = this.routerRef.activeRoute?.route.id;

        this.handleFocus = this.handleFocus.bind(this);
    }

    end () {

        this.dispatchEvent(new CustomEvent(TOUR_END_EVENT));
    }

    cancel () {

        this.dispatchEvent(new CustomEvent(TOUR_CANCEL_EVENT));
    }

    connectedCallback () {

        super.connectedCallback();

        this.currentTour = determineTour(this.activePage);
    }

    updated () {

        if (!this.currentTour) return;

        const tourBox = this.tourDiv?.getBoundingClientRect();

        this.tourStopSize = { height: tourBox?.height || 0, width: tourBox?.width || 0 };

        const { positionAdjust, arrowClass } = positionAdjustments(this.currentTour[this.currentStop], this.tourStopSize);

        this.tourDiv?.setAttribute('style', positionAdjust);
        // Lit render() does not set classList to initial state, so arrow classes need to be removed manually.
        this.tourDiv?.classList[1] && this.tourDiv.classList.remove(this.tourDiv?.classList[1]);

        arrowClass && this.tourDiv && this.tourDiv.classList.add(arrowClass);

        this.tourDiv?.scrollIntoView({ behavior: 'smooth', block: 'center' });

        this.focusableElements = this.tourDiv ? this.tourDiv.querySelectorAll('a, button') : undefined;

        this.focusedIndex = this.focusableElements && this.focusableElements.length - 1 || 0;

        this.focusableElements && this.handleFocus(this.focusableElements[this.focusedIndex]);
    }

    createRenderRoot () {

        return this;
    }

    render () {

        return template.apply(this);
    }

    handleBack () {

        this.currentStop -= (this.currentTour && this.currentStop > 0) ? 1 : 0;
    }

    handleNext () {

        // TODO: add transition
        // transitional handler ->  update current stop and render or end()
        // property / state for attaching classname . . component should not have class name when first added.

        this.currentTour && this.currentStop === this.currentTour.length - 1 ? this.end() : this.currentStop++;

    }

    focusNext () {

        if (this.focusableElements) {

            this.focusedIndex < this.focusableElements.length - 1 ? this.focusedIndex++ : this.focusedIndex = 0;

            this.handleFocus(this.focusableElements[this.focusedIndex]);
        }
    }

    focusPrevious () {

        if (this.focusableElements) {
            this.focusedIndex > 0 ? this.focusedIndex-- : this.focusedIndex = this.focusableElements.length - 1;

            this.handleFocus(this.focusableElements[this.focusedIndex]);
        }
    }

    handleKeydown (e: KeyboardEvent) {

        switch (e.key) {
            case ARROW_LEFT:
                this.focusPrevious();
                break;
            case ARROW_RIGHT:
                this.focusNext();
                break;
            case TAB:
                e.preventDefault();
                e.shiftKey ? this.focusPrevious() : this.focusNext();
                break;
            case ESCAPE:
                e.preventDefault();
                this.cancel();
                break;
            default:
                break;
        }
    }

    handleFocus (e: Node) {

        this.tourDiv && this.tourDiv.contains(e as HTMLElement)
            ? (e as HTMLElement).focus()
            : this.focusableElements && this.focusedIndex && (this.focusableElements[this.focusedIndex] as HTMLElement).focus();
    }
}
