import { html, TemplateResult } from 'lit';
import { TOOLTIPS } from '../../../constants';
import { inferFee, inferPrice, inferProfit, inferRate, inferYield, invertPrice, maturityDate } from '../../../helpers';
import { amount, balance, tokenBalance, tokenPrice } from '../../../shared/templates';
import { OrderContext } from '../../../state/order';
import { Market, Token, TokenType, YieldType } from '../../../types';
import type { CreateOrderElement } from '../create';

/**
 * Renders the order details view.
 */
export const orderDetailsTemplate = function (this: CreateOrderElement) {

    const context = this.orderService.state.context;

    const { market, tokenType, yieldType } = context;

    let details: [TemplateResult | string, TemplateResult | string][] = [];

    switch (tokenType) {

        case 'underlying':

            details = [
                [receivedLabel(yieldType, tokenType, market), receivedTemplate(context)],
                // don't show the price in the simplified ui
                ...(this.simple
                    ? []
                    : [[priceLabel(tokenType, market), priceTemplate(context)]] as [TemplateResult, TemplateResult][]),
                // only show profit for amplified yield orders
                ...(yieldType === 'floating'
                    ? [[profitLabel(), profitTemplate(context)]] as [TemplateResult, TemplateResult][]
                    : []),
                [rateLabel(yieldType), rateTemplate(context)],
            ];

            break;

        case 'zcToken':
        case 'nToken':

            details = [
                [receivedLabel(yieldType, tokenType, market), receivedTemplate(context)],
                [priceLabel(tokenType, market), priceTemplate(context)],
            ];
            break;
    }

    // add fee and maturity to each order details list
    details.push(['Fee', feeTemplate(context)]);
    details.push(['Maturity', maturityTemplate(market)]);

    return html`
    <ul class="order-details" aria-label="order details">
        ${ details.map(([label, value]) => orderDetailsEntryTemplate(label, value)) }
    </ul>
    `;
};

const orderDetailsEntryTemplate = (l: TemplateResult | string, v: TemplateResult | string) => html`<li>
    <span class="label">${ l }</span>
    <span class="value">${ v }</span>
</li>`;

// ---------------
// LABEL TEMPLATES
// ---------------

/**
 * Creates a label with optional tooltip for the order details' `Yielded / Purchased / Received` row
 *
 * @param y - the order's {@link YieldType}
 * @param t - the order's {@link TokenType}
 * @param m - the market associated with the order
 */
const receivedLabel = (y: YieldType, t: TokenType, m?: Market) => {

    const underlying = 'underlying';

    switch (t) {

        case 'underlying':

            if (y === 'fixed') return html`Interest Yielded
            <ui-icon name="question" aria-describedby="received-tooltip"></ui-icon>
            <ui-tooltip id="received-tooltip">${ TOOLTIPS.PROFESSIONAL.ORDER_INPUT.INTEREST_YIELDED(m?.tokens.underlying.symbol || underlying) }</ui-tooltip>
            `;

            else return html`${ m?.tokens.nToken.symbol || 'nTokens' } Purchased
            <ui-icon name="question" aria-describedby="received-tooltip"></ui-icon>
            <ui-tooltip id="received-tooltip">${ TOOLTIPS.PROFESSIONAL.ORDER_INPUT.NTOKEN_PURCHASED(m?.tokens.underlying.symbol || underlying) }</ui-tooltip>
            `;

        case 'nToken':
        case 'zcToken':

            return html`${ m?.tokens.underlying.symbol || underlying } Received`;
    }
};

/**
 * Creates a label with optional tooltip for the order details' `Price` row
 *
 * @param t - the order's {@link TokenType}
 * @param m - the market associated with the order
 */
const priceLabel = (t: TokenType, m?: Market) => {
    const nToken = m?.tokens.nToken;
    const zcToken = m?.tokens.zcToken;
    const underlying = m?.tokens.underlying;

    if (t === 'zcToken') return html`Price per ${ zcToken?.symbol || 'PT' }
    <ui-icon name="question" aria-describedby="price-tooltip"></ui-icon>
    <ui-tooltip id="price-tooltip">${ TOOLTIPS.PROFESSIONAL.ORDER_INPUT.PRICE_ZCTOKENS(nToken?.symbol || 'YTs', underlying?.symbol || 'underlying') }</ui-tooltip>
    `;

    else return html`Price per ${ nToken?.symbol || 'YT' }`;
};

/**
 * Creates a label with tooltip for the order details' `Rate / APY` row
 *
 * @param y - the order's {@link YieldType}
 */
const rateLabel = (y: YieldType) => {

    if (y === 'fixed') return html`Fixed APY
    <ui-icon name="question" aria-describedby="rate-tooltip"></ui-icon>
    <ui-tooltip id="rate-tooltip">${ TOOLTIPS.PROFESSIONAL.ORDER_INPUT.FIXED_APY }</ui-tooltip>
    `;

    else return html`Projected APY
    <ui-icon name="question" aria-describedby="rate-tooltip"></ui-icon>
    <ui-tooltip id="rate-tooltip">${ TOOLTIPS.PROFESSIONAL.ORDER_INPUT.ESTIMATED_APY }</ui-tooltip>
    `;
};

/**
 * Creates a label with tooltip for the order details' `Profit / PnL` row
 *
 * @param y - the order's {@link YieldType}
 */
const profitLabel = () => {

    return html`Projected Profit
    <ui-icon name="question" aria-describedby="profit-tooltip"></ui-icon>
    <ui-tooltip id="profit-tooltip">${ TOOLTIPS.PROFESSIONAL.ORDER_INPUT.ESTIMATED_PNL }</ui-tooltip>
    `;
};

// ---------------
// VALUE TEMPLATES
// ---------------

/**
 * Renders the amount received (yielded) from an order.
 *
 * @param c - the order state context
 */
export const receivedTemplate = (c: OrderContext) => {

    let received: string | undefined;
    let token: Token | undefined;

    const { order, market, orderType, volume, fillPreview } = c;

    if (orderType === 'limit' && order.principal && order.premium && market) {

        ({ received, token } = inferYield(order, market));
    }

    if (orderType === 'market' && volume && fillPreview && market) {

        ({ received, token } = inferYield(order, market, volume, fillPreview.effectivePrice));
    }

    return (received && token) ? tokenBalance(received, token, undefined, false) : html`-`;
};

/**
 * Renders the price of an order based on the order's underlying token.
 *
 * @remarks
 * For `limit` orders the `price` is calculated from the order's `principal` and `premium`.
 * For `market` orders the `price` is the `fillPreview`'s `effectivePrice`.
 *
 * @param c - the order state context
 */
export const priceTemplate = (c: OrderContext) => {

    let price: string | undefined;
    let token: Token | undefined;

    const { order, market, orderType, tokenType, volume, fillPreview } = c;

    if (orderType === 'limit' && order.principal && order.premium && market) {

        token = (tokenType === 'zcToken')
            ? market.tokens.zcToken
            : market.tokens.underlying;

        price = inferPrice(order, market);
    }

    if (orderType === 'market' && volume && fillPreview && market) {

        token = (tokenType === 'zcToken')
            ? market.tokens.zcToken
            : market.tokens.underlying;

        price = (tokenType === 'zcToken')
            ? invertPrice(fillPreview.effectivePrice, token.decimals)
            : fillPreview.effectivePrice;
    }

    return (price && token) ? tokenPrice(price, token, undefined, false) : html`-`;
};

/**
 * Renders the annual percentage yield (APY) of an order.
 *
 * @param c - the order state context
 */
const rateTemplate = (c: OrderContext) => {

    let rate: string | undefined;
    let notAvailable = false;

    const { order, market, orderType, yieldType, volume, fillPreview } = c;

    if (orderType === 'limit' && order.principal && order.premium && market) {

        rate = inferRate(order, market);

        notAvailable = yieldType === 'floating' && !rate && !market?.interestRate;
    }

    if (orderType === 'market' && volume && fillPreview && market) {

        rate = inferRate(order, market, volume, fillPreview.effectivePrice);

        notAvailable = yieldType === 'floating' && !rate && !market?.interestRate;
    }

    return (rate)
        ? amount(rate, '%')
        : notAvailable
            ? rateUnavailableTemplate('apy')
            : html`-`;
};

/**
 * Renders the annual percentage yield (APY) of an order.
 *
 * @param c - the order state context
 */
const profitTemplate = (c: OrderContext) => {

    let profit: string | undefined;
    let notAvailable = false;

    const { order, market, orderType, yieldType, volume, fillPreview } = c;

    if (yieldType === 'floating' && orderType === 'limit' && order.principal && order.premium && market) {

        profit = inferProfit(order, market);

        notAvailable = !profit && !market?.interestRate;
    }

    if (yieldType === 'floating' && orderType === 'market' && volume && fillPreview && market) {

        profit = inferProfit(order, market, volume, fillPreview.effectivePrice);

        notAvailable = !profit && !market?.interestRate;
    }

    return (profit && market)
        ? tokenBalance(profit, market.tokens.underlying, undefined, false)
        : notAvailable
            ? rateUnavailableTemplate('profit')
            : html`-`;
};

/**
 * Renders 'n/a' with an icon and a tooltip explaining why rate is not available.
 */
const rateUnavailableTemplate = (projection: 'profit' | 'apy') => html`
    n/a <ui-icon name="exclamation" aria-describedby="${ projection }-no-interest-rate"></ui-icon>
    <ui-tooltip id="${ projection }-no-interest-rate">
        ${ TOOLTIPS.PROFESSIONAL.ORDER_INPUT.NO_RATE }
    </ui-tooltip>
    `;

/**
 * Renders the maturity date of an order formatted according to the browser locale.
 *
 * @param m - the market associated with the order
 */
export const maturityTemplate = (m?: Market) => {

    return m
        ? html`${ maturityDate(m.maturity) }`
        : html`-`;
};

/**
 * Renders the fee for an order.
 *
 * @param c - the order state context
 */
export const feeTemplate = (c: OrderContext) => {

    let fee: string | undefined;
    let token: Token | undefined;

    const { order, market, orderType, volume, fillPreview } = c;

    if (orderType === 'limit' && order.principal && order.premium && market) {

        ({ fee, token } = inferFee(order, market));
    }

    if (orderType === 'market' && volume && fillPreview && market) {

        ({ fee, token } = inferFee(order, market, volume, fillPreview.effectivePrice));
    }

    return fee
        ? token
            ? tokenBalance(fee, token, undefined, false)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            : balance(fee, market!.tokens.underlying, undefined, false)
        : html`-`;
};
