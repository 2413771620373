import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { banners } from './banner-service';
import { Banner } from './types';

let bannerId = 0;

let bannerOutletId = 0;

const bannerOutletTemplate = function (this: BannerOutletElement) {

    const banner = this.queue[0];

    return html`${ banner
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ? html`<sw-banner id=${ banner.id! }
            .icon=${ banner.icon }
            .type=${ banner.type }
            .dismissable=${ banner.dismissable }
            .content=${ banner.content }></sw-banner>`
        : ''
    }`;
};

@customElement('sw-banner-outlet')
export class BannerOutletElement extends LitElement {

    protected banners = new Map<string, Banner>();

    protected queue = [] as Banner[];

    show (b: Banner): string {

        const id = b.id = b.id || `sw-banner-${ bannerId++ }`;

        this.banners.set(id, b);

        this.updateQueue();

        this.requestUpdate();

        return id;
    }

    dismiss (i: string) {

        this.banners.delete(i);

        this.updateQueue();

        this.requestUpdate();
    }

    refresh (i: string, b: Banner) {

        const banner = this.banners.get(i);

        if (banner) this.banners.set(i, { ...banner, ...b });

        this.updateQueue();

        this.requestUpdate();
    }

    connectedCallback () {

        super.connectedCallback();

        this.id = this.id || `sw-banner-outlet-${ bannerOutletId++ }`;

        banners.connect(this);
    }

    disconnectedCallback () {

        banners.disconnect(this);
    }

    createRenderRoot () {

        return this;
    }

    render () {

        return bannerOutletTemplate.apply(this);
    }

    protected updateQueue () {

        const high = [] as Banner[];
        const medium = [] as Banner[];
        const low = [] as Banner[];

        this.banners.forEach(banner => {

            switch (banner.priority) {

                case 'high':
                    high.push(banner);
                    break;

                case 'medium':
                    medium.push(banner);
                    break;

                default:
                    low.push(banner);
                    break;
            }
        });

        this.queue = [...high, ...medium, ...low];
    }
}
