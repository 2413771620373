import { dispatch } from '@swivel-finance/ui/utils/events';
import { html, LitElement, PropertyValues, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { loader } from '../../templates';
import { NotificationDismissedEvent } from './events';
import { notifications } from './notification-service';
import { Notification, NotificationType } from './types';

const NOTIFICATION_ICONS = {
    'info': 'info',
    'success': 'check',
    'failure': 'rejected',
    'progress': undefined,
};

const notificationTemplate = function (this: NotificationElement) {

    const progress = (this.type === 'progress');

    const icon = progress
        ? undefined
        : this.icon || NOTIFICATION_ICONS[this.type || 'info'];

    const content = (typeof this.content === 'function')
        ? this.content()
        : this.content;

    return html`
    <span class="notification-icon">
        ${ progress
            ? loader()
            : ''
        }
        ${ icon
            ? html`<ui-icon name=${ icon }></ui-icon>`
            : ''
        }
    </span>
    <span class="notification-content">${ content }</span>
    <span class="notification-buttons">
        ${ this.dismissable
            // eslint-disable-next-line @typescript-eslint/unbound-method
            ? html`<button class="dismiss" aria-label="dismiss" @click=${ this.dismiss }><ui-icon name="times"></ui-icon></button>`
            : ''
        }
    </span>
    `;
};

@customElement('sw-notification')
export class NotificationElement extends LitElement implements Notification {

    protected dismissTimeout: number | undefined;

    @property({
        attribute: true,
        reflect: true,
        type: String,
    })
    type: NotificationType | undefined;

    @property({
        attribute: true,
        reflect: true,
        type: String,
    })
    icon: string | undefined;

    @property({
        attribute: true,
        reflect: true,
        type: Number,
    })
    timeout: number | undefined;

    @property({
        attribute: true,
        reflect: true,
        type: Boolean,
    })
    dismissable: boolean | undefined;

    @property({
        attribute: false,
    })
    content: string | (() => TemplateResult) | undefined;

    refresh (n: Notification) {

        Object.entries(n).forEach(([property, value]) => {

            if (value !== undefined && this[property as keyof this] !== value) {

                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                this[property as keyof this] = value;
            }
        });

        this.requestUpdate();
    }

    dismiss () {

        if (this.dismissable) {

            notifications.dismiss(this.id);

            dispatch(this, new NotificationDismissedEvent({
                target: this,
                notification: this,
                dismissed: true,
            }));
        }
    }

    updated (changedProperties: PropertyValues<NotificationElement>) {

        if (changedProperties.has('timeout') || changedProperties.has('dismissable')) {

            this.clearTimeout();

            this.setTimeout();
        }
    }

    connectedCallback () {

        super.connectedCallback();

        this.type = this.type ?? 'info';
        this.timeout = this.timeout ?? 5;
        this.dismissable = this.dismissable ?? true;
    }

    disconnectedCallback () {

        this.clearTimeout();
    }

    createRenderRoot () {

        return this;
    }

    render () {

        return notificationTemplate.apply(this);
    }

    protected clearTimeout () {

        if (this.dismissTimeout) {

            window.clearTimeout(this.dismissTimeout);

            this.dismissTimeout = undefined;
        }
    }

    protected setTimeout () {

        if (this.dismissable && this.timeout) {

            this.dismissTimeout = window.setTimeout(() => this.dismiss(), this.timeout * 1000);
        }
    }
}
