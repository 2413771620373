import { Network } from '../types';

export const ETHEREUM: Network = {
    chainId: 1,
    name: 'Ethereum',
    icon: 'ethereum',
    url: 'https://swivel.exchange',
    links: [
        {
            label: 'Etherscan',
            url: 'https://etherscan.io/',
        },
    ],
};

/**
 * @remarks
 * The rinkeby test network is no longer supported.
 *
 * @deprecated
 */
export const ETHEREUM_RINKEBY: Network = {
    chainId: 4,
    name: 'Ethereum Rinkeby',
    icon: 'ethereum',
    url: 'https://rinkeby.swivel.exchange',
    links: [
        {
            label: 'Etherscan',
            url: 'https://rinkeby.etherscan.io/',
        },
    ],
};

export const ETHEREUM_GOERLI: Network = {
    chainId: 5,
    name: 'Ethereum Goerli',
    icon: 'ethereum',
    url: 'https://goerli.swivel.exchange',
    links: [
        {
            label: 'Etherscan',
            url: 'https://goerli.etherscan.io/',
        },
    ],
};

export const ARBITRUM: Network = {
    chainId: 42161,
    name: 'Arbitrum',
    icon: 'arbitrum',
    url: 'https://arbitrum.swivel.exchange',
    links: [
        {
            label: 'Arbitrum Bridge',
            url: 'https://bridge.arbitrum.io/',
        },
        {
            label: 'Arbiscan',
            url: 'https://arbiscan.io/',
        },
    ],
};

export const ARBITRUM_RINKEBY: Network = {
    chainId: 421611,
    name: 'Arbitrum Rinkeby',
    icon: 'arbitrum',
    // this url can be set to e.g. 'http://localhost:8000' during local development
    // on arbitrum-rinkeby to get a network preview when wallet is not connected
    url: 'https://swivel-exchange-v2-git-feat-arbitrum-swivel.vercel.app',
    links: [
        {
            label: 'Arbitrum Bridge',
            url: 'https://bridge.arbitrum.io/',
        },
        {
            label: 'Arbiscan',
            url: 'https://testnet.arbiscan.io/',
        },
    ],
};

/**
 * A list of all officially supported networks (production environments).
 *
 * @remarks
 * The networks in this list will be rendered in the network selector. If you want to
 * add support for a network in development, without making it available in the network
 * selector, add it to the {@link PRIVATE_NETWORKS} below.
 */
export const PUBLIC_NETWORKS: Network[] = [
    ETHEREUM,
];

/**
 * A list of unofficial networks (development/staging environments).
 *
 * @remarks
 * Networks which have a supported development/staging environment, but are not public and
 * should not appear in the network selector (e.g. arbitrum-rinkeby). We could add ropsten
 * or kovan here if we were ever to use them again for dev environments.
 * You can also add a custom network here during local development and give it your local
 * development server url for full integration.
 */
export const PRIVATE_NETWORKS: Network[] = [
    ARBITRUM,
    ARBITRUM_RINKEBY,
];

/**
 * A placeholder network for any wallet connection which is not part of the public or private networks.
 *
 * @remarks
 * This is only really relevant for local development when using a chain which is not defined in the
 * network lists above. It will be simply displayed as 'Unknown Network' if the local development env
 * allows the current chain id. It is returned and updated by {@link fallbackNetwork}
 */
const DEFAULT_NETWORK: Network = {
    chainId: -1,
    name: 'Unknown Network',
    icon: 'question',
    url: '',
};

const ALL_NETWORKS: Network[] = [
    ...PUBLIC_NETWORKS,
    ...PRIVATE_NETWORKS,
];

const networkByChain = (chainId: number): Network | undefined => ALL_NETWORKS.find(network => network.chainId === chainId);

const networkByUrl = (url: string): Network | undefined => ALL_NETWORKS.find(network => network.url.startsWith(url));

/**
 * Get the currently 'active' network by chain id or url.
 *
 * @remarks
 * This method tries to resolve a {@link Network} object based on either the connected `chainId` or, if none is
 * provided, based on the current url. This makes it possible to get a {@link Network} object even if no wallet
 * is currently connected. It will only resolve permitted networks - either public or private ones.
 * If the `chainId` or current url don't match any permitted network, undefined will be returned. We can use
 * a {@link fallbackNetwork} in that case, if we still want to have 'some' network object.
 *
 * @param chainId - optional chainId of the currently connected network
 */
export const currentNetwork = (chainId?: number): Network | undefined => {

    let network: Network | undefined;

    if (typeof chainId === 'number') {

        network = networkByChain(chainId);

    } else {

        network = networkByUrl(location.origin);
    }

    return network;
};

/**
 * Get a fallback {@link Network} object.
 *
 * @remarks
 * This method is used by the network selector to make it possible to see network information
 * for networks, which are not supported, but might be used in development and on preview deploys.
 * These networks will show as 'Unknown Network'.
 *
 * @param chainId - optional chainId of the currently conncted network
 */
export const fallbackNetwork = (chainId?: number): Network => {

    return {
        ...DEFAULT_NETWORK,
        chainId: chainId ?? -1,
        url: location.origin,
    };
};
