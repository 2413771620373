import { html, TemplateResult } from 'lit';

export const message = (m: string | TemplateResult) =>
    html`<div class="message-box">${ m }</div>`;

export const errorMessage = (m: string | TemplateResult) =>
    html`<div class="message-box error">${ m }</div>`;

export const infoMessage = (m: string | TemplateResult) =>
    html`<div class="message-box info">${ m }</div>`;
