/**
 * Throttle a method
 *
 * @param m - the method to throttle
 * @param t - an optional time in ms to throttle by (default: 1000)
 * @returns the throttled method
 */
export const throttle = <T extends unknown[], R> (m: (...args: T) => R, t = 1000) => {

    let timeout: number | undefined;
    let params: T;

    return (...args: T) => {

        params = args;

        if (timeout) return;

        timeout = window.setTimeout(
            () => {
                timeout = undefined;
                m.apply(this, params);
            },
            t,
        );
    };
};
