import { LIST_CONFIG_MENU, SelectEvent, selectionAttribute } from '@swivel-finance/ui/behaviors/list';
import { ListBoxElement } from '@swivel-finance/ui/elements/listbox';
import { ListItemElement } from '@swivel-finance/ui/elements/listitem';
import { PopupConfig, PopupElement, POPUP_CONFIG_MENU } from '@swivel-finance/ui/elements/popup';
import { setAttribute } from '@swivel-finance/ui/utils/dom';
import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { COOKIE_POLICY_URL, PRIVACY_POLICY_URL } from '../../constants';
import { ENV } from '../../env/environment';
import { HISTORY_ADAPTER_CONFIG_DEFAULT, preferences, router, tour } from '../../services';

const ACTIVE_LINK_CLASS = HISTORY_ADAPTER_CONFIG_DEFAULT.activeClass;

const APP_MENU_CONFIG: PopupConfig = {
    ...POPUP_CONFIG_MENU,
    position: {
        ...POPUP_CONFIG_MENU.position,
        alignment: {
            origin: {
                horizontal: 'end',
                vertical: 'end',
            },
            target: {
                horizontal: 'end',
                vertical: 'start',
            },
            offset: {
                vertical: 'var(--line-height)',
            },
        },
    },
};

const template = function (this: AppMenuElement) {

    return html`
    <ui-popup .config=${ APP_MENU_CONFIG } ${ ref(this.popupRef) }>

        <button type="button" data-part="trigger" aria-label="open app menu">
            <ui-icon name="more"></ui-icon>
        </button>

        <ui-listbox
            class="sw-app-menu-overlay"
            data-part="overlay"
            aria-label="app menu"
            .config=${ LIST_CONFIG_MENU }
            @ui-select-item=${ (e: SelectEvent) => this.handleSelectionChange(e) }
            ${ ref(this.listboxRef) }>

            <ui-listitem role="link">
                <a href="/professional" data-route="professional" tabindex="-1">
                    <ui-icon name="professional"></ui-icon>Professional
                </a>
            </ui-listitem>
            <ui-listitem role="link">
                <a href="/positions" data-route="positions" tabindex="-1">
                    <ui-icon name="positions"></ui-icon>Positions
                </a>
            </ui-listitem>
            <ui-listitem role="link">
                <a href="/staking" data-route="staking" tabindex="-1">
                    <ui-icon name="staking"></ui-icon>Staking
                </a>
            </ui-listitem>
            <ui-listitem role="link">
                <a href="${ ENV.docsUrl }" target="_blank" rel="noopener" tabindex="-1">
                    <ui-icon name="docs"></ui-icon>Docs
                </a>
            </ui-listitem>
            <ui-listitem role="link">
                <a href="${ ENV.discordUrl }" target="_blank" rel="noopener" tabindex="-1">
                    <ui-icon name="discord"></ui-icon>Discord
                </a>
            </ui-listitem>
            <ui-listitem role="link">
                <a href="${ COOKIE_POLICY_URL }" data-route="cookie-policy" target="_blank" tabindex="-1">
                    <ui-icon name="policy"></ui-icon>Cookie Policy
                </a>
            </ui-listitem>
            <ui-listitem role="link">
                <a href="${ PRIVACY_POLICY_URL }" data-route="privacy-policy" target="_blank" tabindex="-1">
                    <ui-icon name="policy"></ui-icon>Privacy Policy
                </a>
            </ui-listitem>
            <ui-listitem .value=${ 'tour' }>
                <ui-icon name="tour"></ui-icon>Tour Swivel
            </ui-listitem>
            <ui-listitem .value=${ 'reset' }>
                <ui-icon name="reset"></ui-icon>Reset Preferences
            </ui-listitem>
            <ui-listitem .value=${ 'theme' } aria-disabled="true">
                <ui-icon name="light"></ui-icon>Light Mode
            </ui-listitem>
        </ui-listbox>

    </ui-popup>
    `;
};

@customElement('sw-app-menu')
export class AppMenuElement extends LitElement {

    popupRef = createRef<PopupElement>();

    listboxRef = createRef<ListBoxElement>();

    constructor () {

        super();

        this.handleRouteChange = this.handleRouteChange.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        router().subscribe(this.handleRouteChange);
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        router().unsubscribe(this.handleRouteChange);
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected async handleSelectionChange (e: SelectEvent) {

        const item = e.detail.current?.item as ListItemElement | undefined;

        await this.hide();

        switch (item?.value) {

            case 'tour':

                tour.start();
                break;

            case 'reset':

                preferences.clear();
                break;

            case 'theme':

                // TODO: add theme switching here
                break;

            default:

                if (!item) break;
                if (!e.detail.change) break;

                item.querySelector('a')?.click();
        }

        this.resetSelection();
    }

    protected handleRouteChange () {

        this.resetSelection();
    }

    protected async hide () {

        await this.popupRef.value?.hide(true);
    }

    protected resetSelection () {

        // the router service marks links as active when they match the activeRoute
        const activeLink = Array.from(this.listboxRef.value?.querySelectorAll('a') ?? [])
            .find(link => ACTIVE_LINK_CLASS ? link.classList.contains(ACTIVE_LINK_CLASS) : false);

        // the menu item containing the active link is selected
        this.listboxRef.value?.querySelectorAll<ListItemElement>('ui-listitem')
            .forEach(item => setAttribute(item, selectionAttribute(item), activeLink && item.contains(activeLink)));
    }
}
