import { TransactionReceipt, TransactionResponse } from '@ethersproject/providers';
import { ERRORS } from '../../constants/errors';
import { errors } from '../../services/errors';

interface TransactionReplacedError extends Error {
    code: 'TRANSACTION_REPLACED';
    hash: string;
    reason: 'cancelled' | 'replaced' | 'repriced';
    cancelled: boolean;
    replacement: TransactionResponse;
    receipt: TransactionReceipt;
}

const isTransactionReplacedError = (e: unknown): e is TransactionReplacedError => {

    return (e as TransactionReplacedError).code === 'TRANSACTION_REPLACED';
};

/**
 * Wait for a transaction to be successfully mined.
 *
 * @remarks
 * Waits for a {@link TransactionResponse} to be mined and resolves with the {@link TransactionReceipt}
 * if the transaction was successful. Will reject with a {@link ProcessedError} if the transaction
 * fails or is cancelled/replaced.
 *
 * @param t - the transaction response to confirm
 */
export const confirmTransaction = async (t: TransactionResponse): Promise<TransactionReceipt> => {

    try {

        const receipt = await t.wait();

        return receipt;

    } catch (error) {

        if (isTransactionReplacedError(error)) {

            // a tx is 'repriced' if only the gas amount has changed (e.g. to speed up the tx)
            if (error.reason === 'repriced') {

                // we await the repriced tx receipt in that case...
                return await confirmTransaction(error.replacement);

            } else {

                throw errors.process(error, ERRORS.ETHEREUM.TRANSACTION.CANCELLED);
            }

        } else {

            throw errors.process(error);
        }
    }
};
