/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-types */
import { Protocols, Swivel } from '@swivel-finance/swivel-js';
import { dialogs } from '@swivel-finance/ui/elements/dialog';
import { ethers } from 'ethers';
import { DoneInvokeEvent, MachineOptions, Receiver, TransitionsConfig, assign, createMachine, send } from 'xstate';
import { OversizedOrderDialogElement } from '../components/orders/outsized-dialog';
import { orderNotification } from '../components/orders/templates/order-notification';
import { ANALYTICS, ERRORS } from '../constants';
import { confirmTransaction, emptyOrZero, locked } from '../helpers';
import { checkOutsized, finalizeOrder, inferAmount, inferExitVault, inferPrice, inferTokenType } from '../helpers/order';
import { analytics } from '../services/consent';
import { ProcessedError, errors } from '../services/errors';
import { logger as logService } from '../services/logger';
import { preferences } from '../services/preferences';
import { swivelApi } from '../services/swivel';
import { token } from '../services/token';
import { notifications } from '../shared/components/notification';
import { debounce } from '../shared/helpers';
import { Balance, Balances, FillPreview, Market, Order, OrderType, TokenType, YieldType } from '../types';

const logger = logService.group('order state');

export interface OrderContext {
    order: Order;
    orderType: OrderType;
    yieldType: YieldType;
    tokenType: TokenType;
    maker?: string;
    swivel?: Swivel;
    market?: Market;
    balances?: Balances;
    token?: Balance;
    volume?: string;
    fillPreview?: FillPreview;
    fillPreviewPending: boolean;
    signature?: string;
    error?: string;
}

interface OrderContextInteractive extends OrderContext {
    maker: string;
    swivel: Swivel;
    market: Market;
    balances: Balances;
    token: Balance;
}

export type OrderState =
    {
        value: 'initial';
        context: OrderContext & {
            volume: undefined;
            fillPreview: undefined;
            signature: undefined;
        };
    }
    | {
        value: 'interactive'
        | { interactive: 'market'; }
        | { interactive: { market: 'invalid'; }; }
        | { interactive: { market: 'valid'; }; }
        | { interactive: { market: 'allowance'; }; }
        | { interactive: 'limit'; }
        | { interactive: { limit: 'invalid'; }; }
        | { interactive: { limit: 'valid'; }; }
        | { interactive: { limit: 'allowance'; }; }
        | { interactive: { limit: 'price'; }; }
        | { interactive: { limit: 'signing'; }; };
        context: OrderContextInteractive & {
            signature: undefined;
        };
    }
    | {
        value: { interactive: { market: 'previewing'; }; };
        context: OrderContextInteractive & {
            volume: string;
            signature: undefined;
        };
    }
    | {
        value: { interactive: { market: 'pending'; }; } | { interactive: { market: 'submitted'; }; };
        context: OrderContextInteractive & {
            volume: string;
            fillPreview: FillPreview;
            signature: undefined;
        };
    }
    | {
        value: { interactive: { limit: 'signed'; }; }
        | { interactive: { limit: 'pending'; }; }
        | { interactive: { limit: 'submitted'; }; };
        context: OrderContextInteractive & {
            signature: string;
        };
    };

export type OrderInitEvent = { type: 'ORDER.INIT'; payload: { maker: string; swivel: Swivel; }; };
export type OrderMarketEvent = { type: 'ORDER.MARKET'; payload: Market; };
export type OrderTokenEvent = { type: 'ORDER.TOKEN'; payload: { balances: Balances; token: Balance; tokenType: TokenType; }; };
export type OrderYieldEvent = { type: 'ORDER.YIELD'; payload: YieldType; };
export type OrderTypeEvent = { type: 'ORDER.TYPE'; payload: OrderType; };
export type OrderVolumeEvent = { type: 'ORDER.VOLUME'; payload: string; };
export type OrderPreviewEvent = { type: 'ORDER.PREVIEW'; payload: FillPreview; };
export type OrderPreviewRequestEvent = { type: 'ORDER.PREVIEW.REQUEST'; payload: { market: Market; order: Order; volume: string; }; };
export type OrderPreviewFailureEvent = { type: 'ORDER.PREVIEW.FAILURE'; payload: Error; };
export type OrderUpdateEvent = { type: 'ORDER.UPDATE'; payload: Partial<Order>; };
export type OrderResetEvent = { type: 'ORDER.RESET'; };
export type OrderSubmitEvent = { type: 'ORDER.SUBMIT'; };

export type OrderEvent =
    OrderInitEvent
    | OrderMarketEvent
    | OrderTokenEvent
    | OrderYieldEvent
    | OrderTypeEvent
    | OrderVolumeEvent
    | OrderPreviewEvent
    | OrderPreviewRequestEvent
    | OrderPreviewFailureEvent
    | OrderUpdateEvent
    | OrderResetEvent
    | OrderSubmitEvent;

const init: TransitionsConfig<OrderContext, OrderEvent> = {
    'ORDER.INIT': {
        actions: 'INIT',
        target: 'initial',
    },
};

const setMarket: TransitionsConfig<OrderContext, OrderEvent> = {
    'ORDER.MARKET': {
        actions: 'SET_MARKET',
        target: 'initial',
    },
};

const setToken: TransitionsConfig<OrderContext, OrderEvent> = {
    'ORDER.TOKEN': {
        actions: 'SET_TOKEN',
        target: 'initial',
    },
};

const setType: TransitionsConfig<OrderContext, OrderEvent> = {
    'ORDER.TYPE': {
        actions: 'SET_TYPE',
        target: 'interactive',
    },
};

const setYield: TransitionsConfig<OrderContext, OrderEvent> = {
    'ORDER.YIELD': {
        actions: 'SET_YIELD',
        target: 'interactive',
    },
};

const setVolume: TransitionsConfig<OrderContext, OrderEvent> = {
    'ORDER.VOLUME': {
        actions: 'SET_VOLUME',
        target: 'invalid',
    },
};

const requestPreview: TransitionsConfig<OrderContext, OrderEvent> = {
    'ORDER.PREVIEW.REQUEST': {
        actions: 'REQUEST_PREVIEW',
    },
};

const setPreview: TransitionsConfig<OrderContext, OrderEvent> = {
    'ORDER.PREVIEW': {
        actions: 'SET_PREVIEW',
    },
};

const previewFailure: TransitionsConfig<OrderContext, OrderEvent> = {
    'ORDER.PREVIEW.FAILURE': {
        actions: 'SET_PREVIEW_FAILURE',
    },
};

const update: TransitionsConfig<OrderContext, OrderEvent> = {
    'ORDER.UPDATE': {
        actions: 'UPDATE_ORDER',
        target: 'invalid',
        cond: 'hasChanged',
    },
};

const reset: TransitionsConfig<OrderContext, OrderEvent> = {
    'ORDER.RESET': {
        actions: 'RESET_ORDER',
        target: 'invalid',
    },
};

const initialContext: OrderContext = {
    order: {
        key: '',
        maker: '',
        protocol: Protocols.Compound,
        underlying: '',
        maturity: '',
        principal: '',
        premium: '',
        expiry: '',
        exit: false,
        vault: false,
    },
    orderType: 'market',
    yieldType: 'fixed',
    tokenType: 'underlying',
    fillPreviewPending: false,
};

export const machineOptions: Partial<MachineOptions<OrderContext, OrderEvent>> = {
    actions: {
        INIT: assign((context, event) => ({
            ...context,
            ...(event as OrderInitEvent).payload,
        })),
        SET_MARKET: assign((context, event) => ({
            ...context,
            order: {
                ...context.order,
                // different markets have different token decimals which distort the amounts - better to reset
                principal: '',
                premium: '',
            },
            market: (event as OrderMarketEvent).payload,
            balances: undefined,
            token: undefined,
            // different markets have different token decimals which distort the amounts - better to reset
            volume: '',
            fillPreview: undefined,
            signature: undefined,
            error: undefined,
        })),
        SET_TOKEN: assign((context, event) => {

            const { tokenType } = (event as OrderTokenEvent).payload;

            return {
                ...context,
                order: {
                    ...context.order,
                    // update the exit/vault flags
                    ...inferExitVault(tokenType, context.yieldType),
                },
                ...(event as OrderTokenEvent).payload,
                fillPreview: undefined,
                signature: undefined,
                error: undefined,
            };
        }),
        SET_YIELD: assign((context, event) => {

            const yieldType = (event as OrderYieldEvent).payload;

            return {
                ...context,
                order: {
                    ...context.order,
                    // update the exit/vault flags
                    ...inferExitVault(context.tokenType, yieldType),
                },
                yieldType: (event as OrderYieldEvent).payload,
                fillPreview: undefined,
                signature: undefined,
                error: undefined,
            };
        }),
        SET_TYPE: assign((context, event) => ({
            ...context,
            orderType: (event as OrderTypeEvent).payload,
            fillPreview: undefined,
            signature: undefined,
            error: undefined,
        })),
        SET_VOLUME: assign((context, event) => ({
            ...context,
            volume: (event as OrderVolumeEvent).payload,
            // when setting a new volume, clear the previous `fillPreview`
            fillPreview: undefined,
            error: undefined,
        })),
        REQUEST_PREVIEW: assign((context) => ({
            ...context,
            fillPreviewPending: true,
            fillPreview: undefined,
            error: undefined,
        })),
        SET_PREVIEW: assign((context, event) => ({
            ...context,
            fillPreviewPending: false,
            fillPreview: (event as OrderPreviewEvent).payload,
            error: undefined,
        })),
        SET_PREVIEW_FAILURE: assign((context, event) => ({
            ...context,
            fillPreviewPending: false,
            fillPreview: undefined,
            error: (event as OrderPreviewFailureEvent).payload.message,
        })),
        UPDATE_ORDER: assign((context, event) => ({
            ...context,
            order: {
                ...context.order,
                ...(event as OrderUpdateEvent).payload,
            },
            fillPreview: undefined,
            signature: undefined,
            error: undefined,
        })),
        RESET_ORDER: assign((context) => ({
            ...context,
            order: {
                ...initialContext.order,
                // we don't reset tokenType and yieldType, so we need to infer the exit/vault flags
                ...inferExitVault(context.tokenType, context.yieldType),
            },
            volume: undefined,
            fillPreview: undefined,
            signature: undefined,
            error: undefined,
        })),
        FINALIZE_ORDER: assign((context) => {

            const market = context.market as Market;
            const maker = context.maker as string;

            const order: Order = finalizeOrder({
                ...context.order,
                maker,
                protocol: market.protocol,
                underlying: market.underlying,
                maturity: market.maturity,
            });

            logger.log('FINALIZE_ORDER: ', order);

            return { ...context, order };
        }),
        CHECK_ALLOWANCE_SUCCESS: assign((context) => ({
            ...context,
            error: undefined,
        })),
        CHECK_ALLOWANCE_FAILURE: assign((context, event) => ({
            ...context,
            error: (event as DoneInvokeEvent<Error>).data.message,
        })),
        SIGN_ORDER_SUCCESS: assign((context, event) => ({
            ...context,
            signature: (event as DoneInvokeEvent<string>).data,
            error: undefined,
        })),
        SIGN_ORDER_FAILURE: assign((context, event) => ({
            ...context,
            signature: undefined,
            error: (event as DoneInvokeEvent<Error>).data.message,
        })),
        SUBMIT_ORDER_SUCCESS: assign((context) => ({
            ...context,
            signature: undefined,
            error: undefined,
        })),
        SUBMIT_ORDER_FAILURE: assign((context, event) => ({
            ...context,
            error: (event as DoneInvokeEvent<Error>).data.message,
        })),
    },
    guards: {
        // the order becomes interactive if these context props are set
        isInteractive: (context) => !!context.market && !!context.token && !!context.balances,
        // this guard is used in interactive state to transition to limit or market order state
        isLimit: (context) => context.orderType === 'limit',
        // the order becomes valid if `principal` and `premium` are set
        // the rest is added by the `FINALIZE_ORDER` action
        isValid: (context) => ((context.orderType === 'market') && !emptyOrZero(context.volume)) && !locked(context.market!)
            || ((context.orderType === 'limit') && !emptyOrZero(context.order.principal) && !emptyOrZero(context.order.premium)) && !locked(context.market!),
        // we should fetch a `fillPreview` if we have a `volume` but no `fillPreview` or `error`
        shouldPreview: (context) => !emptyOrZero(context.volume) && !context.fillPreview && !context.error && !locked(context.market!),
        // an order has changed, when any props in the update event differ from the current order state
        hasChanged: (context, event) => {
            // get the update payload from the event
            const data = (event as OrderUpdateEvent).payload;
            // find an entry in the update that has changed
            const hasChanged = Object.entries(data).some(([key, value]) => {
                // compare the update values to the order in the context
                return context.order[key as keyof Order] !== value;
            });
            return hasChanged;
        },
        // a market order can be submitted if it has a fillPreview and no fillPreview request is pending
        // no order can be submitted if the market is locked
        canSubmit: (context) => ((context.orderType === 'market') && !!context.fillPreview && !context.fillPreviewPending && !locked(context.market!))
            || ((context.orderType === 'limit') && !emptyOrZero(context.order.principal) && !emptyOrZero(context.order.premium) && !locked(context.market!)),
    },
    services: {
        // a callback based service allows us to receive and send events from and to the state machine
        // we can explicitly send an `ORDER.PREVIEW` event to also update the orderbook state machine
        // additionally, it allows us to leave the state machine in `valid` or `invalid` state while
        // the service is fetching data - we don't need an intermediate state with duplicate transitions
        // this in turn allows the service to debounce/queue requests for the `fillPreview`
        fillPreviewService: () => (callback, onReceive: Receiver<OrderEvent>) => {

            let request: Promise<unknown> | undefined;
            let payload: OrderPreviewRequestEvent['payload'] | undefined;

            const fetchFillPreview = async () => {

                // extract payload from closure
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const { order, market, volume } = payload!;

                // clear the payload after extracting it, so we can know if a new payload was added during the fetch
                payload = undefined;

                try {

                    const result = await swivelApi.fillPreview(market.protocol, market.underlying, market.maturity, volume, order.vault, order.exit);

                    callback({ type: 'ORDER.PREVIEW', payload: result });

                } catch (error) {

                    // a BAD_REQUEST error means the volume passed to the `fillPreview` exceeds
                    // the available volume of the orderbook - we should inform the user
                    const processedError
                        = errors.is(error, ERRORS.SERVICES.SWIVEL.FILL_PREVIEW_EXCEEDED)
                            || errors.is(error, ERRORS.SERVICES.SWIVEL.FILL_PREVIEW_ORDERS_EXCEEDED)
                            ? error as ProcessedError
                            : errors.process(error, ERRORS.STATE.ORDER.FILL_PREVIEW);

                    callback({ type: 'ORDER.PREVIEW.FAILURE', payload: processedError });
                }

                // if there's a new payload available, fetch again, otherwise clear the request promise
                request = payload ? fetchFillPreview() : undefined;
            };

            onReceive(debounce(event => {

                if (event.type === 'ORDER.PREVIEW.REQUEST') {

                    // store the payload in the closure (a "latest payload wins"-queue)
                    payload = event.payload;

                    // if no request is currently pending, create one and store it in the closure
                    if (!request) request = fetchFillPreview();
                }
            }, 500));
        },
        checkAllowance: async (context): Promise<void> => {

            const { maker, order, orderType, market, volume, swivel } = context;

            // don't check allowance for `zcTokenExit` (selling zcTokens) and `vaultExit` (selling nTokens)
            if (order.exit === true) return;

            // depending on the order type, we need to check the order amount against the allowance
            const amount = (orderType === 'market')
                ? volume
                : inferAmount(order);

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const address = market!.tokens[inferTokenType(order)].address;

            let allowance: string;

            try {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                allowance = await token.allowance(address, maker!, swivel!.address);

            } catch (error) {

                throw errors.process(error, ERRORS.STATE.ORDER.ALLOWANCE);
            }

            const allowanceSufficient = ethers.BigNumber.from(amount).lte(allowance);

            if (!allowanceSufficient) {

                const notification = notifications.show({
                    type: 'progress',
                    content: 'Waiting for approval...',
                    dismissable: false,
                });

                try {
                    // ask for "unlimited" approval, so we don't have to approve every next transaction
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    await token.approve(address, swivel!.address, ethers.constants.MaxUint256);

                    notifications.update(notification, {
                        content: 'Approval successful.',
                        type: 'success',
                        dismissable: true,
                    });

                } catch (error) {

                    const processedError = errors.isProcessed(error) ? error : errors.process(error);

                    notifications.update(notification, {
                        content: `Approval failed: ${ processedError.message }`,
                        type: 'failure',
                        dismissable: true,
                        timeout: 10,
                    });

                    throw errors.process(processedError, ERRORS.STATE.ORDER.APPROVAL);
                }
            }
        },
        checkPrice: async (context): Promise<void> => {

            const { market, order, orderType } = context;

            // we need a market to check for outsized orders - the state machine
            // should only invoke this service when a market is selected
            if (!market) return;

            // check if the current order is outsized
            const outsized = checkOutsized(order, market, orderType);

            // if the warning dialog is disabled we consider it confirmed by default
            let confirmed = true;

            // check preferences if warning is disabled
            const disabled = preferences.get('disableOversizedWarning') === true;

            // show warning modal and await confirmation/cancellation
            if (outsized && !disabled) {

                confirmed = await dialogs.prompt(new OversizedOrderDialogElement(outsized)) ?? false;
            }

            // send custom event to fullstory
            if (outsized) {

                const eventName = ANALYTICS.OUTSIZED.NAME;
                const eventData = ANALYTICS.OUTSIZED.EVENT({
                    orderKey: order.key,
                    orderPrice: outsized.currentTradePrice,
                    lastTradePrice: outsized.lastTradePrice,
                    warningShown: !disabled,
                    warningIgnored: confirmed === true,
                });

                try {

                    analytics.gtag('event', eventName, eventData);

                } catch (error) {

                    logger.log(eventName, eventData);
                    logger.warn((error as Error).message);
                }
            }

            // resolve/reject the service promise
            if (outsized && !confirmed) throw new Error('Outprized order cancelled.');
        },
        signOrder: async (context): Promise<string> => {

            const swivel = context.swivel as Swivel;
            const order = context.order;

            try {

                return await swivel.signOrder(order);

            } catch (error) {

                const processedError = errors.process(error);
                const override = errors.updateMessage(ERRORS.STATE.ORDER.SIGN, [[/\.$/, `: ${ processedError.message }`]]);

                throw errors.process(error, override);
            }
        },
        submitOrder: async (context): Promise<unknown> => {

            const { market, order, orderType, tokenType, signature, volume } = context;

            let result: unknown;

            if (orderType === 'limit') {

                const notification = notifications.show({
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    content: () => orderNotification(order, market!, inferAmount(order), inferPrice(order, market!), 'progress'),
                    type: 'progress',
                    dismissable: false,
                });

                try {
                    // limit orders are created through the swivel API
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    result = await swivelApi.submitOrder(order, signature!);

                    notifications.update(notification, {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        content: () => orderNotification(order, market!, inferAmount(order), inferPrice(order, market!), 'success'),
                        type: 'success',
                        dismissable: true,
                    });

                } catch (error) {

                    const processedError = errors.isProcessed(error) ? error : errors.process(error);

                    notifications.update(notification, {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        content: () => orderNotification(order, market!, inferAmount(order), inferPrice(order, market!), 'failure', processedError.message),
                        type: 'failure',
                        dismissable: true,
                        timeout: 10,
                    });

                    throw errors.process(processedError, ERRORS.STATE.ORDER.SUBMIT);
                }

            } else {

                // market orders are filled through the swivel HOC
                const swivel = context.swivel as Swivel;
                const fillPreview = context.fillPreview as FillPreview;

                const orders = fillPreview.orders.map(orderMeta => orderMeta.order);
                const amounts = fillPreview.orders.map(orderMeta => orderMeta.meta.previewFill);
                const signatures = fillPreview.orders.map(orderMeta => orderMeta.meta.signature);

                logger.log('fill market orders: ', orders, amounts, signatures);

                const notification = notifications.show({
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    content: () => orderNotification(order, market!, volume!, fillPreview.effectivePrice, 'progress'),
                    type: 'progress',
                    dismissable: false,
                });

                try {

                    // if the tokenType is not `underlying`, we have `zcTokenExit` or `vaultExit`
                    const tx = (tokenType === 'underlying')
                        ? await swivel.initiate(orders, amounts, signatures)
                        : await swivel.exit(orders, amounts, signatures);

                    result = await confirmTransaction(tx);
                    const txnHash = (result as ethers.providers.TransactionReceipt).transactionHash;

                    notifications.update(notification, {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        content: () => orderNotification(order, market!, volume!, fillPreview.effectivePrice, 'success', txnHash),
                        type: 'success',
                        dismissable: true,
                    });

                } catch (error) {

                    const processedError = errors.isProcessed(error) ? error : errors.process(error);

                    notifications.update(notification, {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        content: () => orderNotification(order, market!, volume!, fillPreview.effectivePrice, 'failure', processedError.message),
                        type: 'failure',
                        dismissable: true,
                        timeout: 10,
                    });

                    throw errors.process(processedError, ERRORS.STATE.ORDER.SUBMIT);
                }
            }

            return result;
        },
    },
};

export const machine = createMachine<OrderContext, OrderEvent, OrderState>({
    context: initialContext,
    initial: 'initial',
    states: {
        initial: {
            always: {
                target: 'interactive',
                cond: 'isInteractive',
            },
            on: {
                ...init,
                ...setMarket,
                ...setToken,
            },
        },
        interactive: {
            initial: 'initial',
            on: {
                ...setMarket,
                ...setToken,
                ...setYield,
                ...setType,
            },
            states: {
                initial: {
                    always: [
                        {
                            target: 'limit',
                            cond: 'isLimit',
                        },
                        {
                            target: 'market',
                        },
                    ],
                },
                market: {
                    initial: 'invalid',
                    invoke: {
                        id: 'fillPreviewService',
                        src: 'fillPreviewService',
                    },
                    on: {
                        ...requestPreview,
                        ...setPreview,
                        ...previewFailure,
                    },
                    states: {
                        invalid: {
                            always: {
                                target: 'previewing',
                                cond: 'shouldPreview',
                            },
                            on: {
                                ...setVolume,
                                ...update,
                                ...reset,
                            },
                        },
                        valid: {
                            on: {
                                ...setVolume,
                                ...update,
                                ...reset,
                                'ORDER.SUBMIT': {
                                    target: 'allowance',
                                    cond: 'canSubmit',
                                },
                            },
                        },
                        previewing: {
                            // invoke the callback based fillPreviewService on entry
                            entry: [
                                send((context): OrderPreviewRequestEvent => ({
                                    type: 'ORDER.PREVIEW.REQUEST',
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    payload: { market: context.market!, order: context.order, volume: context.volume! },
                                })),
                                send(
                                    (context): OrderPreviewRequestEvent => ({
                                        type: 'ORDER.PREVIEW.REQUEST',
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        payload: { market: context.market!, order: context.order, volume: context.volume! },
                                    }),
                                    { to: 'fillPreviewService' },
                                ),
                            ],
                            // go right back to valid state (we only get here if the state was valid)
                            always: {
                                target: 'valid',
                            },
                        },
                        allowance: {
                            invoke: {
                                id: 'checkAllowance',
                                src: 'checkAllowance',
                                onDone: {
                                    target: 'pending',
                                    actions: 'CHECK_ALLOWANCE_SUCCESS',
                                },
                                onError: {
                                    target: 'valid',
                                    actions: 'CHECK_ALLOWANCE_FAILURE',
                                },
                            },
                        },
                        pending: {
                            invoke: {
                                id: 'submitOrder',
                                src: 'submitOrder',
                                onDone: {
                                    target: 'submitted',
                                    actions: 'SUBMIT_ORDER_SUCCESS',
                                },
                                onError: {
                                    target: 'submitted',
                                    actions: 'SUBMIT_ORDER_FAILURE',
                                },
                            },
                        },
                        submitted: {
                            entry: send({ type: 'ORDER.RESET' }),
                            on: {
                                ...setVolume,
                                ...update,
                                ...reset,
                            },
                        },
                    },
                },
                limit: {
                    initial: 'invalid',
                    states: {
                        invalid: {
                            always: {
                                target: 'valid',
                                cond: 'isValid',
                            },
                            on: {
                                ...update,
                                ...reset,
                            },
                        },
                        valid: {
                            on: {
                                ...update,
                                ...reset,
                                'ORDER.SUBMIT': {
                                    actions: 'FINALIZE_ORDER',
                                    target: 'allowance',
                                    cond: 'canSubmit',
                                },
                            },
                        },
                        allowance: {
                            invoke: {
                                id: 'checkAllowance',
                                src: 'checkAllowance',
                                onDone: {
                                    target: 'price',
                                    actions: 'CHECK_ALLOWANCE_SUCCESS',
                                },
                                onError: {
                                    target: 'valid',
                                    actions: 'CHECK_ALLOWANCE_FAILURE',
                                },
                            },
                        },
                        price: {
                            invoke: {
                                id: 'checkPrice',
                                src: 'checkPrice',
                                onDone: {
                                    target: 'signing',
                                },
                                onError: {
                                    target: 'valid',
                                },
                            },
                        },
                        signing: {
                            invoke: {
                                id: 'signOrder',
                                src: 'signOrder',
                                onDone: {
                                    target: 'signed',
                                    actions: 'SIGN_ORDER_SUCCESS',
                                },
                                onError: {
                                    target: 'invalid',
                                    actions: 'SIGN_ORDER_FAILURE',
                                },
                            },
                        },
                        signed: {
                            // when reaching signed state, everything worked as expected
                            // go straight through to submitting the order
                            always: {
                                target: 'pending',
                            },
                        },
                        pending: {
                            invoke: {
                                id: 'submitOrder',
                                src: 'submitOrder',
                                onDone: {
                                    target: 'submitted',
                                    actions: 'SUBMIT_ORDER_SUCCESS',
                                },
                                onError: {
                                    target: 'submitted',
                                    actions: 'SUBMIT_ORDER_FAILURE',
                                },
                            },
                        },
                        submitted: {
                            entry: send({ type: 'ORDER.RESET' }),
                            on: {
                                ...update,
                                ...reset,
                            },
                        },
                    },
                },
            },
        },
    },
}, machineOptions);
