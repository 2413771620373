import { task } from '@swivel-finance/ui/utils/async';
import { cancel, dispatch } from '@swivel-finance/ui/utils/events';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { createRef, ref } from 'lit-html/directives/ref.js';
import { ENV } from '../../env/environment';
import { compareAmounts, contractAmount, empty, emptyOrZero, expandAmount, fixed, maturityDays } from '../../helpers';
import { errors } from '../../services';
import { SafetyModuleService, SafetyModuleState, SafetyModuleTokens } from '../../services/safety-module';
import { serviceLocator } from '../../services/service-locator';
import { TIME_SERVICE } from '../../services/time';
import { TokenInputChangeEvent, notifications } from '../../shared/components';
import { amount, amountLabel, balance, status, tokenBalance, tokenImage, tokenSymbol, unit } from '../../shared/templates';
import { RequestRewardsEvent } from '../rewards/events';
import { StakingAmounts, approvalNotification, cooldownNotification, stakingNotification, unstakingNotification } from './notifications';

const emptyAmount = function (u: string) {

    return html`<span class="amount"><span class="value">--</span>${ unit(u) }</span>`;
};

const heroTemplate = function (this: StakeElement) {

    const state = this.safetyModule?.state;
    const isFetching = state?.isFetching ?? true;

    return html`
    <div class="hero-notification" ${ ref(this.hero) }>
        <div class="bubbles bubbles-far">
            <div class="bubble bubble-1"></div>
            <div class="bubble bubble-2"></div>
            <div class="bubble bubble-3"></div>
            <div class="bubble bubble-4"></div>
            <div class="bubble bubble-5"></div>
        </div>
        <div class="bubbles bubbles-mid">
            <div class="bubble bubble-1"></div>
            <div class="bubble bubble-2"></div>
            <div class="bubble bubble-3"></div>
            <div class="bubble bubble-4"></div>
            <div class="bubble bubble-5"></div>
        </div>
        <div class="bubbles bubbles-near">
            <div class="bubble bubble-1"></div>
            <div class="bubble bubble-2"></div>
            <div class="bubble bubble-3"></div>
        </div>
        <div class="notification-content">
            <h2>Stake your Swivel Tokens</h2>
            <p>
                ${ unit('SWIV') } holders (Ethereum network only) can stake their ${ unit('SWIV') } in the Safety Module
                to add more security to the protocol and earn Safety Incentives.
            </p>
            <p>
                <a href="${ this.docsUrl }" target="_blank">Learn more about risks involved.</a>
            </p>
            <div class="apr">
                <ul>
                    <li class="highlight">
                        <span class="label">Staking APR:</span>
                        ${ isFetching || !state
                            ? status('loading')
                            : html`
                            <ui-icon name="info" aria-describedby="apr-tooltip"></ui-icon>
                            <ui-tooltip id="apr-tooltip" class="staking-apr-tooltip">
                                <p>
                                    The Staking APR is comprised of:
                                </p>
                                <ul>
                                    <li>
                                        <span class="label">Fees:</span>
                                        ${ empty(state.aprs.fees)
                                            ? emptyAmount('%')
                                            : amount(fixed(state.aprs.fees).mulUnsafe(fixed(100)).toString(), '%', 3, true)
                                        }
                                    </li>
                                    <li>
                                        <span class="label">Pool APR:</span>
                                        ${ empty(state.aprs.pool)
                                            ? emptyAmount('%')
                                            : amount(fixed(state.aprs.pool).mulUnsafe(fixed(100)).toString(), '%', 3, true)
                                        }
                                    </li>
                                    <li>
                                        <span class="label">SSM Incentives:</span>
                                        ${ empty(state.aprs.incentives)
                                            ? emptyAmount('%')
                                            : amount(fixed(state.aprs.incentives).mulUnsafe(fixed(100)).toString(), '%', 3, true)
                                        }
                                    </li>
                                </ul>
                            </ui-tooltip>
                            ${ empty(state.aprs.staking)
                                ? emptyAmount('%')
                                : amount(fixed(state.aprs.staking).mulUnsafe(fixed(100)).toString(), '%', 3, true)
                            }
                            `
                        }
                    </li>
                    <li>
                        <span class="label">Your balances:</span>
                    </li>
                    <li>
                        <span class="label">
                            <ui-icon name="ethereum"></ui-icon>
                            ${ tokenSymbol(SafetyModuleTokens.ETH) }
                        </span>
                        ${ isFetching || !state
                            ? status('loading')
                            : balance(state.balances.ETH, SafetyModuleTokens.ETH)
                        }
                    </li>
                    <li>
                        <span class="label">
                            ${ tokenImage(SafetyModuleTokens.SWIV) }
                            ${ tokenSymbol(SafetyModuleTokens.SWIV) }
                        </span>
                        ${ isFetching || !state
                            ? status('loading')
                            : balance(state.balances.SWIV, SafetyModuleTokens.SWIV)
                        }
                    </li>
                    <li>
                        <span class="label">
                            ${ tokenImage(SafetyModuleTokens.stkSWIV) }
                            ${ tokenSymbol(SafetyModuleTokens.stkSWIV) }
                        </span>
                        ${ isFetching || !state
                            ? status('loading')
                            : balance(state.balances.stkSWIV, SafetyModuleTokens.stkSWIV)
                        }
                    </li>
                </ul>
            </div>
        </div>
    </div>
    `;
};

const template = function (this: StakeElement) {

    const state = this.safetyModule?.state;
    const isFetching = state?.isFetching ?? true;

    const now = parseInt(this.timeService.timestamp());

    const maxStakeableSWIV = this.safetyModule?.getMaxStakeableSWIV() ?? '0';
    const maxQueueableSWIV = this.safetyModule?.getMaxQueueableSWIV() ?? '0';
    const maxWithdrawableSWIV = state?.cooldownBalances.SWIV ?? '0';

    const canStake = !!state && compareAmounts(maxStakeableSWIV, '0', SafetyModuleTokens.SWIV) > 0;
    const hasStaked = !!state && compareAmounts(state.balances.stkSWIV, '0', SafetyModuleTokens.stkSWIV) > 0;

    const canUnstake = hasStaked && compareAmounts(maxQueueableSWIV, '0', SafetyModuleTokens.SWIV) > 0;
    const hasCooldown = !!state && compareAmounts(state.cooldownBalances.SWIV, '0', SafetyModuleTokens.SWIV) > 0;

    const canWithdraw = !!state
        && hasCooldown
        && now >= state.cooldownTime
        && now <= state.cooldownTime + state.withdrawalWindow;

    return html`
    ${ heroTemplate.call(this) }

    <div class="stake block">
        <h2>Stake</h2>
        ${ this.mode === 'stake'
            ? html`
            <sw-token-input
                .label=${ 'Amount' }
                .token=${ SafetyModuleTokens.SWIV }
                .value=${ contractAmount(this.stakingAmounts.SWIV, SafetyModuleTokens.SWIV) }
                .balance=${ contractAmount(maxStakeableSWIV, SafetyModuleTokens.SWIV) }
                .showBalance=${ true }
                .max=${ contractAmount(maxStakeableSWIV, SafetyModuleTokens.SWIV) }
                .disabled=${ !canStake || this.isStaking || isFetching }
                @change=${ (event: TokenInputChangeEvent) => this.handleStakingAmountChange(event) }></sw-token-input>

            <ul class="preview">
                <li class="preview-item">
                    <span class="label">SWIV Staked</span>
                    ${ isFetching || !state
                        ? status('loading')
                        : tokenBalance(this.stakingAmounts.SWIV, SafetyModuleTokens.SWIV)
                    }
                </li>
                <li class="preview-item">
                    <span class="label">ETH Staked</span>
                    ${ isFetching || !state
                        ? status('loading')
                        : tokenBalance(this.stakingAmounts.ETH, SafetyModuleTokens.ETH)
                    }
                </li>
            </ul>

            <div class="actions">
                <button @click=${ () => this.handleModeChange('dashboard') }>Cancel</button>
                <button class="primary"
                    ?disabled=${ emptyOrZero(this.stakingAmounts.SWIV) || this.isStaking || isFetching }
                    @click=${ () => this.handleStake() }>
                    ${ hasStaked ? 'Stake More' : 'Stake' }
                </button>
            </div>
            `
            : html`
            <p>Your staked amounts</p>
            <ul class="position">
                <li>
                    ${ amountLabel('Amount') }
                    ${ isFetching || !state
                        ? status('loading')
                        : tokenBalance(state.stakedBalances.SWIV, SafetyModuleTokens.SWIV)
                    }
                </li>
                <li>
                    ${ amountLabel('') }
                    ${ isFetching || !state
                        ? status('loading')
                        : tokenBalance(state.stakedBalances.WETH, SafetyModuleTokens.ETH)
                    }
                </li>
            </ul>
            <button class="primary"
                aria-describedby="stake-tooltip"
                ?disabled=${ !canStake || this.mode === 'unstake' }
                @click=${ () => this.handleModeChange('stake') }>
                ${ hasStaked ? 'Stake More' : 'Stake' }
                <ui-icon name="info"></ui-icon>
            </button>
            <ui-tooltip id="stake-tooltip">
                <p>
                    By adding ${ SafetyModuleTokens.SWIV.symbol } and ${ SafetyModuleTokens.ETH.symbol } to the Swivel
                    Safety Module, you help securing Swivel from shortfall events and earn rewards in exchange.
                </p>
                <p>
                    <a href="${ this.docsUrl }" target="_blank">Visit our docs for more information</a>
                     <br>or <br>
                     <a href="#" @click=${ (event: Event) => this.handleRequestRewards(event) }>see your current staking rewards</a>.
                </p>
            </ui-tooltip>
            `
        }
    </div>

    <div class="unstake block">
        <h2>Withdraw</h2>
        ${ this.mode === 'unstake'
            ? html`
            <sw-token-input
                .label=${ 'Amount' }
                .token=${ SafetyModuleTokens.SWIV }
                .value=${ contractAmount(canWithdraw ? this.withdrawalAmounts.SWIV : this.cooldownAmounts.SWIV, SafetyModuleTokens.SWIV) }
                .balance=${ contractAmount(canWithdraw ? maxWithdrawableSWIV : maxQueueableSWIV, SafetyModuleTokens.SWIV) }
                .showBalance=${ true }
                .max=${ contractAmount(canWithdraw ? maxWithdrawableSWIV : maxQueueableSWIV, SafetyModuleTokens.SWIV) }
                .disabled=${ !canWithdraw && !hasStaked || this.isWithdrawing || this.isQueueing || isFetching }
                @change=${ (event: TokenInputChangeEvent) => canWithdraw ? this.handleWithdrawalAmountChange(event) : this.handleCooldownAmountChange(event) }></sw-token-input>

            <ul class="preview">
                <li class="preview-item">
                    <span class="label">${ canWithdraw ? 'SWIV Withdrawn' : 'SWIV Queued' }</span>
                    ${ isFetching || !state
                        ? status('loading')
                        : tokenBalance(canWithdraw ? this.withdrawalAmounts.SWIV : this.cooldownAmounts.SWIV, SafetyModuleTokens.SWIV)
                    }
                </li>
                <li class="preview-item">
                    <span class="label">${ canWithdraw ? 'ETH Withdrawn' : 'ETH Queued' }</span>
                    ${ isFetching || !state
                        ? status('loading')
                        : tokenBalance(canWithdraw ? this.withdrawalAmounts.ETH : this.cooldownAmounts.ETH, SafetyModuleTokens.ETH)
                    }
                </li>
            </ul>

            ${ hasCooldown && canUnstake && !canWithdraw
                    ? html`
                <div class="info">
                    <span class="label"></span>
                    <span class="value"><ui-icon name="exclamation"></ui-icon> This will reset your existing cooldown!</span>
                </div>
                `
                    : nothing
            }

            <div class="actions">
                <button @click=${ () => this.handleModeChange('dashboard') }>Cancel</button>
                <button class="primary"
                    ?disabled=${ !canWithdraw && emptyOrZero(this.cooldownAmounts.SWIV) || canWithdraw && emptyOrZero(this.withdrawalAmounts.SWIV) || this.isQueueing || this.isWithdrawing || isFetching }
                    @click=${ () => canWithdraw ? this.handleUnstake() : this.handleCooldown() }>
                    ${ hasCooldown && (canWithdraw || !canUnstake) ? 'Withdraw' : hasCooldown ? 'Queue More For Withdrawal' : 'Queue For Withdrawal' }
                </button>
            </div>
            `
            : html`
            <p>Your amounts scheduled for withdrawal</p>
            <ul class="position">
                <li>
                    ${ amountLabel('Amount') }
                    ${ isFetching || !state
                        ? status('loading')
                        : tokenBalance(state.cooldownBalances.SWIV, SafetyModuleTokens.SWIV)
                    }
                </li>
                <li>
                    ${ amountLabel('') }
                    ${ isFetching || !state
                        ? status('loading')
                        : tokenBalance(state.cooldownBalances.WETH, SafetyModuleTokens.ETH)
                    }
                </li>
            </ul>
            ${ hasCooldown
                    ? html`
                <div class="info">
                    <span class="label">Ready to withdraw:</span>
                    <span class="value">${ canWithdraw ? 'now' : `${ maturityDays(state.cooldownTime.toString()) }d` }</span>
                </div>
                `
                    : nothing
                }
            ${ !canWithdraw
                    ? html`
                <div class="info">
                    <span class="label">Cooldown period:</span>
                    <span class="value">${ maturityDays((now + (state?.cooldownDuration ?? 0)).toString()) }d</span>
                </div>
                <div class="info">
                    <span class="label">Withdrawal period:</span>
                    <span class="value">${ maturityDays((now + (state?.withdrawalWindow ?? 0)).toString()) }d</span>
                </div>
                `
                    : nothing
                }
            <button class="primary"
                aria-describedby="unstake-tooltip"
                ?disabled=${ !canUnstake && !canWithdraw || this.mode === 'stake' }
                @click=${ () => this.handleModeChange('unstake') }>
                ${ hasCooldown && (canWithdraw || !canUnstake) ? 'Withdraw' : hasCooldown ? 'Queue More For Withdrawal' : 'Queue For Withdrawal' }
                <ui-icon name="info"></ui-icon>
            </button>
            <ui-tooltip id="unstake-tooltip">
                <p>
                    To withdraw your staked tokens, you must first queue them for withdrawal. The cooldown period for
                    withdrawal is ${ maturityDays((now + (state?.cooldownDuration ?? 0)).toString()) } days.
                    During this time, you continue to earn rewards, but you cannot withdraw your tokens yet.
                    After the cooldown period, you can withdraw your tokens within a
                    ${ maturityDays((now + (state?.withdrawalWindow ?? 0)).toString()) } days window.
                </p>
                <p><a href="${ this.docsUrl }" target="_blank">Visit our docs for more information.</a></p>
            </ui-tooltip>
            `
        }
    </div>
    `;
};

@customElement('sw-stake')
export class StakeElement extends LitElement {

    protected hero = createRef<HTMLElement>();

    protected timeService = serviceLocator.get(TIME_SERVICE);

    protected safetyModule?: SafetyModuleService;

    @state()
    protected docsUrl = `${ ENV.docsUrl }swivel-safety-module-ssm`;

    @state()
    protected mode: 'dashboard' | 'stake' | 'unstake' = 'dashboard';

    @state()
    protected stakingAmounts = {
        SWIV: '0',
        ETH: '0',
    };

    @state()
    protected cooldownAmounts = {
        SWIV: '0',
        ETH: '0',
    };

    @state()
    protected withdrawalAmounts = {
        SWIV: '0',
        ETH: '0',
    };

    @state()
    protected isStaking = false;

    @state()
    protected isQueueing = false;

    @state()
    protected isWithdrawing = false;

    // we set a static refresh interval of 1 minute to keep data up to date
    protected refreshInterval = 60000;

    // we store the last refresh timestamp
    protected lastRefresh = 0;

    constructor () {

        super();

        this.handleStateChange = this.handleStateChange.bind(this);

        void this.initialize();
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.safetyModule?.unsubscribe(this.handleStateChange);

        super.disconnectedCallback();
    }

    protected async initialize (): Promise<void> {

        this.safetyModule = await SafetyModuleService.getInstance();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.safetyModule.subscribe(this.handleStateChange);

        await this.refresh();
    }

    protected async refresh (): Promise<void> {

        await this.safetyModule?.fetch();

        this.lastRefresh = Date.now();
    }

    protected reset (): void {

        this.stakingAmounts = {
            SWIV: '0',
            ETH: '0',
        };

        this.cooldownAmounts = {
            SWIV: '0',
            ETH: '0',
        };

        this.withdrawalAmounts = {
            SWIV: '0',
            ETH: '0',
        };

        this.mode = 'dashboard';
    }

    protected shouldRefresh (): boolean {

        return Date.now() - this.lastRefresh > this.refreshInterval;
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected firstUpdated (): void {

        void this.showHero();
    }

    protected async showHero (): Promise<void> {

        await this.updateComplete;

        await task(() => this.hero.value?.classList.add('ui-visible')).done;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected handleStateChange (state: Readonly<SafetyModuleState>): void {

        this.requestUpdate();
    }

    protected handleRequestRewards (event?: Event): void {

        if (event) cancel(event);

        dispatch(document.body, new RequestRewardsEvent());
    }

    protected async handleModeChange (mode: 'dashboard' | 'stake' | 'unstake'): Promise<void> {

        if (mode === 'dashboard') {

            this.reset();
        }

        this.mode = mode;

        if (this.shouldRefresh()) await this.refresh();

        if (mode === 'unstake') {

            this.withdrawalAmounts = {
                SWIV: this.safetyModule?.state.cooldownBalances.SWIV ?? '0',
                ETH: this.safetyModule?.state.cooldownBalances.WETH ?? '0',
            };
        }
    }

    protected async handleStakingAmountChange (event: TokenInputChangeEvent): Promise<void> {

        const swivAmount = expandAmount(event.detail.value || '0', SafetyModuleTokens.SWIV);

        // make sure we're up to date
        if (this.shouldRefresh()) await this.refresh();

        // make sure we don't stake more than we can
        const maxAmount = this.safetyModule?.getMaxStakeableSWIV() ?? '0';

        const amount = compareAmounts(swivAmount, maxAmount, SafetyModuleTokens.SWIV) > 0
            ? maxAmount
            : swivAmount;

        this.stakingAmounts = {
            SWIV: amount,
            ETH: this.safetyModule?.getProportionalETH(amount) ?? '0',
        };
    }

    protected async handleWithdrawalAmountChange (event: TokenInputChangeEvent): Promise<void> {

        const swivAmount = expandAmount(event.detail.value || '0', SafetyModuleTokens.SWIV);

        // make sure we're up to date
        if (this.shouldRefresh()) await this.refresh();

        // make sure we don't withdraw more than we can
        const maxAmount = this.safetyModule?.state.cooldownBalances.SWIV ?? '0';

        const tooMuch = compareAmounts(swivAmount, maxAmount, SafetyModuleTokens.SWIV) > 0;

        this.withdrawalAmounts = {
            SWIV: tooMuch ? maxAmount : swivAmount,
            ETH: (tooMuch ? this.safetyModule?.state.cooldownBalances.WETH : this.safetyModule?.getProportionalETH(swivAmount)) ?? '0',
        };
    }

    protected async handleCooldownAmountChange (event: TokenInputChangeEvent): Promise<void> {

        const swivAmount = expandAmount(event.detail.value || '0', SafetyModuleTokens.SWIV);

        // make sure we're up to date
        if (this.shouldRefresh()) await this.refresh();

        // make sure we don't stake more than we can
        const maxAmount = this.safetyModule?.getMaxQueueableSWIV() ?? '0';

        const amount = compareAmounts(swivAmount, maxAmount, SafetyModuleTokens.SWIV) > 0
            ? maxAmount
            : swivAmount;

        this.cooldownAmounts = {
            SWIV: amount,
            ETH: this.safetyModule?.getProportionalETH(amount) ?? '0',
        };
    }

    protected async handleStake (): Promise<void> {

        // TODO: maybe we should move this to the safety module...?
        this.isStaking = true;

        const amount = this.stakingAmounts.SWIV;

        const amounts: StakingAmounts = {
            SWIV: {
                amount: this.stakingAmounts.SWIV,
                token: SafetyModuleTokens.SWIV,
            },
            ETH: {
                amount: this.stakingAmounts.ETH,
                token: SafetyModuleTokens.ETH,
            },
        };

        const notification = notifications.show({
            type: 'progress',
            dismissable: false,
            content: () => approvalNotification('progress', amount, SafetyModuleTokens.SWIV),
        });

        try {

            await this.safetyModule?.approve(amount);

        } catch (error) {

            const processedError = errors.isProcessed(error) ? error : errors.process(error);

            notifications.update(notification, {
                type: 'failure',
                dismissable: true,
                timeout: 10,
                content: () => approvalNotification('failure', amount, SafetyModuleTokens.SWIV, processedError),
            });

            this.isStaking = false;

            await this.refresh();

            return;
        }

        try {

            notifications.update(notification, {
                content: () => stakingNotification('progress', amounts),
            });

            const receipt = await this.safetyModule?.stake(amount);

            notifications.update(notification, {
                type: 'success',
                dismissable: true,
                content: () => stakingNotification('success', amounts, receipt),
            });

        } catch (error) {

            const processedError = errors.isProcessed(error) ? error : errors.process(error);

            notifications.update(notification, {
                type: 'failure',
                dismissable: true,
                timeout: 10,
                content: () => stakingNotification('failure', amounts, processedError),
            });
        }

        this.reset();

        await this.refresh();

        this.isStaking = false;
    }

    protected async handleUnstake (): Promise<void> {

        this.isWithdrawing = true;

        const amounts: StakingAmounts = {
            SWIV: {
                amount: this.withdrawalAmounts.SWIV,
                token: SafetyModuleTokens.SWIV,
            },
            ETH: {
                amount: this.withdrawalAmounts.ETH,
                token: SafetyModuleTokens.ETH,
            },
        };

        const notification = notifications.show({
            type: 'progress',
            dismissable: false,
            content: () => unstakingNotification('progress', amounts),
        });

        try {

            const receipt = await this.safetyModule?.unstake(this.withdrawalAmounts.SWIV);

            notifications.update(notification, {
                type: 'success',
                dismissable: true,
                content: () => unstakingNotification('success', amounts, receipt),
            });

        } catch (error) {

            const processedError = errors.isProcessed(error) ? error : errors.process(error);

            notifications.update(notification, {
                type: 'failure',
                dismissable: true,
                timeout: 10,
                content: () => unstakingNotification('failure', amounts, processedError),
            });
        }

        this.reset();

        await this.refresh();

        this.isWithdrawing = false;
    }

    protected async handleCooldown (): Promise<void> {

        this.isQueueing = true;

        const amounts: StakingAmounts = {
            SWIV: {
                amount: this.cooldownAmounts.SWIV,
                token: SafetyModuleTokens.SWIV,
            },
            ETH: {
                amount: this.cooldownAmounts.ETH,
                token: SafetyModuleTokens.ETH,
            },
        };

        const notification = notifications.show({
            type: 'progress',
            dismissable: false,
            content: () => cooldownNotification('progress', amounts),
        });

        try {

            const receipt = await this.safetyModule?.cooldown(this.cooldownAmounts.SWIV);

            notifications.update(notification, {
                type: 'success',
                dismissable: true,
                content: () => cooldownNotification('success', amounts, receipt),
            });

        } catch (error) {

            const processedError = errors.isProcessed(error) ? error : errors.process(error);

            notifications.update(notification, {
                type: 'failure',
                dismissable: true,
                timeout: 10,
                content: () => cooldownNotification('failure', amounts, processedError),
            });
        }

        this.reset();

        await this.refresh();

        this.isQueueing = false;
    }
}
