import { html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { banners } from './banner-service';
import { Banner, BannerPriority, BannerType } from './types';

const BANNER_ICONS = {
    'info': 'info',
    'warning': 'exclamation',
    'failure': 'exclamation',
};

const bannerTemplate = function (this: BannerElement) {

    const icon = this.icon || BANNER_ICONS[this.type || 'info'];

    const content = (typeof this.content === 'function')
        ? this.content()
        : this.content;

    return html`
    <span class="banner-icon">
        ${ icon
            ? html`<ui-icon name=${ icon }></ui-icon>`
            : ''
        }
    </span>
    <span class="banner-content">${ content }</span>
    <span class="banner-buttons">
        ${ this.dismissable
            // eslint-disable-next-line @typescript-eslint/unbound-method
            ? html`<button class="dismiss" aria-label="dismiss" @click=${ this.dismiss }><ui-icon name="times"></ui-icon></button>`
            : ''
        }
    </span>
    `;
};

@customElement('sw-banner')
export class BannerElement extends LitElement implements Banner {

    protected dismissTimeout: number | undefined;

    @property({
        attribute: true,
        reflect: true,
        type: String,
    })
    type: BannerType | undefined;

    @property({
        attribute: true,
        reflect: true,
        type: String,
    })
    priority: BannerPriority | undefined;

    @property({
        attribute: true,
        reflect: true,
        type: String,
    })
    icon: string | undefined;

    @property({
        attribute: true,
        reflect: true,
        type: Boolean,
    })
    dismissable: boolean | undefined;

    @property({
        attribute: false,
    })
    content: string | (() => TemplateResult) | undefined;

    refresh (b: Banner) {

        Object.entries(b).forEach(([property, value]) => {

            if (value !== undefined && this[property as keyof this] !== value) {

                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                this[property as keyof this] = value;
            }
        });

        this.requestUpdate();
    }

    dismiss () {

        if (this.dismissable) {

            banners.dismiss(this.id);
        }
    }

    connectedCallback () {

        super.connectedCallback();

        this.type = this.type ?? 'info';
        this.priority = this.priority ?? 'medium';
        this.dismissable = this.dismissable ?? false;
    }

    createRenderRoot () {

        return this;
    }

    render () {

        return bannerTemplate.apply(this);
    }
}
