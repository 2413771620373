import { PopupConfig, PopupElement, POPUP_CONFIG_DEFAULT } from '@swivel-finance/ui/elements/popup';
import { dispatch } from '@swivel-finance/ui/utils/events';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { createRef, Ref, ref } from 'lit/directives/ref.js';
import { TOOLTIPS } from '../../constants';
import { contractAmount, expandAmount } from '../../helpers';
import { TokenInputChangeEvent, TokenInputElement } from '../../shared/components';
import { tokenBalance } from '../../shared/templates';
import { PositionActionType } from '../../state/positions';
import { Balances } from '../../types';
import { actionBalance } from './helpers';

export type PositionActionSubmitEvent = CustomEvent<{ amount: string; }>;
export type PositionActionCancelEvent = CustomEvent;

export const positionActionLabels: Record<PositionActionType, string> = {
    split: 'Split',
    combine: 'Combine',
    redeem: 'Redeem',
    redeemInterest: 'Redeem',
};

export const positionActionTooltips: Record<PositionActionType, (b: Balances) => string> = {
    split: TOOLTIPS.POSITIONS.POSITIONS.SPLIT,
    combine: TOOLTIPS.POSITIONS.POSITIONS.COMBINE,
    redeem: TOOLTIPS.POSITIONS.POSITIONS.REDEEM,
    redeemInterest: TOOLTIPS.POSITIONS.POSITIONS.REDEEM_INTEREST,
};

export const positionActionDescriptions: Record<PositionActionType, (b: Balances) => string> = {
    split: (b: Balances) => `Deposit and split your ${ b.underlying.symbol } into ${ b.zcToken.symbol } and ${ b.nToken.symbol }.`,
    combine: (b: Balances) => `Combine equal amounts of ${ b.zcToken.symbol } and ${ b.nToken.symbol } to redeem your ${ b.underlying.symbol } early.`,
    redeem: (b: Balances) => `Redeem your ${ b.underlying.symbol } deposit.`,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    redeemInterest: (b: Balances) => 'Redeem your earned interest.',
};

export const positionActionIcons: Record<PositionActionType, string> = {
    split: 'split',
    combine: 'combine',
    redeem: 'redeem',
    redeemInterest: 'redeem',
};

const POSITION_ACTION_POPUP_CONFIG: PopupConfig = {
    ...POPUP_CONFIG_DEFAULT,
    focus: {
        ...POPUP_CONFIG_DEFAULT.focus,
        trapFocus: true,
    },
    overlay: {
        ...POPUP_CONFIG_DEFAULT.overlay,
        modal: true,
    },
    position: {
        ...POPUP_CONFIG_DEFAULT.position,
        alignment: {
            origin: {
                horizontal: 'center',
                vertical: 'end',
            },
            target: {
                horizontal: 'center',
                vertical: 'start',
            },
            offset: {
                vertical: 'var(--grid-size)',
            },
        },
    },
};

const popupTemplate = function (this: PositionPopup) {

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const balance = actionBalance(this.action, this.balances!);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const description = positionActionDescriptions[this.action](this.balances!);

    const maxAmount = contractAmount(balance.balance, balance);

    return html`
    <label for="${ this.id }-amount">${ description }</label>
    ${ this.action === 'redeemInterest'
        ? html`
        <span class="amount-info">
            <span class="label">Amount:</span>
            ${ tokenBalance(expandAmount(this.amount, balance), balance) }
        </span>
        `
        : html`
        <sw-token-input
            .id=${ this.id + '-amount' }
            .token=${ balance }
            .balance=${ maxAmount }
            .max=${ maxAmount }
            @change=${ (e: TokenInputChangeEvent) => this.handleChange(e) }
            ${ ref(this.inputRef) }></sw-token-input>
        `
    }
    <div class="controls">
        <button type="button" @click=${ () => this.handleCancel() }>Cancel</button>
        <button type="button" class="primary" @click=${ () => this.handleSubmit() }>Confirm</button>
    </div>
    `;
};

const template = function (this: PositionPopup) {

    return html`
    <ui-popup .config=${ POSITION_ACTION_POPUP_CONFIG } ${ ref(this.popupRef) }>
        <button type="button"
            class="button-icon button-small"
            data-part="trigger"
            aria-describedby="${ this.id }-${ this.action }-tooltip"
            ?disabled=${ this.disabled }>
            ${ positionActionLabels[this.action] }<ui-icon name=${ positionActionIcons[this.action] }></ui-icon>
        </button>
        <ui-tooltip id="${ this.id }-${ this.action }-tooltip">
            ${ this.balances && positionActionTooltips[this.action](this.balances) }
        </ui-tooltip>
        <div class="sw-position-popup-overlay ${ this.action }" data-part="overlay">
            ${ popupTemplate.call(this) }
        </div>
    </ui-popup>
    `;
};

let uniqueId = 0;

@customElement('sw-position-popup')
export class PositionPopup extends LitElement {

    protected popupRef: Ref<PopupElement> = createRef();

    protected inputRef: Ref<TokenInputElement> = createRef();

    id = `sw-position-popup-${ uniqueId++ }`;

    @property({
        attribute: true,
        type: Boolean,
    })
    disabled = false;

    @property({
        attribute: true,
    })
    action: PositionActionType = 'split';

    @property({
        attribute: false,
    })
    balances?: Balances;

    @property({
        attribute: false,
    })
    amount = '';

    protected firstUpdated () {

        // pre-fill the full redeemable zcToken balance for the redeem action
        if (this.action === 'redeem') {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const balance = actionBalance(this.action, this.balances!);
            const amount = contractAmount(balance.balance, balance);

            this.inputRef.value?.setValue(amount);
        }
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleChange (e: TokenInputChangeEvent) {

        this.amount = e.detail.value;
    }

    protected handleSubmit () {

        dispatch(this, 'submit', { amount: this.amount });

        void this.popupRef.value?.hide(true);

        this.reset();
    }

    protected handleCancel () {

        dispatch(this, 'cancel');

        void this.popupRef.value?.hide(true);

        this.reset();
    }

    protected reset () {

        // don't reset the zcToken amount for the redeem action
        if (this.action === 'redeem') return;

        if (this.inputRef.value) {

            this.inputRef.value.setValue('');
        }
    }
}
