export interface Route {
    id: string;
    title?: string;
    pattern: RegExp;
    redirect?: string;
    enter?: RouteHandler;
    exit?: RouteHandler;
    guards?: {
        enter?: RouteHandler;
        exit?: RouteHandler;
    };
}

export interface RouteHandler {
    (route: RouteMatch): Promise<boolean | void> | boolean | void;
}

export interface RouteMatch {
    route: Route;
    url: URL;
    params?: Record<string, string>;
}

export interface RouterConfig {
    routes: Route[];
    base: string;
}

/**
 * The RouteMatcher
 *
 * @remarks
 * The RouteMatcher's only responisbility is to match an input (typically a URL or path)
 * with the configured routes, extract necessary parameters from the input and emit a
 * {@link RouteMatch} that contains the matching result.
 */
export class RouteMatcher {

    protected origin: string;

    /**
     * Creates a RouteMatcher instance
     *
     * @param config - the router configuration
     */
    constructor (protected config: RouterConfig) {

        this.origin = location.origin;
    }

    /**
     * Match a path or url against the configured routes
     *
     * @param p - the path or url to match
     */
    match (p: string): RouteMatch | undefined {

        // normalize the path - it could be a full url or just a path
        // we'll also reuse the URL object in the RouteMatch
        // (easy access to search params and hash)
        const url = new URL(p, this.origin);

        // TODO: handle baseUrl configuration, if present
        // remove the origin to get a clean path for matching (remove the base path here later)
        p = url.href.substr(this.origin.length);

        let match: RouteMatch | undefined;

        // find the first route that matches the path and create a RouteMatch object
        const matched = this.config.routes.find(route => {

            const result = route.pattern.exec(p);

            if (result) {

                const params = result.groups;

                match = {
                    route,
                    url,
                    params,
                };

                return true;
            }
        });

        // if we matched a route with a redirect we're gonna match that redirect
        if (matched && matched.redirect) {

            return this.match(matched.redirect);
        }

        return match;
    }
}

/**
 * The RouterAdapter interface
 *
 * @remarks
 * A RouterAdapter isolates the RouterService from its environment and implementation details, like
 * - the source of routing events (this could be URL changes, link clicks or any event source really)
 * - the routing strategy (a successful navigation could update the History API, the URL hash fragment, etc)
 *
 * It also allows us to swap out the adapter used with the routing service later.
 */
export interface RouterAdapter {

    start (): void;

    stop (): void;

    update (m: RouteMatch): void;

    subscribe (s: (url: string) => void): void;

    unsubscribe (s: (url: string) => void): void;
}

export abstract class AbstractRouterAdapter {

    protected subscribers = new Set<(url: string) => void>();

    subscribe (s: (url: string) => void) {

        this.subscribers.add(s);
    }

    unsubscribe (s: (url: string) => void) {

        this.subscribers.delete(s);
    }

    protected notify (u: string) {

        this.subscribers.forEach(subscriber => subscriber(u));
    }
}

export interface HistoryAdapterConfig {
    activeClass?: string;
    linkSelector?: string;
}


export const HISTORY_ADAPTER_CONFIG_DEFAULT: HistoryAdapterConfig = {
    activeClass: 'active',
    linkSelector: '[data-route]',
};

export class HistoryAdapter extends AbstractRouterAdapter implements RouterAdapter {

    protected config: HistoryAdapterConfig;

    protected active?: RouteMatch;

    constructor (c?: HistoryAdapterConfig) {

        super();

        this.config = { ...HISTORY_ADAPTER_CONFIG_DEFAULT, ...c };

        this.handlePopState = this.handlePopState.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    start () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.addEventListener('popstate', this.handlePopState);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        document.addEventListener('click', this.handleLinkClick);

        this.notify(location.href);
    }

    stop () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.removeEventListener('popstate', this.handlePopState);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        document.removeEventListener('click', this.handleLinkClick);
    }

    /**
     * Update the history adapter with a matched route
     *
     * @remarks
     * This will create a new entry in the browser history and update the browser's url.
     * It will also update the appropriate links with the configured `activeClass`.
     *
     * @param m - the matched route
     */
    update (m: RouteMatch) {

        const href = m.url.toString();

        if (location.href === href) {

            window.history.replaceState(m.route.id, m.route.title ?? '', href);

        } else {

            window.history.pushState(m.route.id, m.route.title ?? '', href);
        }

        document.title = m.route.title ?? document.title;

        if (this.config.linkSelector) {

            if (this.active) {

                const previous = this.config.linkSelector.replace(']', `=${ this.active.route.id }]`);

                document.querySelectorAll(previous).forEach(link => link.classList.remove(this.config.activeClass ?? ''));
            }

            const current = this.config.linkSelector.replace(']', `=${ m.route.id }]`);

            document.querySelectorAll(current).forEach(link => link.classList.add(this.config.activeClass ?? ''));
        }

        this.active = m;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected handlePopState (e: PopStateEvent) {

        this.notify(location.href);
    }

    protected handleLinkClick (e: MouseEvent) {

        const target = e.target as HTMLAnchorElement;

        try {

            if (this.config.linkSelector && target.matches(this.config.linkSelector)) {

                e.preventDefault();

                this.notify(target.href);
            }

        } catch (error) {

            // we don't want to break when the link selector is invalid
            console.error(error);
        }
    }
}

const DEFAULT_ROUTER_CONFIG: RouterConfig = {
    base: '',
    routes: [],
};

export class RouterService {

    protected subscribers = new Set<(match: RouteMatch) => void>();

    protected config: RouterConfig;

    protected matcher: RouteMatcher;

    protected adapter: RouterAdapter;

    protected active?: RouteMatch;

    get activeRoute (): RouteMatch | undefined {

        return this.active;
    }

    /**
     * Creates a new RouterService instance
     *
     * @param c - the router configuration
     * @param a - a router adapter
     */
    constructor (c?: Partial<RouterConfig>, a?: RouterAdapter) {

        this.config = { ...DEFAULT_ROUTER_CONFIG, ...c };

        this.matcher = new RouteMatcher(this.config);

        this.adapter = a ?? new HistoryAdapter();

        this.handleUrlChange = this.handleUrlChange.bind(this);
    }

    start () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.adapter.subscribe(this.handleUrlChange);
        this.adapter.start();
    }

    stop () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.adapter.unsubscribe(this.handleUrlChange);
        this.adapter.stop();
    }

    /**
     * Navigate to the specified url and trigger any matched route
     *
     * @param u - the url to navigate to (can be a path or full url)
     * @returns a promise resolving to `true` if a navigation happened or `false` if no navigation occurred
     */
    async navigate (u: string): Promise<boolean> {

        const match = this.matcher.match(u);

        if (match) {

            if (!(await this.canExit(match))) return false;

            if (!(await this.canEnter(match))) return false;

            if (!(await this.exit(match))) return false;

            if (!(await this.enter(match))) return false;

            // if all transitions worked invoke the adapter (this will update the actual url)
            this.adapter.update(match);

            // store the new active route
            this.active = match;

            // notify all subscribers
            this.notify(match);

            return true;
        }

        return false;
    }

    subscribe (s: (match: RouteMatch) => void) {

        this.subscribers.add(s);
    }

    unsubscribe (s: (match: RouteMatch) => void) {

        this.subscribers.delete(s);
    }

    protected notify (m: RouteMatch) {

        this.subscribers.forEach(subscriber => subscriber(m));
    }

    protected handleUrlChange (u: string) {

        void this.navigate(u);
    }

    protected willExit (m: RouteMatch): boolean {

        return !!this.active?.route && this.active?.route !== m.route;
    }

    protected async canExit (m: RouteMatch): Promise<boolean> {

        const willExit = this.willExit(m);

        const exitGuard = this.active?.route.guards?.exit;

        return !willExit || (exitGuard ? (await exitGuard(m)) !== false : true);
    }

    protected async canEnter (m: RouteMatch): Promise<boolean> {

        const enterGuard = m.route.guards?.enter;

        return enterGuard ? (await enterGuard(m)) !== false : true;
    }

    protected async exit (m: RouteMatch): Promise<boolean> {

        const willExit = this.willExit(m);

        const exitHandler = this.active?.route.exit;

        return !willExit || (exitHandler ? (await exitHandler(m)) !== false : true);
    }

    protected async enter (m: RouteMatch): Promise<boolean> {

        const enterHandler = m.route.enter;

        return enterHandler ? (await enterHandler(m)) !== false : true;
    }
}

let routerInstance: RouterService;

/**
 * Resolves the global router service instance
 *
 * @param c - optional router coniguration (should be set by the root component)
 * @param a - optional router adapter (should be set by the root component)
 * @returns a global router service instance
 */
export const router = (c?: Partial<RouterConfig>, a?: RouterAdapter): RouterService => {

    if (!routerInstance) {

        routerInstance = new RouterService(c, a);
    }

    return routerInstance;
};
