import { Market, Order } from '../../types';
import { emptyOrZero } from '../amount';
import { invertPrice, orderPremium, orderPrincipal } from './calculation';
import { inferAmount, inferPrice } from './inference';
import { priceToken } from './resolution';

/**
 * Converts an order `amount` and `price` into the corresponding order `principal` and `premium` based
 * on the order's exit/vault configuration.
 *
 * @remarks
 * For `vaultInitiates` (floating yield orders) the amount represents the order premium. We need
 * to calculate the order principal from the amount and price.
 * For all other orders the amount represents the order principal and we need to calculate the
 * order premium from the amount and price.
 * For `zcTokenExits` (selling zcToken) the price is NOT `premium paid / principal received` but
 * `underlying received / zcToken spent` so the price needs to be inverted in this case.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @param a - the amount (parsed to token decimals)
 * @param p - the price
 * @returns an object containing the corresponding principal and premium
 */
export const amountPriceToPrincipalPremium = (o: Order, m: Market, a: string, p: string): { principal: string; premium: string; } => {

    let principal = '';
    let premium = '';

    if (!emptyOrZero(a) && !emptyOrZero(p)) {

        const isZcTokenExit = o.exit && !o.vault;
        const isVaultInitiate = !o.exit && o.vault;

        const price = isZcTokenExit
            ? invertPrice(p, priceToken(o, m).decimals)
            : p;

        principal = isVaultInitiate
            ? orderPrincipal(a, price)
            : a;

        premium = isVaultInitiate
            ? a
            : orderPremium(a, price);
    }

    return {
        principal,
        premium,
    };
};

/**
 * Converts an order's `principal` and `premium` into the corresponding order `amount` and `price` based
 * on the order's exit/vault configuration.
 *
 * @param o - the order
 * @param m - the market associated with the order
 * @returns an object containing the corresponding amount and price
 */
export const principalPremiumToAmountPrice = (o: Order, m: Market): { amount: string; price: string; } => {

    let amount = '';
    let price = '';

    if (!emptyOrZero(o.principal) && !emptyOrZero(o.premium)) {

        amount = inferAmount(o);
        price = inferPrice(o, m);
    }

    return {
        amount,
        price,
    };
};
