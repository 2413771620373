import { ListConfig, LIST_CONFIG_MENU } from '@swivel-finance/ui/behaviors/list';
import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { SELECT_CONFIG_MENU_RADIO } from '@swivel-finance/ui/elements/select';
import { Chart, dispose, init } from 'klinecharts';
import { html, LitElement, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { ERRORS } from '../../constants';
import { compareMarkets } from '../../helpers';
import { errors, layout, messages, swivelApi } from '../../services';
import { debounce } from '../../shared/helpers';
import { BALANCE_DECIMALS, errorMessage, PRICE_DECIMALS, status } from '../../shared/templates';
import { services } from '../../state';
import { MarketEvent } from '../../state/market';
import { ChartData, Market } from '../../types';

type MarketState = typeof services.market.state;

const PRIMARY_TECHNICAL_INDICATORS = ['OFF', 'MA', 'EMA', 'SAR'];

const SECONDARY_TECHNICAL_INDICATORS = ['OFF', 'VOL', 'MACD', 'KDJ'];

const PERIODS = new Map([
    ['1D', 1440],
    ['12H', 720],
    ['6H', 360],
    ['1H', 60],
    ['30m', 30],
]);

const LIST_CONFIG_BUTTON_MENU: ListConfig = { ...LIST_CONFIG_MENU, orientation: 'horizontal' };

const indicatorMenuTemplate = (indicators: string[], selected: string, onSelect: (indicator: string) => void) => {

    return html`
    <ui-select class="indicator-select" .config=${ SELECT_CONFIG_MENU_RADIO } @ui-value-changed=${ (e: ValueChangeEvent) => onSelect(e.detail.current as string ?? '') }>
        <ui-listbox class="indicator-select-overlay" data-part="overlay">
            ${ indicators.map(item => html`<ui-listitem aria-checked="${ (item === selected).toString() }" .value=${ item }>${ item }</ui-listitem>`) }
        </ui-listbox>
    </ui-select>

    <ui-listbox class="indicator-menu" .config=${ LIST_CONFIG_BUTTON_MENU } @ui-value-changed=${ (e: ValueChangeEvent) => onSelect(e.detail.current as string ?? '') }>
        ${ indicators.map(item => html`<ui-listitem class="button-small" aria-selected="${ (item === selected).toString() }" .value=${ item }>${ item }</ui-listitem>`) }
    </ui-listbox>
    `;
};

const titleTemplate = function (this: OrderChart) {

    return html`<h2>Trends</h2>`;
};

const template = function (this: OrderChart) {

    const empty = !this.error && !this.data;
    const error = this.error;

    const setPeridod = (period: string) => this.setPeriod(period);

    const setPrimaryIndicator = (indicator: string) => this.setIndicator('primary', indicator);

    const setSecondaryIndicator = (indicator: string) => this.setIndicator('secondary', indicator);

    return html`
    ${ titleTemplate.call(this) }
    <div class="controls">
        ${ indicatorMenuTemplate([...PERIODS.keys()], this.period, setPeridod) }
        ${ indicatorMenuTemplate(PRIMARY_TECHNICAL_INDICATORS, this.indicators.primary.value, setPrimaryIndicator) }
        ${ indicatorMenuTemplate(SECONDARY_TECHNICAL_INDICATORS, this.indicators.secondary.value, setSecondaryIndicator) }
    </div>
    <div id=${ this.chartId } class="${ empty ? 'loading' : '' } ${ error ? 'error' : '' }"></div>
    ${ empty ? status(this.fetching ? 'loading' : 'initial') : nothing }
    ${ error ? errorMessage(error) : nothing }
    `;
};

@customElement('sw-order-chart')
export class OrderChart extends LitElement {

    protected marketService = services.market;

    protected chartId = 'sw-order-chart-container';

    protected chartInstance?: Chart;

    protected indicators = {
        primary: {
            value: 'OFF',
            pane: 'candle_pane',
        },
        secondary: {
            value: 'OFF',
            pane: 'secondary_pane',
        },
    };

    protected period = [...PERIODS.keys()][1];

    @state()
    protected market?: Market;

    @state()
    protected data?: ChartData[];

    @state()
    protected error?: string;

    @state()
    protected fetching = false;

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.handleResize = debounce(this.handleResize.bind(this));
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketService.onTransition(this.handleTransition);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        messages.subscribe(this.handleMessage, 'SAMPLE.OHLCV.CREATE');

        // eslint-disable-next-line @typescript-eslint/unbound-method
        layout.subscribe(this.handleResize, 'CHANGE');

        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.addEventListener('resize', this.handleResize);
    }

    disconnectedCallback () {

        this.dispose();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketService.off(this.handleTransition);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        messages.unsubscribe(this.handleMessage);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        layout.unsubscribe(this.handleResize, 'CHANGE');

        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.removeEventListener('resize', this.handleResize);

        super.disconnectedCallback();
    }

    createRenderRoot () {

        return this;
    }

    render () {

        return template.apply(this);
    }

    updated () {

        this.chartInstance?.applyNewData(this.data || []);
    }

    protected init () {

        if (this.chartInstance) return;

        this.chartInstance = init(this.chartId) as Chart;

        this.chartInstance.setStyleOptions(chartStyles);
        this.chartInstance.setPriceVolumePrecision(PRICE_DECIMALS, BALANCE_DECIMALS);

        this.initIndicatorState();
    }

    protected initIndicatorState () {

        // measure the order-chart element
        const elementSize = this.getBoundingClientRect();

        // if it's big enough, enable the volume track by default
        if (elementSize.height > 470) {

            this.setIndicator('secondary', 'VOL');
        }
    }

    protected dispose () {

        if (!this.chartInstance) return;

        dispose(this.chartInstance);

        this.chartInstance = undefined;
    }

    protected setIndicator (type: 'primary' | 'secondary', value: string) {

        const indicator = this.indicators[type];

        if (value === indicator.value) return;

        if (value === 'OFF') {

            this.chartInstance?.removeTechnicalIndicator(indicator.pane);

        } else {

            this.chartInstance?.createTechnicalIndicator(value, false, { id: indicator.pane });
        }

        this.indicators[type].value = value;

        this.requestUpdate();
    }

    protected setPeriod (period: string) {

        if (period === this.period) return;

        this.period = period;

        void this.fetch();
    }

    protected async fetch () {

        // when a fetch is initiated we want to clear out any previous error messages
        this.error = undefined;
        this.fetching = true;

        this.requestUpdate();

        try {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.data = await swivelApi.fetchOrderChartData(this.market!, PERIODS.get(this.period));

            // make sure the order-chart component is still connected...
            if (this.isConnected) {

                this.init();
            }

        } catch (error) {

            const processedError = errors.process(error, ERRORS.COMPONENTS.CHART.DEFAULT);

            this.data = undefined;
            this.error = processedError.message;
        }

        this.fetching = false;

        this.requestUpdate();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected handleTransition (state: MarketState, event: MarketEvent) {

        if (state.matches('completing') || state.matches('success')) {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const market = state.context.markets.get(state.context.selected)!;
            const changed = !this.market || !compareMarkets(market, this.market);

            if (changed) {

                // when a new market is set, we want to refresh the entire chart
                // and show a loading indicator, so we clear out the data and
                // dispose of the current chart instance
                this.market = market;
                this.data = undefined;

                this.dispose();

                void this.fetch();
            }
        }
    }

    protected handleMessage () {

        void this.fetch();
    }

    protected handleResize () {

        this.chartInstance?.resize();
    }
}

// -------------------------------------------------------------
// CHART STYLES - might move them to separate file at some point
// -------------------------------------------------------------

const font = '\'Roboto Mono\', monospace';
const colorUp = 'hsl(155, 50%, 44%)';
const colorDown = 'hsl(345, 60%, 50%)';
const colorNeutral = 'hsl(215, 20%, 25%)';
const colorActive = 'hsl(215, 80%, 55%)';
const colorGrid = 'rgba(255,255,255,.1)';
const colorLabel = 'hsl(215, 15%, 45%)';
const colorLabelHigh = 'hsl(215, 25%, 70%)';
const colorLabelMax = 'hsl(215, 70%, 90%)';
const colorCrossHair = 'hsla(215, 80%, 85%, .25)';
const colorBorder = 'hsl(215, 20%, 10%)';

const colorIndicator = [
    'hsl(215, 80%, 55%)',
    'hsl(180, 100%, 30%)',
    'hsl(300, 80%, 60%)',
    'hsl(165, 90%, 35%)',
    'hsl(335, 80%, 60%)',
    'hsl(250, 80%, 70%)',
    'hsl(200, 100%, 40%)',
];

const gridStyle = {
    show: true,
    size: 1,
    color: 'rgba(255,255,255,.05)',
    style: 'dash', // 'solid'|'dash'
    dashValue: [1, 1],
};

const crossHairText = {
    show: true,
    color: colorLabelHigh, // '#D9D9D9'
    size: 12,
    family: font, // 'Helvetica Neue'
    weight: 'normal',
    paddingLeft: 4, // 2
    paddingRight: 4, // 2
    paddingTop: 4, // 2
    paddingBottom: 2,
    borderSize: 1,
    borderColor: colorBorder, // '#505050'
    borderRadius: 2,
    backgroundColor: colorNeutral, // '#505050'
};

const chartStyles = {
    grid: {
        show: true,
        horizontal: gridStyle,
        vertical: gridStyle,
    },
    candle: {
        margin: {
            top: 0.2,
            bottom: 0.1,
        },
        // 'candle_solid'|'candle_stroke'|'candle_up_stroke'|'candle_down_stroke'|'ohlc'|'area'
        type: 'candle_solid',
        bar: {
            upColor: colorUp, // '#26A69A'
            downColor: colorDown, // '#EF5350'
            noChangeColor: colorNeutral, // '#888888'
        },
        area: {
            lineSize: 2,
            lineColor: '#2196F3',
            value: 'close',
            fillColor: [{
                offset: 0,
                color: 'rgba(33, 150, 243, 0.01)',
            }, {
                offset: 1,
                color: 'rgba(33, 150, 243, 0.2)',
            }],
        },
        priceMark: {
            show: true,
            high: {
                show: true,
                color: colorLabelHigh, // '#D9D9D9'
                textMargin: 4, // 5
                textSize: 10,
                textFamily: font, // 'Helvetica Neue'
                textWeight: 'normal',
            },
            low: {
                show: true,
                color: colorLabelHigh, // '#D9D9D9'
                textMargin: 4, // 5
                textSize: 10,
                textFamily: font, // 'Helvetica Neue'
                textWeight: 'normal',
            },
            last: {
                show: true,
                upColor: colorUp, // '#26A69A'
                downColor: colorDown, // '#EF5350'
                noChangeColor: colorNeutral, // '#888888'
                line: {
                    show: true,
                    // 'solid'|'dash'
                    style: 'dash',
                    dashValue: [4, 2],
                    size: 1,
                },
                text: {
                    show: true,
                    size: 12,
                    paddingLeft: 4, // 2
                    paddingTop: 4, // 2
                    paddingRight: 4, // 2
                    paddingBottom: 2,
                    color: colorLabelMax, // '#FFFFFF'
                    family: font, // 'Helvetica Neue'
                    weight: 'normal',
                    borderRadius: 2,
                },
            },
        },
        tooltip: {
            // 'always' | 'follow_cross' | 'none'
            showRule: 'always',
            // 'standard' | 'rect'
            showType: 'standard',
            labels: ['Time: ', 'O: ', 'C: ', 'H: ', 'L: ', 'V: '],
            values: null,
            defaultValue: 'n/a',
            rect: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 6,
                offsetLeft: 8,
                offsetTop: 8,
                offsetRight: 8,
                borderRadius: 4,
                borderSize: 1,
                borderColor: colorBorder,
                // fillColor: 'rgba(17, 17, 17, .3)',
            },
            text: {
                size: 12, // 12
                family: font, // 'Helvetica Neue'
                weight: 'normal',
                color: colorLabelHigh, // '#D9D9D9'
                marginLeft: 0, // 8
                marginTop: 2, // 6
                marginRight: 8,
                marginBottom: 2,
            },
        },
    },
    technicalIndicator: {
        margin: {
            top: 0.5, // 0.2
            bottom: 0.1, // 0.1
        },
        bar: {
            upColor: colorUp, // '#26A69A'
            downColor: colorDown, // '#EF5350'
            noChangeColor: colorNeutral, // '#888888'
        },
        line: {
            size: 1,
            colors: colorIndicator, // ['#FF9600', '#9D65C9', '#2196F3', '#E11D74', '#01C5C4']
        },
        circle: {
            upColor: colorUp, // '#26A69A'
            downColor: colorDown, // '#EF5350'
            noChangeColor: colorNeutral, // '#888888'
        },
        lastValueMark: {
            show: false,
            text: {
                show: false,
                color: '#ffffff',
                size: 12,
                family: font, // 'Helvetica Neue'
                weight: 'normal',
                paddingLeft: 3,
                paddingTop: 2,
                paddingRight: 3,
                paddingBottom: 2,
                borderRadius: 2,
            },
        },
        tooltip: {
            // 'always' | 'follow_cross' | 'none'
            showRule: 'always',
            // 'standard' | 'rect'
            showType: 'standard',
            showName: true,
            showParams: true,
            defaultValue: 'n/a',
            text: {
                size: 12, // 12
                family: font, // 'Helvetica Neue'
                weight: 'normal',
                color: colorLabelHigh, // '#D9D9D9'
                marginTop: 8, // 6
                marginRight: 8,
                marginBottom: 8, // 0
                marginLeft: 0, // 8
            },
        },
    },
    xAxis: {
        show: true,
        height: null,
        axisLine: {
            show: false, // true
            color: colorGrid, // '#888888'
            size: 1,
        },
        tickText: {
            show: true,
            color: colorLabel, // '#D9D9D9'
            family: font, // 'Helvetica Neue'
            weight: 'normal',
            size: 10, // 12
            paddingTop: 3,
            paddingBottom: 0, // 6
        },
        tickLine: {
            show: true, // true
            size: 1,
            length: 3,
            color: colorGrid, // '#888888'
        },
    },
    yAxis: {
        show: true,
        width: null,
        // 'left' | 'right'
        position: 'right',
        // 'normal' | 'percentage' | 'log'
        type: 'normal',
        inside: false,
        axisLine: {
            show: false, // true
            color: '#888888',
            size: 1,
        },
        tickText: {
            show: true,
            color: colorLabel, // '#D9D9D9'
            family: font, // 'Helvetica Neue'
            weight: 'normal',
            size: 10, // 12
            paddingLeft: 3,
            paddingRight: 0, // 6
        },
        tickLine: {
            show: true,
            size: 1,
            length: 3,
            color: colorGrid, // '#888888'
        },
    },
    separator: {
        size: 1,
        color: colorGrid, // '#888888'
        fill: true,
        activeBackgroundColor: colorActive, // 'rgba(230, 230, 230, .15)'
    },
    crosshair: {
        show: true,
        horizontal: {
            show: true,
            line: {
                show: true,
                // 'solid'|'dash'
                style: 'dash',
                dashValue: [4, 2],
                size: 1,
                color: colorCrossHair, // '#888888'
            },
            text: crossHairText,
        },
        vertical: {
            show: true,
            line: {
                show: true,
                // 'solid'|'dash'
                style: 'dash',
                dashValue: [4, 2],
                size: 1,
                color: colorCrossHair, // '#888888'
            },
            text: crossHairText,
        },
    },
};
