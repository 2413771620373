import { utils } from 'ethers';
import { html } from 'lit';
import { round } from '../../helpers/amount';
import { Protocol, Token } from '../../types';

export const BALANCE_DECIMALS = 4;
export const BALANCE_PADDING = true;
export const PRICE_DECIMALS = 4;
export const PRICE_PADDING = true;

/**
 * This file contains commonly used templates for rendering:
 * - amounts
 * - amounts with units
 * - token balances
 * - token symbols and names
 *
 * These templates will create consitent representation of amount related outputs,
 * take care of rounding (defaults to `2` decimals) and formatting token balances
 * to their natural representation in the token denomination (18 decimals or others).
 */

/**
 * Creates a template for a unit string.
 *
 * @param u - the unit to render, e.g. '%'
 * @returns a `lit.TemplateResult` for the unit
 */
export const unit = (u: string) => html`<span class="unit">${ u }</span>`;

/**
 * Creates a template for an amount with an optional unit.
 *
 * @param a - the amount to render
 * @param u - an optional unit to render
 * @param d - an optional number of decimals to round the amount to (defaults to `2`)
 * @param p - pad the result with trailing zeros to have a fixed precision (defaults to `false`)
 * @returns a `lit.TemplateResult` for the amount
 */
export const amount = (a: string, u?: string, d = 2, p = false) =>
    html`<span class="amount"><span class="value">${ round(a, d, p) }</span>${ u ? unit(u) : '' }</span>`;

/**
 * Creates a template for an amount label with an optional unit.
 *
 * @param l - the label to render
 * @param u - an optional unit to render
 * @returns a `lit.TemplateResult` for the amount label
 */
export const amountLabel = (l: string, u?: string) =>
    html`<span class="amount"><span class="label">${ l }</span>${ u ? unit(u) : '' }</span>`;

/**
 * Creates a template for a balance, formatted according to the provided token.
 *
 * @param a - the amount (balance) to render
 * @param t - a token belonging to the amount
 * @param d - an optional number of decimals to round the amount to (defaults to `2`)
 * @param p - pad the result with trailing zeros to have a fixed precision (defaults to `false`)
 * @returns a `lit.TemplateResult` for the balance formatted according to the token
 */
export const balance = (a: string, t: Token, d = BALANCE_DECIMALS, p = BALANCE_PADDING) =>
    amount(utils.formatUnits(a, t.decimals), undefined, d, p);

/**
 * Creates a template for a price.
 *
 * @remarks
 * A price is already relative to its token, so we don't need any conversions here.
 *
 * @param a - the amount (price) to render
 * @param d - an optional number of decimals to round the amount to (defaults to `2`)
 * @param p - pad the result with trailing zeros to have a fixed precision (defaults to `false`)
 * @returns a `lit.TemplateResult` for the price formatted according to the token
 */
export const price = (a: string, d = PRICE_DECIMALS, p = PRICE_PADDING) =>
    amount(a, undefined, d, p);

/**
 * Creates a template for a token's symbol.
 *
 * @param t - the token to render
 * @returns a `lit.TemplateResult` for the token symbol
 */
export const tokenSymbol = (t?: Token) =>
    html`${ t?.symbol ? html`<span class="unit token-symbol">${ t.symbol }</span>` : '' }`;

/**
 * Creates a template for a token's name.
 *
 * @param t - the token to render
 * @returns a `lit.TemplateResult` for the token name
 */
export const tokenName = (t?: Token) =>
    html`${ t?.name ? html`<span class="token-name">${ t.name }</span>` : '' }`;

/**
 * Creates a template for a token balance, formatted according to the token and including the token's symbol.
 *
 * @param a - the amount (or balance) to render
 * @param t - a token belonging to the amount
 * @param d - an optional number of decimals to round the amount to (defaults to `2`)
 * @param p - pad the result with trailing zeros to have a fixed precision (defaults to `false`)
 * @returns a `lit.TemplateResult` for the token balance including the token symbol
 */
export const tokenBalance = (a: string, t: Token, d = BALANCE_DECIMALS, p = BALANCE_PADDING) =>
    html`<span class="token-balance">${ balance(a, t, d, p) }${ tokenSymbol(t) }</span>`;

/**
* Creates a template for a token price, formatted according to the token and including the token's symbol.
*
* @param a - the price to render
* @param t - a token belonging to the price
* @param d - an optional number of decimals to round the amount to (defaults to `2`)
* @param p - pad the result with trailing zeros to have a fixed precision (defaults to `false`)
* @returns a `lit.TemplateResult` for the token price including the token symbol
*/
export const tokenPrice = (a: string, t: Token, d = PRICE_DECIMALS, p = PRICE_PADDING) =>
    html`<span class="token-balance">${ price(a, d, p) }${ tokenSymbol(t) }</span>`;

/**
 * Creates a template for a token's image.
 *
 * @param t - the token to render
 * @returns a `lit.TemplateResult` for the token image
 */
export const tokenImage = (t?: Token) =>
    html`${ t?.image ? html`<span class="token-image"><sw-symbol class="image" name=${ t.image }></sw-symbol></span>` : '' }`;

/**
 * Creates a template for a protocol's image.
 *
 * @param t - the protocol to render
 * @returns a `lit.TemplateResult` for the protocol image
 */
export const protocolImage = (p?: Protocol) =>
    html`${ p?.image ? html`<span class="token-image"><sw-symbol class="image" name=${ p.image }></sw-symbol></span>` : '' }`;
