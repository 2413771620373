/**
 * Debounces a method
 *
 * @param m - the method to debounce
 * @param t - an optional time in ms to debounce by (default: 250)
 * @returns the debounced method
 */
export const debounce = <T extends unknown[], R>(m: (...args: T) => R, t = 250) => {

    let timeout: number | undefined;

    return (...args: T) => {

        window.clearTimeout(timeout);

        timeout = window.setTimeout(() => m.apply(this, args), t);
    };
};
