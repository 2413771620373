export interface AnalyticsTourEvent {
    page: string;
}

export interface AnalyticsInsolventEvent {
    page: string;
}

export interface AnalyticsOutsizedEvent {
    orderKey: string;
    orderPrice: string;
    lastTradePrice: string;
    warningShown: boolean;
    warningIgnored: boolean;
}

export const ANALYTICS = {
    TOUR_STARTED: {
        NAME: 'tour_started',
        EVENT: (data: AnalyticsTourEvent) => ({
            time: Date.now(),
            page: data.page,
        }),
    },
    TOUR_ENDED: {
        NAME: 'tour_ended',
        EVENT: (data: AnalyticsTourEvent) => ({
            time: Date.now(),
            page: data.page,
        }),
    },
    TOUR_CANCELLED: {
        NAME: 'tour_cancelled',
        EVENT: (data: AnalyticsTourEvent) => ({
            time: Date.now(),
            page: data.page,
        }),
    },
    INSOLVENT: {
        NAME: 'order_insolvent',
        EVENT: (data: AnalyticsInsolventEvent) => ({
            time: Date.now(),
            page: data.page,
        }),
    },
    OUTSIZED: {
        NAME: '0rder_outsized',
        EVENT: (data: AnalyticsOutsizedEvent) => ({
            time: Date.now(),
            order_key: data.orderKey,
            order_price: data.orderPrice,
            last_trade_price: data.lastTradePrice,
            warning_displayed: data.warningShown,
            warning_ignored: data.warningIgnored,
        }),
    },
};
