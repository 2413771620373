import { AsyncSubscriptionService } from '@swivel-finance/consent';

export const LAYOUT_TOPICS = ['CHANGE'] as const;

export type LayoutTopics = typeof LAYOUT_TOPICS[number];

export class LayoutService extends AsyncSubscriptionService<unknown, LayoutTopics> {

    notifyChange () {

        this.notify(undefined, 'CHANGE');
    }
}

/**
 * A singleton LayoutService instance.
 *
 * @example
 * ```typescript
 * import { layout } from '/services';
 *
 * // subscribe to layout changes
 * layout.subscribe(() => {
 *     // do sth...
 * }, 'CHANGE');
 *
 * // emit layout changes
 * layout.notifyChange();
 * ```
 */
export const layout = new LayoutService();
