import { errors } from '../../../services/errors';
import { layout } from '../../../services/layout';
import type { BannerOutletElement } from './banner-outlet';
import { Banner } from './types';

const NO_OUTLET = 'Error showing banner: No banner outlet found in page.';
const UNKNOWN_OUTLET = 'Error showing banner: The specified outlet is not connected.';

class BannerService {

    protected outlets = new Map<string, BannerOutletElement>();

    protected banners = new Map<string, string>();

    protected defaultOutlet: string | undefined;

    connect (o: BannerOutletElement) {

        this.outlets.set(o.id, o);

        if (!this.defaultOutlet) {

            this.defaultOutlet = o.id;
        }
    }

    disconnect (o: BannerOutletElement) {

        if (this.outlets.has(o.id)) {

            this.outlets.delete(o.id);
        }
    }

    show (b: Banner, o?: string): string {

        const outlet = this.getOutlet(o);

        const id = outlet.show(b);

        void outlet.updateComplete.finally(() => layout.notifyChange());

        this.banners.set(id, outlet.id);

        return id;
    }

    dismiss (i: string) {

        const outlet = this.getOutlet(this.banners.get(i));

        outlet.dismiss(i);

        void outlet.updateComplete.finally(() => layout.notifyChange());

        this.banners.delete(i);
    }

    update (i: string, b: Banner) {

        const outlet = this.getOutlet(this.banners.get(i));

        outlet.refresh(i, b);

        void outlet.updateComplete.finally(() => layout.notifyChange());
    }

    protected getOutlet (o?: string): BannerOutletElement {

        let outlet: BannerOutletElement;

        if (o) {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            outlet = this.outlets.get(o)!;

            if (!outlet) throw errors.process(new Error(UNKNOWN_OUTLET));

        } else {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            outlet = this.outlets.get(this.defaultOutlet || '')!;

            if (!outlet) throw errors.process(new Error(NO_OUTLET));
        }

        return outlet;
    }
}

export const banners = new BannerService();
