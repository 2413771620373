import { VaultTracker } from '@swivel-finance/swivel-js';
import { errors } from '../../services/errors';
import { logger } from '../../services/logger';
import { token } from '../../services/token';
import { wallet } from '../../services/wallet';
import { Balance, Balances, Market, Vault } from '../../types';
import { emptyOrZero, fixed, round } from '../amount';

export const fetchAccountBalance = async (): Promise<Balance> => {

    return token.fetchAccountBalance();
};

/**
 * Fetches an account's {@link Balances} for a specified market
 *
 * @remarks
 * A market has 3 tokens which are ERC20 contracts:
 * - the underlying (the token being traded/deposited)
 * - the cToken (the interest generating token that underlying is deposited into when filling orders)
 * - the zcToken (the zero coupon token that can be redeemed 1-1 for underlying at market maturity)
 *
 * In addition to these, a market has an nToken and a vault:
 * - the nToken is not an ERC20 contract and, similar to the zcToken, represents the amount of
 *   underlying deposited into cTokens
 * - the vault keeps track of the amount of underlying deposited (`notional`), the cToken exchange
 *   rate (the amount of cTokens received per underlying deposited) and the interest generated by
 *   the cToken (`redeemable`)
 *
 * We can fetch the ERC20 token balances directly from the contract, for nToken balance and vault
 * information we need to use the {@link @swivel-finance/swivel-js#VaultTracker} from swivel-js.
 *
 * @param a - the account address
 * @param m - the market
 */
export const fetchMarketBalances = async (a: string, m: Market): Promise<Balances> => {

    // reuse global metamask provider and signer
    const { provider } = await wallet.connection();

    // create the VaultTracker HOC at the market's vault address
    const vaultTracker = new VaultTracker(m.vault, provider);

    try {

        // fetch ERC20 balances and vault information in parallel
        const [underlying, zcToken, vault, maturityRate] = await Promise.all([
            token.fetchBalance(m.tokens.underlying.address, a),
            token.fetchBalance(m.tokens.zcToken.address, a),
            vaultTracker.vaults(a),
            vaultTracker.maturityRate(),
        ]);

        const balances = {
            underlying: {
                ...m.tokens.underlying,
                balance: underlying,
            },
            zcToken: {
                ...m.tokens.zcToken,
                balance: zcToken,
            },
            nToken: {
                ...m.tokens.nToken,
                balance: vault.notional,
            },
            vault: {
                ...vault,
                maturityRate,
                address: m.vault,
            },
        };

        logger.group('helpers/balance').log('fetchMarketBalances... \n  market: \n%o\n  balances: \n%o', m, balances);

        return balances;

    } catch (error) {

        throw errors.isProcessed(error) ? error : errors.process(error);
    }
};

/**
 * Calculate the up-to-date redeemable based on the latest cToken exchange rate or market's maturity rate.
 *
 * @remarks
 * A `Vault`s `redeemable` and `exchangeRate` is only updated through a vault interaction (e.g. adding or
 * removing notional to / from the vault). That means, at any time the `redeemable` might be outdated and
 * missing some marginal interest that was accrued since the last vault interaction. We can calculate that
 * marginal interest on the fly using the following equation:
 * ```
 * // before maturity
 * marginal = (notional + redeemable) * ((marketExchangeRate / vaultExchangeRate) - 1)
 *
 * // after maturity
 * marginal = (notional + redeemable) * ((vaultMaturityRate / vaultExchangeRate) - 1)
 * ```
 * The `vaultExchangeRate` is the recorded exchange rate at the time of the last vault interaction,
 * `marketExchangeRate` and `vaultMaturityRate` are the most up-to-date rates pre- and post-maturity
 * respectively. The calculation adds the difference in accrued interest between the two exchange
 * rates to the last calculated `redeemable`.
 *
 * @param m - the market for which to calculate the redeemable
 * @param v - the market's vault
 * @returns
 */
export const redeemable = (m: Market, v: Vault): string => {

    // if we don't have a vault exchange rate we can't calculate any marginal interest
    if (emptyOrZero(v.exchangeRate)) return v.redeemable;

    // a vault contains the user's notional and redeemable balances
    const redeemable = fixed(emptyOrZero(v.redeemable) ? 0 : v.redeemable);
    const notional = fixed(emptyOrZero(v.notional) ? 0 : v.notional);

    // initialize the marginal interest to 0
    let marginal = fixed(0);

    // the vault's exchange rates and a market's exchange rate (via swivel-js) use the same scale
    // we only create a fraction from the exchange rates, so it's only important for them to have
    // the same scale - the actual scale is not important
    const vaultExchangeRate = fixed(v.exchangeRate);
    const vaultMaturityRate = fixed(v.maturityRate);

    // check if the market is matured (a non-matured market has a `maturityRate` of `0`)
    const matured = !vaultMaturityRate.isZero();

    if (matured) {

        // calculate any missing marginal interest since last vault interaction using the vault's `maturityRate`;
        // N.B.: the vault's `exchangeRate` is <= the vault's `maturityRate` - both become equal when a vault
        // interaction (like redeem) happens past the market's maturity (in which case the marginal becomes `0`)
        // marginal = (notional + redeemable) * ((vaultMaturityRate / vaultExchangeRate) - 1)
        marginal = notional.addUnsafe(redeemable).mulUnsafe(vaultMaturityRate.divUnsafe(vaultExchangeRate).subUnsafe(fixed(1)));

    } else {

        // if don't have the market's exchange rate we can't calculate marginal interest
        if (emptyOrZero(m.exchangeRate)) return v.redeemable;

        // the market's `exchangeRate` has the same scale as the vault's `exchangeRate`
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const marketExchangeRate = fixed(m.exchangeRate!);

        // calculate the missing marginal interest since last vault interaction using the market's current `exchangeRate`
        // marginal = (notional + redeemable) * ((marketExchangeRate / vaultExchangeRate) - 1)
        marginal = notional.addUnsafe(redeemable).mulUnsafe(marketExchangeRate.divUnsafe(vaultExchangeRate).subUnsafe(fixed(1)));
    }

    // the current exchange rate should always be higher than the last recorded one (otherwise we'd have negative
    // interest) and we're simply making sure here we don't add any negative interest
    if (marginal.isNegative()) {

        marginal = fixed(0);
    }

    // return the redeemable plus any marginal interest
    return round(redeemable.addUnsafe(marginal).toString(), 0);
};
