/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TransactionResponse } from '@ethersproject/providers';
import { Swivel } from '@swivel-finance/swivel-js';
import { ethers } from 'ethers';
import { ActorRefFrom, assign, createMachine, DoneInvokeEvent, MachineOptions, send, sendParent, spawn, StateMachine, TransitionsConfig } from 'xstate';
import { ERRORS } from '../constants';
import { ENV } from '../env/environment';
import { confirmTransaction, fetchMarketBalances, fetchMarkets, marketKey, paused } from '../helpers';
import { errors } from '../services/errors';
import { logger as logService } from '../services/logger';
import { token } from '../services/token';
import { notifications } from '../shared/components';
import { Balances, Market } from '../types';

const logger = logService.group('positions');

export interface PositionsInit {
    account: string;
    swivel: Swivel;
}

export interface PositionsData {
    markets: Market[];
    balances: Balances[];
}

export interface PositionsContext extends Partial<PositionsInit>, Partial<PositionsData> {
    actions: Map<string, PositionAction[]>;
    error?: string;
}

export type PositionsState =
    {
        value: 'initial';
        context: PositionsContext;
    }
    | {
        value: 'fetching';
        context: PositionsContext & PositionsInit;
    }
    | {
        value: 'success';
        context: PositionsContext & PositionsInit & PositionsData & {
            error: undefined;
        };
    }
    | {
        value: 'error';
        context: PositionsContext & {
            error: string;
        };
    };

export type PositionsInitEvent = { type: 'POSITIONS.INIT'; payload: PositionsInit; };
export type PositionsFetchEvent = { type: 'POSITIONS.FETCH'; };

export type PositionsActionEvent = { type: 'POSITIONS.ACTION'; payload: PositionActionRequest; };
export type PositionsActionStartEvent = { type: 'POSITIONS.ACTION.START'; payload: PositionActionResponse; };
export type PositionsActionSuccessEvent = { type: 'POSITIONS.ACTION.SUCCESS'; payload: PositionActionResponse; };
export type PositionsActionFailureEvent = { type: 'POSITIONS.ACTION.FAILURE'; payload: PositionActionResponse; };

export type PositionsEvent =
    PositionsInitEvent
    | PositionsFetchEvent
    | PositionsActionEvent
    | PositionsActionStartEvent
    | PositionsActionSuccessEvent
    | PositionsActionFailureEvent;

const init: TransitionsConfig<PositionsContext, PositionsEvent> = {
    'POSITIONS.INIT': {
        actions: 'INIT',
        target: 'fetching',
    },
};

const fetch: TransitionsConfig<PositionsContext, PositionsEvent> = {
    'POSITIONS.FETCH': {
        target: 'fetching',
    },
};

const action: TransitionsConfig<PositionsContext, PositionsEvent> = {
    'POSITIONS.ACTION': {
        actions: [
            'CREATE_ACTION',
            'SEND_ACTION',
        ],
    },
};

const actionSuccess: TransitionsConfig<PositionsContext, PositionsEvent> = {
    'POSITIONS.ACTION.SUCCESS': {
        actions: 'FINISH_ACTION',
    },
};

const actionFailure: TransitionsConfig<PositionsContext, PositionsEvent> = {
    'POSITIONS.ACTION.FAILURE': {
        actions: 'FINISH_ACTION',
    },
};

const initialContext: PositionsContext = {
    actions: new Map(),
};

export const machineOptions: Partial<MachineOptions<PositionsContext, PositionsEvent>> = {
    actions: {
        INIT: assign((context, event) => ({
            ...initialContext,
            ...(event as PositionsInitEvent).payload,
        })),
        FETCH_SUCCESS: assign((context, event) => ({
            ...context,
            ...(event as DoneInvokeEvent<PositionsData>).data,
            error: undefined,
        })),
        FETCH_FAILURE: assign((context, event) => ({
            ...context,
            markets: undefined,
            balances: undefined,
            error: (event as DoneInvokeEvent<Error>).data.message,
        })),
        CREATE_ACTION: assign((context, event) => {

            const payload = (event as PositionsActionEvent).payload;
            const key = marketKey(payload.market);
            // every action gets a unique id which will also be used to identify its state machine
            const id = actionId();

            const action: PositionAction = {
                ...payload,
                id,
                // we spawn the action state machine for this action...
                ref: spawn(actionMachine, id.toString()),
                status: 'pending',
            };

            // ...and store the action in the context
            context.actions.set(key, (context.actions.get(key) ?? []).concat(action));

            return context;
        }),
        SEND_ACTION: send(
            (context, event) => {

                // we use the action we created to generate an event for the spawned action state machine
                const actions = context.actions.get(marketKey((event as PositionsActionEvent).payload.market))!;
                const action = actions[actions.length - 1];

                const account = context.account!;
                const swivel = context.swivel!;

                const { id, type, status, market, amount } = action;

                const startEvent: PositionsActionStartEvent = {
                    type: 'POSITIONS.ACTION.START',
                    payload: {
                        id,
                        type,
                        status,
                        market,
                        amount,
                        account,
                        swivel,
                    },
                };

                return startEvent;
            },
            {
                to: (context, event) => {

                    // the id of the action state machine is the action id
                    const actions = context.actions.get(marketKey((event as PositionsActionEvent).payload.market))!;

                    return actions[actions?.length - 1].id.toString();
                },
            },
        ),
        FINISH_ACTION: assign((context, event) => {

            const payload = (event as PositionsActionSuccessEvent | PositionsActionFailureEvent).payload;
            const action = context.actions.get(marketKey(payload.market))!.find(action => action.id === payload.id)!;

            // update the status of the action
            action.status = payload.status;

            if (payload.error) action.error = payload.error;

            // stop the action state machine
            action.ref!.stop!();
            action.ref = undefined;

            return context;
        }),
    },
    services: {
        fetch: async (context): Promise<{ markets: Market[]; balances: Balances[]; }> => {

            let markets: Market[];
            let balances: Balances[];

            try {

                markets = await fetchMarkets(ENV.previewMarkets ? 'preview' : undefined);

            } catch (error) {

                throw errors.isProcessed(error) ? error : errors.process(error, ERRORS.STATE.MARKET.FETCH);
            }

            try {

                const { account } = context;

                balances = await Promise.all(markets.map(market => fetchMarketBalances(account!, market)));

            } catch (error) {

                throw errors.process(error, ERRORS.STATE.POSITIONS.FETCH);
            }

            return { markets, balances };
        },
    },
};

export const machine = createMachine<PositionsContext, PositionsEvent, PositionsState>({
    context: initialContext,
    initial: 'initial',
    states: {
        initial: {
            on: {
                ...init,
            },
        },
        fetching: {
            invoke: {
                id: 'fetch',
                src: 'fetch',
                onDone: {
                    actions: 'FETCH_SUCCESS',
                    target: 'success',
                },
                onError: {
                    actions: 'FETCH_FAILURE',
                    target: 'error',
                },
            },
        },
        success: {
            on: {
                ...init,
                ...fetch,
                ...action,
                ...actionSuccess,
                ...actionFailure,
            },
        },
        error: {
            on: {
                ...init,
                ...fetch,
            },
        },
    },
}, machineOptions);



let ACTION_ID = 0;

const actionId = () => ACTION_ID++;

export type PositionActionType = 'split' | 'combine' | 'redeem' | 'redeemInterest';

export type PositionActionStatus = 'pending' | 'success' | 'failure';

export interface PositionActionContext {
    id: number;
    type: PositionActionType;
    status: PositionActionStatus;
    swivel?: Swivel;
    market?: Market;
    amount?: string;
    account?: string;
    error?: string;
    transaction?: TransactionResponse;
}

export interface PositionActionRequest {
    type: PositionActionType;
    market: Market;
    amount: string;
}

export interface PositionActionResponse extends PositionActionContext {
    swivel: Swivel;
    market: Market;
    amount: string;
    account: string;
}

export interface PositionAction {
    id: number;
    // eslint-disable-next-line @typescript-eslint/ban-types
    ref?: ActorRefFrom<StateMachine<PositionActionContext, {}, PositionsEvent>>;
    type: PositionActionType;
    status: PositionActionStatus;
    market: Market;
    amount: string;
    error?: string;
    transaction?: TransactionResponse;
}

/**
 * A state machine for handling async split/combine/redeem actions
 */
const actionMachine = createMachine<PositionActionContext, PositionsEvent>({
    id: 'actionMachine',
    context: {
        id: 0,
        type: 'split',
        status: 'pending',
    },
    initial: 'initial',
    states: {
        initial: {
            on: {
                'POSITIONS.ACTION.START': {
                    actions: assign((context, event) => ({
                        ...context,
                        ...event.payload,
                    })),
                    target: 'allowance',
                },
            },
        },
        allowance: {
            invoke: {
                id: 'checkAllowance',
                src: async (context): Promise<void> => {

                    // we don't need approval for combining tokens, redeeming tokens or redeeming interest
                    if (context.type === 'combine'
                        || context.type === 'redeem'
                        || context.type === 'redeemInterest') return;

                    const swivel = context.swivel!;
                    const amount = context.amount!;
                    const account = context.account!;
                    const address = (context.type === 'split')
                        ? context.market!.tokens.underlying.address
                        : context.market!.tokens.zcToken.address;

                    let allowance: string;

                    try {

                        allowance = await token.allowance(address, account, swivel.address);

                        logger.log(`allowance for ${ context.type === 'split' ? 'underlying' : 'zcToken' } '${ address }': ${ allowance }`);

                    } catch (error) {

                        throw errors.process(error, ERRORS.STATE.POSITIONS.ALLOWANCE);
                    }

                    const allowanceSufficient = ethers.BigNumber.from(amount).lte(allowance);

                    if (!allowanceSufficient) {

                        const notification = notifications.show({
                            type: 'progress',
                            content: 'Waiting for approval...',
                            dismissable: false,
                        });

                        try {
                            // ask for "unlimited" approval, so we don't have to approve every next transaction
                            await token.approve(address, swivel.address, ethers.constants.MaxUint256);

                            notifications.update(notification, {
                                content: 'Approval successful.',
                                type: 'success',
                                dismissable: true,
                            });

                        } catch (error) {

                            const processedError = errors.isProcessed(error) ? error : errors.process(error);

                            notifications.update(notification, {
                                content: `Approval failed: ${ processedError.message }`,
                                type: 'failure',
                                dismissable: true,
                                timeout: 10,
                            });

                            throw errors.process(processedError, ERRORS.STATE.POSITIONS.APPROVAL);
                        }
                    }
                },
                onDone: {
                    target: 'pending',
                },
                onError: {
                    actions: [
                        assign((context, event) => ({
                            ...context,
                            status: 'failure',
                            error: (event.data as Error).message,
                        })),
                        sendParent(context => ({
                            type: 'POSITIONS.ACTION.FAILURE',
                            payload: context,
                        })),
                    ],
                    target: 'initial',
                },
            },
        },
        pending: {
            invoke: {
                id: 'actionService',
                src: async (context) => {

                    const swivel = context.swivel!;
                    const market = context.market!;
                    const amount = context.amount!;

                    // ensure we don't allow any actions on paused markets
                    if (paused(market)) {

                        throw errors.process(ERRORS.STATE.MARKET.PAUSED);
                    }

                    let tx: TransactionResponse;

                    try {

                        switch (context.type) {

                            case 'split':

                                tx = await swivel.splitUnderlying(market.protocol, market.underlying, market.maturity, amount);
                                break;

                            case 'combine':

                                tx = await swivel.combineTokens(market.protocol, market.underlying, market.maturity, amount);
                                break;

                            case 'redeem':

                                tx = await swivel.redeemZcToken(market.protocol, market.underlying, market.maturity, amount);
                                break;

                            case 'redeemInterest':

                                tx = await swivel.redeemVaultInterest(market.protocol, market.underlying, market.maturity);
                                break;
                        }

                        await confirmTransaction(tx);

                        return tx;

                    } catch (error) {

                        throw errors.isProcessed(error) ? error : errors.process(error);
                    }
                },
                onDone: {
                    actions: [
                        assign((context, event) => ({
                            ...context,
                            transaction: (event as DoneInvokeEvent<TransactionResponse>).data,
                            status: 'success',
                            error: undefined,
                        })),
                        sendParent(context => ({
                            type: 'POSITIONS.ACTION.SUCCESS',
                            payload: context,
                        })),
                    ],
                    target: 'initial',
                },
                onError: {
                    actions: [
                        assign((context, event) => ({
                            ...context,
                            status: 'failure',
                            error: (event.data as Error).message,
                        })),
                        sendParent(context => ({
                            type: 'POSITIONS.ACTION.FAILURE',
                            payload: context,
                        })),
                    ],
                    target: 'initial',
                },
            },
        },
    },
});
