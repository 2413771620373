/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-types */
import { assign, createMachine, DoneInvokeEvent, MachineConfig, MachineOptions, TransitionsConfig } from 'xstate';
import { ERRORS } from '../constants';
import { compareMarkets, fetchAccountBalance, fetchMarketBalances } from '../helpers';
import { errors } from '../services/errors';
import { Balance, Balances, Market, TokenType } from '../types';
import { EmptyContext } from './types';

export interface WalletContext {
    account?: string;
    balance?: Balance;
    market?: Market;
    balances?: Balances;
    selected?: TokenType;
    error?: string;
}

export interface WalletSchema {
    states: {
        initial: {};
        fetching: {};
        success: {};
        error: {};
    };
}

export type WalletState =
    {
        value: 'initial';
        context: EmptyContext<WalletContext>;
    }
    | {
        value: 'fetching';
        context: WalletContext & {
            account: string;
            market: Market;
        };
    }
    | {
        value: 'success';
        context: WalletContext & {
            account: string;
            balance: Balance;
            market: Market;
            balances: Balances;
            selected: TokenType;
            error: undefined;
        };
    }
    | {
        value: 'error';
        context: WalletContext & {
            balance: undefined;
            balances: undefined;
            selected: undefined;
            error: string;
        };
    };

export type WalletInitEvent = { type: 'WALLET.INIT'; payload: { account: string; market: Market; }; };
export type WalletFetchEvent = { type: 'WALLET.FETCH'; };
export type WalletSelectEvent = { type: 'WALLET.SELECT'; payload: TokenType; };

export type WalletEvent =
    WalletInitEvent
    | WalletFetchEvent
    | WalletSelectEvent;

const init: TransitionsConfig<WalletContext, WalletEvent> = {
    'WALLET.INIT': {
        actions: 'INIT',
        target: 'fetching',
    },
};

const fetch: TransitionsConfig<WalletContext, WalletEvent> = {
    'WALLET.FETCH': {
        target: 'fetching',
    },
};

const initialContext: WalletContext = {};

const machineConfig: MachineConfig<WalletContext, WalletSchema, WalletEvent> = {
    context: initialContext,
    initial: 'initial',
    states: {
        initial: {
            on: {
                ...init,
            },
        },
        fetching: {
            invoke: {
                id: 'fetchBalances',
                src: 'fetchBalances',
                onDone: {
                    target: 'success',
                    actions: 'FETCH_BALANCES_SUCCESS',
                },
                onError: {
                    target: 'error',
                    actions: 'FETCH_BALANCES_FAILURE',
                },
            },
        },
        success: {
            on: {
                ...init,
                ...fetch,
                'WALLET.SELECT': {
                    actions: 'SELECT_TOKEN',
                },
            },
        },
        error: {
            on: {
                ...init,
                ...fetch,
            },
        },
    },
};

interface WalletFetchResult {
    balance: Balance;
    balances: Balances;
}

const machineOptions: Partial<MachineOptions<WalletContext, WalletEvent>> = {
    services: {
        fetchBalances: async (context): Promise<WalletFetchResult> => {

            try {

                const account = context.account!;
                const market = context.market!;

                const [balance, balances] = await Promise.all([
                    fetchAccountBalance(),
                    fetchMarketBalances(account, market),
                ]);

                return {
                    balance,
                    balances,
                };

            } catch (error) {

                throw errors.process(error, ERRORS.STATE.WALLET.FETCH);
            }
        },
    },
    actions: {
        INIT: assign((context, event) => {

            const { account, market } = (event as WalletInitEvent).payload;

            // only reset the selected token if we have a new account or new market
            const selected = !context.market || (account !== context.account) || !compareMarkets(market, context.market)
                ? 'underlying'
                : context.selected;

            return {
                ...context,
                account,
                market,
                selected,
                balances: undefined,
                error: undefined,
            };
        }),
        FETCH_BALANCES_SUCCESS: assign((context, event) => ({
            ...context,
            ...(event as DoneInvokeEvent<WalletFetchResult>).data,
            error: undefined,
        })),
        FETCH_BALANCES_FAILURE: assign((context, event) => ({
            ...context,
            balance: undefined,
            balances: undefined,
            selected: undefined,
            error: (event as DoneInvokeEvent<Error>).data.message,
        })),
        SELECT_TOKEN: assign((context, event) => ({
            ...context,
            selected: (event as WalletSelectEvent).payload,
        })),
    },
};

export const machine = createMachine<WalletContext, WalletEvent, WalletState>(machineConfig, machineOptions);
