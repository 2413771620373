/* eslint-disable import/no-duplicates */
import { html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ENV } from '../../env/environment';
import { locked, paused } from '../../helpers';
import { COOKIE_POLICY_ROUTE_ID, HOME_ROUTE_ID, POSITIONS_ROUTE, POSITIONS_ROUTE_ID, PRIVACY_POLICY_ROUTE_ID, PROFESSIONAL_ROUTE_ID, ROUTES, STAKING_ROUTE_ID } from '../../routes';
import { RouteMatch, router, RouterService } from '../../services';
import { Banner, banners } from '../../shared/components';
import '@swivel-finance/ui/elements/checkbox/checkbox';
import '@swivel-finance/ui/elements/dialog/dialog';
import '@swivel-finance/ui/elements/icon/icon';
import '@swivel-finance/ui/elements/listitem/listitem';
import '@swivel-finance/ui/elements/listbox/listbox';
import '@swivel-finance/ui/elements/collapsible/collapsible';
import '@swivel-finance/ui/elements/collapsible/accordion';
import '@swivel-finance/ui/elements/popup/popup';
import '@swivel-finance/ui/elements/select/select';
import '@swivel-finance/ui/elements/toggle/toggle';
import '@swivel-finance/ui/elements/tooltip/tooltip';
import '@swivel-finance/ui/elements/panel-container/panel-container';
import '@swivel-finance/ui/elements/wizard/wizard';
import '../../shared/components/banner/banner';
import '../../shared/components/banner/banner-outlet';
import '../../shared/components/notification/notification';
import '../../shared/components/notification/notification-outlet';
import { services } from '../../state';
import '../account/network-selector';
import '../account/version-selector';
import '../balances/balance';
import '../balances/balance-selector';
import '../lend/lend';
import '../stake/stake';
import '../markets/market-grid';
import '../markets/market-selector';
import '../orders/create';
import '../orders/order-chart';
import '../orders/orderbook';
import '../orders/outsized-dialog';
import '../orders/user-orders';
import '../policies/cookie-policy';
import '../policies/privacy-policy';
import '../positions/positions';
import '../rewards/rewards';
import './app-menu';

type MarketState = typeof services.market.state;

const lockedTemplate = function (this: SwivelApp) {

    return html`<p>
        The selected market is about to mature and trading has been <a href="${ ENV.docsUrl }swivel-exchange/maturity" target="_blank">halted</a>.
        Check your positions and redeem your tokens upon maturity.</p>
        <button @click=${ () => this.goToPositions() }>Positions</button>`;
};

const pausedTemplate = function (this: SwivelApp) {

    return html`<p>
        This market is currently paused! Check in on our <a href="${ ENV.discordUrl }" target="_blank">discord</a> for more information.</p>`;
};

const simplifiedTemplate = function (this: SwivelApp) {

    return html`
    <main class="simplified">
        <div class="column simplified">
            <sw-lend></sw-lend>
        </div>
    </main>
    `;
};

const professionalTemplate = function (this: SwivelApp) {

    return html`
    <main class="professional">
        <div class="column settings">
            <sw-market-selector></sw-market-selector>
            <sw-balance-selector></sw-balance-selector>
            <sw-create-order></sw-create-order>
        </div>
        <div class="column orderbook">
            <sw-orderbook></sw-orderbook>
        </div>
        <div class="column details">
            <sw-order-chart></sw-order-chart>
            <sw-user-orders></sw-user-orders>
        </div>
    </main>
    `;
};

const positionsTemplate = function (this: SwivelApp) {

    return html`
    <main class="positions">
        <div class="column positions">
            <sw-positions></sw-positions>
        </div>
    </main>
    `;
};

const stakingTemplate = function (this: SwivelApp) {

    return html`
    <main class="staking">
        <div class="column staking">
            <sw-stake></sw-stake>
        </div>
    </main>
    `;
};

const cookiesTemplate = function (this: SwivelApp) {

    return html`
    <main class="policy">
        <div class="column">
            <sw-cookie-policy></sw-cookie-policy>
        </div>
    </main>
    `;
};

const privacyTemplate = function (this: SwivelApp) {

    return html`
    <main class="policy">
        <div class="column">
            <sw-privacy-policy></sw-privacy-policy>
        </div>
    </main>
    `;
};

const template = function (this: SwivelApp) {

    let routeTemplate: (this: SwivelApp) => TemplateResult;

    switch (this.activeRoute?.route.id) {

        case PROFESSIONAL_ROUTE_ID:
            routeTemplate = professionalTemplate;
            break;
        case POSITIONS_ROUTE_ID:
            routeTemplate = positionsTemplate;
            break;
        case STAKING_ROUTE_ID:
            routeTemplate = stakingTemplate;
            break;
        case COOKIE_POLICY_ROUTE_ID:
            routeTemplate = cookiesTemplate;
            break;
        case PRIVACY_POLICY_ROUTE_ID:
            routeTemplate = privacyTemplate;
            break;
        default:
            routeTemplate = simplifiedTemplate;
            break;
    }

    return [
        html`
        <sw-banner-outlet></sw-banner-outlet>
        <header>
            <nav>
                <h1><a href="/" data-route="${ HOME_ROUTE_ID }"><ui-icon name="swivel-circle"></ui-icon>swivel</a></h1>
                <sw-version-selector></sw-version-selector>
                <a href="/professional" data-route="${ PROFESSIONAL_ROUTE_ID }">Professional</a>
                <a href="/positions" data-route="${ POSITIONS_ROUTE_ID }">Positions</a>
                <a href="/staking" data-route="${ STAKING_ROUTE_ID }">Staking</a>
            </nav>
            <a class="discord" href="${ ENV.discordUrl }" target="_blank" rel="noopener">
                <ui-icon name="discord"></ui-icon><span>Discord</span>
            </a>
            <div class="account">
                <sw-rewards></sw-rewards>
                <sw-balance type="underlying"></sw-balance>
                <sw-balance></sw-balance>
                <sw-network-selector></sw-network-selector>
            </div>
            <sw-app-menu></sw-app-menu>
        </header>
        <sw-notification-outlet></sw-notification-outlet>
        `,
        routeTemplate.call(this),
    ];
};

@customElement('sw-app')
export class SwivelApp extends LitElement {

    protected services = services;

    protected router!: RouterService;

    protected routes = ROUTES;

    protected activeRoute?: RouteMatch;

    protected marketBanner?: string;

    constructor () {

        super();

        this.handleMarketTransition = this.handleMarketTransition.bind(this);
        this.handleRouteChange = this.handleRouteChange.bind(this);

        this.router = router({
            routes: this.routes,
        });
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.router.subscribe(this.handleRouteChange);

        this.router.start();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.services.market.onTransition(this.handleMarketTransition);

        this.services.start();
    }

    disconnectedCallback () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.router.unsubscribe(this.handleRouteChange);

        this.router.stop();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.services.market.off(this.handleMarketTransition);

        this.services.stop();

        super.disconnectedCallback();
    }

    protected createRenderRoot () {

        // opt out of shadow DOM for the root component
        return this;
    }

    protected render () {

        return template.apply(this);
    }

    protected handleMarketTransition (state: MarketState) {

        if (state.matches('completing') || state.matches('success')) {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const market = state.context.markets.get(state.context.selected)!;

            const isLocked = locked(market);
            const isPaused = paused(market);

            if ((isLocked || isPaused) && this.activeRoute?.route.id !== POSITIONS_ROUTE.id) {

                const banner: Banner = {
                    type: 'info',
                    content: () => isLocked
                        ? lockedTemplate.call(this)
                        : pausedTemplate.call(this),
                };

                if (this.marketBanner) {

                    banners.update(this.marketBanner, banner);

                } else {

                    this.marketBanner = banners.show(banner);
                }

            } else {

                if (this.marketBanner) banners.dismiss(this.marketBanner);

                this.marketBanner = undefined;
            }
        }
    }

    protected handleRouteChange (m: RouteMatch) {

        this.activeRoute = m;

        this.requestUpdate();

        void this.updateComplete.then(() => {

            // we don't show the locked market banner on the positions page
            // when going back to home or professional, the MARKET.FETCH event will cause a re-check
            if (m.route.id === POSITIONS_ROUTE.id) {

                if (this.marketBanner) banners.dismiss(this.marketBanner);

                this.marketBanner = undefined;
            }
        });
    }

    protected goToPositions () {

        void router().navigate('/positions?sortBy=maturity');
    }
}
