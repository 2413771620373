import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { html, LitElement, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { VERSION } from '../../constants';
import { ENV } from '../../env/environment';
import { logger as logService } from '../../services';
import { Version } from '../../types';

// create a logger for the version selector
const logger = logService.group('version selector');

// and enable it on production builds as well
logger.disabled = false;

/**
 * The current versions of this deploy
 *
 * @remarks
 * The version of the deploy is inferred from the codebase's version.
 * If the codebase's version can't be found in the `versions` array,
 * we create a separate entry to help surface the misconfiguration
 * to the UI.
 */
const CURRENT_VERSION = ENV.versions.find(version => version.id === VERSION) ?? {
    id: VERSION,
    label: 'v?',
    url: document.location.origin,
};

/**
 * The available (deployed) versions of the swivel exchange
 *
 * @remarks
 * Available versions are set through the deploy environment, so we don't have to
 * change code after we:
 * - change the deploy url of a particular version
 * - remove a version from the list of deployed versions
 */
const VERSIONS = ENV.versions.includes(CURRENT_VERSION)
    ? ENV.versions
    : ((): Version[] => {
        // log an error if the current version is not configured in the VERSIONS environment variable
        logger.error(`VERSIONS configuration error: The codebase version '${ VERSION }' is not configured in VERSIONS '${ JSON.stringify(ENV.versions) }'`);
        // add the current version to the versions list
        return ENV.versions.concat(CURRENT_VERSION);
    })();

const template = function (this: VersionSelectorElement) {

    return VERSIONS.length > 1
        ? html`
        <ui-select
            .placeholder=${ '' }
            .overlayClasses=${ ['version-selector-overlay'] }
            @ui-value-changed=${ (event: ValueChangeEvent<Version>) => this.handleValueChange(event) }>
            <ui-listbox data-part="overlay" aria-label="Select Swivel Protocol Version">
                ${ VERSIONS.map(version => html`<ui-listitem .value=${ version } aria-selected="${ this.version === version }">${ version.label }</ui-listitem>`) }
            </ui-listbox>
        </ui-select>`
        : nothing;
};

@customElement('sw-version-selector')
export class VersionSelectorElement extends LitElement {

    protected version: Version = CURRENT_VERSION;

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleValueChange (event: ValueChangeEvent<Version>): void {

        this.version = event.detail.current;

        window.location.href = this.version.url;
    }
}
