import { html, TemplateResult } from 'lit';
import { ENV } from '../env/environment';
import { ETHEREUM_GOERLI } from './networks';

export interface TourStop {
    content: () => TemplateResult;
    selector: string;
    arrow: boolean;
    position: string | undefined;
}

// disabled for now, as the simplified page is supposed to be simple enough to not need a tour
// export const SIMPLIFIED: TourStop[] = [
//     {
//         content: () => html`<p>Welcome to Swivel Finance! Let's take a quick tour of the best yield swap Defi platform out there. You'll be trading in no time!</p>`,
//         selector: 'main.simplified',
//         arrow: false,
//         position: undefined,
//     },
//     {
//         content: () => html`<p>Swivel allows you to trade future yield minted from the best lending markets in Defi. Click here to see what tokens you can lend, and when each market matures.</p>`,
//         selector: 'sw-market-popup',
//         arrow: true,
//         position: 'below',
//     },
//     {
//         content: () => html`<p>A <em>Fixed Yield</em> order allows you to earn immediate interest on your underlying asset. While <em>Amplified Yield</em> allows you to purchase Yield Tokens, which generate yield until the market's maturity.</p>`,
//         selector: 'sw-create-order > div',
//         arrow: true,
//         position: 'below',
//     },
//     {
//         content: () => html`<p>Just enter the amount you want to lend, see the rate you'll receive/pay, and place your order! Be sure to check <em>tooltips<ui-icon name="question"></ui-icon></em> for more helpful info.</p>`,
//         selector: 'sw-create-order .order-details',
//         arrow: true,
//         position: 'above',
//     },
// ];

export const PROFESSIONAL: TourStop[] = [
    {
        content: () => html`<p>The Professional page gives a bit more insight into our exchange! Use the Balances selector to choose the token you want to trade. You can lend/trade underlying, or once you have some Yield Tokens or Principal Tokens, sell them back to the market as market or limit orders. </p>`,
        selector: 'sw-wallet-popup > button',
        arrow: false,
        position: undefined,
    },
    {
        content: () => html`<p>Swivel's orderbook sits off chain to minimize gas fees and optimize LP user experience. Check here for the latest bids and offers on various tokens.</p>`,
        selector: 'sw-orderbook > h2',
        arrow: true,
        position: 'below',
    },
    {
        content: () => html`<p>The Orders tab shows your limit order activity. The Trades tab details the transactions that filled your orders, as well as market orders. Limit orders expire after 2 weeks.</p>`,
        selector: 'sw-user-orders > div.title [role=tablist] button:first-child',
        arrow: true,
        position: 'above',
    },
    {
        content: () => html`<p>Once you are ready, approve Swivel to lend/trade your tokens and place your order!<br><br>For some practice, visit our testnet at <a href="${ ETHEREUM_GOERLI.url }" target="_blank">${ ETHEREUM_GOERLI.url }</a>. Keep an eye out for helpful <em>tooltips<ui-icon name="question"></ui-icon></em>, and don't hesitate to check out our <a href="${ ENV.docsUrl }" target="_blank">docs</a> and passionate <a href="${ ENV.discordUrl }" target="_blank">Discord</a> community for assistance. Happy trading!</p>`,
        selector: 'sw-create-order ul.order-details',
        arrow: true,
        position: 'above',
    },
];

export const POSITIONS: TourStop[] = [
    {
        content: () => html`<p>You've got some open positions. Excellent! On this page you can easily view and manage your assets. Check out some of the ways Swivel makes it easy to adjust your positions . . .</p>`,
        selector: 'sw-positions > .sidebar',
        arrow: false,
        position: undefined,
    },
    {
        content: () => html`<p>For any active Markets, you're just one click away from our exchange where you can trade/lend your assets.</p>`,
        selector: '.list > .entry .group.market',
        arrow: true,
        position: 'below',
    },
    {
        content: () => html`<p>By "Splitting" your tokens, you can quickly take any amount of your available underlying asset and mint equal parts Yield Tokens and Principal Tokens for trading or holding.</p>`,
        selector: '.list > .entry .group.underlying',
        arrow: true,
        position: 'below',
    },
    {
        content: () => html`<p>If a market has not reached maturity but you would like to exit your position, you're also just one click away from combining your available Yield Tokens and Principal Tokens to redeem your underlying principal early.</p>`,
        selector: '.list > .entry .group.tokens',
        arrow: true,
        position: 'below',
    },
    {
        content: () => html`<p>Your Yield Tokens generate you interest that you can redeem at any time!<br>Whether now, or after maturity, you can “Redeem” whatever interest you've generated. Noice.</p>`,
        selector: '.list > .entry .group.redeemable',
        arrow: true,
        position: 'below',
    },
];

export const TOURS = {
    PROFESSIONAL,
    POSITIONS,
};
