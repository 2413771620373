import { Protocols } from '@swivel-finance/swivel-js';
import { Protocol } from '../types';

export const COMPOUND_PROTOCOL: Protocol = {
    type: Protocols.Compound,
    name: 'Compound',
    symbol: 'COMP',
    image: 'compound',
};

export const RARI_PROTOCOL: Protocol = {
    type: Protocols.Rari,
    name: 'Rari',
    symbol: 'RARI',
    image: 'rari',
};

export const AAVE_PROTOCOL: Protocol = {
    type: Protocols.Aave,
    name: 'Aave',
    symbol: 'AAVE',
    image: 'aave',
};

export const EULER_PROTOCOL: Protocol = {
    type: Protocols.Euler,
    name: 'Euler',
    symbol: 'EUL',
    image: 'euler',
};

export const YEARN_PROTOCOL: Protocol = {
    type: Protocols.Yearn,
    name: 'Yearn',
    symbol: 'YFI',
    image: 'yearn',
};

export const LIDO_PROTOCOL: Protocol = {
    type: Protocols.Lido,
    name: 'Lido',
    symbol: 'LDO',
    image: 'lido',
};

export const FRAX_PROTOCOL: Protocol = {
    type: Protocols.Frax,
    name: 'Frax',
    symbol: 'FRX',
    image: 'frax',
};

export const PROTOCOL_MAP = {
    [Protocols.Aave]: AAVE_PROTOCOL,
    [Protocols.Compound]: COMPOUND_PROTOCOL,
    [Protocols.Frax]: FRAX_PROTOCOL,
    [Protocols.Euler]: EULER_PROTOCOL,
    [Protocols.Lido]: LIDO_PROTOCOL,
    [Protocols.Rari]: RARI_PROTOCOL,
    [Protocols.Yearn]: YEARN_PROTOCOL,
};
