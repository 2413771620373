import {
    BugsnagConsentConfig,
    BugsnagService,
    ConsentBanner,
    ConsentCategory,
    ConsentElement,
    ConsentGroup,
    ConsentService,
    ConsentStatus,
    ConsentTopics,
    GoogleAnalyticsConsentConfig,
    GoogleAnalyticsService,
    StorageConsentConfig,
    Topics,
} from '@swivel-finance/consent';
import '@swivel-finance/consent/dist/consent/components/consent-banner';
import '@swivel-finance/consent/dist/consent/components/consent-element';
import { COOKIE_POLICY_URL, PRIVACY_POLICY_URL } from '../constants';
import { ENV } from '../env/environment';

/**
 * The actual consent topics used by swivel (we don't use fullstory any longer)
 */
export const SWIVEL_CONSENT_TOPICS = [
    'storage',
    'google_analytics',
    'bugsnag',
] as const;

export type SwivelConsentTopics = Topics<typeof SWIVEL_CONSENT_TOPICS>;

let consentBanner: ConsentBanner<SwivelConsentTopics> | undefined;
let consentElement: ConsentElement<SwivelConsentTopics> | undefined;

// create consent groups for the required services
const groups: ConsentGroup<SwivelConsentTopics>[] = [
    {
        category: ConsentCategory.PREFERENCES,
        description: 'Allow us to store your preferences on your device to improve your experience. Stored preferences include your cookie consent choices, your preferred user interface theme, your last selected market, etc.',
        configs: [
            StorageConsentConfig,
        ],
    },
    {
        category: ConsentCategory.STATISTICS,
        description: 'Allow us to collect statistics data to improve our service. The collected information helps us understand how visitors interact with our service, and whether there may be technical issues.',
        configs: [
            BugsnagConsentConfig,
            GoogleAnalyticsConsentConfig,
        ],
    },
];

// create consent service from groups
const consent = new ConsentService(SWIVEL_CONSENT_TOPICS, groups);

// create service instances and connect them to the consent service instance
const bugsnag = new BugsnagService(consent as ConsentService<ConsentTopics>, ENV.bugsnag);
const analytics = new GoogleAnalyticsService(consent as ConsentService<ConsentTopics>, ENV.analytics);



const createBackdrop = () => {

    const backdrop = document.createElement('div');

    backdrop.classList.add('backdrop');

    return backdrop;
};

// show the consent banner
export const showConsentBanner = () => {

    consentBanner = document.createElement('sw-consent-banner') as ConsentBanner<SwivelConsentTopics>;

    consentBanner.consentService = consent;
    consentBanner.cookiePolicyUrl = COOKIE_POLICY_URL;

    document.body.append(consentBanner);

    consentBanner.addEventListener('consent-settings', showConsentElement, { once: true });
};

// show the consent manager
export const showConsentElement = () => {

    if (consentElement) return;

    // if there's a consent banner on the page, dismiss it when opening the consent manager
    if (consentBanner) consentBanner.dismiss();

    const backdrop = createBackdrop();

    document.body.append(backdrop);

    consentElement = document.createElement('sw-consent') as ConsentElement<SwivelConsentTopics>;

    consentElement.consentService = consent;
    consentElement.cookiePolicyUrl = COOKIE_POLICY_URL;
    consentElement.privacyPolicyUrl = PRIVACY_POLICY_URL;

    document.body.append(consentElement);

    // hide the consent element when any choice was made
    consentElement.addEventListener('consent-changed', () => {

        consentElement?.remove();
        backdrop.remove();
        consentElement = undefined;

    }, { once: true });
};

// check if consent was processed
export const checkConsent = () => {

    const consentProcessed = consent.read('storage') !== ConsentStatus.UNSET;

    // if no consent has been processed/stored, show the consent element
    if (!consentProcessed) {

        showConsentBanner();
    }
};

export { consent, bugsnag, analytics };
