/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Protocols } from '@swivel-finance/swivel-js';
import { ERRORS, MAX_FILL_PERIOD, SECONDS_PER_DAY } from '../constants';
import { ENV } from '../env/environment';
import { contractAmount } from '../helpers/amount';
import { ChartData, Fill, FillPreview, Market, Order, Orderbook, OrderStatus, OrderWithMeta, SwivelConfig, UserFills, UserOrders } from '../types';
import { errors, ProcessedError } from './errors';
import { get, post } from './http';

/**
 * The maximum amount of orders that can be fetched per request.
 *
 * @remarks
 * This number is derived from the limitiation of the url length on AWS.
 * Requests with a url longer than 2048 bytes are blocked. A single order
 * key is 66 characters long, plus separator, host, path and query param length.
 */
const MAX_ORDERS_PER_FETCH = 25;

/**
 * A map of order statuses to include in user order requests.
 */
const ORDER_STATUSES: Partial<Record<OrderStatus, string>> = {
    CANCELLED: 'cancelled',
    EXPIRED: 'expired',
    FULL: 'full',
    INSOLVENT: 'insolvent',
    INSOLVENT_TEMPORARY: 'insolvent_temporary',
};

const ORDER_STATUS_PARAMS = Object.values(ORDER_STATUSES);

export type MarketStatus = 'active' | 'matured' | 'archived' | 'preview';

export interface MarketResponse {
    protocol: Protocols;
    underlying: string;
    maturity: string;
}

export type ChartDataResponse = {
    data: {
        close: string;
        high: string;
        low: string;
        open: string;
        volume: string;
    };
    timestamp: number;
}[];

export interface VolumeResponse {
    period: {
        start: number;
        end: number;
    };
    totalAmount: string;
    users: Record<string, string>;
}

export const swivelApi = {

    /**
     * Fetches the token config for each market.
     *
     * @returns an object containing the configs for each token
     */
    fetchConfig (): Promise<SwivelConfig> {

        const endpoint = `${ ENV.apiUrl }/configs`;

        return get<SwivelConfig>(endpoint);
    },

    /**
     * Fetches available markets.
     *
     * @returns an array of `MarketResponse`s
     */
    fetchMarkets (status?: MarketStatus): Promise<MarketResponse[]> {

        const endpoint = `${ ENV.apiUrl }/markets`;
        const payload = status
            ? { status }
            : undefined;

        return get<MarketResponse[]>(endpoint, payload);
    },

    /**
     * Fetch orders by key.
     *
     * @param k - an array of order keys
     * @returns an array of orders with metadata
     */
    async fetchOrders (k: string[]): Promise<OrderWithMeta[]> {

        const parts = Math.ceil(k.length / MAX_ORDERS_PER_FETCH);
        const batches: string[][] = [];

        // split the array of order keys into batches with 25 keys per batch max
        for (let i = 0; i < parts; i++) {

            const start = i * MAX_ORDERS_PER_FETCH;
            const end = start + MAX_ORDERS_PER_FETCH;

            batches.push(k.slice(start, end));
        }

        const endpoint = `${ ENV.apiUrl }/orders`;

        // create a request for each batch of order keys
        const batchRequests = batches.map(batch => get<{ orders: OrderWithMeta[]; }>(endpoint, { keys: batch.join(',') }));

        // request the batches in parallel
        const batchResults = await Promise.all(batchRequests);

        // combine the batched results into a sinlgle array of `OrderWithMeta`
        const result = batchResults.reduce((prev, curr) => {
            return prev.concat(curr.orders);
        }, [] as OrderWithMeta[]);

        return result;
    },

    /**
     * Fetches a market's orderbook.
     *
     * @param p - the market's protocol
     * @param u - the market's underlying
     * @param m - the market's maturity
     * @returns the market's `OrderbookResponse`
     */
    fetchOrderbook (p: Protocols, u: string, m: string): Promise<Orderbook> {

        const endpoint = `${ ENV.apiUrl }/orderbook`;
        const payload = {
            protocol: p,
            underlying: u,
            maturity: m,
        };

        return get<Orderbook>(endpoint, payload);
    },

    /**
     * Fetches a market's trade chart data
     *
     * @param m - the market
     * @param i - the interval (resolution) of the chart data (in minutes)
     * @param s - the start of the sample data (in seconds)
     * @param e - the end of the sample data (in seconds)
     * @returns an array of {@link ChartData} entries
     */
    async fetchOrderChartData (m: Market, i = 5, s?: number, e?: number): Promise<ChartData[]> {

        const end = orderChartEnd(e);
        const start = orderChartStart(i, end);

        const endpoint = `${ ENV.apiUrl }/samples`;
        const payload = {
            protocol: m.protocol,
            underlying: m.underlying,
            maturity: m.maturity,
            interval: i,
            start,
            end,
            type: 'OHLCV',
        };

        const result = await get<ChartDataResponse>(endpoint, payload);

        return parseOrderChartData(result, m);
    },

    /**
     * Fetches a user's (active) orders for a given market
     *
     * @param a - the user account address
     * @param p - the market's protocol
     * @param u - the market's underlying
     * @param m - the market's maturity
     */
    async fetchUserOrders (a: string, p: Protocols, u: string, m: string): Promise<UserOrders> {

        const endpoint = `${ ENV.apiUrl }/users/${ a }/orders`;
        const payload = {
            protocol: p,
            underlying: u,
            maturity: m,
            statuses: ORDER_STATUS_PARAMS.join(','),
        };

        return get<UserOrders>(endpoint, payload);
    },

    /**
     * Fetches the last trade for a given market
     *
     * @param p - the market's protocol
     * @param u - the market's underlying
     * @param m - the market's maturity
     */
    async fetchLastTrade (p: Protocols, u: string, m: string): Promise<Fill | undefined> {

        const endpoint = `${ ENV.apiUrl }/fills`;
        const payload = {
            protocol: p,
            underlying: u,
            maturity: m,
            depth: 1,
        };

        const trades = await get<Fill[]>(endpoint, payload);

        return trades[0];
    },

    /**
     * Fetches a user's fills for a given market in a given period
     *
     * @param a - the user account address
     * @param p - the market's protocol
     * @param u - the market's underlying
     * @param m - the market's maturity
     * @param e - the end of the period in UTC seconds, default: now
     * @param s - the start of the period in UTC seconds, default: 7 days before `end`
     */
    async fetchUserFills (a: string, p: Protocols, u: string, m: string, e?: number, s?: number): Promise<UserFills> {

        const now = Math.floor(Date.now() / 1000);
        const end = e ?? now;
        // ensure start of period is not earlier than `MAX_FILL_PERIOD` before end
        const start = Math.max(s ?? (end - MAX_FILL_PERIOD), end - MAX_FILL_PERIOD);


        const endpoint = `${ ENV.apiUrl }/users/${ a }/fills`;
        const payload = {
            protocol: p,
            underlying: u,
            maturity: m,
            start,
            end,
        };

        const fills = await get<UserFills>(endpoint, payload);

        const orders = await this.fetchOrders(Object.keys(fills));

        orders.forEach(order => {
            // attach the order and metadata to the fill result
            fills[order.order.key].order = order.order;
            fills[order.order.key].meta = order.meta;
            // fill back the order key into each fill for easier referencing
            fills[order.order.key].exits.forEach(fill => fill.key = order.order.key);
            fills[order.order.key].initiates.forEach(fill => fill.key = order.order.key);
        });

        return fills;
    },

    /**
     * Fetches the trade volume for a market in the given period
     *
     * @param p - the market's protocol
     * @param u - the market's underlying
     * @param m - the market's maturity
     * @param e - the end of the period in UTC seconds, default: now
     * @param s - the start of the period in UTC seconds, default: 28 days before `end`
     */
    fetchTradeVolume (p: Protocols, u: string, m: string, e?: number, s?: number): Promise<VolumeResponse> {

        const now = Math.floor(Date.now() / 1000);
        const end = e ?? now;
        const start = s ?? end - 30 * SECONDS_PER_DAY;

        const endpoint = `${ ENV.apiUrl }/volumes`;
        const payload = {
            protocol: p,
            underlying: u,
            maturity: m,
            start,
            end,
            users: true,
        };

        return get<VolumeResponse>(endpoint, payload);
    },

    /**
     * Submits a limit order to the Swivel API.
     *
     * @param o - the order
     * @param s - the order signature
     */
    async submitOrder (o: Order, s: string): Promise<void> {

        const endpoint = `${ ENV.apiUrl }/orders`;
        const payload = {
            order: o,
            signature: s,
        };

        try {

            await post<undefined>(endpoint, payload);

        } catch (error) {

            if (errors.is(error, ERRORS.HTTP.BAD_REQUEST)) {

                const response = (error as ProcessedError).source as Response;
                const result = await response.json() as { error: { message: string; }; };
                const message = result.error.message;

                if (message === 'invalid order principal') {

                    throw errors.process(error, ERRORS.SERVICES.SWIVEL.LIMIT_ORDER_PRINCIPAL);
                }
            }

            throw error;
        }
    },

    /**
     * Fetches a fill preview for a market order.
     *
     * @param p - the market's protocol
     * @param u - the order's underlying
     * @param m - the order's maturity
     * @param a - the order's amount / volume
     * @param v - the order's vault flag
     * @param e - the order's exit flag
     * @returns the order's fill preview
     */
    async fillPreview (p: Protocols, u: string, m: string, a: string, v: boolean, e: boolean): Promise<FillPreview> {

        const endpoint = `${ ENV.apiUrl }/fillpreview`;
        const payload = {
            protocol: p,
            underlying: u,
            maturity: m,
            volume: a,
            vault: v,
            exit: e,
        };

        try {

            return await get(endpoint, payload);

        } catch (error) {

            if (errors.is(error, ERRORS.HTTP.BAD_REQUEST)) {

                const response = (error as ProcessedError).source as Response;
                const result = await response.json() as { error: { message: string; }; };
                const message = result.error.message;

                switch (message) {
                    case 'passed volume exceeds available':
                        throw errors.process(error, ERRORS.SERVICES.SWIVEL.FILL_PREVIEW_EXCEEDED);
                    case 'passed volume exceeds the maximum number of orders allowed':
                        throw errors.process(error, ERRORS.SERVICES.SWIVEL.FILL_PREVIEW_ORDERS_EXCEEDED);
                    default:
                        throw error;
                }
            }

            throw error;
        }
    },
};

export default swivelApi;

const ORDER_CHART_SAMPLES = 800;

/**
 * Returns the end time for OHLCV samples
 *
 * @param e - the end of the sample data (in seconds), default: current time
 */
const orderChartEnd = (e?: number): number => {

    return Math.floor(e ?? Date.now() / 1000);
};

/**
 * Calculates a start time for OHLCV samples based on interval length and sample points
 *
 * @param i - the interval (in minutes), default: 5 minutes
 * @param e - the end of the sample data (in seconds), default: current time
 */
const orderChartStart = (i = 5, e?: number): number => {

    const end = e ?? orderChartEnd();

    // the end time minus the interval in seconds times the amount of samples
    return Math.floor(end - (i * 60 * ORDER_CHART_SAMPLES));
};

/**
 * Convert a {@link ChartDataResponse} to a {@link ChartData} array
 *
 * @param d - the chart data response
 * @param m - the market (needed for the undelying token decimals)
 */
const parseOrderChartData = (d: ChartDataResponse, m: Market): ChartData[] => {

    const decimals = m.tokens.underlying.decimals;
    const threshold = 0.5;

    const [averageHigh, averageLow] = d.reduce(
        ([high, low], item) => ([high + parseFloat(item.data.high), low + parseFloat(item.data.low)]),
        [0, 0],
    ).map(sum => sum / d.length);

    const result: ChartData[] = [];

    d.forEach((item, index) => {

        // these are prices, so we can just parse them to floats
        let open = parseFloat(item.data.open);
        let close = parseFloat(item.data.close);
        let high = parseFloat(item.data.high);
        let low = parseFloat(item.data.low);

        if (open > averageHigh + threshold || open < averageLow - threshold) {

            open = result[index - 1].close ?? 0;
        }

        if (close > averageHigh + threshold || close < averageLow - threshold) {

            close = open;
        }

        if (high > averageHigh + threshold || high < averageLow - threshold) {

            high = open;
        }

        if (low > averageHigh + threshold || low < averageLow - threshold) {

            low = open;
        }

        // volume is gonna be a 'wei' amount, we convert it to base token denomination
        const volume = parseFloat(contractAmount(item.data.volume, decimals));

        // with volume in base denomination, we can calculate the turnover
        const turnover = ((open + close + high + low) / 4) * volume;

        result.push({
            open,
            high,
            low,
            close,
            volume,
            turnover,
            timestamp: item.timestamp * 1000,
        });
    });

    return result;
};
